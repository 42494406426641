import { computed, onUnmounted, ref, watch } from 'vue';
import graphQlClient from '../../libs/graphql-client';
import _ from 'lodash';

let instance = window.edwPlugInstance;

if (!instance) {
  instance = ref({
    dictionaries: {},
    dictionariesProms: {},
    languagesListProm: undefined,
    languagesList: [],
    missingLbls: {},
    langId: (navigator.language || navigator.userLanguage).substring(0, 2),
    debug: false,
    warn: true,
    isReady: Promise.resolve(true)
  });

  checkInstanceLangId();

  Object.defineProperty(window, 'edwPlugInstance', { value: instance });
}

function loadLanguagesList (force) {
  if (!force && instance.value.languagesListProm) { return instance.value.languagesListProm; }

  instance.value.languagesListProm = graphQlClient.query(`
    SysLanguage_get {
      id
      name
    }
  `, undefined, { group: 'dictionary' }).then((languagesList) => {
    instance.value.languagesList = languagesList;
    return instance.value.languagesList;
  }).catch(() => {
    instance.value.languagesListProm = undefined;
  });

  return instance.value.languagesListProm;
}

function getSortedLanguagesList () {
  return instance.value.languagesList.sort((a, b) => {
    if (a.id === instance.value.langId) { return -1; }
    if (b.id === instance.value.langId) { return 1; }
    if (a.id === 'en') { return -1; }
    if (b.id === 'en') { return 1; }
    return 0;
  });
}

function checkInstanceLangId (force) {
  return loadLanguagesList(force).then(() => {
    if (!instance.value.languagesList.find((lang) => lang.id === instance.value.langId)) {
      instance.value.langId = 'en';
    }
  }).catch((err) => { console.error('Dictionary error', err); });
}

function loadDictionaries (ids, langs, force) {
  ids = Array.isArray(ids) ? ids : [ids];
  langs = Array.isArray(langs) ? langs : [langs || instance.value.langId];
  if (langs.length === 0) { langs.push(instance.value.langId); }

  const proms = [];
  const dictionariesToLoad = [];
  ids.forEach((dicId) => {
    if (!dicId) { console.error('[loadDictionaries] No dictionary to load'); return; }
    langs.forEach((langId) => {
      if (!langId) { console.error('[loadDictionaries] No dictionary to load'); return; }
      if (force || instance.value.dictionariesProms[langId]?.[dicId] == null) {
        dictionariesToLoad.push({ id: dicId, langId: langId });
      } else {
        proms.push(instance.value.dictionariesProms[langId][dicId]);
      }
    });
  });

  if (dictionariesToLoad.length) {
    const queryProm = graphQlClient.query(`
      dictionaries (dictionaries: $dictionaries) {
        id
        langId
        entries
      }
    `, {
      dictionaries: { type: '[DictionaryQueryInput!]!', value: dictionariesToLoad }
    }, undefined, { group: 'dictionary' }).then((resDictionaries) => {
      resDictionaries.forEach((dictionary) => {
        if (dictionary.langId && dictionary.id) {
          instance.value.dictionaries[dictionary.langId] ??= {};
          instance.value.dictionaries[dictionary.langId][dictionary.id] = dictionary.entries;
        }
      });
    }).catch(() => {
      dictionariesToLoad.forEach((dictionary) => {
        instance.value.dictionaries[dictionary.langId] ??= {};
        instance.value.dictionaries[dictionary.langId][dictionary.id] = undefined;
        instance.value.dictionariesProms[dictionary.langId] ??= {};
        instance.value.dictionariesProms[dictionary.langId][dictionary.id] = undefined;
      });
    });

    dictionariesToLoad.forEach((dictionary) => {
      instance.value.dictionariesProms[dictionary.langId] ??= {};
      instance.value.dictionariesProms[dictionary.langId][dictionary.id] = queryProm;
    });
    proms.push(queryProm);
  }

  return Promise.all(proms).then(() => undefined);
}

function saveDictionaries (dictionaries) {
  return graphQlClient.mutation(`
    saveDictionaries (dictionaries: $dictionaries) {
      id
      langId
      entries
    }
  `, {
    dictionaries: { type: '[DictionaryInput!]!', value: dictionaries }
  }, undefined, { group: 'dictionary' }).then((resDictionaries) => {
    resDictionaries.forEach((dictionary) => {
      if (dictionary.langId && dictionary.id) {
        if (!instance.value.dictionaries[dictionary.langId]) { instance.value.dictionaries[dictionary.langId] = {}; }
        instance.value.dictionaries[dictionary.langId][dictionary.id] = dictionary.entries;
      }
    });
  });
}

const CAPTURE_REGEX = /(?:\w+:)?\w+([.]\w+)*/i;
const COMPILE_REGEX = /{{((?:\w+:)?\w+([.]\w+)*)}}/gi;
const TRASFORMER_REGEX = /(?:(\w+):)(.*)/i;
const TRANSFORMATIONS = {
  u: function (string) {
    return string.toUpperCase();
  },
  l: function (string) {
    return string.toLowerCase();
  },
  t: function (string, dictionary) {
    if (dictionary == null) { return string; }
    return dictionary[string] || string;
  }
};

function replace (dictionary, info, match, capture) {
  if (!CAPTURE_REGEX.test(capture) || info == null) {
    return match;
  }

  let transform = capture.match(TRASFORMER_REGEX);
  if (transform) {
    capture = transform[2];
    transform = TRANSFORMATIONS[transform[1]];
  }

  let render = _.get(info, capture);
  if (typeof transform === 'function') {
    render = transform(render, dictionary, info, capture);
  }

  return render;
}

function compile (str, info, dictionary) {
  if (typeof str !== 'string') { return str; }
  return str.replace(COMPILE_REGEX, replace.bind(null, dictionary, info));
}

// ### DICTIONARY EDITOR ### //
let lastDicIdTs = 0;
function genDictionaryKey (key) {
  let dicIdTs = Date.now();
  if (dicIdTs === lastDicIdTs) { dicIdTs = lastDicIdTs + 1; }
  lastDicIdTs = dicIdTs;
  return `{{${dicIdTs}_${key}}}`;
}

function getDictionaryEditor (dictionary) {
  if (!dictionary) { console.error('[getDictionaryEditor] Cannot generate an editor without the dictionary'); return; }

  const dicKeysToSave = new Set();

  const language = ref(instance.value.langId);
  const custDictionaries = ref({});

  const reset = () => {
    dicKeysToSave.clear();
    const tmpCustDictionaries = {};
    instance.value.languagesList.forEach((lang) => {
      tmpCustDictionaries[lang.id] = instance.value.dictionaries[lang.id] ? _.clone(instance.value.dictionaries[lang.id][dictionary]) : {};
    });
    custDictionaries.value = tmpCustDictionaries;
  };
  const save = () => {
    if (dicKeysToSave.size === 0) { return Promise.resolve(); }
    const dictionariesToSave = [];
    for (const lang in custDictionaries.value) {
      const curDictionary = { id: dictionary, langId: lang, entries: {} };
      dicKeysToSave.forEach((dicKey) => {
        if (custDictionaries.value[lang][dicKey] != null) {
          curDictionary.entries[dicKey] = custDictionaries.value[lang][dicKey];
        }
      });
      dictionariesToSave.push(curDictionary);
    }
    return saveDictionaries(dictionariesToSave).then(() => { reset(); }).catch((err) => { console.error('Dictionary error', err); });
  };
  const setOnLanguage = (langId, prop, value) => {
    if (!langId || typeof prop !== 'string') { return; }
    const dicKey = prop.replace(/^{{?([\w.-]+)}}?$/, (_, key) => key);
    dicKeysToSave.add(dicKey);
    if (custDictionaries.value[langId]) {
      custDictionaries.value[langId][dicKey] = value;
    }
  };
  const getOnLanguage = (langId, prop) => {
    if (!langId || typeof prop !== 'string') { return; }
    const dicKey = prop.replace(/^{{?([\w.-]+)}}?$/, (_, key) => key);
    if (custDictionaries.value[langId]) {
      return custDictionaries.value[langId][dicKey];
    }
  };
  const setOnAllLanguages = (prop, value) => {
    if (typeof prop !== 'string') { return; }
    const dicKey = prop.replace(/^{{?([\w.-]+)}}?$/, (_, key) => key);
    dicKeysToSave.add(dicKey);
    instance.value.languagesList.map(lang => lang.id).forEach((langId) => {
      custDictionaries.value[langId][dicKey] = value;
    });
  };
  const translateObj = (obj, lang) => {
    if (!obj) { return; }
    const retObj = _.clone(obj);
    const curDic = custDictionaries.value[lang || language.value];
    for (const key in retObj) {
      if (typeof retObj[key] === 'string') {
        retObj[key] = retObj[key].replace(/^{{?([\w.-]+)}}?$/, (_, dicKey) => curDic[dicKey] || '');
      } else if (typeof retObj[key] === 'object') {
        retObj[key] = translateObj(retObj[key], lang);
      }
    }
    return retObj;
  };
  const genKey = (key) => genDictionaryKey(key);

  watch(language, () => { if (!custDictionaries.value[language.value]) { custDictionaries.value[language.value] = {}; } }, { immediate: true });

  loadLanguagesList()
    .then(() => loadDictionaries(dictionary, instance.value.languagesList.map(lang => lang.id)))
    .then(() => {
      instance.value.languagesList.forEach((lang) => {
        custDictionaries.value[lang.id] = {
          ...((instance.value.dictionaries[lang.id] && _.clone(instance.value.dictionaries[lang.id][dictionary])) || {}),
          ...custDictionaries.value[lang.id]
        };
      });
    })
    .catch((err) => { console.error('Dictionary error', err); });

  const dictionaryEditor = new Proxy({
    language: computed({
      get: () => { return language.value; },
      set: (newLang) => {
        if (!instance.value.languagesList.find((lang) => lang.id === newLang)) {
          language.value = 'en';
        } else {
          language.value = newLang;
        }
      }
    }),
    languagesList: computed(() => { return getSortedLanguagesList(); }),
    setLanguage (newLang) { this.language.value = newLang; },
    setOnLanguage,
    getOnLanguage,
    setOnAllLanguages,
    translateObj,
    genKey,
    reset,
    save,
    compile (str, info) { return compile(this[str], info, this); },
    __v_isRef: null
  }, {
    get: function (target, prop) {
      if (prop === 'null' || prop === 'undefined') { return true; }
      if (prop in target) { return target[prop]; }
      if (prop in custDictionaries.value) { return custDictionaries.value[prop]; }

      const dicKey = prop.replace(/^{{?([\w.-]+)}}?$/, (_, key) => key);
      if (custDictionaries.value?.[language.value]?.[dicKey] != null) {
        return custDictionaries.value[language.value][dicKey];
      }

      return '';
    },
    set: function (target, prop, value) {
      if (prop === 'null' || prop === 'undefined') { return true; }
      if (prop in target) { target[prop] = value; return true; }
      const dicKey = prop.replace(/^{{?([\w.-]+)}}?$/, (_, key) => key);
      dicKeysToSave.add(dicKey);
      custDictionaries.value[language.value][dicKey] = value;

      return true;
    }
  });

  return dictionaryEditor;
}
// ### DICTIONARY EDITOR ### //

// ### GLOBAL DICTIONARY ### //
let useId = 0;
const activeDictionaries = ref([]);
const edw = new Proxy({
  getDictionaryEditor,
  language: computed({
    get: () => { return instance.value.langId; },
    set: (newLang) => {
      const dictionariesToLoad = new Set(activeDictionaries.value.map((el) => el.dicId));
      return checkInstanceLangId().then(() => loadDictionaries([...dictionariesToLoad], newLang)).then(() => {
        instance.value.langId = newLang;
      }).catch((err) => { console.error('Dictionary error', err); });
    }
  }),
  languagesList: computed(() => { return getSortedLanguagesList(); }),
  setLanguage (newLang) { this.language.value = newLang; },
  useDictionary (ids, force) {
    ids = Array.isArray(ids) ? ids : [ids];

    // add used dictionaries to the active dictionaries
    const curUseId = useId++;
    ids.forEach((dicId) => { activeDictionaries.value.push({ useId: curUseId, dicId }); });

    // create a function to remove the newly added dictionaries to the active dictionaries index
    let dictionaryUnused = false;
    const unuseDictionary = () => {
      if (dictionaryUnused) { return; }
      dictionaryUnused = true;
      activeDictionaries.value = activeDictionaries.value.filter((actDic) => { return actDic.useId !== curUseId; });
    };
    onUnmounted(() => { unuseDictionary(); });

    const loadProm = checkInstanceLangId()
      .then(() => loadDictionaries(ids, instance.value.langId, force))
      .then(() => unuseDictionary)
      .catch((err) => { console.error('Dictionary error', err); });

    instance.value.isReady = loadProm.then(() => true).catch(() => false);

    return loadProm;
  },
  loadDictionaries,
  compile (str, info) { return compile(this[str], info, this); },
  translateError (err) {
    if (typeof err === 'string') {
      return this[err];
    } else {
      // This is meant to translate both graphql and standard errors
      if (err && err.message) {
        return this.compile(err.message, err.extensions?.context || {});
      }
      return err && err.message;
    }
  },
  isReady () { return instance.value.isReady; },
  __debug (active) { instance.value.debug = !!active; },
  __warn (active) { instance.value.warn = !!active; },
  __v_isRef: null
}, {
  get: function (target, prop) {
    if (prop === 'null' || prop === 'undefined') { return ''; }
    if (prop in target) { return target[prop]; }

    const dicKey = prop.replace(/^{{?([\w.-]+)}}?$/, (_, key) => key);
    for (let i = activeDictionaries.value.length - 1; i >= 0; i--) {
      if (instance.value.dictionaries[instance.value.langId] &&
          instance.value.dictionaries[instance.value.langId][activeDictionaries.value[i].dicId] &&
          dicKey in instance.value.dictionaries[instance.value.langId][activeDictionaries.value[i].dicId]
      ) {
        if (instance.value.debug) {
          return `<${dicKey}>[${instance.value.langId}-${activeDictionaries.value[i].dicId}]`;
        } else {
          return instance.value.dictionaries[instance.value.langId][activeDictionaries.value[i].dicId][dicKey];
        }
      }
    }

    if (!instance.value.missingLbls[dicKey]) {
      if (instance.value.warn) {
        console.warn('Missing translation for label "' + dicKey + '"');
      }
      instance.value.missingLbls[dicKey] = true;
    }

    if (instance.value.debug) {
      return `<${dicKey}>`;
    }
    return dicKey;
  },
  set: function () { return true; }
});
// ### GLOBAL DICTIONARY ### //

export default {
  install: function (app) {
    app.provide('$edw', edw);
    app.config.globalProperties.$edw = edw;

    if (app.config.globalProperties.$store) {
      app.config.globalProperties.$store.$edw = edw;
    }
  }
};

export {
  edw
};
