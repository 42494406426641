<script lang="ts">
import { watch, ref, inject, watchEffect } from 'vue';
import lodash from 'lodash';
import CnsModal from '../cns-modal.vue';
import CnsModalAlert from '../cns-modal-alert.vue';
import CnsButton from '../cns-button.vue';
import CnsIcon from '../cns-icon.vue';
import CnsInput from '../cns-input.vue';
import CnsListItem from '../cns-list-item.vue';
import CnsSettingsFormRow from '../../cns-settings/cns-settings-form-row.vue';
import CnsSettingsSectionTitle from '../../cns-settings/cns-settings-section-title.vue';
import GraphQl from '../../../libs/graphql-client/index.mjs';
import CnsSignalSelectorRow from '../cns-signal-selector/cns-signal-selector-row.vue';
import CnsPeripheralSelectorRow from '../cns-peripheral-selector/cns-peripheral-selector-row.vue';
import { loadConfigNode, saveConfigNode, saveParametersValues, deleteConfigNode } from './utils';
import type {
  ConfigNode,
  SaveConfigNodeInput,
  SaveParametersInput,
  InputRowDescInput,
  InputRowDescTitle,
  InputRowDescAutoConf,
  CnsNodeConfigRowInputRow
} from './types';
import { Fop } from '../../../libs/fop-utils/index.browser.mjs';

interface InputRowInput extends InputRowDescInput {
  value: string | undefined;
  min?: string;
  max?: string;
}

interface InputRowTitle extends InputRowDescTitle {
  value: string | undefined;
  min?: string;
  max?: string;
}

interface InputRowAutoConf extends InputRowDescAutoConf {
  value: string | undefined;
  min?: string;
  max?: string;
}

type InputRow = InputRowInput | InputRowTitle | InputRowAutoConf;
</script>

<script setup lang="ts">
const $edw = inject<any>('$edw');

defineOptions({ inheritAttrs: false });
const props = defineProps<{
  nodeId: string;
  icon: string;
  caption?: string;
  rows?: CnsNodeConfigRowInputRow[];
  autoConf?:(autoConfPeripherals: { [name: string]: any }) => { [name: string]: string };
  class?: any,
  style?: any,
  copyParentPermissionsOnSave?: boolean
}>();

const emit = defineEmits(['deleted']);

const nodeEditModal = ref<any>();
const nodeDelModal = ref<any>();
const node = ref<ConfigNode | undefined>();
const nodeLoading = ref<boolean>(false);
const nodeSaving = ref<boolean>(false);

const nodeDesc = ref<string>('');

const inputRows = ref<InputRow[]>([]);
watch(() => props.rows, () => {
  inputRows.value = (props.rows || []).map((desc): InputRow => ({
    ...desc,
    value: desc.type === 'text' ? '' : undefined
  }));
}, { immediate: true });

watchEffect(() => {
  nodeDesc.value = node.value?.desc || '';

  inputRows.value.forEach((input) => {
    if (input.type === 'title') { return; }

    const series = node.value?.series?.find((s) => s.varType.name === input.name);
    if (!series) { return; }

    if (input.type === 'text') {
      input.value = series.lastValidSampleValue || '';
    } else if (input.type === 'signal') {
      input.value = series.source?.type === 'DataloggerPeripheralSignal'
        ? series.source?.id
        : undefined;
    } else if (input.type === 'peripheral') {
      input.value = series.source?.type === 'DataloggerPeripheral'
        ? series.source?.id
        : undefined;
    } else if (input.type === 'peripheral-ref-alarm') {
      input.value = series.source?.type === 'DataloggerPeripheralRefAlarm'
        ? series.source?.id
        : undefined;
    }

    if (input.labelName) {
      const labelPar = node.value?.series?.find((s) => s.varType.name === input.labelName);
      if (labelPar) {
        input.label = labelPar.lastValidSampleValue || input.label;
      }
    }

    if (input.minName) {
      const minPar = node.value?.series?.find((s) => s.varType.name === input.minName);
      if (minPar) {
        input.min = minPar.lastValidSampleValue || '';
      }
    }

    if (input.maxName) {
      const maxPar = node.value?.series?.find((s) => s.varType.name === input.maxName);
      if (maxPar) {
        input.max = maxPar.lastValidSampleValue || '';
      }
    }
  });
});

watch(() => props.nodeId, () => loadNode(), { immediate: true });

async function loadNode () {
  nodeLoading.value = true;
  node.value = await loadConfigNode(props.nodeId);
  nodeLoading.value = false;
}

async function saveNode () {
  if (!node.value) { return; }
  if (nodeSaving.value) { return; }
  nodeSaving.value = true;

  const nodeToSave: ConfigNode = lodash.cloneDeep(node.value);
  const paramsToSave: SaveParametersInput[] = [];

  nodeToSave.desc = nodeDesc.value;

  inputRows.value.forEach((input) => {
    if (input.type === 'title') { return; }

    const series = nodeToSave?.series?.find((s) => s.varType.name === input.name);
    if (!series) { return; }

    if (input.type === 'text') {
      paramsToSave.push({ serieId: series.id, value: input.value || '' });
    } else if (input.type === 'signal') {
      series.source = input.value
        ? { type: 'DataloggerPeripheralSignal', id: input.value }
        : {};
    } else if (input.type === 'peripheral') {
      series.source = input.value
        ? { type: 'DataloggerPeripheral', id: input.value }
        : {};
    } else if (input.type === 'peripheral-ref-alarm') {
      series.source = input.value
        ? { type: 'DataloggerPeripheralRefAlarm', id: input.value }
        : {};
    }

    if (input.labelName) {
      const labelPar = nodeToSave?.series?.find((s) => s.varType.name === input.labelName);
      if (labelPar) {
        paramsToSave.push({ serieId: labelPar.id, value: input.label || '' });
      }
    }

    if (input.minName) {
      const minPar = nodeToSave?.series?.find((s) => s.varType.name === input.minName);
      if (minPar) {
        paramsToSave.push({ serieId: minPar.id, value: input.min || '' });
      }
    }

    if (input.maxName) {
      const maxPar = nodeToSave?.series?.find((s) => s.varType.name === input.maxName);
      if (maxPar) {
        paramsToSave.push({ serieId: maxPar.id, value: input.max || '' });
      }
    }
  });

  await saveParametersValues(paramsToSave);
  await saveConfigNode(nodeToSave as SaveConfigNodeInput, { copyParentPermissionsOnSave: props.copyParentPermissionsOnSave });

  loadNode();
  nodeEditModal.value.close();
  nodeSaving.value = false;
}

async function resetNode () {
  if (nodeSaving.value) { return; }
  loadNode();
  nodeEditModal.value.close();
}

async function deleteNode () {
  if (!node.value) { return; }
  if (nodeSaving.value) { return; }
  nodeSaving.value = true;
  if (await nodeDelModal.value.open() === true) {
    await deleteConfigNode(node.value.id);
    emit('deleted');
  }
  nodeSaving.value = false;
}

async function runAutoConf (name: string, peripheralId?: string) {
  if (!props.autoConf) { return; }
  if (!peripheralId) { return; }

  const peripheral = await GraphQl.query(`
    DataloggerPeripheral_get(fop: $fop, noCache: true) {
      id
      name
      signals {
        id
        name
        grp
      }
    }
  `, {
    fop: { type: 'FilterOrderPaginate', value: new Fop().filter('id', '=', peripheralId).serialize() }
  }).then((res: any) => res[0]);
  const autoConfRes = await props.autoConf({ [name]: peripheral });

  inputRows.value.forEach((input) => {
    if (input.type === 'title') { return; }
    if (autoConfRes?.[input.name]) {
      input.value = autoConfRes[input.name];
    }
  });
}
</script>

<template>
  <cns-list-item
    class="border"
    :icon="props.icon + ' fw'"
    :label="node?.desc || ''"
    :caption="props.caption"
    :class="props.class"
    :style="props.style"
  >
    <template #suffix>
      <cns-button
        variant="link"
        icon="gear"
        size="sm"
        @click="nodeEditModal.open()"
      />
      <cns-button
        variant="link"
        icon="xmark"
        size="sm"
        @click="deleteNode()"
      />
      <cns-modal ref="nodeEditModal" size="lg" :title="node?.desc || ''">
        <cns-settings-form-row v-model="nodeDesc" :label="$edw.name" editable />

        <template v-for="input in inputRows" :key="input.name">
          <cns-settings-section-title
            v-if="input.type === 'title'"
            :text="input.label"
            class="mb-0"
          />
          <cns-settings-form-row
            v-if="input.type === 'text'"
            v-model="input.value"
            :label="input.label"
            type="text"
            editable
          >
            <template #label v-if="input.labelName">
              <CnsInput v-model="input.label" />
            </template>
            <template #value>
              <div v-if="input.minName" :title="$edw.min" class="d-flex align-items-center gap-1 me-2">
                <CnsIcon type="gauge-min"/>
                <CnsInput v-model="input.min" style="width: 50px;"/>
              </div>
              <div v-if="input.maxName" :title="$edw.max" class="d-flex align-items-center gap-1 me-2">
                <CnsIcon type="gauge-max"/>
                <CnsInput v-model="input.max" style="width: 50px;"/>
              </div>
              <CnsInput v-model="input.value" />
            </template>
          </cns-settings-form-row>
          <cns-peripheral-selector-row
            v-if="input.type === 'peripheral'"
            v-model="input.value"
            :label="input.label"
            v-bind="$attrs"
          >
            <template #label v-if="input.labelName">
              <CnsInput v-model="input.label" />
            </template>
            <template #value-prefix>
              <div v-if="input.minName" :title="$edw.min" class="d-flex align-items-center gap-1 me-2">
                <CnsIcon type="gauge-min"/>
                <CnsInput v-model="input.min" style="width: 50px;"/>
              </div>
              <div v-if="input.maxName" :title="$edw.max" class="d-flex align-items-center gap-1 me-2">
                <CnsIcon type="gauge-max"/>
                <CnsInput v-model="input.max" style="width: 50px;"/>
              </div>
            </template>
          </cns-peripheral-selector-row>
          <cns-peripheral-selector-row
            v-if="input.type === 'peripheral-ref-alarm'"
            v-model="input.value"
            :label="input.label"
            v-bind="$attrs"
          >
            <template #label v-if="input.labelName">
              <CnsInput v-model="input.label" />
            </template>
            <template #value-prefix>
              <div v-if="input.minName" :title="$edw.min" class="d-flex align-items-center gap-1 me-2">
                <CnsIcon type="gauge-min"/>
                <CnsInput v-model="input.min" style="width: 50px;"/>
              </div>
              <div v-if="input.maxName" :title="$edw.max" class="d-flex align-items-center gap-1 me-2">
                <CnsIcon type="gauge-max"/>
                <CnsInput v-model="input.max" style="width: 50px;"/>
              </div>
            </template>
          </cns-peripheral-selector-row>
          <cns-signal-selector-row
            v-if="input.type === 'signal'"
            v-model="input.value"
            :label="input.label"
            v-bind="$attrs"
          >
            <template #label v-if="input.labelName">
              <CnsInput v-model="input.label" />
            </template>
            <template #value-prefix>
              <div v-if="input.minName" :title="$edw.min" class="d-flex align-items-center gap-1 me-2">
                <CnsIcon type="gauge-min"/>
                <CnsInput v-model="input.min" style="width: 50px;"/>
              </div>
              <div v-if="input.maxName" :title="$edw.max" class="d-flex align-items-center gap-1 me-2">
                <CnsIcon type="gauge-max"/>
                <CnsInput v-model="input.max" style="width: 50px;"/>
              </div>
            </template>
          </cns-signal-selector-row>
          <cns-peripheral-selector-row
            v-if="input.type === 'auto-conf-peripheral'"
            v-model="input.value"
            :label="input.label"
            v-bind="$attrs"
            @update:model-value="runAutoConf(input.name, input.value)"
          >
            <template #label v-if="input.labelName">
              <CnsInput v-model="input.label" />
            </template>
            <template #value-prefix>
              <div v-if="input.minName" :title="$edw.min" class="d-flex align-items-center gap-1 me-2">
                <CnsIcon type="gauge-min"/>
                <CnsInput v-model="input.min" style="width: 50px;"/>
              </div>
              <div v-if="input.maxName" :title="$edw.max" class="d-flex align-items-center gap-1 me-2">
                <CnsIcon type="gauge-max"/>
                <CnsInput v-model="input.max" style="width: 50px;"/>
              </div>
            </template>
          </cns-peripheral-selector-row>
        </template>

        <div class="d-flex justify-content-end gap-2 mt-2">
          <cns-button
            variant="secondary"
            :text="$edw.cancel"
            :disabled="nodeSaving"
            @click="resetNode()"
          />
          <cns-button
            variant="primary"
            :text="$edw.save"
            :loading="nodeSaving"
            @click="saveNode()"
          />
        </div>
      </cns-modal>
      <cns-modal-alert ref="nodeDelModal" v-slot="{ confirm, cancel }" :title="$edw.confirmDelete">
        <div class="mb-3">
          <span>{{ $edw.sureDeleteDevice + ' ' }}</span>
          <span class="text-bold">{{ node?.desc }}</span>
          <span>{{ '?' }}</span>
        </div>

        <div class="d-flex justify-content-end gap-2">
          <cns-button :text="$edw.cancel" default @click="cancel()" />
          <cns-button :text="$edw.confirm" variant="danger" @click="confirm()" />
        </div>
      </cns-modal-alert>
    </template>
  </cns-list-item>
</template>
