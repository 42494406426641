<!-- eslint-disable max-lines-per-function -->
<script setup lang="ts">
// /* global HigJS */
import cnsIcon from '../../../cns/cns-icon.vue';
// import { inject } from 'vue';

interface Props {
  label: string,
  value: string | number,
  timestamp: string,
  um: string,
  min: number,
  max: number,
  icon: string,
}

const props = withDefaults(defineProps<Props>(), {
  label: '-',
  value: '-',
  timestamp: '-',
  um: '-',
  min: 0,
  max: 0, // TODO: infinity
  icon: ''
});

// const $edw = inject<any>('$edw');

</script>

<template>
  <div
    class="d-flex gap-4 align-items-center rounded px-4 py-1 w-100"
    :style="{ backgroundColor: 'var(--hig-primary)' }"
  >
    <div :style="{ backgroundColor: 'white', width: '40px', height: '40px' }" class="rounded-circle d-flex">
      <cns-icon
        :type="props.icon + ' fw'"
        :style="{ color: 'var(--hig-primary)' }"
        class="m-auto"
      />
    </div>
    <div
      class="text-truncate"
      :title="props.timestamp"
    >
      <small class="fs-6" :style="{ color: 'white' }">{{ props.label }}</small>
      <div class="d-flex gap-1">
        <h1 :style="{ color: 'white' }"><strong>{{ props.value }}</strong></h1>
        <small :style="{ color: 'white' }">{{ props.um }}</small>
      </div>
    </div>
  </div>
</template>
