import { h, ref } from 'vue';
import { edw as $edw } from '../../../plugins/edw/index.mjs';
import CnsAlert from '../cns-alert.vue';
import CnsErrorAlert from '../cns-error-alert.vue';
import { createStandaloneComponent } from '../../../libs/vue-utils';

function getCustomNoRowsOverlay (provides = {}) {
  return class CustomNoRowsOverlay {
    params = ref(null);
    eGui;
    component;

    init (params) {
      this.params.value = params;

      this.eGui = document.createElement('div');
      this.eGui.className = 'no-rows-overlay';

      this.component = createStandaloneComponent(() => {
        const error = this.params.value.getError();
        if (error) {
          return h(CnsErrorAlert, { error });
        } else {
          return h(CnsAlert, { icon: 'table-rows', variant: 'light' }, () => [h('p', { class: 'm-1' }, [$edw.noData])]);
        }
      }, provides);
      this.component.mount(this.eGui);
    }

    refresh (params) {
      this.params.value = params;
      return true;
    }

    getGui () { return this.eGui; }

    destroy () {
      this.component.unmount();
      this.component = null;
      this.eGui = null;
    }
  };
}

export {
  getCustomNoRowsOverlay
};
