import { lib_Utils as VisionUtils } from '@hig/vision-sdk';
import graphQlClient from '../../../libs/graphql-client';
import { edw } from '../../edw/index.mjs';
import _ from 'lodash';
import Utils from '../../../libs/utils';

function menuToTree (menuItems, parent) {
  const res = [];
  menuItems.forEach((el) => {
    if (el.parent?.name === parent?.name) {
      res.push({
        ...el,
        parent: undefined,
        children: menuToTree(menuItems, el)
      });
    }
  });
  return res;
}

function parseMenu (menuItems) {
  menuItems = menuItems
    .map((el) => ({
      ...el,
      parent: el.parent ?? { id: undefined, name: undefined }
    }))
    .sort((a, b) => a.order - b.order);
  return menuToTree(menuItems);
}

export const useUserModule = function () {
  // ### SYS CONF ### //
  function loadSysConf (opt = {}) {
    if (!opt.force && this.state.sysConfProm) { return this.state.sysConfProm; }
    const langId = (navigator.language || navigator.userLanguage).split('-')[0] || 'en';

    this.state.sysConfProm = graphQlClient.query(`
      sysConf(langId: "${langId}") {
        sysConfId
        timeZone
        loginSetup
        idLang
        numFormatId
        dateFormatId
        diskSpace
        sessionExpTime
        sessionExpTimeApi
        expTimeApiLastAccess
        maxNumRecordExport
        abilUpdatedDataAlrm
        virtualHostCode
        googleAuth
      }
    `).then((sysConf) => {
      this.state.sysConf = { ...sysConf, diz: {}, HigJS: window.HigJS, logsLvls: { userClick: 2, admin: 1, superAdmin: 0 } };

      /* ### BACKWARDS COMPATIBILITY ### */
      window.sysConf = this.state.sysConf;
      window.sysConf.cns3 = true;
      window.diz = this.state.sysConf.diz;

      window.sessionStorage.setItem('sysConf', JSON.stringify(window.sysConf));
      /* ### BACKWARDS COMPATIBILITY ### */

      this.getConfig('@cns3/core.config.passwordConstraints').then((passwordConstraints) => {
        this.state.passwordConstraints = passwordConstraints;
      });

      return this.state.sysConf;
    }).catch((err) => {
      this.state.sysConfProm = undefined;
      throw err;
    });

    return this.state.sysConfProm;
  }
  // ### SYS CONF ### //

  // ### USER ### //
  function loadUser (opt = {}) {
    if (!opt.force && this.state.userProm) { return this.state.userProm; }

    this.state.userProm = Promise.all([
      graphQlClient.query(`
        curUser {
          sid
          id
          userCode
          username
          userType
          name
          surname
          mail
          timeZone {
            id
          }
          contacts
          template {
            id
            url
            urlMobile
            templateConf
            logo
          }
          lang { id }
          numFormat { id format }
          dateFormat { id format }
          cnsModules {
            id
            name
            desc
            type
            src
          }
          indexMenu {
            id
            name
            order
            label
            icon
            path
            module { id }
            modulePath
            props
            task { taskCode }
            parent { id name }
            device
            config { config }
          }
          confMenu {
            id
            name
            order
            label
            icon
            path
            module { id }
            modulePath
            props
            task { taskCode }
            parent { id name }
            device
            config { config }
          }
          tasks { taskCode }
        }
      `),
      graphQlClient.query(`
        completeLoginInfo {
          roles
          tasks
          userPgViews
          plugins
        }
      `)
    ]).then(async ([curUser, completeLoginInfo]) => {
      this.state.user = { ...curUser, moreInfo: completeLoginInfo };

      this.state.user.tasks = new Set(Array.isArray(this.state.user.tasks) ? this.state.user.tasks.map((task) => task.taskCode) : []);

      this.state.user.indexMenu = parseMenu(this.state.user.indexMenu);
      this.state.user.confMenu = parseMenu(this.state.user.confMenu);

      if (this.state.user.cnsModules) {
        const cnsModulesById = {};
        this.state.user.cnsModules.forEach((module) => { cnsModulesById[module.id] = module; });
        this.state.user.cnsModules = cnsModulesById;
      }

      edw.setLanguage(this.state.user.lang.id);

      /* ### BACKWARDS COMPATIBILITY ### */
      window.userConf = {
        // data fetched above with curUser (this is needed to make loadUserMoreInfo and loadUserDirectConf)
        ids: this.state.user.sid,
        name: this.state.user.username,
        userId: this.state.user.id,
        userType: this.state.user.userType,
        timeZone: this.state.user.timeZone?.id,
        langId: this.state.user.lang?.id,
        templateId: this.state.user.template?.id,
        url: this.state.user.template?.url,
        urlMobile: this.state.user.template?.urlMobile,
        templateConf: this.state.user.template?.templateConf,
        numFormat: this.state.user.numFormat?.format,
        dateFormat: this.state.user.dateFormat?.format,
        disable: 0
      };

      if (window.userConf.plugins) {
        window.userConf.plugins = window.userConf.plugins.map((el) => { el.pluginId = String(el.pluginId); return el; });
      }

      await loadUserMoreInfo.call(this, { force: true });

      // now after the version of all the "old" modules is loaded i can parse the module.src and replace the {{moduleId}} with the respective version
      if (this.state.user.cnsModules) {
        const matchReplacePkgVersion = /{{(\w+)}}/; // match the string `{{pkgId}}` in module.src

        for (const moduleId in this.state.user.cnsModules) {
          const pkgVersion = this.state.user.moreInfo.pkgListByType[this.state.user.cnsModules[moduleId].src.match(matchReplacePkgVersion)?.[1]]?.version;
          if (!pkgVersion) continue;

          this.state.user.cnsModules[moduleId].src = this.state.user.cnsModules[moduleId].src.replace(matchReplacePkgVersion, pkgVersion);
        }
      }

      if (this.state.user.userType === 'direct') {
        await loadUserDirectConf.call(this, { force: true });
      }

      window.userConf = {
        ...window.userConf,

        // Data fetched with completeLoginInfo and loadUserMoreInfo
        ...this.state.user.moreInfo,

        // data fetched with loadUserDirectConf
        ...this.state.user.direct
      };

      // Fix a bug with cns2
      window.userConf.plugins = window.userConf.plugins.map((pl) => {
        pl.pluginId = String(pl.pluginId);
        return pl;
      });

      window.userConf.userPgViews = window.userConf.userPgViews.map((pgView) => {
        pgView.pgViewId = String(pgView.pgViewId);
        return pgView;
      });

      window.userConf_str = JSON.stringify(window.userConf);

      window.sessionStorage.setItem('userConf', JSON.stringify(window.userConf));
      window.sessionStorage.setItem('pageConf', JSON.stringify(window.pageConf));
      /* ### BACKWARDS COMPATIBILITY ### */
    }).then(() => {
      return this.state.user;
    }).catch((err) => {
      this.state.userProm = undefined;
      throw err;
    });

    return this.state.userProm;
  }

  function doLogin (opt = {}) {
    if (!opt.apiKey && (!opt.username || !opt.password)) { console.error('Cannot login without api key or username and password'); return Promise.reject(new Error('LoginError')); }

    return graphQlClient.mutation(`
      login(credentials: $credentials)
    `, {
      credentials: { type: 'Credentials', value: { apiKey: opt.apiKey, u: opt.username, p: opt.password } }
    }).then(() => Promise.all([
      this.isLoggedIn({ force: true }),
      this.loadUser({ force: true })
    ])).then(() => {
      return this.state.user;
    });
  }

  function doLogout () {
    return graphQlClient.mutation(`
      logout
    `).then(() => {
      // Reload the page
      window.sessionStorage.clear();
      window.location.href = window.location.origin;
    });
  }

  function isLoggedIn (opt = {}) {
    if (!opt.force && this.state.isLoggedInProm && (Date.now() - this.state.lastIsLoggedIn) < this.state.isLoggedInReqInterval) { return this.state.isLoggedInProm; }

    this.state.isLoggedInProm = graphQlClient.query(`
      isLogged
    `).then((isLogged) => {
      this.state.lastIsLoggedIn = Date.now();
      return isLogged;
    }).catch((err) => {
      this.state.isLoggedInProm = undefined;
      throw err;
    });

    return this.state.isLoggedInProm;
  }

  /* ### BACKWARDS COMPATIBILITY ### */
  function loadUserDirectConf (opt = {}) {
    if (!opt.force && this.state.directUserConfProm) { return this.state.directUserConfProm; }
    if (this.state.user.id == null) { return; }

    const reqs = [];
    reqs.push({ act: 'getInterfaceByUserId', data: { userId: this.state.user.id } });
    reqs.push({ act: 'pkgServerList', data: {} });

    this.state.directUserConfProm = VisionUtils.ajaxRequest(reqs).then((res) => {
      this.state.user.direct = {};

      // getInterfaceByUserId
      if (res[0]) {
        this.state.user.direct.nodeInterfaces = res[0];
        for (const plant in res[0]) {
          if (res[0][plant].interfaces && res[0][plant].interfaces.length) {
            window.pageConf.plantToShow = plant;
            break;
          }
        }
      }

      // pkgServerList
      if (res[1]) {
        window.pageConf.pkgList = res[1].pkgList;
        window.pageConf.pkgListByType = {};
        window.pageConf.pkgList.forEach((pkg) => {
          window.pageConf.pkgListByType[pkg.id] = pkg;
        });
        this.state.user.direct.pkgListByType = window.pageConf.pkgListByType;
      }

      // getMobilePath
      this.state.user.direct.mobileInfo = res[2];
    }).then(() => {
      return Promise.all([
        graphQlClient.query(`
          Datalogger_get {
            deviceId: id
            serialNumber: sn
            name
            versioneSW: version
            model
            hwType: hardware
            gwcVersion: coreVersion
          }
        `)
      ]);

      // return VisionUtils.ajaxRequest([
      //   { act: 'readDispositivi', data: { hideFakeDev: 1, fields: ['nome', 'serialNumber', 'dispositiviId', 'hwType', 'model', 'versioneSW', 'gwcVersion'] } }
      //   // { act: 'readImpianti', data: {} }
      // ], '/PHP/phpScript/user.php');
    }).then((res) => {
      if (res[0]) {
        this.state.user.direct.devices = (Array.isArray(res[0]) ? res[0] : [])
          .filter((device) => device.hwType !== 'virtual');
      }

      if (this.state.user.direct.devices.length > 0) {
        const reqsMobilePath = this.state.user.direct.devices.map((device) => ({ act: 'getMobilePath', idReq: device.serialNumber, data: { sn: device.serialNumber, hwType: device.hwType } }));
        return VisionUtils.ajaxRequest(reqsMobilePath);
      }
    }).then((mobilePaths) => {
      if (Array.isArray(mobilePaths) && this.state.user.direct.devices.length === mobilePaths.length) {
        this.state.user.direct.devices.forEach((device, i) => {
          device.mobileVer = mobilePaths[i].coreMobile;
          device.mobilePath = mobilePaths[i].path;
        });
      }

      return this.state.user;
    }).catch((err) => {
      console.error(err);
      // throw (err instanceof Error ? err : new Error(err));
    });

    return this.state.directUserConfProm;
  }

  function loadUserMoreInfo (opt = {}) {
    if (!opt.force && this.state.moreUserInfoProm) { return this.state.moreUserInfoProm; }
    if (this.state.user.id == null) { return; }

    this.state.moreUserInfoProm = VisionUtils.ajaxRequest({ act: 'pkgServerList', data: {} }).then((res) => {
      if (res) {
        if (!this.state.user.moreInfo) { this.state.user.moreInfo = {}; }
        this.state.user.moreInfo.pkgList = this.state.sysConf.pkgList = res.pkgList || [];
        this.state.user.moreInfo.pkgListByType = this.state.sysConf.pkgListByType = (res.pkgList || []).reduce((acc, pkg) => { acc[pkg.id] = pkg; return acc; }, {});
      }
    }).then(() => {
      return Promise.all([
        this.loadSysDateFormats(),
        this.loadSysNumFormats(),
        this.loadSysTimeZones()
      ]);
    }).then((res) => {
      if (res[0]) {
        this.state.sysConf.dateFormatsList = res[0];
        this.state.sysConf.dateFormatsId = [];
        this.state.sysConf.dateFormatsLbl = [];

        for (let j = 0; j < this.state.sysConf.dateFormatsList.length; j++) {
          this.state.sysConf.dateFormatsId.push(this.state.sysConf.dateFormatsList[j].dateFormatId);
          this.state.sysConf.dateFormatsLbl.push(this.state.sysConf.dateFormatsList[j].dateFormat);
        }
      }

      if (res[1]) {
        this.state.sysConf.numberFormatsList = res[1];
        this.state.sysConf.numberFormatsId = [];
        this.state.sysConf.numberFormatsLbl = [];

        for (let j = 0; j < this.state.sysConf.numberFormatsList.length; j++) {
          this.state.sysConf.numberFormatsId.push(this.state.sysConf.numberFormatsList[j].numFormatId);
          this.state.sysConf.numberFormatsLbl.push(this.state.sysConf.numberFormatsList[j].numFormat);
        }
      }

      if (res[2]) {
        this.state.sysConf.timeZonesList = [];
        this.state.sysConf.timeZonesId = [];

        for (let j = 0; j < res[2].length; j++) {
          this.state.sysConf.timeZonesList.push(res[2][j].name);
          this.state.sysConf.timeZonesId.push(res[2][j].name);
        }
      }
    }).catch((err) => {
      console.error(err);
      // throw (err instanceof Error ? err : new Error(err));
    });

    return this.state.moreUserInfoProm;
  }

  function checkPassword (value, username) {
    if (!value) value = '';
    return Utils.checkPasswordConstraints(value, username, this.state.passwordConstraints);
  }

  /* ### BACKWARDS COMPATIBILITY ### */
  // ### USER ### //

  return {
    state: {
      sysConf: {},
      sysConfProm: undefined,
      user: {},
      userProm: undefined,
      isLoggedInProm: undefined,
      lastIsLoggedIn: 0,
      isLoggedInReqInterval: Utils.ms('15m'),
      directUserConfProm: undefined,
      moreUserInfoProm: undefined
    },
    getters: {
      sysConf: (state) => state.sysConf,
      user: (state) => state.user
    },
    actions: {
      loadSysConf,
      loadUser,
      doLogin,
      doLogout,
      isLoggedIn,
      checkPassword
    }
  };
};
