const AVAILABLE_FILTER_OP = new Set(['=', '!=', '<', '>', '<=', '>=', '~', '%']);
const FILTER_NULL_VALUE = '__fop_null';

export default {
  AVAILABLE_FILTER_OP,
  FILTER_NULL_VALUE
};

export {
  AVAILABLE_FILTER_OP,
  FILTER_NULL_VALUE
};
