<script lang="ts">
import { ref, watch, inject } from 'vue';
import GraphQl from '../../../libs/graphql-client/index.mjs';
import CnsButton from '../cns-button.vue';
import CnsListItem from '../cns-list-item.vue';
import CnsSettingsFormRow from '../../cns-settings/cns-settings-form-row.vue';
import Utils from '../../../libs/utils/index.mjs';
import InstallationSelectorModal from './cns-installation-selector-modal.vue';
import { Installation } from './types';

type LoadInstallationDescArg = { id: string; }
const loadInstallationDesc = Utils.batch<LoadInstallationDescArg, Installation | undefined>((args) => {
  const installationsIds = new Set();
  args.map((arg: LoadInstallationDescArg) => installationsIds.add(arg.id));

  return GraphQl.query(`
    Installation_get(id: [${[...installationsIds]}], noCache: true) {
      id
      name
      desc
    }
  `).then((res: Installation[]) =>
    args.map((arg) =>
      res.find((installation: Installation) => String(installation.id) === String(arg.id))
    )
  );
}, { cacheBy: 'id' });
</script>

<script setup lang="ts">
const $edw = inject<any>('$edw');

defineOptions({ inheritAttrs: false });
const props = defineProps<{
  label: string;
  class?: any;
  style?: any;
}>();
const value = defineModel<string | undefined>();

const modal = ref<any>();
const loading = ref<boolean>(false);
const installation = ref<Installation | undefined>();

watch(value, async () => {
  installation.value = undefined;
  if (!value.value) { return; }

  loading.value = true;
  installation.value = await loadInstallationDesc({ id: value.value }).catch(() => undefined);
  loading.value = false;
}, { immediate: true });

defineExpose({ getSelected: () => installation.value });
</script>

<template>
  <cns-settings-form-row :label="props.label" :class="props.class" :style="props.style">
    <template #label="labelProps">
      <slot name="label" v-bind="labelProps" />
    </template>
    <template #value>
      <slot name="value-prefix" />
      <div class="d-flex align-items-center gap-2">
        <cns-list-item
          style="width: 250px;"
          :icon="loading ? 'spinner-third spin fw' : 'meter-bolt fw'"
          :label="installation ? installation.name : $edw.noInstallationSelected"
          :caption="
            installation
              ? installation.desc
              : $edw.clickToSelectAnInstallation
          "
          @click="modal.open()"
        >
          <template #suffix>
            <cns-button
              v-if="installation"
              icon="xmark"
              variant="link"
              size="sm"
              @click.stop="() => { value = undefined }"
            />
          </template>
        </cns-list-item>
      </div>
      <installation-selector-modal ref="modal" v-model="value" :title="props.label" v-bind="$attrs" />
    </template>
  </cns-settings-form-row>
</template>
