import { h, ref } from 'vue';
import { Fop } from '../../../libs/fop-utils/index.browser.mjs';
import CnsSelect from '../cns-select.vue';
import CnsInput from '../cns-input.vue';
import CnsDatepicker from '../cns-datepicker.vue';
import CnsButton from '../cns-button.vue';
import { CnsTableCol } from './cns-table-types.js';
import { edw as $edw } from '../../../plugins/edw/index.mjs';
import { createStandaloneComponent } from '../../../libs/vue-utils';

interface AgGridFilterInterface {
  colId: string;
  init: (params: any) => void;
  onValueChanged: (v: any) => void;
  onOpChanged: (o: any) => void;
  getGui: () => HTMLElement;
  isFilterActive: () => boolean;
  doesFilterPass: (params: any) => boolean;
  getModel: () => any;
  setModel: (model: any) => void;
  destroy: () => void;
}

type AgGridFilter = string | AgGridFilterInterface | { new(): AgGridFilterInterface };

interface AgGridFilterParams {
  field: string;
  provides: object,
  buttons?: string[];
  closeOnApply?: boolean;
  filterOptions?: string[];
  selectFilter?: { options: { text: string, value: any }[], multiple: boolean };
  datetimeFilter?: { type: 'datetime' | 'datetimerange', minDate?: string, maxDate?: string };
}

interface AgGridFilterOptions {
  filter: AgGridFilter;
  filterParams: AgGridFilterParams;
}

const FILTER_TYPE_HIG_TO_AG = { // Still available from ag-grid: 'empty' | 'inRange' | 'notContains' | 'startsWith' | 'endsWith' | 'blank' | 'notBlank'
  '=': 'equals',
  '!=': 'notEqual',
  '<': 'lessThan',
  '<=': 'lessThanOrEqual',
  '>': 'greaterThan',
  '>=': 'greaterThanOrEqual',
  '%': 'contains',
  '~': 'regex'
};
// "Flip" key-values
const FILTER_TYPE_AG_TO_HIG = {};
for (const k in FILTER_TYPE_HIG_TO_AG) { FILTER_TYPE_AG_TO_HIG[FILTER_TYPE_HIG_TO_AG[k]] = k; }

class AgGridFilterClass implements AgGridFilterInterface {
  colId: string;
  field: string;
  filterChangedCallback: () => void;
  eGui: HTMLElement;
  filterInputWrapper: HTMLElement;

  val: any = ref();
  ops: string[] = [];
  op: any = ref();
  active = ref(false);

  #filterActionsWrapper;
  #filterActionsComponent;

  init (params) {
    this.colId = params.column.colId;
    this.field = params.field;
    this.filterChangedCallback = params.filterChangedCallback;
    this.ops = params.filterOptions;
    this.op.value = this.ops?.[0];

    this.eGui = document.createElement('div');
    this.eGui.innerHTML = `
      <div class="ag-filter overflow-visible">
        <div class="overflow-visible ag-filter-body-wrapper ag-simple-filter-body-wrapper d-flex flex-row gap-2 align-items-center justify-content-center p-3">
          <p class="p-0 m-0" style="line-height: 1em">${params.colDef.headerName}</p>
          <div class="filter-input-wrapper m-0 d-flex flex-row gap-2 align-items-center justify-content-center"></div>
          <div class="filter-actions-wrapper d-flex align-items-center justify-content-center gap-1 m-0"></div>
        </div>
      </div>
    `;
    this.filterInputWrapper = this.eGui.querySelector('.filter-input-wrapper');
    this.#filterActionsWrapper = this.eGui.querySelector('.filter-actions-wrapper');

    this.#filterActionsComponent = createStandaloneComponent(() => {
      return [
        h(CnsButton, {
          icon: 'times',
          variant: 'link',
          disabled: !this.isFilterActive(),
          onClick: () => {
            this.active.value = false;
            this.onValueChanged(undefined);
            this.onOpChanged(undefined);
            this.triggerFilterChanged();
            params.api.hidePopupMenu();
          }
        }),
        h(CnsButton, {
          icon: 'check',
          variant: 'link',
          onClick: () => {
            this.active.value = true;
            this.triggerFilterChanged();
            params.api.hidePopupMenu();
          }
        })
      ];
    }, params.provides);
    this.#filterActionsComponent.mount(this.#filterActionsWrapper);
  }

  onValueChanged (v) {
    this.val.value = v;
  }

  onOpChanged (o) {
    this.op.value = o ?? this.ops?.[0];
  }

  triggerFilterChanged () {
    this.filterChangedCallback();
    setTimeout(() => this.filterChangedCallback(), 0); // TODO: Dunno why exactlty, but with this it works
  }

  getGui () {
    return this.eGui;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  doesFilterPass (params) { // Return true if the passed row passes the filter. Otherwise false.
    return true;
  }

  isFilterActive () { // Return true if the filter is active. What this means is up to the filter.
    return this.active.value;
  }

  getModel () { return null; }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  setModel (model) {}

  destroy () {
    this.#filterActionsComponent.unmount();
    this.#filterActionsComponent = null;
    this.#filterActionsWrapper = null;
    this.eGui = null;
  }
}

class TextFilter extends AgGridFilterClass {
  #component;

  init (params) {
    super.init(params);

    this.#component = createStandaloneComponent(() => {
      const res = [];

      if (this.ops.length > 1) {
        res.push(
          h(CnsSelect, {
            modelValue: this.op.value,
            options: this.ops.map((option) => ({ text: $edw[option], value: option })),
            'onUpdate:modelValue': this.onOpChanged.bind(this),
            style: { minWidth: '120px' }
          })
        );
      }

      res.push(
        h(CnsInput, {
          placeholder: $edw.filter + '...',
          modelValue: this.val.value,
          'onUpdate:modelValue': this.onValueChanged.bind(this),
          lazy: true,
          style: { minWidth: '200px' }
        })
      );

      return res;
    }, params.provides);
    this.#component.mount(this.filterInputWrapper);
  }

  isFilterActive () {
    return super.isFilterActive() && !!this.val.value;
  }

  doesFilterPass (params) {
    return new Fop().filter(this.field, FILTER_TYPE_AG_TO_HIG[this.op.value], this.val.value).doesFilterPass(params.data);
  }

  getModel () {
    return this.isFilterActive() ? { filterType: 'text', type: this.op.value, filter: this.val.value } : undefined;
  }

  setModel (model) {
    this.onValueChanged(model?.filter);
    this.onOpChanged(model?.type);
    this.triggerFilterChanged();
  }

  destroy () {
    this.#component.unmount();
    this.#component = null;
    super.destroy();
  }
}

class SelectFilter extends AgGridFilterClass {
  #component;
  #options = [];

  init (params) {
    super.init(params);
    this.#options = [{ text: $edw.selectNoValue, value: undefined }, ...(params.selectFilter.options || [])];

    this.#component = createStandaloneComponent(() => {
      const res = [];

      if (this.ops.length > 1) {
        res.push(
          h(CnsSelect, {
            modelValue: this.op.value,
            options: this.ops.map((option) => ({ text: $edw[option], value: option })),
            'onUpdate:modelValue': this.onOpChanged.bind(this),
            style: { minWidth: '120px' }
          })
        );
      }

      res.push(
        h(CnsSelect, {
          placeholder: $edw.selectOne,
          options: this.#options,
          multiple: params.selectFilter.multiple,
          modelValue: this.val.value,
          'onUpdate:modelValue': this.onValueChanged.bind(this),
          style: { minWidth: '200px' }
        })
      );

      return res;
    }, params.provides);
    this.#component.mount(this.filterInputWrapper);
  }

  isFilterActive () {
    return super.isFilterActive() && this.val.value != null;
  }

  doesFilterPass (params) {
    return new Fop().filter(this.field, FILTER_TYPE_AG_TO_HIG[this.op.value], this.val.value).doesFilterPass(params.data);
  }

  getModel () {
    return this.isFilterActive() ? { filterType: 'text', type: this.op.value, filter: this.val.value } : undefined;
  }

  setModel (model) {
    this.onValueChanged(model?.filter);
    this.onOpChanged(model?.type);
    this.triggerFilterChanged();
  }

  destroy () {
    this.#component.unmount();
    this.#component = null;
    super.destroy();
  }
}

class DatetimeFilter extends AgGridFilterClass {
  #component;
  #subType = null;
  #isRange = false;

  init (params) {
    super.init(params);

    this.#subType = params.datetimeFilter.type;
    this.#isRange = this.#subType === 'datetimerange';

    this.#component = createStandaloneComponent(() => {
      const res = [];

      if (this.#isRange) {
        res.push(
          h(CnsSelect, {
            modelValue: this.op.value,
            options: this.ops.map((option) => ({ text: $edw[option], value: option })),
            'onUpdate:modelValue': this.onOpChanged.bind(this),
            style: { minWidth: '120px' }
          })
        );
      }

      res.push(
        h(CnsDatepicker, {
          range: this.#isRange,
          modelValue: this.val.value,
          placeholder: $edw.selectDateOrRange,
          'min-date': params.datetimeFilter?.minDate,
          'max-date': params.datetimeFilter?.maxDate,
          'onUpdate:modelValue': this.onValueChanged.bind(this),
          'close-on-auto-apply': true,
          style: { minWidth: '200px' }
        })
      );

      return res;
    }, params.provides);
    this.#component.mount(this.filterInputWrapper);
  }

  isFilterActive () {
    return super.isFilterActive() && ((this.#isRange && Array.isArray(this.val.value) && this.val.value[0] && this.val.value[1]) || !!this.val.value);
  }

  doesFilterPass (params) {
    console.log('DatetimeFilter doesFilterPass', params, this); // FIXME check if works for both range and single date

    let fop = new Fop();
    if (this.#isRange) {
      fop = fop.filter(this.field, '>=', this.val.value?.[0]);
      fop = fop.filter(this.field, '<=', this.val.value?.[1]);
    } else {
      fop = fop.filter(this.field, FILTER_TYPE_AG_TO_HIG[this.op.value], this.val.value);
    }
    return fop.doesFilterPass(params.data);
  }

  getModel () {
    const filter = this.val.value?.getTime ? this.val.value.getTime() : this.val.value;
    return this.isFilterActive() ? { filterType: 'date', type: this.#isRange ? 'inRange' : this.op.value, filter } : undefined;
  }

  setModel (model) {
    this.onValueChanged(model?.filter);
    this.onOpChanged(model?.type);
    this.triggerFilterChanged();
  }

  destroy () {
    this.#component.unmount();
    this.#component = null;
    super.destroy();
  }
}

function getCnsTableColFilter (column: CnsTableCol, provides: object = {}):AgGridFilterOptions {
  if (!column.filtrable) { return; }

  const colFilter = column.filter || { type: 'text', op: ['contains'] };

  const filterOps = [];
  if (Array.isArray(colFilter.op)) {
    for (const op of colFilter.op) {
      const filterOp = typeof op === 'string' && FILTER_TYPE_AG_TO_HIG[op] ? op : FILTER_TYPE_HIG_TO_AG[op];
      if (!filterOp) { continue; }
      filterOps.push(filterOp);
    }
  }

  const agGridFilter:AgGridFilterOptions = {
    filter: undefined,
    filterParams: {
      field: column.filterField || column.field || column.name,
      provides,
      filterOptions: [],
      closeOnApply: true,
      buttons: ['reset', 'apply']
    }
  };

  switch (colFilter.type) {
    case 'text':
      agGridFilter.filter = TextFilter;
      agGridFilter.filterParams.filterOptions = filterOps;
      break;
    case 'select':
      agGridFilter.filter = SelectFilter;
      agGridFilter.filterParams.selectFilter = {
        options: colFilter.options,
        multiple: colFilter.multiple
      };
      agGridFilter.filterParams.filterOptions = ['equals'];
      break;
    case 'datetime':
    case 'datetimerange':
      agGridFilter.filterParams.filterOptions = colFilter.type === 'datetime' ? filterOps : [];
      agGridFilter.filter = DatetimeFilter;
      agGridFilter.filterParams.datetimeFilter = {
        type: colFilter.type,
        minDate: colFilter.minDate,
        maxDate: colFilter.maxDate
      };
      break;
  }

  return agGridFilter;
}

export {
  getCnsTableColFilter,
  AgGridFilter,
  AgGridFilterParams,
  AgGridFilterOptions,
  FILTER_TYPE_HIG_TO_AG,
  FILTER_TYPE_AG_TO_HIG
};
