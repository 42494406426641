<script setup>
import CnsIcon from '../cns-icon.vue';

const props = defineProps({
  icon: { type: String, default: 'circle-exclamation' },
  counter: { type: Number, default: undefined },
  variant: {
    type: String,
    default: undefined,
    validator (value) { return ['info', 'warning', 'danger'].includes(value); }
  }
});
</script>

<template>
  <div class="plant-list-status-icon" :class="[ props.variant ]">
    <cns-icon class="icon" :type="props.icon" :class="{ 'active': props.counter > 0 }" />
    <div
      class="counter position-absolute rounded-circle"
      :style="{ 'background-color': props.color }"
      :class="{ 'show': props.counter > 0 }"
    >
      {{ props.counter }}
    </div>
  </div>
</template>

<style scoped>
.plant-list-status-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}

.icon {
  opacity: .4;
  transition: opacity .3s ease;
}

.icon.active {
  opacity: .7;
}

.plant-list-status-icon:hover > .icon {
  opacity: 1;
}

.counter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5em;
  height: 1.5em;
  font-size: .65em;
  line-height: .65em;
  bottom: 0.3em;
  right: 0.3em;
  overflow: hidden;
  transition: transform .3s ease;
  transform-origin: center;
  transform: scale(0);
  color: var(--hig-light-text);
  background-color: var(--hig-light-accent);
}

.counter.show {
  transform: scale(1);
}

.plant-list-status-icon.info .counter {
  color: var(--hig-info-text);
  background-color: var(--hig-info);
}

.plant-list-status-icon.danger .counter {
  color: var(--hig-danger-text);
  background-color: var(--hig-danger);
}

.plant-list-status-icon.warning .counter {
  color: var(--hig-warning-text);
  background-color: var(--hig-warning);
}
</style>
