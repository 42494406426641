<template>
  <button :class="classes" :type="type" :disabled="disabled" :aria-pressed="toggle ? String(pressed) : null" :autocomplete="toggle ? 'off' : null">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'b-button',
  props: {
    variant: { type: String, default: 'secondary' },
    size: { type: String, default: undefined },
    type: { type: String, default: 'button' },
    pill: { type: Boolean, default: false },
    squared: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    pressed: { type: Boolean, default: undefined }
  },
  computed: {
    toggle: function () {
      return this.pressed !== undefined;
    },
    classes: function () {
      return [
        'btn',
        'btn-' + this.variant,
        {
          ['btn-' + this.size]: !!this.size,
          'rounded-pill': this.pill,
          'rounded-0': this.squared && !this.pill,
          disabled: this.disabled,
          active: this.pressed
        }
      ];
    }
  }
};
</script>
