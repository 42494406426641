<script setup>
import { ref, nextTick } from 'vue';
import CnsModal from './cns-modal.vue';

const modal = ref(null);
const content = ref(null);
const isOpen = ref(false);
let closeCb = null;

function open (options) {
  if (!modal.value) { return; }
  if (isOpen.value) { return; }

  isOpen.value = true;
  modal.value.open(options);
  nextTick(() => {
    content.value.querySelector('[default]')?.focus();
  });
  return new Promise((resolve) => { closeCb = resolve; });
}

function close () {
  if (!modal.value) { return; }
  if (!isOpen.value) { return; }

  isOpen.value = false;
  modal.value.close();
}

function confirm (res) {
  if (!isOpen.value) { return; }
  res = res instanceof Event ? null : res;

  close();
  closeCb && closeCb(res != null ? res : true);
}

function cancel (res) {
  if (!isOpen.value) { return; }
  res = res instanceof Event ? null : res;

  close();
  closeCb && closeCb(res != null ? res : false);
}

defineExpose({ open, close });
</script>

<template>
  <cns-modal ref="modal" @close="cancel()" v-slot="{ opt }">
    <div ref="content">
      <slot :confirm="confirm" :cancel="cancel" :opt="opt"></slot>
    </div>
  </cns-modal>
</template>

<script scoped>
</script>
