<script setup>
import { inject, ref, nextTick, watch, computed } from 'vue';
import _ from 'lodash';
import CnsButton from './cns-button.vue';
import CnsSelect from './cns-select.vue';
import CnsInput from './cns-input.vue';
import CnsDatepicker from './cns-datepicker.vue';
import CnsAlert from './cns-alert.vue';
import CnsDropdown from './cns-dropdown.vue';
import CnsInlineSelect from './cns-inline-select.vue';
import { VIIcon } from '@hig/vision-sdk';
import { Fop } from '../../libs/fop-utils/index.browser.mjs';

const $edw = inject('$edw');

const props = defineProps({
  /* base options */
  cols: { type: Array, default: function () { return []; } },
  data: { type: [Array, Function], default: function () { return []; } },
  'rows-height': { type: Number, default: undefined },
  'rows-gap': { type: Number, default: 0 },
  'rows-id': { type: String, default: 'id' },

  order: { type: Array, default: undefined },
  filter: { type: Object, default: undefined },

  /* data fetch options */
  'fetch-page-size': { type: Number, default: 500 },
  'fetch-debounce': { type: Number, default: 1000 },
  'fetch-trigger-distance': { type: Number, default: 50 },
  /* Set this to true if the collisions check needs to be faster (It's probaby useless) */
  'fetch-fast-collisions-check': { type: Boolean, default: false },

  /* style options */
  'sticky-header': { type: Boolean, default: false },
  variant: { type: String, default: undefined },
  striped: { type: Boolean, default: false },
  hover: { type: Boolean, default: false },
  bordered: { type: Boolean, default: false },
  borderless: { type: Boolean, default: false },
  responsive: { type: [Boolean, String], default: false },
  'vertical-align': { type: String, default: 'middle' },
  'horizontal-align': { type: String, default: 'center' },
  loading: { type: Boolean, default: false },
  useTimeLocal: { type: Boolean, default: true }
});
const emit = defineEmits(['row-click', 'cell-click', 'update:order', 'update:filter']);

const data = ref([]);
const dataIsFetch = computed(() => typeof props.data === 'function');
const dataDisplay = computed(() => {
  return data.value.map((row) => {
    const resRow = { _row: row };
    props.cols.forEach((col) => {
      resRow[col.name] = _.get(row, col.path || col.name);
      if (col.translate) {
        resRow[col.name] = $edw[resRow[col.name]];
      }
    });
    return resRow;
  });
});
const dataIndexes = computed(() => Object.keys(dataDisplay.value));

const order = ref(new Map());
const filter = ref(new Map());

const FILTER_TYPES = ['select', 'date', 'daterange', 'time', 'datetime', 'datetimerange', 'free'];

let skipPropOrderWatch = false;
watch(() => props.order, () => {
  if (props.order == null) { return; }
  if (skipPropOrderWatch) { skipPropOrderWatch = false; return; }
  order.value = new Map();
  props.order.forEach((ord) => { order.value.set(ord.col, ord.dir); });
}, { immediate: true });
function toggleOrder (col) {
  if (!order.value.get(col)) {
    order.value.set(col, 1);
  } else if (order.value.get(col) === 1) {
    order.value.set(col, -1);
  } else if (order.value.get(col) === -1) {
    order.value.delete(col);
  }

  const orderEmit = [];
  order.value.forEach((order, col) => { orderEmit.push({ col, dir: order }); });
  skipPropOrderWatch = true;
  emit('update:order', orderEmit);

  fetchData({ updateFop: true });
}

let skipPropFilterWatch = false;
watch(() => props.filter, () => {
  if (props.filter == null) { return; }
  if (skipPropFilterWatch) { skipPropFilterWatch = false; return; }
  filter.value = new Map();
  Object.keys(props.filter).forEach((col) => { filter.value.set(col, { op: props.filter[col].op, val: props.filter[col].val }); });
}, { immediate: true });
function setFilter (col, val, colName) {
  let filterChanged = false;
  if (filter.value.get(col) && (!val || !val.val || !val.op)) {
    filter.value.delete(col);
    filterChanged = true;
  } else if (val) {
    // if the type of the column is datetimerange then i change the filter to an `and`
    let newVal;
    if (filterConfByCol.value[colName]?.type === 'datetimerange' && val?.val?.start && val?.val?.stop) {
      newVal = { op: '', val: '', and: [{ field: col, op: '>', val: val.val.start }, { field: col, op: '<', val: val.val.stop }] };
    }
    filter.value.set(col, newVal ?? val);
    filterChanged = true;
  }

  if (filterChanged) {
    const filterEmit = {};
    filter.value.forEach((filter, col) => { filterEmit[col] = { op: filter.op, val: filter.val }; });
    skipPropFilterWatch = true;
    emit('update:filter', filterEmit);

    fetchData({ updateFop: true });
  }

  // update the `active` so the button `remove` is shown to the user
  filterConfByCol.value[colName] && (filterConfByCol.value[colName].active = val !== null);
}

const pageCount = ref(0);
const dataFetchFop = computed(() => {
  const res = { filter: [], order: [], paginate: {} };

  // Here unshift is used instead of push so that newer filters are more important
  filter.value.forEach((filter, col) => {
    res.filter.unshift({ field: col, ...filter });
  });

  // Here unshift is used instead of push so that newer sort are more important
  order.value.forEach((order, col) => {
    res.order.unshift({ field: col, dir: order });
  });

  res.paginate.page = pageCount.value;
  res.paginate.size = props.fetchPageSize;

  return res;
});

const dataFetchId = ref(0);
const dataLoading = ref(false);
const dataComplete = ref(false);
const dataLoadError = ref(null);
const dataAll = ref();

const loading = computed(() => dataLoading.value || props.loading);

let fetchDataTo;
function fetchData ({ immediate, reset, updateFop } = {}) {
  if (!dataIsFetch.value || (!reset && dataAll.value)) {
    data.value = new Fop({ ...dataFetchFop.value, paginate: undefined }).apply(dataAll.value ? dataAll.value : props.data);
    return;
  }
  if (reset || updateFop) {
    pageCount.value = 0;
    dataComplete.value = false;
    dataAll.value = null;
    dataLoadError.value = null;
  } else if (dataLoading.value) { return; }
  if (pageCount.value > 0 && dataComplete.value) { return; }
  if (dataLoadError.value) { return; }

  dataLoading.value = true;
  const curDataFetchId = ++dataFetchId.value;
  clearTimeout(fetchDataTo);
  fetchDataTo = setTimeout(() => {
    Promise.resolve().then(() => props.data(dataFetchFop.value)).then((res) => {
      if (curDataFetchId !== dataFetchId.value) { return; }

      if (res && Array.isArray(res)) {
        if (pageCount.value === 0) {
          data.value = res;
          resetScrollTop();
          pageCount.value++;
        } else {
          let i = 0;
          let j = data.value.length - 1;
          let collisionFound = false;

          // When using the fast collisions check check for collisions only the latest fetchPageSize elemenst of data
          const dataCheckLimit = props.fetchFastCollisionsCheck ? j - (props.fetchPageSize - 1) : 0;

          // find the first colliding element
          if (res[i]) {
            while (j >= dataCheckLimit && !collisionFound) {
              if (data.value[j][props.rowsId] != null && res[i][props.rowsId] != null && String(data.value[j][props.rowsId]) === String(res[i][props.rowsId])) {
                collisionFound = true;
                break;
              }
              j--;
            }
          }

          // find the latest colliding element
          if (collisionFound) {
            while (i < res.length && j < data.value.length) {
              if (data.value[j][props.rowsId] == null || res[i][props.rowsId] == null || String(data.value[j][props.rowsId]) !== String(res[i][props.rowsId])) {
                break;
              }
              j++;
              i++;
            }
          }

          // add res to data removing all colliding elements
          data.value = data.value.concat(res.slice(i));
          pageCount.value++;

          // TODO: Check if it's worth it to load the first i elements to put at the beginning
          // This won't work if the elements are added in the middle of the list
          // if (i > 0) {
          //   console.log('removing colliding elements', res.slice(0, i));
          //   return props.data({ ...dataFetchFop.value, paginate: { size: i } }).then((res) => {});
          // }
        }
      } else {
        if (pageCount.value === 0) { data.value = []; }
        dataComplete.value = true;
        // i can set the complete data only of no filters were applied, otherway there is the risk that some data wasn't loaded
        if (dataFetchFop.value.filter.length === 0) dataAll.value = _.cloneDeep(data.value);
      }
    }).catch((err) => {
      if (curDataFetchId !== dataFetchId.value) { return; }
      if (pageCount.value === 0) { data.value = []; }
      dataLoadError.value = err.message;
    }).finally(() => {
      if (curDataFetchId !== dataFetchId.value) { return; }
      dataLoading.value = false;

      nextTick(() => {
        // If the data is not complete, there was no errors on the latest load and it doesn't cover the whole table height rows, load some more data
        if (!dataComplete.value && !dataLoadError.value && Math.ceil(tableContainer.value.offsetHeight / rowsHeight.value) >= data.value.length) {
          fetchData();
        }
      });
    });
  }, immediate ? 0 : props.fetchDebounce);
}

const filterConfByCol = ref({});
watch(() => ([props.cols, props.filter]), () => {
  filterConfByCol.value = {};
  props.cols.forEach((col) => {
    const curColFilterValue = filter.value.get(col.filter || col.name);

    filterConfByCol.value[col.name] = {
      type: 'free',
      opOptions: [],
      valOptions: [],
      active: !!curColFilterValue,
      value: curColFilterValue || { op: null, val: null }
    };

    if (col.filtrable && col.filtrable.type && FILTER_TYPES.includes(col.filtrable.type)) {
      filterConfByCol.value[col.name].type = col.filtrable.type;
    }

    if (col.filtrable && Array.isArray(col.filtrable.op)) {
      filterConfByCol.value[col.name].opOptions = col.filtrable.op.map((opt) => {
        return {
          text: opt.text && $edw[opt.text],
          desc: opt.desc && $edw[opt.desc],
          value: opt.value
        };
      });
      if (filterConfByCol.value[col.name].value.op == null && filterConfByCol.value[col.name].opOptions.length > 0) {
        filterConfByCol.value[col.name].value.op = filterConfByCol.value[col.name].opOptions[0].value;
      }
    }

    if (col.filtrable && Array.isArray(col.filtrable.val)) {
      filterConfByCol.value[col.name].type = 'select';
      filterConfByCol.value[col.name].valOptions = col.filtrable.val.map((opt) => {
        return {
          text: opt.text && $edw[opt.text],
          desc: opt.desc && $edw[opt.desc],
          value: opt.value
        };
      });
      if (filterConfByCol.value[col.name].value.val == null && filterConfByCol.value[col.name].valOptions.length > 0) {
        filterConfByCol.value[col.name].value.val = filterConfByCol.value[col.name].valOptions[0].value;
      }
    }
  });
}, { immediate: true, deep: true });

let skipPropDataWatch = false;
watch(() => props.data, () => {
  if (skipPropDataWatch) { skipPropDataWatch = false; return; }
  data.value = [];
  fetchData({ immediate: true, reset: true });
}, { immediate: true });

function setData (row, col, newVal) {
  console.log(`Set ${row} -> ${col} -> ${newVal}`);
  if (!data.value[row]) { return; }
  for (let i = 0; i < props.cols.length; i++) {
    if (props.cols[i].name === col) {
      _.set(data.value[row], props.cols[i].path || props.cols[i].name, newVal);
      break;
    }
  }

  if (!dataIsFetch.value) {
    emit('update:data', data.value);
    skipPropDataWatch = true;
  }
}

const tableClasses = computed(() => [
  {
    ['table-' + props.variant]: !!props.variant,
    'table-sticky-header': props.stickyHeader,
    'table-striped': props.striped,
    'table-hover': props.hover,
    'table-bordered': props.bordered,
    'table-borderless': props.borderless,
    'table-responsive': props.responsive === true,
    ['table-responsive-' + props.responsive]: typeof props.responsive === 'string'
  }
]);

/* HTML REFS */
const tableContainer = ref(null);
const tableHead = ref(null);
const tableHeadHeight = computed(() => tableHead.value && tableHead.value.getBoundingClientRect().height);
const tableBody = ref(null);
const dummyRow = ref(null);
const placeholderRowHeight = computed(() => (rowsHeight.value * (dataIndexes.value.length - rowsPool.value.length) + props.rowsGap));

/* RECYCLE SCROLL */
const rowsHeight = ref(0);
watch(() => props.rowsHeight, () => {
  if (props.rowsHeight) {
    rowsHeight.value = props.rowsHeight;
  }
}, { immediate: true });

const rowsPoolFrom = ref(0);
const rowsPoolCount = ref(0);
const rowsPool = ref([]);
const scrolling = ref(false);

watch(dataDisplay, () => {
  nextTick(() => { calcRowsHeight(); updateRowsPool(); });
}, { immediate: true });

watch(() => props.cols, () => {
  nextTick(() => { calcRowsHeight(); });
}, { immediate: true });

function calcRowsHeight () {
  if (!dummyRow.value) { return; }

  if (!props.rowsHeight) {
    dummyRow.value.style.display = 'table-row';
    rowsHeight.value = Math.round(dummyRow.value.getBoundingClientRect().height + props.rowsGap);
    dummyRow.value.style.display = 'none';
  }
}

function updateRowsPool () {
  if (rowsHeight.value === 0) { calcRowsHeight(); }
  if (rowsHeight.value === 0) { return; }

  rowsPoolFrom.value = Math.max(0, Math.floor(tableContainer.value.scrollTop / rowsHeight.value) - 10);
  rowsPoolCount.value = Math.min(dataIndexes.value.length, Math.ceil(tableContainer.value.offsetHeight / rowsHeight.value) + 20);

  if (rowsPool.value.length !== rowsPoolCount.value) { rowsPool.value = new Array(rowsPoolCount.value); }

  const rowIToShow = new Set(dataIndexes.value.slice(rowsPoolFrom.value, rowsPoolFrom.value + rowsPoolCount.value));
  let i = 0;

  // Remove items that should not be visible
  for (i = 0; i < rowsPool.value.length; i++) {
    if (rowsPool.value[i] != null && rowsPool.value[i].rowI != null && rowIToShow.has(rowsPool.value[i].rowI)) {
      rowIToShow.delete(rowsPool.value[i].rowI);
    } else {
      rowsPool.value[i] = null;
    }
  }

  // Add items that where not visible in empty slots
  i = 0;
  for (let j = rowsPoolFrom.value; j < rowsPoolFrom.value + rowsPoolCount.value; j++) {
    if (rowIToShow.has(dataIndexes.value[j])) {
      while (i < rowsPool.value.length) {
        if (rowsPool.value[i] == null) {
          rowsPool.value[i] = { rowI: dataIndexes.value[j], offset: props.rowsGap + (rowsHeight.value * j) - (rowsHeight.value * i), odd: j % 2 !== 0 };
          break;
        }
        i++;
      }
    }
  }

  // Hide unused elements of the pool
  for (i = 0; i < rowsPool.value.length; i++) {
    if (rowsPool.value[i] == null) {
      rowsPool.value[i] = { rowI: -i, offset: -9999 };
    }
  }
}

let ticking = false;
function onScroll () {
  if (!ticking) {
    window.requestAnimationFrame(() => {
      scrolling.value = tableContainer.value.scrollTop > 0;

      if (dataIsFetch.value) { // we only need to execute this if fetchData really loads some more data
        const distanceFromBottom = tableContainer.value.scrollHeight - (tableContainer.value.scrollTop + tableContainer.value.offsetHeight);
        if (distanceFromBottom < (props.fetchTriggerDistance * rowsHeight.value) && !dataLoading.value && !dataAll.value) {
          fetchData({ immediate: true });
        }
      }

      updateRowsPool();

      ticking = false;
    });
    ticking = true;
  }
}

/* CLICK */
function cellClick (rowI, col) {
  emit('row-click', { index: rowI, row: data.value[rowI] });
  emit('cell-click', { index: rowI, row: data.value[rowI], col, val: dataDisplay.value[rowI][col] });
}

/* UTILS */
function resetScrollTop () {
  nextTick(() => { tableContainer.value.scrollTop = 0; });
}

function dataReset (clear) {
  if (clear) { data.value = []; }
  return fetchData({ reset: true });
}

defineExpose({ data, dataReset });
</script>

<template>
  <div ref="tableContainer" class="tableContainer w-100 h-100 overflow-auto" :class="{ 'loading': loading }" @scroll="onScroll">
    <table ref="table" class="table m-0" :class="tableClasses" style="border-collapse: collapse; border-spacing: 0px; min-width: 100%; width: auto;" :style="{ 'vertical-align': props.verticalAlign, 'text-align': props.horizontalAlign }" role="grid">
      <thead ref="tableHead">
        <tr :class="{ 'shadow': scrolling }">
          <th v-for="(col, colI) in props.cols" :key="colI" :class="{ 'filtering': !!col.filtrable, 'sorting': col.sortable }" :width="col.width">
            <cns-dropdown v-if="!!col.filtrable && filterConfByCol[col.name]" class="filterButton" keep-open-on-click>
              <template #target="{ toggle }">
                <span @click="toggle" class="filterIcon w-100 h-100 d-flex align-items-center justify-content-center" :class="{ 'active': filterConfByCol[col.name].active }">
                  <VIIcon type="bars-filter" class="pe-none"/>
                </span>
            </template>

              <template #content="{ close }">
                <div class="d-flex flex-column p-2" style="min-width: 250px;">
                  <div class="d-flex flex-nowrap mb-2 gap-2 align-items-center">
                    <span class="flex-fill text-center">{{$edw[col.label]}}</span>
                    <cns-inline-select :options="filterConfByCol[col.name].opOptions" v-model="filterConfByCol[col.name].value.op" size="sm" class="text-decoration-none border border-1"/>

                  <cns-select          v-if="filterConfByCol[col.name].type === 'select'" :options="filterConfByCol[col.name].valOptions" v-model="filterConfByCol[col.name].value.val" size="sm" />
                  <cns-datepicker v-else-if="filterConfByCol[col.name].type === 'date'"          v-model="filterConfByCol[col.name].value.val" :enableTimePicker="false" close-on-auto-apply :local="props.useTimeLocal"/>
                  <cns-datepicker v-else-if="filterConfByCol[col.name].type === 'daterange'"     v-model="filterConfByCol[col.name].value.val" :enableTimePicker="false" close-on-auto-apply range :local="props.useTimeLocal"/>
                  <cns-datepicker v-else-if="filterConfByCol[col.name].type === 'time'"          v-model="filterConfByCol[col.name].value.val" timePicker close-on-auto-apply :local="props.useTimeLocal"/>
                  <cns-datepicker v-else-if="filterConfByCol[col.name].type === 'datetime'"      v-model="filterConfByCol[col.name].value.val" close-on-auto-apply :local="props.useTimeLocal"/>
                  <cns-datepicker v-else-if="filterConfByCol[col.name].type === 'datetimerange'" v-model="filterConfByCol[col.name].value.val" close-on-auto-apply range :local="props.useTimeLocal"/>
                  <cns-input      v-else-if="filterConfByCol[col.name].type === 'free'"          v-model="filterConfByCol[col.name].value.val" size="sm" />
                </div>

                <div class="d-flex flex-nowrap justify-content-end gap-2">
                  <cns-button v-if="filterConfByCol[col.name].active" @click="setFilter(col.filter || col.name, null, col.name); close();" variant="secondary" size="sm" :text="$edw.remove"></cns-button>
                  <cns-button @click="setFilter(col.filter || col.name, filterConfByCol[col.name].value, col.name); close();" variant="primary" size="sm" :text="$edw.apply"></cns-button>
                </div>
              </div>
            </template>
          </cns-dropdown>
          <slot :name="col.name+'-head'" :col="col.name" :lbl="col.label">
            <slot name="head" :col="col.name" :lbl="col.label">
              <div class="headStdContent">{{$edw[col.label]}}</div>
            </slot>
          </slot>
          <span v-if="col.sortable" class="sortButton">
              <span @click="toggleOrder(col.sort || col.name)" class="sortIcon w-100 h-100 d-flex align-items-center justify-content-center" :class="{ 'active': !!order.get(col.sort || col.name) }">
                <VIIcon v-if="order.get(col.sort || col.name) === 1" type="arrow-down-short-wide" class="pe-none"/>
                <VIIcon v-else-if="order.get(col.sort || col.name) === -1" type="arrow-up-short-wide" class="pe-none"/>
                <VIIcon v-else type="arrow-down-arrow-up" class="pe-none"/>
              </span>
            </span>
          <!-- <span class="columnResizer" @mousedown="startColumnResize($event, col.name)" @click.stop/> -->
        </th>
      </tr>
      </thead>
      <tbody ref="tableBody" class="tableBody" :style="{ '--row-height': `${rowsHeight}px` }">
      <!-- Error row -->
      <tr v-if="!dataLoading && dataLoadError" class="noHover" :style="{ height: 'auto', position: 'sticky', top: tableHeadHeight + 'px', zIndex: 1 }">
        <td :colspan="props.cols.length">
          <cns-alert icon="hexagon-exclamation" variant="danger" class="m-0">{{$edw[dataLoadError]}}</cns-alert>
        </td>
      </tr>

      <!-- No data row -->
      <tr v-else-if="!dataLoading && dataDisplay.length === 0" class="noHover" :style="{ height: '100%', boxShadow: 'none' }">
        <td :colspan="props.cols.length">
          <div class="text-muted">{{$edw.noData}}</div>
        </td>
      </tr>

      <!-- Row used to calculate rows height -->
      <tr v-if="dataDisplay.length > 0" ref="dummyRow" class="dummyRow" style="display: none;" :style="{ '--row-height': `auto` }">
        <td v-for="(col, colI) in props.cols" :key="colI">
          <slot :name="col.name" :val="dataDisplay[0][col.name]" :row="dataDisplay[0]._row" :index="0" :col="col.name">
            <slot name="cell" :val="dataDisplay[0][col.name]" :row="dataDisplay[0]._row" :index="0" :col="col.name">
              <span class="cellStdContent">{{dataDisplay[0][col.name]}}</span>
            </slot>
          </slot>
        </td>
      </tr>

      <!-- Data rows -->
      <tr v-for="({ rowI, offset, odd }, poolI) in rowsPool" :key="poolI" class="dataRow" :class="{ 'odd': odd }" :style="{ transform: `translateY(${offset}px)` }">
        <template v-if="dataDisplay[rowI] != null">
          <td v-for="(col, colI) in props.cols" :key="colI" @click="cellClick(rowI, col.name)">
            <slot :name="col.name" :val="dataDisplay[rowI][col.name]" :setVal="(newVal) => setData(rowI, col.name, newVal)" :row="dataDisplay[rowI]._row" :index="rowI" :col="col.name">
              <slot name="cell" :val="dataDisplay[rowI][col.name]" :setVal="(newVal) => setData(rowI, col.name, newVal)" :row="dataDisplay[rowI]._row" :index="rowI" :col="col.name">
                <span class="cellStdContent">{{dataDisplay[rowI][col.name]}}</span>
              </slot>
            </slot>
          </td>
        </template>
      </tr>

      <!-- Placeholder -->
      <tr class="noHover" :style="{ height: placeholderRowHeight > 0 ? `${placeholderRowHeight}px` : '100%' }"><td :colspan="props.cols.length" :style="{ height: 'auto' }"></td></tr>
      </tbody>
    </table>
    <div class="loaderContainer w-100 position-sticky bottom-0"><div class="loaderBar"></div></div>
    <!-- <div v-if="resizingCol" class="colResizerIndicator h-100 position-fixed top-0 left-0" :style="{ transform: `translateX(${colResizerPos}px)` }"></div> -->
  </div>
</template>

<style scoped>
/* .columnResizer {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 10px;
  cursor: col-resize;
  transform: translateX(50%);
  z-index: 1;
}

.colResizerIndicator {
  background-color: #F00;
  width: 4px;
  z-index: 1;
} */

.tableContainer {
  scroll-behavior: smooth;
  background-color: var(--hig-page-bg);
}

.table {
  height: 100%;
}

.table, .table > thead, .table > thead > *, .table > thead > * > * {
  background: inherit;
}

.table > thead > * > * {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  position: relative;
}

.table > thead > * > * > .headStdContent {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.table > tbody {
  --row-height: auto;
  border: none;
}

.table > thead > * {
  border: none;
  box-shadow: 0 2px 0 var(--hig-page-border-medium);
  position: relative;
}

.table > tbody > * {
  height: var(--row-height);
  border: none;
  box-shadow: inset 0 -1px 0 var(--hig-page-border-light);
}

.table > :not(caption) > * > * {
  border: none;
}

.table > tbody > * > * {
  padding: 0 !important;
  background-color: var(--hig-table-accent-bg);
}

/* .table > tbody > * > * > :deep(*) { */
.table > tbody > * > * {
  height: var(--row-height);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.table .cellStdContent {
  padding: 0 3px;
}

/* TABLE SORTING */
/* .table > thead > * > :not(.sorting) > .sortButton > .sortIcon {
  display: none;
} */

.table > thead > * > .sorting {
  padding-right: 30px !important;
}

.table > thead > * > .sorting > .sortButton {
  cursor: pointer;
  position: absolute;
  right: 0rem;
  bottom: 0;
  height: 100%;
  width: 30px;
}

.table > thead > * > .sorting > .sortButton > .sortIcon {
  opacity: .3;
  transition: opacity .3s ease;
}

.table > thead > * > .sorting > .sortButton > .sortIcon.active {
  opacity: 1;
}

.table > thead > * > .sorting > .sortButton > .sortIcon:hover {
  opacity: .7;
}
/* TABLE SORTING */

/* TABLE FILTERING */
/* .table > thead > * > :not(.filtering) > .filterButton > .filterIcon {
  display: none;
} */

.table > thead > * > .filtering {
  padding-left: 30px !important;
}

.table > thead > * > .filtering > .filterButton {
  cursor: pointer;
  position: absolute;
  left: 0rem;
  bottom: 0;
  height: 100%;
  width: 30px;
}

.table > thead > * > .filtering > .filterButton > .filterIcon {
  opacity: .3;
  transition: opacity .3s ease
}

.table > thead > * > .filtering > .filterButton > .filterIcon.active {
  opacity: 1;
}

.table > thead > * > .filtering > .filterButton > .filterIcon:hover {
  opacity: .7;
}
/* TABLE FILTERING */

/* TABLE BORDERED */
.table.table-bordered > tbody > * {
  border: none;
  box-shadow: inset 0 -1px 0 var(--hig-page-border-light);
}

.table.table-bordered > :not(caption) > * > * {
  border: none;
  box-shadow: inset 1px 0 0 var(--hig-page-border-light);
}

.table.table-bordered > thead > * > * {
  box-shadow: inset 1px 0 0 var(--hig-page-border-light), inset 0 1px 0 var(--hig-page-border-light);
}

.table.table-bordered > :not(caption) > * > *:last-child {
  border: none;
  box-shadow: inset 1px 0 0 var(--hig-page-border-light), inset -1px 0 0 var(--hig-page-border-light);
}

.table.table-bordered > thead> * > *:last-child {
  border: none;
  box-shadow: inset 1px 0 0 var(--hig-page-border-light), inset -1px 0 0 var(--hig-page-border-light), inset 0 1px 0 var(--hig-page-border-light);
}
/* TABLE BORDERED */

/* TABLE BORDERLESS */
.table.table-borderless > :not(caption) > * {
  border: none;
  box-shadow: none;
}

.table.table-borderless > :not(caption) > * > * {
  border: none;
  box-shadow: none;
}
/* TABLE BORDERLESS */

/* TABLE STRIPED */
.table.table-striped > tbody > * > * {
  --hig-table-accent-bg: none;
  color: inherit;
}

.table.table-striped > tbody > tr.odd > * {
  --hig-table-accent-bg: var(--hig-table-striped-bg);
  color: var(--hig-table-striped-color);
}
/* TABLE STRIPED */

/* TABLE HOVER */
.table.table-hover > tbody > tr:hover:not(.noHover) > * {
  --hig-table-accent-bg: var(--hig-primary-bg-active);
  color: var(--hig-primary-text);
  cursor: pointer;
}
/* TABLE HOVER */

/* TABLE STICKY HEADER */
.table.table-sticky-header > thead > * {
  position: sticky;
  top: 0;
  z-index: 1;
  transition: box-shadow .3s ease;
}
/* TABLE STICKY HEADER */

.loaderContainer {
  height: 3px;
  background-color: var(--hig-primary-shadow);
  overflow: hidden;
  display: none;
}

.loaderBar {
  width: 100%;
  height: 100%;
  background-color: var(--hig-primary);
  animation: indeterminateLoaderAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateLoaderAnimation {
  0% { transform: translateX(0) scaleX(0); }
  40% { transform: translateX(0) scaleX(0.4); }
  100% { transform: translateX(100%) scaleX(0.5); }
}

.tableContainer.loading > .table {
  height: calc(100% - 3px);
}

.tableContainer.loading > .loaderContainer {
  display: block;
}
</style>
