import { HigError } from '../hig-error/index.mjs';

class UtilsError extends HigError {
  static PasswordNoUpperCase () {
    return new this('PasswordNoUpperCase', { warning: true, print: false });
  }

  static PasswordNoLowerCase () {
    return new this('PasswordNoLowerCase', { warning: true, print: false });
  }

  static PasswordNoDigit () {
    return new this('PasswordNoDigit', { warning: true, print: false });
  }

  static PasswordNoSpecialCharacter ({ specialCharList }: { specialCharList: string }) {
    return new this('PasswordNoSpecialCharacter', { warning: true, print: false, context: { specialCharList } });
  }

  static PasswordCharacterNotAllowed ({ charNotAllowed }: { charNotAllowed: string }) {
    return new this('PasswordCharacterNotAllowed', { warning: true, print: false, context: { charNotAllowed } });
  }

  static PasswordMinLength ({ minLength }: { minLength: number }) {
    return new this('PasswordMinLength', { warning: true, print: false, context: { minLength } });
  }

  static PasswordMaxLength ({ maxLength }: { maxLength: number }) {
    return new this('PasswordMaxLength', { warning: true, print: false, context: { maxLength } });
  }

  static PasswordNoUsername () {
    return new this('PasswordNoUsername', { warning: true, print: false });
  }
}

export {
  UtilsError
};
