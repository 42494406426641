<script setup>
import { computed } from 'vue';

const props = defineProps({
  loading: { type: Boolean, default: false },
  placeholder: { type: [Object, String], default: undefined }
});

const placeholder = computed(() => typeof props.placeholder === 'string' ? props.placeholder : '');
</script>

<template>
  <div
    :class="{ 'loading': !!props.loading }"
    :style="{
      '--loading-width': props.placeholder?.width || 'auto',
      '--loading-height': props.placeholder?.height || 'auto'
    }"
  >
    <template v-if="props.loading && props.placeholder">{{ placeholder }}</template>
    <slot v-else/>
  </div>
</template>

<style scoped>
.loading {
  --loading-width: auto;
  --loading-height: auto;
  border-radius: var(--hig-border-radius);
  animation: loading 3s linear infinite;
  backdrop-filter: brightness(.5);
  color: transparent !important;
  background: transparent !important;
  user-select: none;

  min-width: var(--loading-width);
  min-height: var(--loading-height);
}

.loading:deep(*) {
  visibility: hidden;
}

@keyframes loading {
  50% { backdrop-filter: brightness(.9); }
}
</style>
