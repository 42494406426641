<script setup>
import { computed, ref, watch } from 'vue';
import CnsDropdown from './cns-dropdown.vue';

const props = defineProps({
  modelValue: { type: [String, Number], default: undefined },
  options: { type: Array, default: function () { return []; } }
});
const emit = defineEmits(['update:modelValue']);

const value = ref(null);
const valueText = computed(() => {
  const curOption = props.options.find((opt) => String(opt.value) === String(value.value));
  return curOption ? curOption.text : '';
});

watch(() => props.modelValue, () => { value.value = props.modelValue; }, { immediate: true });

function change (newVal) {
  value.value = newVal;
  emit('update:modelValue', newVal);
}
</script>

<script>
export default {
  inheritAttrs: false
};
</script>

<template>
  <cns-dropdown>
    <template #target="{ toggle }">
      <span class="cns-inline-select p-0" @click="toggle" v-bind="$attrs">
        {{valueText}}
      </span>
    </template>

    <div class="p-1 gap-1 d-flex flex-column">
      <div v-for="opt in props.options" :key="opt.value" class="cns-inline-select-option px-2 py-1 d-flex gap-2 rounded" :class="{ 'selected': String(opt.value) === String(value) }" @click="change(opt.value)">
        <span>{{opt.text}}</span>
        <span v-if="opt.desc" style="opacity: .5;">{{opt.desc}}</span>
      </div>
    </div>
  </cns-dropdown>
</template>

<style scoped>
.cns-inline-select{
  cursor: pointer;
  white-space: nowrap;
  text-decoration: underline;
}

.cns-inline-select-option{
  cursor: pointer;
}

.cns-inline-select-option.selected{
  background-color: var(--hig-primary);
  color: var(--hig-primary-text);
}

.cns-inline-select-option:hover{
  background-color: var(--hig-neutral-400);
  color: var(--hig-neutral-900);
}

.cns-inline-select-option.selected:hover{
  background-color: var(--hig-primary);
  color: var(--hig-primary-text);
}
</style>
