<template>
  <div class="cns-module">
    <div v-if="error" class="position-absolute top-50 start-50 translate-middle">
      <div class="mb-3 alert alert-danger" role="alert">{{id + ' - ' + $edw[error]}}</div>
      <div class="d-flex flex-row justify-content-center">
        <b-button variant="secondary" class="mx-1" @click="retryModuleLoad()">{{$edw.retry}}</b-button>
      </div>
    </div>
    <parcel v-else-if="type === 'systemjs'" :module="src" :props="props" :path="path" @error="moduleError = $event"/>
    <iframe v-else-if="type === 'html'" :src="src" @error="moduleError = $event" class="w-100 h-100 d-block"></iframe>
  </div>
</template>

<script>
/**
 * module must be something like this:
 * {
 *   id: 'my-module'
 *   name: 'My module'
 *   desc: 'This module do things'
 *   type: 'systemjs',
 *   src: '@cns3/index'
 * }
 */
import parcel from './parcel.vue';
import BButton from '../../../components/bootstrap/b-button.vue';

export default {
  name: 'cns-module',
  components: { parcel, BButton },
  props: {
    module: { type: Object, default: undefined },
    props: { type: Object, default: function () { return {}; } },
    path: { type: String, default: undefined },
    isRoute: { type: Boolean, default: false }
  },
  data () {
    return {
      moduleError: undefined
    };
  },
  computed: {
    error: function () {
      if (!this.module) { return 'noModuleSpecified'; }
      if (!this.module.type || (this.module.type !== 'systemjs' && this.module.type !== 'html')) { return 'moduleTypeNotValid'; }
      if (this.moduleError) { return this.moduleError; }
      return undefined;
    },
    id: function () { return this.module && this.module.id; },
    name: function () { return this.module && this.module.name; },
    type: function () { return this.module && this.module.type; },
    src: function () {
      if (this.module && this.module.type === 'systemjs') {
        return this.module.src;
      } else if (this.module && this.module.type === 'html') {
        const srcUrl = new URL(this.module.src, window.location.href);
        let pathname = srcUrl.pathname;
        const query = new URLSearchParams();
        srcUrl.searchParams.forEach((val, key) => query.append(key, val));

        if (this.path) {
          pathname = pathname.replace(/\/(\w+\.html)?$/, `/${this.path}`);
        } else if (this.props?.path) {
          pathname = pathname.replace(/\/(\w+\.html)?$/, `/${this.props.path}`);
        }

        if (this.props) {
          Object.keys(this.props).forEach((key) => {
            if (key === 'path') { return; }
            query.append(key, this.props[key]);
          });
        }

        if (this.$attrs) {
          Object.keys(this.$attrs).forEach((key) => {
            query.append(key, this.$attrs[key]);
          });
        }

        const queryStr = query.toString();
        return srcUrl.origin + pathname + srcUrl.hash + (queryStr ? '?' + queryStr : '');
      }
      return '';
    }
  },
  watch: {
    module: function () {
      this.moduleError = undefined;
    }
  },
  methods: {
    retryModuleLoad () {
      setTimeout(() => {
        this.moduleError = undefined;
      }, 500);
    }
  },
  mounted () {}
};
</script>

<style scoped>
.cns-module{
  overflow: auto;
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
