<script>
import { ref, watch, h, inject, nextTick, defineComponent, provide, getCurrentInstance, onUnmounted } from 'vue';
import Breadcumb from './router-view-split-breadcumb.vue';
import CnsButton from '../../../components/cns/cns-button.vue';
import Utils from '../../../libs/utils';
import _ from 'lodash';

const HISTORY = '__cnsRouter_rvs_history';
const HISTORY_TITLES = '__cnsRouter_rvs_historyTitles';
const HISTORY_INDEX = '__cnsRouter_rvs_historyIndex';
const HISTORY_ID = '__cnsRouter_rvs_historyId';

const SET_ROUTE_TITLE_INJECT_KEY = Symbol('__cnsRouter_rvs_setRouteTitle');

const PAGE_INDEX = '__cnsRouter_rvs_index';
const PAGE_TYPE = '__cnsRouter_rvs_type';
const IS_ROOT = '__cnsRouter_rvs_root';
const IS_CURR = '__cnsRouter_rvs_curr';
const IS_PREV = '__cnsRouter_rvs_prev';

const PAGE_POS = '__cnsRouter_rvs_pos';
const IS_FULL = '__cnsRouter_rvs_full';
const IS_LEFT = '__cnsRouter_rvs_left';
const IS_RIGHT = '__cnsRouter_rvs_right';

function patchToState (to, state) {
  to = typeof to === 'string' ? { path: to } : to;
  if (to) { return { ...to, state: { ...to?.state, ...(state || {}) } }; }
}

function proxyPatchObj (obj, patch) {
  return new Proxy(obj, {
    get (_, prop) { return prop in patch ? patch[prop] : Reflect.get(...arguments); }
  });
}

function getRouteProps (route) {
  let routeProps = route?.props;
  if (typeof routeProps === 'function') { routeProps = routeProps(); }
  if (typeof routeProps !== 'object') { routeProps = undefined; }
  return routeProps;
}

function getComponentAttrRec (component, attr) {
  if (!component || !component.attrs) { return undefined; }
  if (component.attrs[attr] != null) { return component.attrs[attr]; }
  return getComponentAttrRec(component.parent, attr);
};

const RouterViewSplit = defineComponent({
  name: 'RouterViewSplit',
  props: {},
  compatConfig: { MODE: 3 },

  setup (props, { attrs }) {
    const $depth = inject(window.cnsRouterInstance.injectKeys.routerDepth);
    const $router = inject(window.cnsRouterInstance.injectKeys.router);

    const breadcumbVnode = h(Breadcumb, { onGo: (i) => { $router.go(i - historyIndex.value); } });

    const history = ref();
    const historyTitles = ref();
    let fromHistoryId = 0;
    const historyId = ref();
    let fromHistoryIndex = 0;
    const historyIndex = ref();
    let fromCurrFull = false;
    const currFull = ref(false);

    let isRendered = {};
    let navigationGuards = {};

    const navigationGuardsAdd = (index, type, args) => {
      if (!navigationGuards[index]) { navigationGuards[index] = []; }
      const ngIndex = navigationGuards[index].push({ type, args }) - 1;

      // If the navigation guard was added by the current or previous page activate it too
      if (index === historyIndex.value || index === historyIndex.value - 1) {
        navigationGuards[index][ngIndex].unsub = $router[type](...args);
      }

      return () => {
        if (!navigationGuards[index]?.[ngIndex]) { return; }
        navigationGuards[index][ngIndex].unsub?.();
        navigationGuards[index][ngIndex] = undefined;
      };
    };

    const navigationGuardsSubUnsub = (fromIndex, toIndex) => {
      if (Array.isArray(navigationGuards[fromIndex - 1]) && fromIndex - 1 !== toIndex && fromIndex - 1 !== toIndex - 1) {
        for (const guard of navigationGuards[fromIndex - 1]) {
          if (!guard) { return; }
          guard.unsub?.();
          guard.unsub = undefined;
        }
      }

      if (Array.isArray(navigationGuards[fromIndex]) && fromIndex !== toIndex && fromIndex !== toIndex - 1) {
        for (const guard of navigationGuards[fromIndex]) {
          if (!guard) { return; }
          guard.unsub?.();
          guard.unsub = undefined;
        }
      }

      if (Array.isArray(navigationGuards[toIndex - 1]) && toIndex - 1 !== fromIndex && toIndex - 1 !== fromIndex - 1) {
        for (const guard of navigationGuards[toIndex - 1]) {
          if (!guard) { return; }
          guard.unsub = $router[guard.type](guard.arguments);
        }
      }

      if (Array.isArray(navigationGuards[toIndex]) && toIndex !== fromIndex && toIndex !== fromIndex - 1) {
        for (const guard of navigationGuards[toIndex]) {
          if (!guard) { return; }
          guard.unsub = $router[guard.type](guard.arguments);
        }
      }
    };

    const navigationGuardsClear = () => {
      for (const index in navigationGuards) {
        for (const guard of navigationGuards[index]) {
          if (!guard) { return; }
          guard.unsub?.();
        }
      }
      navigationGuards = {};
    };

    const setHistoryRoute = (index, route) => {
      if (!route) { return; }

      const currMatchedRoute = route.matched?.[(route.matched?.length ?? 0) - ($depth + 1)];

      history.value[index] = {
        path: route.path,
        props: _.cloneDeep({ ...getRouteProps(currMatchedRoute), ...route.props }),
        noSplit: currMatchedRoute?.meta?.noSplit
      };

      historyTitles.value ??= [];
      historyTitles.value[index] = historyTitles.value[index] || route.name || '';
    };

    const setHistoryRouteTitle = (index, title) => {
      historyTitles.value ??= [];
      historyTitles.value[index] = title;
      breadcumbVnode.component?.exposed?.setEntry?.(index, title);
    };

    const updateHistoryIdAndIndex = (route) => {
      const newHistoryIndex = route.state?.[HISTORY_INDEX + '_' + $router.basePath] || 0;
      if (historyIndex.value == null || newHistoryIndex !== historyIndex.value) {
        navigationGuardsSubUnsub(historyIndex.value, newHistoryIndex);

        historyIndex.value = newHistoryIndex;
      }

      const newHistoryId = route.state?.[HISTORY_ID + '_' + $router.basePath] || (fromHistoryId + 1);
      if (historyId.value == null || newHistoryId !== historyId.value) {
        navigationGuardsClear();

        historyId.value = newHistoryId;

        isRendered = {};

        const ssHistory = sessionStorage.getItem(HISTORY + '_' + $router.basePath + '_' + historyId.value);
        history.value = ssHistory ? JSON.parse(ssHistory) : undefined;

        const ssHistoryTitles = sessionStorage.getItem(HISTORY_TITLES + '_' + $router.basePath + '_' + historyId.value);
        historyTitles.value = ssHistoryTitles ? JSON.parse(ssHistoryTitles) : undefined;

        if (!Array.isArray(history.value) || history.value.length === 0) {
          history.value = [];
          historyTitles.value = [];
          historyIndex.value = 0;
        }
      }

      setHistoryRoute(historyIndex.value, route);

      nextTick(() => {
        breadcumbVnode.component?.exposed?.setActive?.(historyIndex.value);
        breadcumbVnode.component?.exposed?.setEntries?.(historyTitles.value.slice(0, historyIndex.value + 1));
      });
    };
    updateHistoryIdAndIndex($router.route);

    // Every time the route changes save it to session storage
    const saveHistoryToSS = _.debounce(() => { sessionStorage.setItem(HISTORY + '_' + $router.basePath + '_' + historyId.value, JSON.stringify(history.value)); }, 500);
    watch(history, () => { if (history.value && historyId.value != null) { saveHistoryToSS(); } }, { deep: true });

    // Every time the route titles change save those to session storage
    const saveHistoryTitlesToSS = _.debounce(() => { sessionStorage.setItem(HISTORY_TITLES + '_' + $router.basePath + '_' + historyId.value, JSON.stringify(historyTitles.value)); }, 500);
    watch(historyTitles, () => { if (historyTitles.value && historyId.value != null) { saveHistoryTitlesToSS(); } }, { deep: true });

    // When loading a route in the current module that does not contain a history id add it and reset the index
    const unsubBeforeEach = $router.beforeEach((to) => {
      if (to.state[HISTORY_ID + '_' + $router.basePath] == null && to.basePath === $router.basePath) {
        return { path: to.path, props: to.props, query: to.query, state: { ...to.state, [HISTORY_ID + '_' + $router.basePath]: fromHistoryId + 1, [HISTORY_INDEX + '_' + $router.basePath]: 0 } };
      }
    });

    // Every time the route changes update it
    const unsubAfterEach = $router.afterEach((to) => { updateHistoryIdAndIndex(to); });

    // Remove the navigarion guards when unmounting this component
    onUnmounted(() => { unsubBeforeEach(); unsubAfterEach(); });

    const getRouter = (component) => {
      return proxyPatchObj($router, {
        push: (to) => {
          const componentSide = getComponentAttrRec(component, PAGE_TYPE);
          switch (componentSide) {
            case IS_ROOT:
            case IS_CURR:
              return $router.push(patchToState(to, { [HISTORY_ID + '_' + $router.basePath]: historyId.value, [HISTORY_INDEX + '_' + $router.basePath]: historyIndex.value + 1 }))
                .then(() => setHistoryRoute(historyIndex.value, $router.route));
            case IS_PREV:
              return $router.replace(patchToState(to, $router.route?.state))
                .then(() => setHistoryRoute(historyIndex.value, $router.route));
            default:
              return $router.push(to);
          }
        },

        replace: (to) => {
          const componentSide = getComponentAttrRec(component, PAGE_TYPE);
          switch (componentSide) {
            case IS_ROOT:
            case IS_CURR:
              return $router.replace(patchToState(to, $router.route?.state))
                .then(() => setHistoryRoute(historyIndex.value, $router.route));
            case IS_PREV:
              return Promise.resolve()
                .then(() => setHistoryRoute(historyIndex.value - 1, window.cnsRouterInstance.parseToRoute(to, $router.basePath)));
            default:
              return $router.replace(to);
          }
        },

        back: () => {
          const componentSide = getComponentAttrRec(component, PAGE_TYPE);
          switch (componentSide) {
            case IS_PREV:
              return $router.go(-2);
            case IS_ROOT:
            case IS_CURR:
            default:
              return $router.go(-1);
          }
        },

        go: (to) => {
          const componentSide = getComponentAttrRec(component, PAGE_TYPE);
          switch (componentSide) {
            case IS_PREV:
              return $router.go((Utils.isNum(to) ? Number(to) : 0) - 1);
            case IS_ROOT:
            case IS_CURR:
            default:
              return $router.go(to);
          }
        },

        beforeEach () {
          const componentIndex = getComponentAttrRec(component, PAGE_INDEX);
          return navigationGuardsAdd(componentIndex, 'beforeEach', arguments);
        },
        afterEach () {
          const componentIndex = getComponentAttrRec(component, PAGE_INDEX);
          return navigationGuardsAdd(componentIndex, 'afterEach', arguments);
        }
      });
    };

    const currentInstance = getCurrentInstance();
    provide(window.cnsRouterInstance.injectKeys.routerDepth, $depth + 1);
    Object.defineProperty(currentInstance.provides, window.cnsRouterInstance.injectKeys.router, {
      get () { return getRouter(getCurrentInstance()); },
      enumerable: true
    });
    Object.defineProperty(currentInstance.provides, SET_ROUTE_TITLE_INJECT_KEY, {
      get () {
        const component = getCurrentInstance();
        return (newTitle) => {
          const index = getComponentAttrRec(component, PAGE_INDEX);
          setHistoryRouteTitle(index, newTitle);
        };
      },
      enumerable: true
    });

    const componentsCache = {};
    function getRouteComponent (path) {
      if (!componentsCache[path]) {
        const route = window.cnsRouterInstance.parseToRoute(path, $router.basePath);
        const currMatchedRoute = route?.matched?.[(route?.matched?.length ?? 0) - ($depth + 1)];

        componentsCache[path] = currMatchedRoute?.component;
      }
      return componentsCache[path];
    }

    let afterDrawUpdateTo;
    let requestAnimationFrameTo;
    return () => {
      const forward = fromHistoryIndex < historyIndex.value;
      const backward = fromHistoryIndex > historyIndex.value;
      const expandCurr = currFull.value === true && fromCurrFull === false;
      const compressCurr = currFull.value === false && fromCurrFull === true;

      // This function is called multiple times before rendering
      // so only update those values after the last call
      clearTimeout(afterDrawUpdateTo);
      afterDrawUpdateTo = setTimeout(() => {
        fromHistoryId = historyId.value;
        fromHistoryIndex = historyIndex.value;
        fromCurrFull = currFull.value;
      }, 0);

      let showMaximizeButton = true;
      const pagesVnodes = [];
      for (let i = 0; i <= historyIndex.value; i++) {
        // If the history entry does not exists skip it
        // this should neve happen but in case it does it still kinda works
        if (!history.value[i]) { continue; }

        const isRoot = historyIndex.value === 0;
        const isCurr = i === historyIndex.value;
        const isPrev = i === historyIndex.value - 1;
        const isFull = isRoot || (isCurr && (currFull.value || history.value[i].noSplit || history.value[i - 1]?.noSplit));
        const isNextFull = isPrev && (currFull.value || history.value[i].noSplit || history.value[i + 1]?.noSplit);
        const isLeft = isPrev && !isFull && !isNextFull;
        const isRight = isCurr && !isFull;

        showMaximizeButton = showMaximizeButton && !(isCurr && (history.value[i].noSplit || history.value[i - 1]?.noSplit));

        // Do not render vNodes that where not rendered before and are not visible
        if (!isRoot && !isCurr && !isPrev && !isRendered[i]) { continue; }
        isRendered[i] = true;

        const classes = {};

        classes.full = isFull;
        classes.left = isLeft;
        classes.right = isRight;
        classes.new = isCurr && forward;
        classes.old = (((isPrev && !isNextFull) || (isCurr && isFull)) && backward) || (isPrev && (expandCurr || compressCurr));

        pagesVnodes.push(h('div', { class: ['route-container', classes], key: i }, [
          h(getRouteComponent(history.value[i].path), {
            ...history.value[i].props,
            [PAGE_TYPE]: isRoot ? IS_ROOT : (isCurr ? IS_CURR : IS_PREV),
            [PAGE_POS]: isFull ? IS_FULL : (isRight ? IS_RIGHT : IS_LEFT),
            [PAGE_INDEX]: i
          })
        ]));
      }

      cancelAnimationFrame(requestAnimationFrameTo);
      requestAnimationFrameTo = requestAnimationFrame(() => {
        if (forward) {
          document.querySelector('.new[' + currentInstance.type.__scopeId + ']')?.classList?.remove?.('new');
        } else if (backward || compressCurr) {
          document.querySelector('.old[' + currentInstance.type.__scopeId + ']')?.classList?.remove?.('old');
        } else if (expandCurr) {
          const old = document.querySelector('.old[' + currentInstance.type.__scopeId + ']');
          if (old) {
            const remOldClass = () => {
              old.classList.remove('old');
              old.removeEventListener('transitionend', remOldClass);
              old.removeEventListener('transitioncancel', remOldClass);
            };
            old.addEventListener('transitionend', remOldClass);
            old.addEventListener('transitioncancel', remOldClass);
          }
        }
      });

      return h('div', { ...props, ...attrs, class: ['router-view-split', props.class] }, [
        h('div', { class: ['navigator', 'px-4', 'pt-2', { show: historyIndex.value >= 1 }] }, [
          breadcumbVnode,
          h(CnsButton, { class: ['maximize-button', { show: showMaximizeButton }], icon: currFull.value ? 'table-columns' : 'window-maximize', variant: 'link', onClick: () => { currFull.value = !currFull.value; } })
        ]),
        h('div', { class: 'router' }, pagesVnodes)
      ]);
    };
  }
});

RouterViewSplit.PAGE_INDEX = PAGE_INDEX;

RouterViewSplit.PAGE_TYPE = PAGE_TYPE;
RouterViewSplit.IS_ROOT = IS_ROOT;
RouterViewSplit.IS_CURR = IS_CURR;
RouterViewSplit.IS_PREV = IS_PREV;

RouterViewSplit.PAGE_POS = PAGE_POS;
RouterViewSplit.IS_FULL = IS_FULL;
RouterViewSplit.IS_LEFT = IS_LEFT;
RouterViewSplit.IS_RIGHT = IS_RIGHT;

RouterViewSplit.useSetRouteTitle = () => inject(SET_ROUTE_TITLE_INJECT_KEY, () => { return () => {}; });

export default RouterViewSplit;
</script>

<style scoped>
.router-view-split {
  --transition-duration: .3s;
  --transition-timing: ease;
  position: relative;
}

.navigator {
  position: absolute;
  height: 2.3rem;
  top: -2.5rem;
  left: 0;
  transform: translateY(0%);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: transform var(--transition-duration) var(--transition-timing);
}

.navigator.show {
  transition: transform var(--transition-duration) var(--transition-duration) var(--transition-timing);
  transform: translateY(100%);
}

.maximize-button:not(.show)  {
  display: none !important;
}

.router {
  position: relative;
  height: 100%;
  transition: height var(--transition-duration) var(--transition-timing), margin-top var(--transition-duration) var(--transition-timing);
}

.navigator.show ~ .router {
  margin-top: 2.3rem;
  height: calc(100% - 2.3rem);
}

.route-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: all var(--transition-duration) var(--transition-timing);
}

.route-container.full { width: 100%; left: 0%; }
.route-container.left { width: 50%; left: 0%; }
.route-container.right { width: 50%; left: 50%; }

.route-container.new.full { width: 100%; left: 100%; }
.route-container.old.full { width: 100%; left: -100%; }

.route-container.new:not(.full) { width: 50%; left: 100%; }
.route-container.old:not(.full) { width: 50%; left: -50%; }

.route-container:not(.full):not(.left):not(.right):not(.new):not(.old){
  display: none;
}
</style>
