import { isNode } from '../runtime-detector/index.mjs';
import Utils from '../utils';
import EventEmitter from 'events';
import _ from 'lodash';

/* #IF isNode */
import cluster from 'cluster';
import ClusterUtils from '../cluster-utils/index.mjs';
/* #FI */

class Cache {
  #ee = new EventEmitter();

  constructor (opt = {}) {
    this.cache = {};
    this.setExpiration(Utils.isNum(opt.expiration) ? opt.expiration : (isNode ? 10000 : 3600000));
    this.setMaxSize(Utils.isNum(opt.maxSize) ? opt.maxSize : undefined);
    this.initCompleted = true;
    this.syncPromises = new Set();

    /* #IF isNode */
    this.clusterSync = opt.clusterSync && typeof opt.clusterSync === 'string' ? opt.clusterSync : null;
    if (this.clusterSync) {
      this.initCompleted = false;
      if (cluster.isPrimary) {
        const forwardToWorkers = (act, msg, from) => Promise.all(
          Object.keys(cluster.workers).map((workerId) => {
            if (from == null || cluster.workers[workerId].process.pid !== from.process.pid) {
              return ClusterUtils.send.toWorker(workerId, act, msg, { timeout: 10000 });
            }
            return Promise.resolve();
          })
        );

        ClusterUtils.on(`${this.clusterSync}_CacheSet`, (msg, from) => {
          this.set(msg.key, msg.value, msg.expiration);
          return forwardToWorkers(`${this.clusterSync}_CacheSet`, msg, from);
        });
        ClusterUtils.on(`${this.clusterSync}_CacheFlush`, (msg, from) => {
          this.flush();
          return forwardToWorkers(`${this.clusterSync}_CacheFlush`, msg, from);
        });
        ClusterUtils.on(`${this.clusterSync}_CacheSetExpiration`, (msg, from) => {
          this.setExpiration(msg.expiration);
          return forwardToWorkers(`${this.clusterSync}_CacheSetExpiration`, msg, from);
        });
        ClusterUtils.on(`${this.clusterSync}_CacheSetMaxSize`, (msg, from) => {
          this.setMaxSize(msg.maxSize);
          return forwardToWorkers(`${this.clusterSync}_CacheSetMaxSize`, msg, from);
        });
        ClusterUtils.on(`${this.clusterSync}_CacheGet`, (msg, from) => {
          return { expiration: this.expiration, maxSize: this.maxSize, cache: this.cache };
        });

        this.initCompleted = true;
      } else {
        ClusterUtils.on(`${this.clusterSync}_CacheSet`, (msg) => { this.set(msg.key, msg.value, msg.expiration); });
        ClusterUtils.on(`${this.clusterSync}_CacheFlush`, (msg) => { this.flush(); });
        ClusterUtils.on(`${this.clusterSync}_CacheSetExpiration`, (msg) => { this.setExpiration(msg.expiration); });
        ClusterUtils.on(`${this.clusterSync}_CacheSetMaxSize`, (msg) => { this.setMaxSize(msg.maxSize); });

        // When a cache is created on a worker get the starting values from master
        const initialSync = ClusterUtils.send.toPrimary(`${this.clusterSync}_CacheGet`, {}, { timeout: 10000 })
          .then(({ expiration, maxSize, cache }) => {
            this.setExpiration(expiration);
            this.setMaxSize(maxSize);
            this.cache = cache;
          })
          .catch(() => undefined);

        this.syncPromises.add(initialSync);
        initialSync.finally(() => {
          this.syncPromises.delete(initialSync);
          this.initCompleted = true;
        });
      }
    }
    /* #FI */

    return new Proxy({
      isSync: () => Promise.all([...this.syncPromises]).then(() => undefined).catch(() => undefined),
      get: (key) => {
        if (!this.initCompleted) {
          console.warn('[Cache] Warning, cache still not initialized, await isSync before trying to use the cache');
          return;
        }

        return this.get(key);
      },
      set: (key, value, expiration) => {
        if (!this.initCompleted) {
          console.warn('[Cache] Warning, cache still not initialized, await isSync before trying to use the cache');
          return;
        }

        this.set(key, value, expiration);

        /* #IF isNode */
        // Send the cached value to the other processes
        if (this.clusterSync && ClusterUtils.isRunning) {
          const syncProm = ClusterUtils.send[cluster.isPrimary ? 'toAllWorkers' : 'toPrimary'](`${this.clusterSync}_CacheSet`, { key, value, expiration }, { timeout: 10000 }).catch(() => undefined);

          this.syncPromises.add(syncProm);
          syncProm.finally(() => { this.syncPromises.delete(syncProm); });
        }
        /* #FI */

        return value;
      },
      onSet: (callback) => this.onSet(callback),
      flush: () => {
        if (!this.initCompleted) {
          console.warn('[Cache] Warning, cache still not initialized, await isSync before trying to use the cache');
          return;
        }

        this.flush();

        /* #IF isNode */
        // Send the Cache flush command to the other processes
        if (this.clusterSync && ClusterUtils.isRunning) {
          const syncProm = ClusterUtils.send[cluster.isPrimary ? 'toAllWorkers' : 'toPrimary'](`${this.clusterSync}_CacheFlush`, {}, { timeout: 10000 }).catch(() => undefined);

          this.syncPromises.add(syncProm);
          syncProm.finally(() => { this.syncPromises.delete(syncProm); });
        }
        /* #FI */
      },
      onFlush: (callback) => this.onFlush(callback),
      setExpiration: (expiration) => {
        if (!this.initCompleted) {
          console.warn('[Cache] Warning, cache still not initialized, await isSync before trying to use the cache');
          return;
        }

        this.setExpiration(expiration);

        /* #IF isNode */
        // Send the new expiration to the other processes
        if (this.clusterSync && ClusterUtils.isRunning) {
          ClusterUtils.send[cluster.isPrimary ? 'toAllWorkers' : 'toPrimary'](`${this.clusterSync}_CacheSetExpiration`, { expiration }, { timeout: 10000 }).catch(() => undefined);
        }
        /* #FI */
      },
      setMaxSize: (maxSize) => {
        if (!this.initCompleted) {
          console.warn('[Cache] Warning, cache still not initialized, await isSync before trying to use the cache');
          return;
        }

        this.setMaxSize(maxSize);

        /* #IF isNode */
        // Send the new expiration to the other processes
        if (this.clusterSync && ClusterUtils.isRunning) {
          ClusterUtils.send[cluster.isPrimary ? 'toAllWorkers' : 'toPrimary'](`${this.clusterSync}_CacheSetMaxSize`, { maxSize }, { timeout: 10000 }).catch(() => undefined);
        }
        /* #FI */
      },
      ownKeys: () => {
        if (!this.initCompleted) {
          console.warn('[Cache] Warning, cache still not initialized, await isSync before trying to use the cache');
          return;
        }

        return Object.keys(this.cache)
          .filter((key) => this.get(key) !== undefined);
      },
      toString: () => {
        if (!this.initCompleted) {
          console.warn('[Cache] Warning, cache still not initialized, await isSync before trying to use the cache');
          return;
        }

        const res = {};
        Object.keys(this.cache).forEach((key) => {
          const value = this.get(key);
          if (value !== undefined) { res[key] = value; }
        });

        return JSON.stringify(res);
      }
    }, {
      get: (target, key) => {
        if (target[key]) { return target[key]; }
        return target.get(key);
      },
      set: (target, key, value) => {
        target.set(key, value);
        return true;
      },
      has: (target, key) => {
        return target.get(key) != null;
      },
      ownKeys: (target) => {
        return target.ownKeys();
      },
      getOwnPropertyDescriptor (target, key) {
        const value = target.get(key);
        if (value != null) {
          return { configurable: true, enumerable: true, writable: true, value };
        }
      },
      deleteProperty: (target, key) => {
        target.set(key, undefined);
        return true;
      },
      defineProperty: (target, prop, descriptor) => {
        console.warn('Cannot define a property on Cache, please just set it or use the set method');
        return false;
      }
      // preventExtensions
      // isExtensible
      // getPrototypeOf
      // setPrototypeOf
    });
  }

  setExpiration (expiration) {
    if (Utils.isNum(expiration)) {
      this.expiration = Number(expiration);
    } else {
      throw new Error('Invalid expiration: ' + expiration);
    }
    this.checkCacheExpiration();
  }

  setMaxSize (maxSize) {
    this.maxSize = Utils.isNum(maxSize) ? Number(maxSize) : null;
    this.checkCacheExpiration();
  }

  checkCacheExpiration () {
    if (this.checkCacheExpirationRunning) { return; }

    clearTimeout(this.checkCacheExpirationTo);
    this.checkCacheExpirationRunning = true;

    const now = Date.now();
    let cacheEntriesCount = 0;
    const sortedCacheKeys = Object.keys(this.cache).sort((a, b) => a.expiration != null && b.expiration != null ? a.expiration - b.expiration : -1);
    sortedCacheKeys.forEach((key) => {
      cacheEntriesCount++;
      if (
        (this.cache[key]?.expiration != null && this.cache[key].expiration < now) ||
        (this.maxSize != null && cacheEntriesCount > this.maxSize)
      ) {
        delete this.cache[key];
      }
    });

    // TODO: check ram usage, if too high use global.gc();

    this.checkCacheExpirationTo = setTimeout(() => this.checkCacheExpiration(), Utils.ms('5m'));
    this.checkCacheExpirationRunning = false;
  }

  get (key) {
    if (this.cache[key] && (this.cache[key].expiration == null || this.cache[key].expiration >= Date.now())) {
      return _.cloneDeep(this.cache[key].value);
    }
  }

  set (key, value, expiration) {
    const oldValue = this.cache[key]?.value;
    if (value == null) {
      delete this.cache[key];
    } else {
      const exp = Utils.isNum(expiration) ? Number(expiration) : this.expiration;
      this.cache[key] = {
        expiration: exp > 0 ? Date.now() + exp : null,
        value
      };
    }

    this.#ee.emit('set', key, value, oldValue);
  }

  onSet (callback) {
    this.#ee.on('set', callback);
    return () => this.#ee.off('set', callback);
  }

  flush () {
    const oldValues = Object.keys(this.cache)
      .map((key) => ({ key, oldValue: this.get(key) }))
      .filter((el) => el.oldValue !== undefined);

    this.cache = {};

    oldValues.forEach((el) => {
      this.#ee.emit('set', el.key, undefined, el.oldValue);
    });
    this.#ee.emit('flush');
  }

  onFlush (callback) {
    this.#ee.on('flush', callback);
    return () => this.#ee.off('flush', callback);
  }
}

export default Cache;
