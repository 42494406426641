<script setup lang="ts">
import { ref, inject, watch } from 'vue';
import CnsModal from '../cns-modal.vue';
import CnsDataloggerSelector from './cns-datalogger-selector.vue';
import CnsButton from '../cns-button.vue';

const $edw = inject<any>('$edw');

defineOptions({ inheritAttrs: false });
const props = defineProps<{
  title: string;
}>();

const datalogger = defineModel<string | undefined>();
const _datalogger = ref<string | undefined>();
watch(datalogger, () => { _datalogger.value = datalogger.value; }, { immediate: true });

const modal = ref<any>();

defineExpose({
  open: () => modal.value.open?.(),
  close: () => modal.value.close?.()
});
</script>

<template>
  <cns-modal ref="modal" :title="props.title">
    <cns-datalogger-selector
      v-model="_datalogger"
      v-bind="$attrs"
    />
    <div class="d-flex justify-content-end gap-2 mt-2">
      <cns-button :text="$edw.cancel" variant="secondary" @click="() => modal.close()" />
      <cns-button :text="$edw.confirm" variant="primary" @click="() => { datalogger = _datalogger; modal.close() }" />
    </div>
  </cns-modal>
</template>
