import { h, ref } from 'vue';
import CnsIcon from '../cns-icon.vue';
import { createStandaloneComponent } from '../../../libs/vue-utils';

function getCustomCellRenderer (provides = {}) {
  return class CustomCellRenderer {
    params = ref(null);
    eGui;
    component;

    init (params) {
      this.params.value = params;

      this.eGui = document.createElement('div');
      this.eGui.className = 'ag-cell-value-wrapper';

      this.component = createStandaloneComponent(() => {
        if (!this.params.value.data) {
          return h('div', { class: 'd-flex align-items-center justify-content-center' }, [
            h(CnsIcon, { type: 'spinner-third spin' })
          ]);
        } else {
          const val = this.params.value?.getValue();
          const slot = this.params.value?.getSlot?.();
          if (slot) {
            const col = this.params.value?.getCol?.();
            return slot({ val, col, row: this.params.value.data ?? {}, index: this.params.value.rowIndex });
          } else {
            return h('span', { class: 'ag-cell-value', role: 'presentation' }, this.params.value.valueFormatted || val);
          }
        }
      }, provides);
      this.component.mount(this.eGui);
    }

    refresh (params) {
      this.params.value = params;
      return true;
    }

    getGui () { return this.eGui; }

    destroy () {
      this.component.unmount();
      this.component = null;
      this.eGui = null;
    }
  };
}

export {
  getCustomCellRenderer
};
