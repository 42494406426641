import { inject, ref } from 'vue';
import edwPlugin from '../edw/index.mjs';
import { useUserModule } from './modules/user.mjs';
import { usePagesConfigsModule } from './modules/pagesConfigs.mjs';
import { useUtilsModule } from './modules/utils.mjs';
import { useNodesModule } from './modules/nodes.mjs';
import { useEventsModule } from './modules/events.mjs';

const modules = [
  useUserModule(),
  usePagesConfigsModule(),
  useUtilsModule(),
  useNodesModule(),
  useEventsModule()
];

let instance = window.cnsPlugInstance;

if (!instance) {
  instance = ref({});

  for (let i = 0; i < modules.length; i++) {
    for (const variable in modules[i].state) {
      instance.value[variable] = modules[i].state[variable];
    }
  }

  Object.defineProperty(window, 'cnsPlugInstance', { value: instance });
}

const cns = {};
Object.defineProperty(cns, 'state', { get: () => instance.value, set: (newVal) => { instance.value = newVal; }, enumerable: true });

for (let i = 0; i < modules.length; i++) {
  for (const action in modules[i].actions) {
    cns[action] = modules[i].actions[action].bind(cns);
  }

  // TODO: Mix getters and state entries so that it's not necessary to define a getter for every element in the state
  for (const getter in modules[i].getters) {
    Object.defineProperty(cns, getter, { get: modules[i].getters[getter].bind(cns, cns.state), enumerable: true });
  }
}

export default {
  install: function (app) {
    app.use(edwPlugin);
    app.provide('$cns', cns);
    app.config.globalProperties.$cns = cns;

    if (app.config.globalProperties.$store) {
      app.config.globalProperties.$store.$cns = cns;
    }
  }
};

function useCns () {
  return inject('$cns');
}

export {
  useCns
};
