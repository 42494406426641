<template>
  <div v-if="show" ref="modal" class="modal fade" tabindex="-1" role="dialog" style="display: block;">
    <div :class="classes">
      <div class="modal-content">
        <div class="modal-header">
          <slot name="header" :opt="opt_" :close="close">
            <div class="w-100 d-flex justify-content-end">
              <h5 v-if="title" class="modal-title flex-fill">{{title}}</h5>
              <VI-Icon v-if="!noClose" class="close float-end" type="times" @click="close" />
            </div>
          </slot>
        </div>
        <div class="modal-body d-flex flex-column">
          <slot :opt="opt_" :close="close"></slot>
        </div>
        <div class="modal-footer">
          <slot name="footer" :opt="opt_" :close="close"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VIIcon } from '@hig/vision-sdk';

export default {
  name: 'b-modal',
  components: {
    VIIcon
  },
  props: {
    title: { type: String, default: '' },
    'no-close': { type: Boolean, default: false },
    centered: { type: Boolean, default: false },
    scrollable: { type: Boolean, default: false },
    size: { type: String, default: undefined }
  },
  data () {
    return {
      show: false,
      opt_: null,
      closeTimeout: undefined
    };
  },
  computed: {
    classes: function () {
      return [
        'modal-dialog',
        {
          'modal-dialog-centered': this.centered,
          'modal-dialog-scrollable': this.scrollable,
          ['modal-' + this.size]: !!this.size
        }
      ];
    }
  },
  methods: {
    open (opt) {
      clearTimeout(this.closeTimeout);
      this.show = true;
      this.opt_ = opt;

      this.$nextTick(() => {
        requestAnimationFrame(() => {
          this.$refs.modal.classList.add('show');
        });
      });
    },
    close () {
      if (this.$refs.modal) { this.$refs.modal.classList.remove('show'); };
      this.closeTimeout = setTimeout(() => { this.show = false; }, 150);
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
.modal{
  transition: opacity .15s ease, background-color .15s ease;
  background-color: #21212100;
}

.modal.show{
  background-color: #212121AA;
}

.close{
  font-size: 1.5em;
  padding: 3px 10px;
  cursor: pointer;
}

.modal-header:empty, .modal-footer:empty{
  display: none !important;
}
</style>
