import type { SaveParametersInput, ConfigNode, SaveConfigNodeInput, NewConfigNodeInput } from './types';
import GraphQl from '../../../libs/graphql-client/index.mjs';
import { Fop } from '../../../libs/fop-utils/index.browser.mjs';

export function saveParametersValues (points: SaveParametersInput[]): Promise<void> {
  if (!points.length) { return Promise.resolve(); }

  return GraphQl.mutation('Data_savePoints (datapoints: $datapoints)', {
    datapoints: {
      type: '[DataPointInput!]!',
      value: points
    }
  }).then(() => undefined);
}

const configNodeCache: { [id: string]: Promise<ConfigNode> } = {};
export function loadConfigNode (id: string, force: boolean = false): Promise<ConfigNode> {
  if (force || !configNodeCache[id]) {
    configNodeCache[id] = GraphQl.query(`
      Node_get(fop: $fop, noCache: true) {
        id
        label
        desc
        template { id name }
        series (fop: { filter: [{ field: "varType.type", op: "=", val: "parameter" }] }) {
          id
          varType {
            name
            type
          }
          source
          lastValidSampleValue
        }
      }
    `, {
      fop: { type: 'FilterOrderPaginate', value: new Fop().filter('id', '=', id).serialize() }
    }).then((res: any) => res?.[0] as ConfigNode);
  }

  return configNodeCache[id];
};

export async function saveConfigNode (node: SaveConfigNodeInput, { copyParentPermissionsOnSave = false }: { copyParentPermissionsOnSave?: boolean } = {}): Promise<ConfigNode> {
  if (node.series) {
    // Remove the lastValidSampleValue from all the series before saving
    // else there might be some data inconsistency
    node.series.forEach((serie) => { delete serie.lastValidSampleValue; });

    await GraphQl.mutation('Series_save (entities: $entities) { id }', {
      entities: {
        type: '[SeriesInput!]!',
        value: node.series.map((serie) => ({
          id: serie.id,
          source: serie.source
        }))
      }
    });

    delete node.series;
  }

  const savedNode = await GraphQl.mutation(
    `Node_save (entities: $entities) {
      id
      label
      desc
      template { id name }
      series (fop: { filter: [{ field: "varType.type", op: "=", val: "parameter" }] }) {
        id
        varType {
          name
          type
        }
        source
        lastValidSampleValue
      }
    }`, {
      entities: { type: '[NodeInput!]!', value: [node] }
    }
  ).then((res: any) => {
    if (res?.[0]) { configNodeCache[res[0].id] = Promise.resolve(res[0]); }
    return res?.[0];
  });

  if (copyParentPermissionsOnSave) {
    await GraphQl.mutation(`Node_copyParentsPermissions (id: ${savedNode.id})`)
      .catch((err: Error) => { console.log('Error saving node permissions', err); });
  }

  return savedNode;
}

export function deleteConfigNode (id: string): Promise<void> {
  return GraphQl.mutation(`Node_del (id: [${id}])`).then(() => undefined);
}

export function newConfigNode ({ label, desc, templateId, parentId }: NewConfigNodeInput): Promise<ConfigNode> {
  if (!parentId) { throw new Error('parentId is required'); }
  if (!templateId) { throw new Error('templateId is required'); }

  return saveConfigNode({
    label: label || `nodeLabel_${Date.now()}`,
    desc: desc || 'New device',
    parents: { add: [parentId] },
    template: {
      id: templateId
    }
  });
}
