import _ from 'lodash';
import { AVAILABLE_FILTER_OP, FILTER_NULL_VALUE } from './libs/constants.mjs';
import jsFop from './libs/js.mjs';
import Utils from '../utils';

class Fop {
  static NULL = FILTER_NULL_VALUE;

  #fop = {};

  constructor (fop = {}) {
    if (fop instanceof Fop) {
      fop = fop.serialize();
    }

    this.#fop.filter = fop.filter && this.#checkAndParseFilters(fop.filter);
    this.#fop.order = fop.order && this.#checkAndParseOrders(fop.order);
    this.#fop.paginate = fop.paginate && this.#checkAndParsePagination(fop.paginate);
    this.#fop.slice = fop.slice && this.#checkAndParseSlice(fop.slice);
  }

  // Check filters correctness and keep only useful things (this also has the side effect of cloning the input filters)
  #checkAndParseFilters (filters) {
    if (!Array.isArray(filters)) { throw new Error('filter is not an array'); }

    const parsedFilters = [];
    for (let i = 0; i < filters.length; i++) {
      if (filters[i].or) {
        parsedFilters.push({ or: this.#checkAndParseFilters(filters[i].or) });
        continue;
      }
      if (filters[i].and) {
        parsedFilters.push({ and: this.#checkAndParseFilters(filters[i].and) });
        continue;
      }

      // field and op cannot be empty strings or 0, val can
      if (!filters[i].field || typeof filters[i].field !== 'string' || !filters[i].op || filters[i].val == null) {
        throw new Error(`Malformed filter: ${JSON.stringify(filters[i])}`);
      }

      if (!AVAILABLE_FILTER_OP.has(filters[i].op)) {
        throw new Error(`filter op not supported: ${filters[i].op}`);
      }

      if (Array.isArray(filters[i].val) && filters[i].op !== '=' && filters[i].op !== '!=') {
        throw new Error('Array value on filter is only supported with "=" or "!="');
      }

      parsedFilters.push({ field: filters[i].field, op: filters[i].op, val: _.cloneDeep(filters[i].val) });
    }

    return parsedFilters;
  }

  // Check orders correctness and keep only useful things (this also has the side effect of cloning the input orders)
  #checkAndParseOrders (orders) {
    if (!Array.isArray(orders)) { throw new Error('order is not an array'); }

    const parsedOrders = [];

    for (let i = 0; i < orders.length; i++) {
      // field cannot be empty string or 0
      if (!orders[i].field || typeof orders[i].field !== 'string' || !Utils.isNum(orders[i].dir)) {
        throw new Error(`Malformed order: ${JSON.stringify(orders[i])}`);
      }

      parsedOrders.push({ field: orders[i].field, dir: orders[i].dir, cast: orders[i].cast });
    }

    return parsedOrders;
  }

  // Check pagination correctness and keep only useful things (this also has the side effect of cloning the input pagination)
  #checkAndParsePagination (pagination) {
    if (!Utils.isNum(pagination.size)) {
      throw new Error('Fop.paginate: Missing pagination size');
    }

    if (!Utils.isNum(pagination.page)) {
      throw new Error('Fop.paginate: Missing pagination page');
    }

    return { size: Number(pagination.size), page: Number(pagination.page) };
  }

  #checkAndParseSlice (slice) {
    if (!Utils.isNum(slice.offset)) {
      throw new Error('Fop.slice: Missing slice offset');
    }

    if (!Utils.isNum(slice.limit)) {
      throw new Error('Fop.slice: Missing slice limit');
    }

    return { offset: Number(slice.offset), limit: Number(slice.limit) };
  }

  #filter (filter) {
    let filtersToAdd = [];
    if (filter === false) {
      return new this.constructor({ filter: undefined, order: this.#fop.order, paginate: this.#fop.paginate, slice: this.#fop.slice });
    } else if (Array.isArray(filter)) { // example: filter([{ field: 'field', op: 'op', val: 'val' }, ...]);
      filtersToAdd = filter;
    } else if (typeof filter === 'object') { // example: filter({ field: 'field', op: 'op', val: 'val' });
      filtersToAdd = [filter];
    } else if (arguments.length === 3) { // example: filter('field', 'op', 'val');
      filtersToAdd = [{ field: arguments[0], op: arguments[1], val: arguments[2] }];
    } else {
      throw new Error('Fop.filter: Unsupported arguments');
    }

    return new this.constructor({ filter: [...(this.#fop.filter || []), ...filtersToAdd], order: this.#fop.order, paginate: this.#fop.paginate, slice: this.#fop.slice });
  }

  #filterOr (filter) {
    if (Array.isArray(filter)) { // example: filter.or([{ field: 'field', op: 'op', val: 'val' }, ...]);
      return this.filter({ or: filter });
    } else {
      throw new Error('Fop.filter.or: Unsupported arguments');
    }
  }

  #filterAnd (filter) {
    if (Array.isArray(filter)) { // example: filter.and([{ field: 'field', op: 'op', val: 'val' }, ...]);
      return this.filter({ and: filter });
    } else {
      throw new Error('Fop.filter.and: Unsupported arguments');
    }
  }

  #filterGet (filter) {
    let filterToGet = { field: null, op: null, val: null };
    if (typeof filter === 'object') { // example: filter.get({ field: 'field', op: 'op', val: 'val' });
      filterToGet = { ...filterToGet, ...filter };
    } else if (arguments.length > 0) { // example: filter.get('field', 'op', 'val');
      filterToGet = { field: arguments[0], op: arguments[1], val: arguments[2] };
    } else {
      throw new Error('Fop.filter.get: Unsupported arguments');
    }

    if (filterToGet.field == null && filterToGet.op == null && filterToGet.val == null) {
      throw new Error('Fop.order.get: Unsupported arguments');
    }

    return (this.#fop.filter || []).filter((filter) => {
      if (filterToGet.field != null && filterToGet.field !== filter.field) { return false; }
      if (filterToGet.op != null && filterToGet.op !== filter.op) { return false; }
      if (filterToGet.val != null) {
        if (Array.isArray(filterToGet.val) && !_.isEqual(filterToGet.val, filter.val)) {
          return false;
        } else if (String(filterToGet.val) !== String(filter.val)) {
          return false;
        }
      }

      return true;
    });
  }

  #filterRem () {
    let filtersToRem = [];
    try {
      filtersToRem = this.#filterGet(...arguments);
    } catch {
      throw new Error('Fop.filter.rem: Unsupported arguments');
    }

    return new this.constructor({ filter: (this.#fop.filter || []).filter((filter) => !filtersToRem.includes(filter)), order: this.#fop.order, paginate: this.#fop.paginate, slice: this.#fop.slice });
  }

  get filter () {
    const filter = function () { return this.#filter(...arguments); }.bind(this);
    filter.or = function () { return this.#filterOr(...arguments); }.bind(this);
    filter.and = function () { return this.#filterAnd(...arguments); }.bind(this);
    filter.get = function () { return this.#filterGet(...arguments); }.bind(this);
    filter.rem = function () { return this.#filterRem(...arguments); }.bind(this);
    return filter;
  }

  #order (order) {
    let orderToAdd = [];
    if (order === false) {
      return new this.constructor({ filter: this.#fop.filter, order: undefined, paginate: this.#fop.paginate, slice: this.#fop.slice });
    } else if (Array.isArray(order)) { // example: order([{ field: 'field', dir: 'dir', cast: 'cast' }, ...]);
      orderToAdd = order;
    } else if (typeof order === 'object') { // example: order({ field: 'field', dir: 'dir', cast: 'cast' });
      orderToAdd = [order];
    } else if (arguments.length === 2) { // example: order('field', 'dir');
      orderToAdd = [{ field: arguments[0], dir: arguments[1] }];
    } else if (arguments.length === 3) { // example: order('field', 'dir', 'cast');
      orderToAdd = [{ field: arguments[0], dir: arguments[1], cast: arguments[2] }];
    } else {
      throw new Error('Fop.order: Unsupported arguments');
    }

    return new this.constructor({ filter: this.#fop.filter, order: [...(this.#fop.order || []), ...orderToAdd], paginate: this.#fop.paginate, slice: this.#fop.slice });
  }

  #orderGet (order) {
    let orderToGet = { field: null, dir: null };
    if (typeof order === 'object') { // example: order.get({ field: 'field', dir: 'dir', cast: 'cast' });
      orderToGet = { ...orderToGet, ...order };
    } else if (arguments.length > 0) { // example: order.get('field', 'dir', 'cast');
      orderToGet = { field: arguments[0], dir: arguments[1], cast: arguments[2] };
    } else {
      throw new Error('Fop.order.get: Unsupported arguments');
    }

    if (orderToGet.field == null && orderToGet.dir == null) {
      throw new Error('Fop.order.get: Unsupported arguments');
    }

    return (this.#fop.order || []).filter((order) => {
      if (orderToGet.field != null && orderToGet.field !== order.field) { return false; }
      if (orderToGet.dir != null && Number(orderToGet.dir) !== Number(order.dir)) { return false; }
      if (orderToGet.cast != null && Number(orderToGet.cast) !== Number(order.cast)) { return false; }

      return true;
    });
  }

  #orderRem () {
    let orderToRem = [];
    try {
      orderToRem = this.#orderGet(...arguments);
    } catch {
      throw new Error('Fop.order.rem: Unsupported arguments');
    }

    return new this.constructor({ filter: this.#fop.filter, order: (this.#fop.order || []).filter((order) => !orderToRem.includes(order)), paginate: this.#fop.paginate, slice: this.#fop.slice });
  }

  get order () {
    const order = function () { return this.#order(...arguments); }.bind(this);
    order.get = function () { return this.#orderGet(...arguments); }.bind(this);
    order.rem = function () { return this.#orderRem(...arguments); }.bind(this);
    return order;
  }

  slice (slice) {
    let newSlice;

    if (slice === false) {
      return new this.constructor({ filter: this.#fop.filter, order: this.#fop.order, paginate: this.#fop.paginate, slice: undefined });
    } else if (typeof slice === 'object') { // example: slice({ offest: 0, limit: 100 });
      newSlice = slice;
    } else if (arguments.length === 2) { // example: slice(0, 100);
      newSlice = { offset: arguments[0], limit: arguments[1] };
    } else if (arguments.length === 1) { // example: slice(0);
      newSlice = { offset: arguments[0], limit: this.#fop.slice?.limit };
    } else {
      throw new Error('Fop.slice: Unsupported arguments');
    }

    return new this.constructor({ filter: this.#fop.filter, order: this.#fop.order, paginate: this.#fop.paginate, slice: newSlice });
  }

  paginate (pagination) {
    let newPagination;

    if (pagination === false) {
      return new this.constructor({ filter: this.#fop.filter, order: this.#fop.order, paginate: undefined, slice: this.#fop.slice });
    } else if (typeof pagination === 'object') { // example: paginate({ page: 0, size: 100 });
      newPagination = pagination;
    } else if (arguments.length === 2) { // example: paginate(0, 100);
      newPagination = { page: arguments[0], size: arguments[1] };
    } else if (arguments.length === 1) { // example: paginate(0);
      newPagination = { page: arguments[0], size: this.#fop.paginate?.size };
    } else {
      throw new Error('Fop.paginate: Unsupported arguments');
    }

    return new this.constructor({ filter: this.#fop.filter, order: this.#fop.order, paginate: newPagination, slice: this.#fop.slice });
  }

  serialize () {
    return _.cloneDeep(this.#fop);
  }

  #apply (data, opt = {}) {
    return jsFop.apply(this.#fop, data, opt);
  }

  #applyTot (data, fields, opt = {}) {
    return jsFop.totals(this.#fop, data, fields, opt);
  }

  get apply () {
    const apply = function () { return this.#apply(...arguments); }.bind(this);
    apply.tot = function () { return this.#applyTot(...arguments); }.bind(this);
    return apply;
  }

  doesFilterPass (data, opt = {}) {
    return jsFop.doesFilterPass(this.#fop, data, opt);
  }

  getAllFields (opt = {}) {
    let res = [opt.idField || 'id'];

    if (Array.isArray(this.#fop.filter)) {
      const getFilterFieldsRec = (filters) => {
        let fields = [];
        filters.forEach((filter) => {
          if (Array.isArray(filter.or)) {
            fields = fields.concat(getFilterFieldsRec(filter.or));
          } else if (Array.isArray(filter.and)) {
            fields = fields.concat(getFilterFieldsRec(filter.and));
          } else {
            fields.push(filter.field);
          }
        });
        return fields;
      };

      res = res.concat(getFilterFieldsRec(this.#fop.filter));
    }

    if (Array.isArray(this.#fop.order)) {
      res = res.concat(this.#fop.order.map((ord) => ord.field));
    }

    return [...new Set(res)].filter((field) => !!field); // remove duplicates and nulls
  }

  hasFilter () { return Array.isArray(this.#fop.filter) && this.#fop.filter.length > 0; }
  hasOrder () { return Array.isArray(this.#fop.order) && this.#fop.order.length > 0; }
  hasPaginate () { return this.#fop.paginate != null && Object.keys(this.#fop.paginate).length > 0; }
  hasSlice () { return this.#fop.slice != null && Object.keys(this.#fop.slice).length > 0; }
  isEmpty () { return !this.hasFilter() && !this.hasOrder() && !this.hasPaginate() && !this.hasSlice(); }

  renameFields (callback) {
    let renamedFilter;
    let renamedOrder;

    if (Array.isArray(this.#fop.filter)) {
      const renameFiltersRec = (filters) => {
        return filters.map((filter) => {
          if (Array.isArray(filter.or)) {
            return { or: renameFiltersRec(filter.or) };
          } else if (Array.isArray(filter.and)) {
            return { and: renameFiltersRec(filter.and) };
          } else {
            const newFieldName = callback(filter.field, 'filter', filter);
            return { field: typeof newFieldName === 'string' ? newFieldName : filter.field, op: filter.op, val: filter.val };
          }
        });
      };

      renamedFilter = renameFiltersRec(this.#fop.filter);
    }

    if (Array.isArray(this.#fop.order)) {
      renamedOrder = this.#fop.order.map((ord) => {
        const newFieldName = callback(ord.field, 'order', ord);
        return { field: typeof newFieldName === 'string' ? newFieldName : ord.field, dir: ord.dir, cast: ord.cast };
      });
    }

    return new this.constructor({ filter: renamedFilter, order: renamedOrder, paginate: this.#fop.paginate, slice: this.#fop.slice });
  }

  getSubfieldFop (field) {
    const subfieldFop = { filter: [], order: [] };

    if (Array.isArray(this.#fop.filter)) {
      const getSubfieldFiltersRec = (filters) => {
        const subfieldFilters = [];
        filters.forEach((filter) => {
          if (Array.isArray(filter.or)) {
            const tmpFilters = getSubfieldFiltersRec(filter.or);
            if (tmpFilters.length > 0) { subfieldFilters.push({ or: tmpFilters }); }
          } else if (Array.isArray(filter.and)) {
            const tmpFilters = getSubfieldFiltersRec(filter.and);
            if (tmpFilters.length > 0) { subfieldFilters.push({ and: tmpFilters }); }
          } else {
            if (filter.field.indexOf(field + '.') === 0) {
              subfieldFilters.push({ field: filter.field.substring(field.length + 1), op: filter.op, val: filter.val });
            }
          }
        });
        return subfieldFilters;
      };

      subfieldFop.filter = getSubfieldFiltersRec(this.#fop.filter);
    }

    if (Array.isArray(this.#fop.order)) {
      this.#fop.order.forEach((ord) => {
        if (ord.field.indexOf(field + '.') === 0) {
          subfieldFop.order.push({ field: ord.field.substring(field.length + 1), dir: ord.dir, cast: ord.cast });
        }
      });
    }

    return new this.constructor(subfieldFop);
  }

  equals (fop, checks = { filter: true, order: true, paginate: true, slice: true }) {
    if (!fop) { return false; }

    const otherFop = fop.serialize ? fop.serialize() : fop;

    // Paginate
    if (checks.paginate) {
      if (Boolean(otherFop.paginate) ^ Boolean(this.#fop.paginate)) { return false; }
      if (!(otherFop.paginate?.page === this.#fop.paginate?.page && otherFop.paginate?.size === this.#fop.paginate?.size)) { return false; }
    }

    // Slice
    if (checks.slice) {
      if (Boolean(otherFop.slice) ^ Boolean(this.#fop.slice)) { return false; }
      if (!(otherFop.slice?.offset === this.#fop.slice?.offset && otherFop.slice?.limit === this.#fop.slice?.limit)) { return false; }
    }

    // Order
    if (checks.order) {
      if (Boolean(otherFop.order) ^ Boolean(this.#fop.order)) { return false; }
      if (otherFop.order) {
        for (let i = 0; i < otherFop.order.length; i++) {
          if (otherFop.order[i].field !== this.#fop.order[i].field || otherFop.order[i].dir !== this.#fop.order[i].dir || otherFop.order[i].cast !== this.#fop.order[i].cast) {
            return false;
          }
        }
      }
    }

    // Filter
    if (checks.filter) {
      if (Boolean(otherFop.filter) ^ Boolean(this.#fop.filter)) { return false; }
      if (otherFop.filter) {
        for (const f1 of otherFop.filter) {
          if (!this.#fop.filter.find(f2 => this.#checkFilterEqualsRec(f1, f2))) {
            return false;
          }
        }
      }
    }

    return true;
  }

  #checkFilterEqualsRec (f1, f2) {
    const f1AndOr = f1.and ? 'and' : (f1.or ? 'or' : null);
    const f2AndOr = f2.and ? 'and' : (f2.or ? 'or' : null);
    if (Boolean(f1AndOr) ^ Boolean(f2AndOr)) { return false; }
    if (f1AndOr) {
      if (f1AndOr !== f2AndOr) { return false; }
      for (const f3 of f1[f1AndOr]) {
        if (!f2[f2AndOr].find(f4 => this.#checkFilterEqualsRec(f3, f4))) {
          return false;
        }
      }
    }
    return f1.field === f2.field && f1.op === f2.op && f1.val === f2.val;
  }
}

export {
  Fop
};
