<script setup lang='ts'>
import { ref, computed, onMounted, inject, watch } from 'vue';
import CnsList from '../cns-list.vue';
import CnsListItem from '../cns-list-item.vue';
import GraphQl from '../../../libs/graphql-client/index.mjs';
import { Fop } from '../../../libs/fop-utils/index.browser.mjs';
import CnsSelect from '../cns-select.vue';
import CnsFilterInput from '../cns-filter-input.vue';
import { Installation, Datalogger, Peripheral } from './types';
import CnsButton from '../cns-button.vue';

const props = withDefaults(defineProps<{
  noInstallationsFilter?: boolean;
  noDataloggersFilter?: boolean;
  multiple?: boolean;
}>(), {
  noInstallationsFilter: false,
  noDataloggersFilter: false,
  multiple: false
});

const value = defineModel<string | undefined>();
const installationsSelected = defineModel<string[]>('installations', { default: () => [] });
const dataloggersSelected = defineModel<string[]>('dataloggers', { default: () => [] });
const searchFilter = defineModel<string>('filter', { default: '' });

const $edw: any = inject('$edw');
const error = ref();

const installations = ref<Installation[]>();
const installationsOptions = computed(() => installations.value?.map((el) => ({
  value: el.id,
  text: el.name,
  desc: el.desc
})) ?? []);

const dataloggers = ref<Datalogger[]>();
const dataloggersOptions = computed(() => dataloggers.value?.map((el) => ({
  value: el.id,
  text: `${el.name} (${el.sn})`
})) ?? []);

const peripherals = ref<Peripheral[]>([]);
const peripheralsLoading = ref<boolean>(false);

async function loadInstallations () {
  installations.value = await GraphQl.query(`
    Installation_get (noCache: true) {
      id
      name
      desc
    }
  `).catch((err) => {
    error.value = $edw.translateError(err);
  });
}

async function loadDataloggers () {
  let _fop = new Fop();

  if (installationsSelected.value.length > 0) {
    _fop = _fop.filter('installations[*].id', '=', installationsSelected.value);
  }

  dataloggers.value = await GraphQl.query(`
    Datalogger_get (fop: $fop, noCache: true) {
      id
      sn
      name
    }
  `, {
    fop: { type: 'FilterOrderPaginate', value: _fop.serialize() }
  }).catch((err) => {
    error.value = $edw.translateError(err);
  });
}

async function loadPeripherals () {
  // Do not load peripherals if there are no dataloggers to get peripherals from
  if (!dataloggers.value?.length) { return; }
  peripheralsLoading.value = true;

  let _fop = new Fop()
    .order('label', 1)
    .paginate(0, 200);

  // Always filter by available dataloggers so that selecting a plant will also filter the available peripherals
  _fop = _fop.filter('datalogger.id', '=', dataloggers.value?.map((el) => el.id));
  if (dataloggersSelected.value.length) {
    // If there are selected dataloggers get the intersection between those and the available ones
    _fop = _fop.filter('datalogger.id', '=', dataloggersSelected.value);
  }

  if (searchFilter.value) {
    const filters = searchFilter.value.split(' ').map((el) => el.trim()).filter((el) => el.length > 0);
    filters.forEach((filter) => {
      _fop = _fop.filter({
        or: [
          { field: 'name', op: '%', val: filter },
          { field: 'label', op: '%', val: filter },
          { field: 'datalogger.sn', op: '%', val: filter },
          { field: 'datalogger.name', op: '%', val: filter }
        ]
      });
    });
  }

  peripherals.value = await GraphQl.query(`
    DataloggerPeripheral_get (fop: $fop, noCache: true) {
      id
      label
      model
      type
      datalogger {
        id
        sn
        name
      }
    }
  `, {
    fop: { type: 'FilterOrderPaginate', value: _fop.serialize() }
  }).catch((err) => {
    error.value = $edw.translateError(err);
  });

  peripherals.value.sort((a, b) => {
    const aSelected = isSelected({ id: a.id });
    const bSelected = isSelected({ id: b.id });
    if (aSelected && !bSelected) { return -1; }
    if (!aSelected && bSelected) { return 1; }
    if (a.label < b.label) { return -1; }
    if (a.label > b.label) { return 1; }
    return 0;
  });

  peripheralsLoading.value = false;
}

const items = computed(() => peripherals.value.map((peripheral) => ({
  id: peripheral.id,
  label: peripheral.label,
  caption: `@ ${peripheral.datalogger.name} (${peripheral.datalogger.sn})`
})) ?? []);

onMounted(() => { loadInstallations(); });
watch(installationsSelected, () => { loadDataloggers(); }, { deep: true, immediate: true });
watch([dataloggers, dataloggersSelected, searchFilter], () => { loadPeripherals(); }, { deep: true, immediate: true });

function isSelected (item: any) {
  if (!props.multiple) {
    return item.id === value.value;
  } else {
    return value.value?.includes(item.id);
  }
}

function toggleSelected (item: any) {
  if (!props.multiple) {
    if (item.id === value.value) {
      value.value = undefined;
    } else {
      value.value = item.id;
    }
  } else {
    if (!Array.isArray(value.value)) { value.value = []; }
    if (value.value.includes(item.id)) {
      value.value = value.value.filter((el) => el !== item.id);
    } else {
      value.value = [...value.value, item.id];
    }
  }
}

function unselectAll () {
  if (!props.multiple) {
    value.value = undefined;
  } else {
    value.value = [];
  }
}
</script>

<template>
  <div class="d-flex flex-column gap-2">
    <div class="d-flex align-items-center justify-content-end gap-2">
      <cns-button v-if="props.multiple" @click="unselectAll" :text="$edw.unselectAll" :disabled="!value?.length" />
      <cns-select
        v-if="!props.noInstallationsFilter"
        style="width: 50%;"
        v-model="installationsSelected"
        :options="installationsOptions"
        :placeholder="$edw.installation"
        multiple
        searchable
      />
      <cns-select
        v-if="!props.noDataloggersFilter"
        style="width: 50%;"
        v-model="dataloggersSelected"
        :options="dataloggersOptions"
        :placeholder="$edw.datalogger"
        multiple
        searchable
      />
    </div>
    <div class="">
      <cns-filter-input
        class="w-100"
        v-model="searchFilter"
        :placeholder="$edw.search + ' (' + $edw.pressEnterToSearch + ')'"
        :loading="peripheralsLoading"
        :debounce="1500"
      />
    </div>
    <div class="flex-fill" style="height: 60vh;">
      <cns-list :items="items" v-slot="{ item }" class="h-100 overflow-auto">
        <cns-list-item
          icon="meter-bolt"
          :label="item.label"
          :caption="item.caption"
          checkbox
          :active="isSelected(item)"
          @click="toggleSelected(item)"
        />
      </cns-list>
    </div>
  </div>
</template>
