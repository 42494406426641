<script setup>
import CnsIcon from '../cns-icon.vue';

const props = defineProps({
  order: { type: Number, default: 0 }
});
const emit = defineEmits(['update:order']);

function toggleOrder () {
  emit('update:order', ((props.order + 2) % 3) - 1);
}
</script>

<template>
  <div class="plant-list-header d-flex align-items-center gap-1 " @click="toggleOrder()">
    <div class="d-flex flex-column justify-content-center">
      <cns-icon type="caret-down solid flip-vertical" class="sort-icon" :class="{ active: props.order != -1 }" />
      <cns-icon type="caret-down solid" class="sort-icon" :class="{ active: props.order != 1 }" />
    </div>
    <slot />
  </div>
</template>

<style scoped>
.plant-list-header {
  font-weight: bold;
  cursor: pointer;
  opacity: .8;
  transition: opacity .3s ease;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.plant-list-header:hover {
  opacity: 1;
}

.sort-icon {
  font-size: 1rem;
  line-height: 0.5rem;
}

.sort-icon:not(.active) {
  opacity: .2;
}
</style>
