<script setup>
import 'leaflet/dist/leaflet.css';
import 'leaflet-control-mini-map/dist/minimap.css';
import { computed, nextTick, onMounted, provide, ref } from 'vue';
import { baseMaps } from '../../libs/map-utils/index.mjs';

const props = defineProps({
  center: { type: Object, required: true },
  zoom: { type: Number, default: 10 },
  options: { type: Object, default: function () { return {}; } }
});

let LeafletReady;
let Leaflet;
let LeafletMap;
const markers = ref({});
const leafletMarkers = {};

function addLeafletMarker (marker) {
  if (marker.html && marker.pos) {
    LeafletReady?.then(() => {
      let points = [];
      let isGeoJson = false;
      if (Array.isArray(marker.pos)) {
        points = marker.pos.filter(coordinate => coordinate.lat && coordinate.lon);
      } else if (marker.pos.lat && marker.pos.lon) {
        points = [marker.pos];
      } else {
        isGeoJson = true;
        points = marker.pos.geometry?.coordinates ?? marker.pos.coordinates ?? [];
      }

      if (points?.length > 0) {
        let geojsonFeature = {};
        if (isGeoJson) {
          geojsonFeature = marker.pos;
        } else {
          const isPolygon = points.length > 1;
          geojsonFeature = {
            type: isPolygon ? 'Polygon' : 'Point',
            coordinates: isPolygon ? [points.map(coord => [coord.lon, coord.lat])] : [points[0].lon, points[0].lat]
          };
        }

        const geojsonMarkerOptions = {
          radius: 8,
          fillColor: 'var(--hig-primary)',
          color: '#000',
          weight: 1,
          opacity: 1,
          fillOpacity: 0.8
        };

        const iconMarker = Leaflet.divIcon({
          className: 'marker-div',
          html: marker.html
        });

        leafletMarkers[marker.id] = Leaflet.geoJSON(geojsonFeature, {
          style: {
            fillColor: 'var(--hig-primary)',
            weight: 1,
            opacity: 1,
            color: 'var(--hig-primary-accent)', // Outline color
            fillOpacity: 0.8
          },
          pointToLayer: (_, latlng) => {
            if (!Array.isArray(marker.pos)) {
              return Leaflet.marker(latlng, {
                icon: iconMarker
              });
            } else {
              return Leaflet.circleMarker(latlng, geojsonMarkerOptions)
                .bindTooltip(marker.html, { permanent: true, direction: 'center', interactive: true, className: 'class-tooltip' })
                .openTooltip();
            }
          },
          onEachFeature: (feature, layer) => {
            if (feature.type !== 'Point') {
              layer.bindTooltip(marker.html, { permanent: true, direction: 'center', interactive: true, className: 'class-tooltip' }).openTooltip();
              layer.on('click', function () {
                markers.value[marker.id]?.html?.click();
              });
              return layer;
            }
          }
        }).addTo(LeafletMap);
      }
    });
  }
}
function updateLeafletMarker (marker) {
  if (leafletMarkers[marker.id]) {
    LeafletReady.then(() => {
      delete leafletMarkers[marker.id].removeFrom(LeafletMap);
    });
  }
  addLeafletMarker(marker);
}
function addLeafletPopup (marker) {
  if (marker.pos && marker.html) {
    const width = marker.width || marker.html.clientWidth + marker.html.scrollWidth;
    LeafletReady.then(() => {
      Leaflet.popup({ autoClose: true, closeButton: true, closeOnClick: false, closeOnEscapeKey: true, maxWidth: width, minWidth: width, className: 'cns-map-popup' })
        .setLatLng({ lat: marker.pos.lat, lng: marker.pos.lon })
        .setContent(marker.html)
        .on('remove', () => {
          marker.opened = false;
          leafletMarkers[marker.id].removeFrom(LeafletMap);
          leafletMarkers[marker.id].addTo(LeafletMap);
        }).openOn(LeafletMap);
    });
  }
}

provide('__cnsMap_addMarker', (marker) => {
  if (!markers.value[marker.id]) {
    markers.value[marker.id] = marker;
  }
  updateLeafletMarker(markers.value[marker.id]);
});

provide('__cnsMap_remMarker', (markerId) => {
  if (markers.value[markerId]) {
    LeafletReady.then(() => {
      delete markers.value[markerId];
      leafletMarkers[markerId]?.removeFrom(LeafletMap);
    });
  }
});

provide('__cnsMap_openPopup', (markerId) => {
  const marker = markers.value[markerId];
  marker && addLeafletPopup(marker);
});

const tile = computed(() => {
  return baseMaps[props.options.tileLayer ?? 'OSM'];
});

onMounted(() => {
  LeafletReady = import('leaflet').then((LeafletLib) => {
    Leaflet = LeafletLib;
    Object.assign(window.L, Leaflet);
    LeafletMap = Leaflet.map('mapContainer', props.options).setView([props.center.lat, props.center.lon], props.zoom);
  });
  LeafletReady.then(() => {
    Leaflet.tileLayer(tile.value.url, {
      attribution: tile.value.attribution,
      maxZoom: tile.value.maxZoom ?? 20,
      minZoom: tile.value.minZoom ?? 1
    }).addTo(LeafletMap);

    if (props.options.miniMap) {
      import('leaflet-control-mini-map').then((module) => {
        const osm = Leaflet.tileLayer(tile.value.url, {
          maxZoom: 13,
          attribution: tile.value.attribution
        });
        new module.MiniMap(osm, { position: 'bottomleft', width: props.options.miniMapWidth ?? 150, height: props.options.miniMapHeight ?? 150, mapOptions: props.options }).addTo(LeafletMap);
      });
    }
    if (props.options.fitBounds) {
      nextTick(() => {
        const arrayMarkers = Object.values(leafletMarkers);
        if (arrayMarkers.length > 0) {
          const group = Leaflet.featureGroup(arrayMarkers);
          LeafletMap.fitBounds(group.getBounds());
        }
      });
    }
    if (props.options.showTiles) {
      const tileLayers = {};
      Object.entries(baseMaps).forEach(([key, value]) => {
        tileLayers[key] = Leaflet.tileLayer(value.url, {
          ...value
        });
      });
      Leaflet.control.layers(tileLayers, {}, { position: 'bottomright' }).addTo(LeafletMap);
    }
  });
});
</script>

<template>
  <div class="w-100 h-100 overflow-hidden">
    <div class="w-100 h-100 overflow-hidden" id="mapContainer" />
    <div hidden>
      <slot />
    </div>
  </div>
</template>

<style scoped>
:deep(.cns-map-popup) {
  margin-left: -6px;
}

:deep(.leaflet-control-minimap) {
  border-radius: 10% !important;
}

:deep(.leaflet-control-layers-toggle) {
  background-image: unset !important;
  background-color: var(--hig-primary) !important;
  vertical-align: middle;
  display: table-cell;
  text-align: center;
  border-radius: 50% !important;
  border: none !important;
}

:deep(.leaflet-control-layers-toggle:before) {
  content: "\f5fd";
  font-family: "Font Awesome 6 Pro";
  font-size: x-large;
  color: white;
}

:deep(.leaflet-control-layers) {
  border-radius: 50% !important;
  border: none !important;
}

:deep(.leaflet-control-layers-expanded) {
  border-radius: 10% !important;
  border: none !important;
}

:deep(.leaflet-tooltip.class-tooltip) {
  background: unset !important;
  border: none !important;
  box-shadow: none !important;
  opacity: 1 !important;
}

:deep(.leaflet-marker-icon) {
  width: auto !important;
  height: auto !important;
}

:deep(.class-tooltip div .cns-map-marker-marker > :first-child) {
  display: none;
}

:deep(.class-tooltip div .cns-map-marker-marker > p) {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
