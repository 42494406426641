<script setup>
import { computed, provide, ref, watch } from 'vue';
import CnsIcon from './cns-icon.vue';
import CnsAlert from './cns-alert.vue';
import CnsDropdown from './cns-dropdown.vue';
import _ from 'lodash';

const props = defineProps({
  title: { type: String, default: '' },
  backButton: { type: Boolean, default: false },
  noHeader: { type: Boolean, default: false },
  noBody: { type: Boolean, default: false },
  variant: { type: String, default: undefined },
  alert: { type: Object, default: undefined },
  alertText: { type: String, default: undefined },
  alertIcon: { type: String, default: undefined },
  alertVariant: { type: String, default: undefined },
  alertActions: { type: Array, default: undefined },
  accordion: { type: Boolean, default: false },
  reverseTabIcon: { type: Boolean, default: false },
  activeTab: { type: String, default: undefined }
});
const emit = defineEmits(['back', 'update:activeTab']);

const accordionOpen = defineModel('accordionOpen', false);

const alert = computed(() => ({
  text: props.alertText || props.alert?.text,
  icon: props.alertIcon || props.alert?.icon,
  variant: props.alertVariant || props.alert?.variant,
  actions: props.alertActions || props.alert?.actions
}));

const cardRef = ref();
const cardHeaderRef = ref();
const cardHeaderContainerRef = ref();
const compactTabsTabRef = ref();
const compactTabsDropdownRef = ref();
const tabs = ref([]);
const useTabs = computed(() => tabs.value.length > 0);
const semiCompactTabs = ref(false);
const compactTabs = ref(false);
const cardBodyRef = ref();
const activeTab = computed(() => tabs.value.find((tab) => tab.active));

defineExpose({ cardRef, cardHeaderRef, cardBodyRef });

function activateTab (id) {
  emit('update:activeTab', id);
  tabs.value.forEach((tab) => {
    tab.active = tab.id === id;
  });
}

provide('__cnsCard_setTab', (newTab) => {
  // newTab => { id, title, active }
  const tabIndex = tabs.value.findIndex((tab) => tab.id === newTab.id);
  if (tabIndex >= 0) {
    tabs.value[tabIndex] = newTab;
  } else {
    tabs.value.push(newTab);
  }

  if (
    (props.activeTab == null && activeTab.value == null) ||
    props.activeTab === newTab.id
  ) {
    newTab.active = true;
  }
});

provide('__cnsCard_remTab', (id) => {
  tabs.value = tabs.value.filter((tab) => tab.id !== id);
  if (props.activeTab == null && tabs.value.length > 0) {
    tabs.value[0].active = true;
  }
});

if (typeof ResizeObserver !== 'undefined') {
  async function checkCompactTabs () {
    semiCompactTabs.value = false;
    compactTabs.value = false;
    requestAnimationFrame(() => {
      if (cardHeaderContainerRef.value && cardHeaderContainerRef.value.scrollWidth > cardHeaderContainerRef.value.clientWidth) {
        semiCompactTabs.value = true;
      }

      requestAnimationFrame(() => {
        if (cardHeaderContainerRef.value && cardHeaderContainerRef.value.scrollWidth > cardHeaderContainerRef.value.clientWidth) {
          compactTabs.value = true;
        }
      });
    });
  }
  const cardResizeObserver = new ResizeObserver(_.debounce(checkCompactTabs, 250));
  watch(cardRef, async () => {
    cardResizeObserver.disconnect();
    await checkCompactTabs();
    if (cardRef.value) {
      cardResizeObserver.observe(cardRef.value);
    }
  }, { immediate: true });
  watch(tabs, () => { checkCompactTabs(); });
}
</script>

<template>
  <div class="cns-card card d-flex flex-column" :class="{ [`cns-card-${props.variant}`]: !!props.variant }" ref="cardRef">
    <cns-alert v-if="alert.text" :actions="alert.actions" :variant="alert.variant" :icon="alert.icon" class="card-alert mx-2 mt-2">{{ alert.text }}</cns-alert>
    <header :id="`card-header-${props.title}`" class="card-header py-0 px-3 px-lg-4 border-0" v-if="!props.noHeader" ref="cardHeaderRef">
      <div class="card-header-container d-flex w-100" :class="{ 'accordion-title-border': props.accordion && !accordionOpen, 'accordion-pointer': props.accordion }" ref="cardHeaderContainerRef" @click="accordionOpen = !accordionOpen">
        <div v-if="props.backButton" class="back-button d-flex align-items-center" @click="emit('back')">
          <cns-icon class="px-2 py-1 pe-2" type="chevron-left"/>
        </div>
        <!-- title -->
        <slot v-if="!useTabs" name="title">
          <h3 class="title flex-fill d-flex align-items-center my-0 py-3 pe-1 fw-normal overflow-hidden">
              <span v-if="props.title" :title="props.title" class="d-inline-block text-truncate p-0 m-0">{{props.title}}</span>
            </h3>
        </slot>
        <!-- tabs -->
        <template v-if="useTabs">
          <template v-if="tabs.length > 1 && compactTabs">
            <h3
              class="tab active compact-tabs-tab flex-fill d-flex align-items-center my-0 py-3 px-2 fw-normal justify-content-between"
              :class="{ open: compactTabsDropdownRef && compactTabsDropdownRef.isOpen }"
              ref="compactTabsTabRef"
              @click="compactTabsDropdownRef.toggle()"
              :style="{ borderColor: activeTab.color && activeTab.color + '!important' }"
            >
              <div class="text-nowrap" v-if="activeTab">
                <cns-icon class="me-3" v-if="activeTab.icon" :type="activeTab.icon"/>
                <span>{{ activeTab.title }}</span>
              </div>
              <cns-icon type="chevron-down" class="compact-tabs-tab-icon"/>
            </h3>

            <cns-dropdown ref="compactTabsDropdownRef" :target="compactTabsTabRef">
              <div class="p-1 d-flex flex-column">
                <h3
                  v-for="tab in tabs"
                  :key="tab.id"
                  @click="activateTab(tab.id)"
                  class="dropdown-tab d-flex align-items-center rounded my-0 p-2"
                >
                  <span>{{ tab.title }}</span>
                </h3>
              </div>
            </cns-dropdown>
          </template>
          <template v-else>
            <h3
              v-for="tab in tabs"
              :key="tab.id"
              @click="activateTab(tab.id)"
              :class="{ active: tab.active, 'justify-content-center': semiCompactTabs, 'reverse-tab-icon': props.reverseTabIcon  && tab.icon }"
              class="tab flex-fill d-flex align-items-center my-0 py-3 px-2 fw-normal text-nowrap"
              :style="{ borderColor: (tab.active && tab.color && tab.color + '!important') || 'inherit' }"
            >
              <slot name="iconTab" :semiCompactTabs="semiCompactTabs" :tab="tab">
                <cns-icon :class="{ 'me-3': !semiCompactTabs && !props.reverseTabIcon, 'mx-2': semiCompactTabs }" v-if="tab.icon" :type="tab.icon"/>
              </slot>
              <span v-if="!semiCompactTabs || (semiCompactTabs && !tab.icon)">{{ tab.title }}</span>
            </h3>
          </template>
        </template>
        <div class="card-actions d-flex align-items-center justify-content-end gap-2 py-3">
          <slot name="actions" />
        </div>
        <div v-if="props.accordion" class="accordion-button justify-content-end accordion-pointer ps-1" :class="{ open: accordionOpen }">
          <cns-icon :type="accordionOpen ? 'chevron-up' : 'chevron-down'" />
        </div>
      </div>
    </header>
    <div v-if="!props.noBody" :id="`card-body-${props.title}`" class="card-body mx-0 mb-2 px-3 px-lg-4 d-flex flex-column"
      :class="{ 'mt-2': noHeader, 'd-none': props.accordion && !accordionOpen }"
      ref="cardBodyRef"
    >
      <slot/>
    </div>
    <template v-else>
      <slot/>
    </template>
  </div>
</template>

<style scoped>
.cns-card .card-header {
  height: 65px;
  flex: 0 0 auto;
  background-color: inherit;
}

.cns-card .card-alert {
  height: 55px;
}

.cns-card .card-body {
  height: calc(100% - 65px);
  flex: 0 0 auto;
}

.cns-card .card-alert~.card-body {
  height: calc(100% - (120px + .5rem));
}

.cns-card .card-header-container {
  height: 100%;
  /* overflow-x: auto; */

  -ms-overflow-style: none;
  scrollbar-width: none;
}

.cns-card .card-header-container .card-actions:empty {
  display: none;
}

.cns-card .card-header-container::-webkit-scrollbar {
  display: none;
}

.cns-card .card-header-container > * {
  border-color: var(--hig-primary) !important;
  border-bottom: 2px solid;
}

.cns-card .card-header-container.accordion-title-border > * {
  border:none !important;
}

.title {
  border-color: var(--hig-primary) !important;
  border-bottom: 2px solid;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title-accordion {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cns-card .card-header-container .card-actions:empty {
  display: none;
}

.cns-card .card-header-container::-webkit-scrollbar {
  display: none;
}

.cns-card .card-header-container > .back-button {
  cursor: pointer;
}

.cns-card .card-header-container > .tab {
  border-color: var(--hig-page-text-muted) !important;
  color: var(--hig-page-text-muted);
  cursor: pointer;
  transition: color .3s ease, border-color .3s ease;
}

.cns-card .card-header-container > .tab:hover {
  border-color: var(--hig-page-text) !important;
  color: var(--hig-page-text);
}

.cns-card .card-header-container > .back-button + .tab,
.cns-card .card-header-container > .title + .tab {
  margin-left: .3em;
}

.cns-card .card-header-container > .tab.active {
  border-color: var(--hig-primary) !important;
  color: var(--hig-page-text);
}

.cns-card .card-header-container > .compact-tabs-tab > .compact-tabs-tab-icon {
  transition: transform .3s ease;
  font-size: 1em;
}

.cns-card .card-header-container > .compact-tabs-tab.open > .compact-tabs-tab-icon {
  transform: rotate(180deg);
}

.cns-card .card-header-container > .accordion-button {
  max-width: fit-content;
}

.cns-card .card-header-container > .accordion-button > .compact-accordion-icon {
  /* transition: transform 1.3s ease; */
  font-size: 1.8em;
}

.accordion-pointer {
  cursor: pointer;
}

.dropdown-tab {
  cursor: pointer;
}

.dropdown-tab:hover {
  background-color: var(--hig-primary);
  color: var(--hig-primary-text);
}

/* Change the background of the child cards to a different shade */
.cns-card:deep(.cns-card) {
  background-color: var(--hig-page-bg-light);
}

/* Variants (the `:is(.cns-card)` is a fix for when a card is inside a modal) */
.cns-card.cns-card-primary:is(.cns-card) {
  color: var(--hig-primary-text);
  background: var(--hig-primary);
  border-color: var(--hig-primary-border);
}
.cns-card.cns-card-primary:is(.cns-card) .card-header-container { border-color: var(--hig-primary-text) !important; }
.cns-card.cns-card-primary:deep(.cns-card.cns-card-primary) {
  background-color: var(--hig-primary-accent);
  border-color: var(--hig-primary-border-active);
}

.cns-card.cns-card-secondary:is(.cns-card) {
  color: var(--hig-secondary-text);
  background: var(--hig-secondary);
  border-color: var(--hig-secondary-border);
}
.cns-card.cns-card-secondary:is(.cns-card) .card-header-container { border-color: var(--hig-secondary-text) !important; }
.cns-card.cns-card-secondary:deep(.cns-card.cns-card-secondary) {
  background-color: var(--hig-secondary-accent);
  border-color: var(--hig-secondary-border-active);
}

.cns-card.cns-card-success:is(.cns-card) {
  color: var(--hig-success-text);
  background: var(--hig-success);
  border-color: var(--hig-success-border);
}
.cns-card.cns-card-success:is(.cns-card) .card-header-container { border-color: var(--hig-success-text) !important; }
.cns-card.cns-card-success:deep(.cns-card.cns-card-success) {
  background-color: var(--hig-success-accent);
  border-color: var(--hig-success-border-active);
}

.cns-card.cns-card-info:is(.cns-card) {
  color: var(--hig-info-text);
  background: var(--hig-info);
  border-color: var(--hig-info-border);
}
.cns-card.cns-card-info:is(.cns-card) .card-header-container { border-color: var(--hig-info-text) !important; }
.cns-card.cns-card-info:deep(.cns-card.cns-card-info) {
  background-color: var(--hig-info-accent);
  border-color: var(--hig-info-border-active);
}

.cns-card.cns-card-warning:is(.cns-card) {
  color: var(--hig-warning-text);
  background: var(--hig-warning);
  border-color: var(--hig-warning-border);
}
.cns-card.cns-card-warning:is(.cns-card) .card-header-container { border-color: var(--hig-warning-text) !important; }
.cns-card.cns-card-warning:deep(.cns-card.cns-card-warning) {
  background-color: var(--hig-warning-accent);
  border-color: var(--hig-warning-border-active);
}

.cns-card.cns-card-danger:is(.cns-card) {
  color: var(--hig-danger-text);
  background: var(--hig-danger);
  border-color: var(--hig-danger-border);
}
.cns-card.cns-card-danger:is(.cns-card) .card-header-container { border-color: var(--hig-danger-text) !important; }
.cns-card.cns-card-danger:deep(.cns-card.cns-card-danger) {
  background-color: var(--hig-danger-accent);
  border-color: var(--hig-danger-border-active);
}

.cns-card.cns-card-light:is(.cns-card) {
  color: var(--hig-light-text);
  background: var(--hig-light);
  border-color: var(--hig-light-border);
}
.cns-card.cns-card-light:is(.cns-card) .card-header-container { border-color: var(--hig-light-text) !important; }
.cns-card.cns-card-light:deep(.cns-card.cns-card-light) {
  background-color: var(--hig-light-accent);
  border-color: var(--hig-light-border-active);
}

.cns-card.cns-card-dark:is(.cns-card) {
  color: var(--hig-dark-text);
  background: var(--hig-dark);
  border-color: var(--hig-dark-border);
}
.cns-card.cns-card-dark:is(.cns-card) .card-header-container { border-color: var(--hig-dark-text) !important; }
.cns-card.cns-card-dark:deep(.cns-card.cns-card-dark) {
  background-color: var(--hig-dark-accent);
  border-color: var(--hig-dark-border-active);
}

.cns-card.cns-card-muted {
  color: var(--hig-page-text-muted);
}
.cns-card.cns-card-muted .card-header-container { border-color: var(--hig-page-text-muted) !important; }

.card-header {
  border-radius: calc(var(--hig-card-border-radius) - 1px) calc(var(--hig-card-border-radius) - 1px) calc(var(--hig-card-border-radius) - 1px) calc(var(--hig-card-border-radius) - 1px);
}

.reverse-tab-icon {
  flex-direction: row-reverse;
  justify-content: space-between;
}

</style>
