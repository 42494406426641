<template>
  <div class="bTable">
    <div class="bTable_body">
      <table ref="table" class="m-0 nowrap" :class="classes" style="border-collapse: collapse; border-spacing: 0px; min-width: 100%; width: auto;" :style="{ 'vertical-align': verticalAlign, 'text-align':horizontalAlign }" role="grid">
        <thead ref="head">
          <tr>
            <th v-for="(col, colI) in cols" :key="colI" :class="{ 'sorting': col.sortable, 'sorting_asc': sort_ && sort_.col == col.name && sort_.dir == -1, 'sorting_desc': sort_ && sort_.col == col.name && sort_.dir == 1 }" @click="col.sortable && toggleSort(col.name)" :width="col.width">
              <slot :name="col.name+'-head'" :col="col.name" :lbl="col.label">
                <slot name="head" :col="col.name" :lbl="col.label"> <!-- This slot is a backup of the outer one -->
                  <div class="headStdContent">{{col.label}}</div>
                </slot>
              </slot>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr ref="row-1" class="row-1"><td v-for="(col, colI) in cols" :key="colI"><slot :name="col.name" val="" :row="{}" :index="-1"></slot></td></tr>
          <tr v-for="rowI in pageIndexes" :key="rowI" :class="{ [selectedRowClass]: selectedRows[rowI] }" @mousedown.stop.prevent="rowClick($event, rowI)">
            <td v-for="(col, colI) in cols" :key="colI" @click="col.clickable && cellClick(rowI, col.name)" :style="{ 'cursor': (selectableRows || col.clickable) ? 'pointer' : 'auto' }">
              <slot :name="col.name" :val="get_(data[ rowI ], col.path || col.name)" :row="data[ rowI ]" :index="rowI" :col="col.name">
                <slot name="cell" :val="get_(data[ rowI ], col.path || col.name)" :row="data[ rowI ]" :index="rowI" :col="col.name"> <!-- This slot is a backup of the outer one -->
                  <div class="cellStdContent">{{get_(data[ rowI ], col.path || col.name)}}</div>
                </slot>
              </slot>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="!noPagination" class="bTable_footer row mt-2">
      <div class="col-sm-12 col-md-5 footer_status ps-3">
        <div class="text-muted" role="status" aria-live="polite">{{Math.min( page_ * rowsPerPage_ + 1, indexes.length )}} - {{Math.min( page_ * rowsPerPage_ + rowsPerPage_, indexes.length ) + ' ' + $edw.of + ' ' + indexes.length}}</div>
      </div>
      <div class="col-sm-12 col-md-7 footer_pagination">
        <div class="pagination_container">
          <ul class="pagination">
            <li :class="[ 'paginate_button', 'page-item', 'previous', { 'disabled': isFirstPage } ]" >
              <a style="cursor: pointer;" data-dt-idx="0" tabindex="0" class="page-link" @click="goToPrevPage()">{{$edw.prev}}</a>
            </li>
            <li v-for="item in paginationItems" :key="item" :class="[ 'paginate_button', 'page-item', { 'active': item == page_ } ]">
              <a style="cursor: pointer;" tabindex="0" class="page-link" @click="goToPage( item )">{{ item + 1 }}</a>
            </li>
            <li :class="[ 'paginate_button', 'page-item', 'next', { 'disabled': isLastPage } ]">
              <a style="cursor: pointer;" data-dt-idx="7" tabindex="0" class="page-link" @click="goToNextPage()">{{$edw.next}}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

// col: {
//   name: String
//   path: String // the path of the parameter to display
//   search: String // the path of the parameter to use for search
//   sort: String // the path of the parameter to use for sorting
//   label: String
//   sticky: Boolean
//   width: Number || String
//   searchable: Boolean
//   sortable: Boolean
//   clickable: Boolean
// }

export default {
  name: 'b-table',
  props: {
    cols: { type: Array, default: function () { return []; } },
    data: { type: Array, default: function () { return []; } },
    filter: { type: String, default: undefined },
    sort: { type: Object, default: undefined },
    rowsPerPage: { type: Number, default: undefined },
    page: { type: Number, default: undefined },
    'no-pagination': { type: Boolean, default: false },
    variant: { type: String, default: undefined },
    striped: { type: Boolean, default: false },
    hover: { type: Boolean, default: false },
    bordered: { type: Boolean, default: false },
    borderless: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    responsive: { type: [Boolean, String], default: false },
    'vertical-align': { type: String, default: 'middle' },
    'horizontal-align': { type: String, default: 'center' },
    'selectable-rows': { type: [Boolean, String], default: false }
  },
  data () {
    return {
      sort_: this.sort,
      rowHeight: 0,
      tableHeight: 0,
      page_: this.page || 0,
      selectedRows: {}
    };
  },
  computed: {
    classes: function () {
      return [
        'table',
        {
          ['table-' + this.variant]: !!this.variant,
          'table-striped': this.striped,
          'table-hover': this.hover,
          'table-bordered': this.bordered,
          'table-borderless': this.borderless,
          'table-sm': this.small,
          'table-responsive': this.responsive === true,
          ['table-responsive-' + this.responsive]: typeof this.responsive === 'string'
        }
      ];
    },
    selectedRowClass: function () {
      return typeof this.selectableRows === 'string' ? 'table-' + this.selectableRows : 'table-primary';
    },
    indexes: function () {
      let indexes = this.data.map((_, index) => { return index; });

      if (this.filter) {
        const colsSearchPath = this.cols.map((col) => { return col.search || col.path || col.name; });

        indexes = indexes.filter((i) => {
          for (let j = 0; j < colsSearchPath.length; j++) {
            if (_.get(this.data[i], colsSearchPath[j]) != null && contains(_.get(this.data[i], colsSearchPath[j]), this.filter)) {
              return true;
            }
          }
          return false;
        });
      }

      if (this.sort_ && this.sort_.col !== '' && this.sort_.dir != null && this.sort_.dir !== 0) {
        const sortCol = this.cols.find((col) => { return col.name === this.sort_.col; });
        if (sortCol) {
          const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
          const sortPath = sortCol.sort || sortCol.path || sortCol.name;
          const sortDir = this.sort_.dir;

          const sortFunc = (a, b) => {
            if (_.get(this.data[a], sortPath) != null && _.get(this.data[b], sortPath) != null) {
              if (sortDir > 0) {
                return collator.compare(String(_.get(this.data[a], sortPath)), String(_.get(this.data[b], sortPath)));
              } else if (sortDir < 0) {
                return collator.compare(String(_.get(this.data[b], sortPath)), String(_.get(this.data[a], sortPath)));
              } else {
                return a < b ? -1 : 1;
              }
            }
            return 0;
          };

          indexes = indexes.sort(sortFunc);
        }
      }

      return indexes;
    },
    rowsPerPage_: function () {
      if (this.noPagination) { return this.indexes.length; }
      if (this.rowsPerPage) { return parseInt(this.rowsPerPage); }
      return Math.max(parseInt(this.tableHeight / this.rowHeight), 1);
    },
    pagesCount: function () {
      if (this.noPagination) { return 1; }
      return Math.ceil(this.indexes.length / this.rowsPerPage_);
    },
    pageIndexes: function () {
      if (this.noPagination) { return this.indexes; }
      return this.indexes.slice(this.page_ * this.rowsPerPage_, this.page_ * this.rowsPerPage_ + this.rowsPerPage_);
    },
    paginationItems: function () {
      const ret = [this.page_]; const maxItems = 5;

      let i = 1;
      while (ret.length < maxItems && ret.length < this.pagesCount) {
        if (this.page_ + i <= this.pagesCount - 1) { ret.push(this.page_ + i); }
        if (this.page_ - i >= 0) { ret.unshift(this.page_ - i); }

        i++;
      }

      return ret;
    },
    isFirstPage: function () { return this.page_ === 0; },
    isLastPage: function () { return this.page_ === this.pagesCount - 1; }
  },
  watch: {
    sort: function () { this.sort_ = this.sort; },
    page: function () { this.page_ = this.page; },
    indexes: {
      deep: true,
      handler: function () { this.goToPage(this.page_); }
    },
    selectableRows: function () {
      if (this.selectableRows) {
        document.body.addEventListener('click', this.bodyClick);
      } else {
        document.body.removeEventListener('click', this.bodyClick);
      }
    }
  },
  methods: {
    goToPrevPage () {
      this.page_ = Math.max(this.page_ - 1, 0);
    },
    goToNextPage () {
      this.page_ = Math.min(this.page_ + 1, this.pagesCount - 1);
    },
    goToPage (index) {
      this.page_ = Math.min(Math.max(index, 0), this.pagesCount - 1);
    },
    toggleSort (col) {
      this.sort_ = { col: col, dir: (this.sort_ && this.sort_.col === col) ? (this.sort_.dir + 1 === 2 ? -1 : this.sort_.dir + 1) : 1 };
    },
    calcTableAndRowHeight () {
      if (!this.$refs['row-1'] || !this.$refs.table || !this.$refs.head) { return; }
      this.$refs['row-1'].style.display = 'table-row';
      this.rowHeight = this.$refs['row-1'].offsetHeight;
      this.$refs.head.style.height = '2px';
      this.$refs.table.style.height = '100%';
      this.tableHeight = this.$refs.table.offsetHeight - this.$refs.head.offsetHeight;
      this.$refs.table.style.height = '';
      this.$refs['row-1'].style.display = '';
    },
    rowClick (e, rowI) {
      if (!this.selectableRows) { return; }
      if (e.ctrlKey) {
        (this.selectedRows[rowI] && (delete this.selectedRows[rowI])) || (this.selectedRows[rowI] = true);
      } else if (e.shiftKey) {
        const selRows = Object.keys(this.selectedRows);
        const lastSelRow = selRows.length ? selRows[selRows.length - 1] : rowI;

        for (let i = Math.min(lastSelRow, rowI); i <= Math.max(lastSelRow, rowI); i++) {
          this.selectedRows[i] = true;
        }
      } else {
        this.selectedRows = { [rowI]: true };
      }
      document.getSelection().removeAllRanges();
      this.$emit('row-select', this.selectedRows);
    },
    bodyClick () {
      this.selectedRows = {};
      this.$emit('row-select', this.selectedRows);
    },
    cellClick (rowI, col) {
      this.$emit('row-click', { index: rowI, row: this.data[rowI] });
      this.$emit('cell-click', { index: rowI, row: this.data[rowI], col: col, val: this.data[rowI][col] });
    },
    get_: _.get
  },
  mounted () {
    this.$nextTick(() => { this.calcTableAndRowHeight(); });

    if (this.selectableRows) {
      document.body.addEventListener('mousedown', this.bodyClick);
    }
  },
  unmounted () {
    if (this.selectableRows) {
      document.body.removeEventListener('mousedown', this.bodyClick);
    }
  }
};

const contains = function (a, b) { return String(a).toLowerCase().indexOf(b.toLowerCase()) > -1; };
</script>

<style scoped>
.bTable {
  display: flex;
  flex-flow: column nowrap;
  /* height: 100%; */
}

.bTable_body {
  height: 0;
  flex: 0 1 100%;
}

.bTable_footer {
  flex: 0 0 auto;
}

.footer_status, .footer_pagination {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.pagination_container {
  flex: 0 0 100%;
  margin: 0;
  white-space: nowrap;
  text-align: right;
}

.pagination {
  margin: 2px 0;
  white-space: nowrap;
  justify-content: flex-end;
}

.footer_pagination .paginate_button {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
  supported by Chrome, Edge, Opera and Firefox */
}

table {
  table-layout: fixed;
}

table th {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
  supported by Chrome, Edge, Opera and Firefox */
}

table.nowrap th,
table.nowrap td {
  white-space: nowrap;
}

table td{
  padding: 0 .5rem !important;
}

table th{
  padding: .25rem .5rem !important;
}

table>thead .sorting,
table>thead .sorting_asc,
table>thead .sorting_desc{
  padding: .25rem 30px .25rem .5rem !important;
}

table>thead .sorting,
table>thead .sorting_asc,
table>thead .sorting_desc,
table>thead .sorting_asc_disabled,
table>thead .sorting_desc_disabled {
  cursor: pointer;
  position: relative;
}

table>thead .sorting:before,
table>thead .sorting:after,
table>thead .sorting_asc:before,
table>thead .sorting_asc:after,
table>thead .sorting_desc:before,
table>thead .sorting_desc:after,
table>thead .sorting_asc_disabled:before,
table>thead .sorting_asc_disabled:after,
table>thead .sorting_desc_disabled:before,
table>thead .sorting_desc_disabled:after {
  position: absolute;
  bottom: .25rem;
  display: block;
  opacity: .3;
}

table>thead .sorting_asc:before,
table>thead .sorting_desc:after {
  opacity: 1;
}

table>thead .sorting:before,
table>thead .sorting_asc:before,
table>thead .sorting_desc:before,
table>thead .sorting_asc_disabled:before,
table>thead .sorting_desc_disabled:before {
  left: auto;
  right: 1rem;
  content: "↑";
}

table>thead .sorting:after,
table>thead .sorting_asc:after,
table>thead .sorting_desc:after,
table>thead .sorting_asc_disabled:after,
table>thead .sorting_desc_disabled:after {
  left: auto;
  right: .5rem;
  content: "↓";
}

table tr.row-1{
  display: none;
}
</style>
