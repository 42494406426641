<script setup>
import { computed, inject, ref, watch } from 'vue';
import { useCnsRouter } from '../../plugins/cns-router';
import CnsPage from '../cns/cns-page.vue';
import CnsActions from '../cns/cns-actions.vue';
import CnsModal from '../cns/cns-modal.vue';
import { CnsTable } from '../cns/cns-table';
import BCheckbox from '../bootstrap/b-checkbox.vue';
import _ from 'lodash';

const $edw = inject('$edw');
const $router = useCnsRouter();

const props = defineProps({
  title: { type: String, default: '' },
  addTitle: { type: String, default: '' },
  cols: { type: Object, required: true },
  colsAssociated: { type: Object, default: undefined },
  fetch: { type: Function, required: true },
  fetchAssociated: { type: Function, required: true },
  idField: { type: String, default: 'id' },
  linkTo: { type: Function, default: undefined },
  remove: { type: Function, default: undefined },
  add: { type: Function, default: undefined },
  save: { type: Function, default: undefined }
});

const cols = computed(() => [{ name: 'selected', label: '', minWidth: 20, width: 20, searchable: false }, ...props.cols]);
const colsAssociated = computed(() => {
  const cols = [...(props.colsAssociated || props.cols)];
  if (props.remove != null) {
    cols.unshift({ name: 'selected', label: '', minWidth: 20, width: 20, searchable: false });
  }
  return cols;
});

const toRemove = ref({});
const toAdd = ref({});
const toSave = ref({});

const modal = ref();
const tableAssociated = ref();

const error = ref();
const saveOk = ref();
const savingRem = ref(false);
const savingAdd = ref(false);
const savingSave = ref(false);

const actions = computed(() => {
  const actions = [];

  if (props.add != null) {
    actions.push({ text: $edw.add, icon: 'plus', action: openAddModal });
  }

  if (props.save != null) {
    actions.push({ text: $edw.save, icon: 'floppy-disk', action: saveSave, disabled: Object.keys(toSave.value).length === 0 });
  }

  if (props.remove != null) {
    actions.push({ text: $edw.removeSelected, icon: 'trash', variant: 'danger', action: saveRem, loading: savingRem.value, disabled: Object.keys(toRemove.value).length === 0 });
  }

  return actions;
});

const modalActions = computed(() => [
  { text: $edw.cancel, icon: 'cancel', variant: 'secondary', action: closeAddModal },
  { text: $edw.add, icon: 'add', variant: 'primary', action: saveAdd, loading: savingAdd.value, disabled: Object.keys(toAdd.value).length === 0 }
]);

const cnsCardAlert = ref({});
watch([error, saveOk], () => {
  if (error.value) {
    cnsCardAlert.value.text = error.value;
    cnsCardAlert.value.variant = 'danger';
    cnsCardAlert.value.actions = [{ text: $edw.ok, action: () => { error.value = undefined; } }];
    cnsCardAlert.value.icon = 'circle-exclamation';
  } else if (saveOk.value) {
    cnsCardAlert.value.text = $edw.savedCorrectly;
    cnsCardAlert.value.variant = 'success';
    cnsCardAlert.value.actions = [{ text: $edw.ok, action: () => { saveOk.value = undefined; } }];
    cnsCardAlert.value.icon = 'check';
  } else {
    cnsCardAlert.value = {};
  }
});

async function saveAdd () {
  if (savingAdd.value || props.add == null) { return; };
  savingAdd.value = true;
  await props.add(Object.keys(toAdd.value))
    .then(() => { saveOk.value = true; toAdd.value = {}; })
    .catch((err) => { error.value = $edw.translateError(err); });
  savingAdd.value = false;
  reset();
}

async function saveRem () {
  if (savingRem.value || props.remove == null) { return; };
  savingRem.value = true;
  await props.remove(Object.keys(toRemove.value))
    .then(() => { saveOk.value = true; toRemove.value = {}; })
    .catch((err) => { error.value = $edw.translateError(err); });
  savingRem.value = true;
  reset();
}

async function saveSave () {
  if (savingSave.value || props.save == null) { return; };
  savingSave.value = true;
  await props.save(Object.values(toSave.value))
    .then(() => { saveOk.value = true; toSave.value = {}; })
    .catch((err) => { error.value = $edw.translateError(err); });
  savingSave.value = false;
  reset();
}

function toggleItemToRem (item) {
  const id = _.get(item, props.idField);
  if (toRemove.value[id]) {
    delete toRemove.value[id];
  } else {
    toRemove.value[id] = true;
  }
}

function toggleItemToAdd (item) {
  const id = _.get(item, props.idField);
  if (toAdd.value[id]) {
    delete toAdd.value[id];
  } else {
    toAdd.value[id] = true;
  }
}

function updateItemValue (item, col, newVal) {
  const itemToSave = _.clone(item);
  _.set(itemToSave, col.field || col.name, newVal);

  const id = _.get(itemToSave, props.idField);
  toSave.value[id] = itemToSave;
}

async function fetchAssociated (fop) {
  const rows = await props.fetchAssociated(fop);
  if (!rows || rows.length === 0) { return false; }
  return rows;
}
async function fetchAssociable (fop) {
  const rows = await props.fetch(fop);
  if (!rows || rows.length === 0) { return false; }

  const rowsIds = [...new Set(rows.map((row) => _.get(row, props.idField)))];
  const alreadyAssociatedRows = await props.fetchAssociated({ filter: [{ field: props.idField, op: '=', val: rowsIds }] });

  const alreadyAssociatedIds = {};
  if (Array.isArray(alreadyAssociatedRows)) {
    alreadyAssociatedRows.forEach((row) => { alreadyAssociatedIds[_.get(row, props.idField)] = true; });
  }

  return rows.filter((row) => !alreadyAssociatedIds[_.get(row, props.idField)]);
}

function reset () {
  toRemove.value = {};
  toAdd.value = {};
  closeAddModal();
  savingRem.value = false;
  savingAdd.value = false;

  tableAssociated.value.purgeCache();
}

function openAddModal () { modal.value.open(); }
function closeAddModal () { modal.value.close(); }

function linkTo (row) {
  if (props.linkTo) {
    $router.push(props.linkTo(row)).then(() => {
      closeAddModal();
    });
  }
}

defineExpose({ reset });
</script>

<template>
  <cns-page
    :title="title"
    back-button
    @back="$router.back()"
    :alert="cnsCardAlert"
    no-scroll-x
    no-scroll-y
    full-width
  >
    <template #actions>
      <cns-actions :actions="actions" :visible="Math.max(actions.length - 1, 1)"/>
    </template>

    <cns-table
      ref="tableAssociated"
      :cols="colsAssociated"
      :data="fetchAssociated"
      :fetch-page-size="200"
      :rows-id="props.idField"
      @row-click="({ row }) => linkTo(row)"
      compact
      hover
      bordered
    > <!-- FIXME delete rows-height once fixed cns-table-simple (rows sometimes stretch too much) -->
      <template v-for="col in colsAssociated" :key="col.name" v-slot:[col.name]="opt">
        <div v-if="col.name === 'selected'" class="w-100 h-100 d-flex align-items-center justify-content-center p-1" @click.stop>
          <b-checkbox :modelValue="toRemove[_.get(opt.row, props.idField)]" @update:modelValue="() => toggleItemToRem(opt.row)" no-anim/>
        </div>
        <slot v-else :name="col.name" :val="opt.val" :set-val="(newVal) => { updateItemValue(opt.row, col, newVal); }" :row="opt.row" :index="opt.index" :col="opt.col">
          {{ opt.val }}
        </slot>
      </template>
    </cns-table>

    <cns-modal ref="modal" :title="addTitle" size="xl" scrollable>
      <template #actions>
        <cns-actions :actions="modalActions" :visible="2"/>
      </template>

      <div class="w-100" style="height: 80vh;">
        <cns-table
          ref="tableAvailable"
          :cols="cols"
          :data="fetchAssociable"
          :fetch-page-size="200"
          :rows-id="props.idField"
          @row-click="({ row }) => linkTo(row)"
          compact
          hover
          bordered
        > <!-- FIXME delete rows-height once fixed cns-table-simple (rows sometimes stretch too much) -->
          <template #selected="{ row }">
            <div class="w-100 h-100 d-flex align-items-center justify-content-center p-1" @click.stop>
              <b-checkbox :modelValue="toAdd[_.get(row, props.idField)]" @update:modelValue="() => toggleItemToAdd(row)" no-anim/>
            </div>
          </template>
        </cns-table>
      </div>
    </cns-modal>

  </cns-page>
</template>
