<script setup lang="ts">
/* global HigJS */
import { watchEffect, ref, watch } from 'vue';
import cnsIcon from '../../../cns/cns-icon.vue';
import createInstance from 'svg-gauge';
import Utils from '../../../../libs/utils/index.mjs';

interface Props {
  value: number | string,
  um?: string,
  label?: string,
  desc?: string,
  minWidth?: string,
  maxWidth?: string,
  color?: string;
  max?: number,
  min?: number,
  title?: string,
  decimals?: number,
  loading?: boolean,
  icon?: string | null,
  class?: string,
  mutedColor?: string,
}

const props = withDefaults(defineProps<Props>(), {
  value: undefined,
  um: '',
  label: '',
  desc: '',
  minWidth: '0',
  maxWidth: '1000px',
  color: 'var(--hig-primary)',
  max: 100,
  min: 0,
  title: '',
  decimals: 2,
  loading: false,
  icon: null,
  class: '',
  mutedColor: 'var(--hig-page-text-muted)'
});

const circle3Ref = ref(null);

const instance = ref();

watch(props, (d) => {
  if (instance.value && d.value !== undefined) {
    if (!isNaN(Number(d.value))) {
      instance.value.setValueAnimated(d.value, 1);
    }

    if (d.max) {
      instance.value.setMaxValue(d.max, 1);
    }
  }
});

watch(instance, (d) => {
  if (!d || !circle3Ref.value) {
    return;
  }
  circle3Ref.value.querySelector('.value').style.stroke = props.color;
  circle3Ref.value.querySelector('.value').style.strokeWidth = 5;
  circle3Ref.value.querySelector('.value-text').style.fill = props.color;
  circle3Ref.value.querySelector('.dial').style.stroke = '#aaa';
});

watchEffect(() => {
  if (!circle3Ref.value || props.value === undefined || instance.value) {
    return;
  }
  instance.value = createInstance(circle3Ref.value, {
    min: props.min,
    max: props.max,
    value: Utils.isNum(props.value) ? props.value : 0,
    radius: 45,
    label: (value) => {
      if (!Utils.isNum(value)) {
        return String(props.value);
      }
      return HigJS.num.format(Utils.round(value, 2, true));
    }
  });
});

</script>

<template>
  <div class="w-100 h-100">
    <div v-if="!instance" class="w-100 d-flex justify-content-center">
      <div class="spinner-border text-primary" role="status" />
    </div>
    <div v-else-if="isNaN(Number(props.value))"
      class="d-flex flex-column justify-content-center align-items-center w-100 h-100">
      <cns-icon type="gauge fw" />
    </div>
    <div class="w-100 d-flex flex-column align-items-center">
      <div ref="circle3RefContainer" :title="props.title" class="d-flex flex-column w-100 h-100" :style="{
      minWidth: props.minWidth,
      maxWidth: props.maxWidth,
      visibility: isNaN(Number(props.value)) ? 'hidden' : 'visible'
    }">
        <div ref="circle3Ref" class="m-auto h-100 w-100 flex-grow-1 position-relative">
          <p class="text-center mb-4 position-absolute top-50 start-50 translate-middle" :style="{
      margin: 0,
      padding: 0,
      paddingTop: '30%',
      color: props.color
    }">
            {{ props.um }}
          </p>
          <div />
        </div>
      </div>

      <p v-if="props.label" class="text-center" :style="{ margin: 0, padding: 0 }">
        {{ props.label }}
      </p>
    </div>
  </div>
</template>
