<script setup>
import { inject, ref, computed, nextTick, onMounted, useSlots } from 'vue';
import CnsModal from './cns-modal.vue';
import CnsIcon from './cns-icon.vue';
import CnsButton from './cns-button.vue';

const props = defineProps({
  icon: { type: String, default: 'circle-info' },
  title: { type: String, default: undefined },
  desc: { type: String, default: undefined },
  details: { type: String, default: undefined },
  variant: { type: String, default: 'info' },
  actions: { type: Array, default: undefined }
});
const slots = useSlots();

const $edw = inject('$edw');
const modal = ref();

const allowShowDetails = computed(() => props.details || slots.details);
const showDetails = ref(false);

onMounted(() => {
  nextTick(() => {
    modal.value.open();
  });
});
</script>

<template>
  <cns-modal ref="modal" centered no-header>
    <div class="d-flex flex-column align-items-center" :class="{ [`cns-modal-notice-${props.variant}`]: !!props.variant }">
      <cns-icon class="icon mb-2" :type="props.icon"/>
      <span class="title mb-2 text-center">
        <slot name="title">
          {{ props.title ?? $edw.info  }}
        </slot>
      </span>
      <span class="desc mb-3 text-center">
        <slot>
          {{ props.desc }}
        </slot>
      </span>
      <div class="d-flex justify-content-center gap-2 w-100">
        <cns-button class="button" @click="showDetails = !showDetails" :variant="`outline-${props.variant}`" :text="$edw.details" v-if="allowShowDetails" />
        <cns-button class="button" v-for="action in actions" :key="action.text" @click="() => action.action && action.action()" :variant="action.variant || props.variant" :text="action.text" />
      </div>
      <span class="details p-2 rounded mt-3 w-100" v-if="showDetails">
        <slot name="details">
          {{ props.details }}
        </slot>
      </span>
    </div>
  </cns-modal>
</template>

<style scoped>
.icon { font-size: 4.5em; }
.title { font-size: 40px; }
.desc { font-size: 16px; }
.details {
  font-size: 14px;
  background: var(--hig-page-bg-light);
}

.button {
  flex: 0 1 60%;
}

.cns-modal-notice-primary .icon { color: var(--hig-primary) }
.cns-modal-notice-secondary .icon { color: var(--hig-secondary); }
.cns-modal-notice-success .icon { color: var(--hig-success); }
.cns-modal-notice-info .icon { color: var(--hig-info); }
.cns-modal-notice-warning .icon { color: var(--hig-warning); }
.cns-modal-notice-danger .icon { color: var(--hig-danger); }
.cns-modal-notice-light .icon { color: var(--hig-light); }
.cns-modal-notice-dark .icon { color: var(--hig-dark); }
</style>
