import CnsDataCard from '../cns-data-card.vue';
import CnsDataChart from '../cns-data-chart.vue';
import CnsDataCircle from '../cns-data-circle.vue';

import type { Component } from 'vue';
import type { FilterOrderPaginateFilter } from '../../../libs/fop-utils/libs/types.js';
import type { TidyAggregateNodeDataAggregator, SeriesData, TidyFormatDataByNodeOutput } from '../../../libs/data-utils/index.js';

// #region CnsDataWrapperConfig
interface PredefinedComponents {
  'cns-data-chart': Component;
  'cns-data-card': Component;
  'cns-data-circle': Component;
}

type CnsDataWrapperConfigDataIntervalObj = { start: string | number, stop: string | number };
type CnsDataWrapperConfigDataInterval = 'last' | string | CnsDataWrapperConfigDataIntervalObj;

interface CnsDataWrapperConfigData {
  // The type of data to load
  type: 'agg' | 'raw' | 'parameter' | 'raw-dl';

  // The name of the variable to load
  name: string;

  // The period to load the data from, can be on of: '15m', '1hour', '1day', '1month' (if type is 'agg')
  // or the number of seconds between samples (if type is 'raw' or 'raw-dl'), this is useless if type is 'parameter'
  period?: string | number;

  // The interval of data to load ('last' by default)
  interval?: CnsDataWrapperConfigDataInterval;

  // The name of the function to execute on the data before the aggregation
  transformation?: string;

  // The name of the function to execute on the data to aggregate it, sum by default
  aggregation?: string;

  // The number of decimals to round the value to, 2 by default
  decimals?: number;
  nDec?: number; // Deprecated, translated to decimals

  // If true pass to the component only the values and not the whole SeriesData
  valueOnly?: boolean,

  // How to group the values when aggregating them, special cases:
  // - undefined -> 'timestamp': Good for the 98% of applications
  // - false -> none: Aggergate all values together
  groupBy?: keyof TidyFormatDataByNodeOutput | boolean | undefined
}

interface CnsDataWrapperConfigAlarms {
  // The type of data to pass to the component
  type: 'list' | 'count';

  // The status of the alarms to get
  status?: string | string[];

  // The levels of the alarms to get
  level?: string | string[];

  // The interval in which to get the alarms
  interval?: CnsDataWrapperConfigDataIntervalObj;
}

interface CnsDataWrapperConfigActionWriteRawDl {
  type: 'write-raw-dl';

  // The name of the variable to write to
  // (the name of a node parameter, the value will be written to the associated raw variable)
  name: string;

  // The value to write, if this is null then the value will be taken from the emit argument
  value?: number | string;

  // A list of data entries to reload after the write operation
  refetchData?: string[];

  // If set then this number of milliseconds will be waited before refetching the data (1000ms by default)
  refetchDelay?: number;
}

// TODO: Add other actions like so if needed
interface CnsDataWrapperConfigActionAnotherAction {
  type: 'another-action'
}

type CnsDataWrapperConfigAction =
  CnsDataWrapperConfigActionWriteRawDl |
  CnsDataWrapperConfigActionAnotherAction;

interface CnsDataWrapperConfig {
  // The name of the component to render
  component?: keyof PredefinedComponents | string;

  // The props to directly pass to the component
  props?: { [key: string]: any };

  // Some options for the nodes
  nodes?: {
    filter: FilterOrderPaginateFilter[]
  };

  // The list of data to load
  data?: {
    // name is the name the resulting value will get in the props passed to the component
    [name: string]: CnsDataWrapperConfigData
  };

  // The list of alarms to get
  alarms?: {
    [name: string]: CnsDataWrapperConfigAlarms
  };

  // The list of possible actions
  actions?: {
    [name: string]: CnsDataWrapperConfigAction
  }

  // The data refresh interval in ms or as a string,
  // for the format of that see: https://developers.higeco.com/doc/cns/cns3/cns3-sdk/libs/utils.html#ms-desc
  dataUpdateInterval?: string | number;
}
// #endregion CnsDataWrapperConfig

// #region CnsDataWrapperNode
interface CnsDataWrapperNode {
  id: string,
  nodeId?: number | string, // This is the old id, this is converted to `id` for internal use
  [key: string]: any
}
// #endregion CnsDataWrapperNode

interface CnsDataWrapperAggregation {
  [key: string]: TidyAggregateNodeDataAggregator
}

interface CnsDataWrapperTransformation {
  [key: string]: any
}

interface FetchDataResult {
  data: SeriesData;
  isValid: boolean;
  isConfigured: boolean;
  unitOfMeasure: string;
}

export type {
  CnsDataWrapperConfig,
  CnsDataWrapperConfigData,
  CnsDataWrapperConfigDataInterval,
  CnsDataWrapperConfigDataIntervalObj,
  CnsDataWrapperConfigAlarms,
  CnsDataWrapperConfigAction,
  CnsDataWrapperConfigActionWriteRawDl,
  CnsDataWrapperNode,
  CnsDataWrapperTransformation,
  CnsDataWrapperAggregation,

  PredefinedComponents,
  FetchDataResult
};

const PREDEFINED_COMPONENTS: PredefinedComponents = {
  'cns-data-chart': CnsDataChart,
  'cns-data-card': CnsDataCard,
  'cns-data-circle': CnsDataCircle
};

export {
  PREDEFINED_COMPONENTS
};
