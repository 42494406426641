function EventEmitterFactory () {
  let subscribersIndex = 0;
  const subscribers = new Map();

  const subscribe = function (subCb) {
    const subIndex = subscribersIndex++;
    subscribers.set(subIndex, subCb);
    const unsubscribe = function () { subscribers.delete(subIndex); };

    return unsubscribe;
  };

  const emit = function * () {
    for (const subCb of subscribers.values()) {
      yield subCb(...arguments);
    }
  };

  const removeAllSubscribers = function () {
    subscribers.clear();
    subscribersIndex = 0;
  };

  return { subscribe, emit, removeAllSubscribers };
}

export default EventEmitterFactory;
