import { onUnmounted } from 'vue';

export const useEventsModule = function () {
  function createEvent (event, argsDefs = {}) {
    if (!event) { throw new Error('Missing event name'); }

    const _event = '__cns_event_' + event;
    const _subscribe = (callback, options) => subscribe(_event, callback, options);
    _subscribe.notify = (data) => {
      const _data = {};
      Object.keys(argsDefs).forEach((arg) => {
        if (data[arg] == null) {
          throw new Error(`[${event}] Missing "${arg}" in event data`);
        } else if (Object.getPrototypeOf(data[arg]).constructor !== argsDefs[arg]) {
          throw new Error(`[${event}] Argument "${arg}" type is wrong, expected ` + (argsDefs[arg].name || 'unknown'));
        }

        _data[arg] = data[arg];
      });
      Object.keys(data).forEach((arg) => {
        if (argsDefs[arg] == null) {
          console.warn(`[${event}] Argument "${arg}" it's not defined in the arguments definitions`, argsDefs);
        }
      });

      notify(_event, _data);
    };
    _subscribe.event = _event;

    return _subscribe;
  }

  function subscribe (event, callback, options = {}) {
    const _callback = (e) => { callback(e.detail); };
    window.addEventListener(event, _callback, options);

    const off = () => window.removeEventListener(event, _callback, false);
    onUnmounted(() => { off(); });

    return off;
  }

  function notify (event, data) {
    window.dispatchEvent(new window.CustomEvent(event, { detail: data }));
  }

  return {
    state: {},
    getters: {},
    actions: {
      createEvent
    }
  };
};
