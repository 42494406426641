<script setup>
import { ref, watch } from 'vue';

const emit = defineEmits(['go']);

const entries = ref([]);
const active = ref();
const displayEntries = ref([]);
const displayEntriesMoreIndex = ref();

let animationRunning = false;
let updateAfterAnimation = false;

function setEntries (newEntries) { entries.value = newEntries; }
function setEntry (index, newEntry) { entries.value[index] = newEntry; }
function setActive (index) { active.value = index; }

watch([entries, active], () => {
  if (animationRunning) {
    updateAfterAnimation = true;
  } else {
    updateDisplayEntries();
  }
}, { deep: true });

function updateDisplayEntries () {
  const to = active.value != null ? active.value + 1 : entries.value.length;

  displayEntries.value = entries.value.map((el, i) => ({ text: el, i })).slice(Math.max(0, to - 5), Math.min(entries.value.length, to));
  if (to > 5) {
    displayEntriesMoreIndex.value = to - 6;
  } else {
    displayEntriesMoreIndex.value = undefined;
  }
}

function animationStart () {
  animationRunning = true;
}

function animationEnd () {
  animationRunning = false;

  if (updateAfterAnimation) {
    updateAfterAnimation = false;
    updateDisplayEntries();
  }
}

defineExpose({ setEntries, setEntry, setActive });
</script>

<template>
  <nav aria-label="breadcrumb" class="d-flex align-items-center">
    <Transition name="fade">
      <span v-if="displayEntriesMoreIndex != null" class="breadcrumb-more">
        <span class="badge bg-light">
          <a href="#" @click.prevent="emit('go', displayEntriesMoreIndex)">
            {{ '+' + (displayEntriesMoreIndex + 1) }}
          </a>
        </span>
      </span>
    </Transition>
    <TransitionGroup
      name="fade"
      tag="ol"
      class="breadcrumb mb-0"
      @before-enter="animationStart"
      @before-leave="animationStart"
      @after-enter="animationEnd"
      @after-leave="animationEnd"
      @enter-cancelled="animationEnd"
      @leave-cancelled="animationEnd"
    >
      <li
        v-for="(entry, i) in displayEntries"
        :key="entry.i"
        class="breadcrumb-item"
        :class="{
          first: i === 0,
          active: entry.i === active
        }"
        :aria-current="entry.i === active ? 'page' : ''"
      >
        <template v-if="entry.i === active">
          <span class="breadcrumb-item-span badge bg-primary">{{ entry.text }}</span>
        </template>
        <template v-else>
          <span class="breadcrumb-item-span badge bg-light"><a href="#" @click.prevent="emit('go', entry.i)">{{ entry.text }}</a></span>
        </template>
      </li>
    </TransitionGroup>
  </nav>
</template>

<style scoped>
.breadcrumb {
  position: relative;
}

.breadcrumb-more {
  padding-right: 0.6rem;
}

.breadcrumb-more:after {
  color: var(--hig-neutral-600);
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDcgMTAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTQuMzg3MzkgNC4zMTYyOFY1LjYyMUwzLjczNDM5IDQuOTY5MjZMNC4zODczOSA0LjMxNzUxVjQuMzE2MjhaTTAuMDAxMjMxMzkgMS4zMDM0OUwxLjMwNTk5IDBMNi4zMTU1NyA1TDEuMzA1OTkgMTBMMCA4LjY5NjUxTDMuNzAxMTMgNUwwIDEuMzAzNDlIMC4wMDEyMzEzOVoiIGZpbGw9IiM4RDhEOEYiLz48L3N2Zz4K);
  float: right;
  padding-left: 0.5rem;
}

.breadcrumb-item {
  padding-right: 0.2rem;
}

.breadcrumb-item+.breadcrumb-item {
  padding-left: 0.6rem;
}

.breadcrumb-item.first {
  padding-left: 0;
}

.breadcrumb-item.first::before {
  display: none;
}

.fade-move,
.fade-enter-active,
.fade-leave-active {
  transition: all .2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-leave-active { position: absolute; }
</style>
