<script>
import { h, inject, defineComponent, provide } from 'vue';

function proxyPatchObj (obj, patch) {
  return new Proxy(obj, {
    get (_, prop) { return prop in patch ? patch[prop] : Reflect.get(...arguments); }
  });
}

function normalizeSlot (slot, data) {
  if (!slot) { return null; }
  const slotContent = slot(data);
  return slotContent.length === 1 ? slotContent[0] : slotContent;
}

export default defineComponent({
  name: 'RouterView',
  inheritAttrs: false,
  props: {
    to: { type: [String, Object], default: undefined }
  },
  compatConfig: { MODE: 3 },

  setup (props, { attrs, slots }) {
    const $depth = inject(window.cnsRouterInstance.injectKeys.routerDepth);
    const $router = inject(window.cnsRouterInstance.injectKeys.router);

    const beforeEachUnsubs = [];
    const afterEachUnsubs = [];
    provide(window.cnsRouterInstance.injectKeys.routerDepth, $depth + 1);
    provide(window.cnsRouterInstance.injectKeys.router, proxyPatchObj($router, {
      beforeEach (f) {
        const unsubscribe = $router.beforeEach(f);
        beforeEachUnsubs.push(unsubscribe);
        return unsubscribe;
      },
      afterEach (f) {
        const unsubscribe = $router.afterEach(f);
        afterEachUnsubs.push(unsubscribe);
        return unsubscribe;
      }
    }));

    // const viewRef = ref();
    return () => {
      let route;
      let matchedRoute;
      if (props.to) {
        route = window.cnsRouterInstance.parseToRoute(props.to, $router.basePath);
        matchedRoute = route?.matched?.[0];
      } else {
        route = $router.route;
        const matchedIndex = ($router.route?.matched?.length ?? 0) - ($depth + 1);
        matchedRoute = $router.route?.matched?.[matchedIndex];
      }
      const ViewComponent = matchedRoute?.component;

      let matchedRouteProps = matchedRoute?.props;
      if (typeof matchedRouteProps === 'function') { matchedRouteProps = matchedRouteProps(); }
      if (typeof matchedRouteProps !== 'object') { matchedRouteProps = undefined; }
      const RouteProps = { ...matchedRouteProps, ...route?.props };

      if (!ViewComponent) {
        return normalizeSlot(slots.default, { component: undefined, route, matchedRoute });
      }

      const onVnodeUnmounted = () => {
        // unsubscribe all subscriptions made within this route
        beforeEachUnsubs.forEach((unsubscribe) => unsubscribe());
        afterEachUnsubs.forEach((unsubscribe) => unsubscribe());
      };

      const component = h(
        ViewComponent,
        Object.assign({}, RouteProps, attrs, {
          onVnodeUnmounted
          // onVnodeBeforeMount: () => { console.log('onVNodeBeforeMount'); }, // <= this works
          // ref: viewRef // <= This can be useful in future
        })
      );

      return (
        // pass the vnode to the slot as a prop.
        // h and <component :is="..."> both accept vnodes
        normalizeSlot(slots.default, { component: component, route, matchedRoute }) || component
      );
    };
  }
});
</script>
