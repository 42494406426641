<script setup>
import { inject, ref, computed, watch, onMounted, nextTick } from 'vue';
import BCheckbox from '../bootstrap/b-checkbox.vue';
import CnsDiv from '../cns/cns-div.vue';
import CnsInput from '../cns/cns-input.vue';
import CnsSelect from '../cns/cns-select.vue';
import CnsIcon from '../cns/cns-icon.vue';
import CnsDropdown from '../cns/cns-dropdown.vue';
import CnsButton from '../cns/cns-button.vue';
import CnsDatepicker from '../cns/cns-datepicker.vue';
import CnsSettingsDictionaryEditor from './cns-settings-dictionary-label-editor.vue';
import Utils from '../../libs/utils';
import { vModel } from '../../libs/vue-utils';

const $edw = inject('$edw');

const props = defineProps({
  modelValue: { type: [Number, String, Array, Boolean, Object], default: undefined },
  config: { type: Object, default: undefined },
  label: { type: String, default: undefined },
  desc: { type: String, default: undefined },
  type: { type: String, default: undefined },
  error: { type: String, default: undefined },
  loading: { type: Boolean, default: undefined },
  readonly: { type: Boolean, default: undefined },
  plaintext: { type: Boolean, default: undefined },
  editable: { type: Boolean, default: undefined },
  default: { type: [Number, String, Array], default: undefined },
  placeholder: { type: String, default: undefined },
  rules: { type: Array, default: undefined },
  min: { type: Number, default: undefined },
  max: { type: Number, default: undefined },
  mandatory: { type: Boolean, default: undefined },
  options: { type: Array, default: undefined },
  multiple: { type: Boolean, default: undefined },
  range: { type: Boolean, default: undefined },
  time: { type: Boolean, default: undefined },
  edwEditor: { type: Object, default: undefined },
  relationsEdit: { type: Function, default: undefined },

  // Deprecated props
  title: { type: String, default: undefined }, // -> label
  value: { type: [Number, String, Array], default: undefined }, // -> modelValue
  editing: { type: Boolean, default: undefined }, // -> editable
  required: { type: Boolean, default: undefined } // -> mandatory
});
const emit = defineEmits(['change', 'update:model-value']);

const config = computed(() => ({
  ...(props.config || {}),
  label: getFirstDefined(props.config?.label, props.label, props.title),
  desc: getFirstDefined(props.config?.desc, props.desc),
  type: getFirstDefined(props.config?.type, props.type, 'text'),
  error: getFirstDefined(props.config?.error, props.error),
  loading: getFirstDefined(props.config?.loading, props.loading, false),
  readonly: getFirstDefined(props.config?.readonly, props.readonly, false),
  plaintext: getFirstDefined(props.config?.plaintext, props.plaintext, false),
  editable: getFirstDefined(props.config?.editable, props.editable, props.editing, false),
  default: getFirstDefined(props.config?.default, props.default),
  placeholder: getFirstDefined(props.config?.placeholder, props.placeholder),
  rules: getFirstDefined(props.config?.rules, props.rules),
  min: getFirstDefined(props.config?.min, props.min),
  max: getFirstDefined(props.config?.max, props.max),
  mandatory: getFirstDefined(props.config?.mandatory, props.mandatory, props.required, false),
  options: getFirstDefined(props.config?.options, props.options, []),
  multiple: getFirstDefined(props.config?.multiple, props.multiple, false),
  range: getFirstDefined(props.config?.range, props.range, false),
  time: getFirstDefined(props.config?.time, props.time, false),
  edwEditor: getFirstDefined(props.config?.edwEditor, props.edwEditor),
  relationsEdit: getFirstDefined(props.config?.relationsEdit, props.relationsEdit)
}));

const value = vModel({
  get: () => getFirstDefined(props.modelValue, props.value),
  set: (newVal) => {
    emit('change', newVal);
    emit('update:model-value', newVal);
  }
});

const labelValue = computed({
  get () {
    if (config.value.type === 'label' && typeof config.value.edwEditor === 'object' && value.value) {
      return config.value.edwEditor[value.value];
    }
    return '';
  },
  set (newVal) {
    if (config.value.type === 'label' && typeof config.value.edwEditor === 'object' && value.value) {
      config.value.edwEditor[value.value] = newVal;
    }
  }
});

function formatDate (utc, time) {
  return typeof window !== 'undefined' ? window?.HigJS?.num?.formatDate?.({ utc: utc, date: true, time: time }) : utc;
}
const plaintextValue = computed(() => {
  switch (config.value.type) {
    case 'password': return '• • • • •';
    case 'select':
      if (config.value.multiple) {
        return (value.value || []).map((el) => config.value.options?.find?.((opt) => opt.value == el)?.text || '').filter((el) => !!el).join(', ') || ''; // eslint-disable-line eqeqeq
      } else {
        return config.value.options?.find?.((opt) => opt.value == value.value)?.text || ''; // eslint-disable-line eqeqeq
      }
    case 'datepicker':
      if (config.value.range) {
        return formatDate(value.value?.start, config.value.time) + ' - ' + formatDate(value.value?.stop, config.value.time);
      } else {
        return formatDate(value.value, config.value.time);
      }
    case 'label':
      if (typeof config.value.edwEditor === 'object' && value.value) {
        return config.value.edwEditor[value.value];
      } else {
        return '';
      }
    default:
      return value.value;
  }
});

const _error = ref();
const error = computed({
  get () {
    return getFirstDefined(props.error, props.config?.error, _error.value);
  },
  set (newVal) {
    _error.value = newVal;
  }
});

async function isValid () {
  await nextTick();
  const valToCheck = config.value.type === 'label' ? labelValue.value : value.value;

  if (config.value.mandatory) {
    if (valToCheck == null || valToCheck === '' || (Array.isArray(valToCheck) && valToCheck.length === 0)) {
      error.value = $edw.thisFieldIsMandatory;
      return false;
    }
  }

  if (Array.isArray(config.value.rules)) {
    const errors = await Utils.checkValue(valToCheck, config.value.rules);
    if (errors) {
      error.value = errors[0];
      return false;
    }
  }

  if (config.value.min != null) {
    if (valToCheck < config.value.min) {
      error.value = `${$edw.valueLowerThanMin} (${config.value.min})`;
      return false;
    }
  }

  if (config.value.max != null) {
    if (valToCheck > config.value.max) {
      error.value = `${$edw.valueGreaterThanMax} (${config.value.max})`;
      return false;
    }
  }

  error.value = undefined;
  return true;
}
watch([value, labelValue], () => isValid());

const showPassword = ref(false);
const loadingPlaceholder = computed(() => {
  switch (config.value.type) {
    case 'checkbox': return '###';
    case 'password': return '###########';
    case 'select': return '#############';
    case 'datepicker': return '###############';
    case 'label': return '################';
    default: return '##################';
  }
});

const dictionaryEditorModal = ref();
function openDictionaryEditorModal () {
  dictionaryEditorModal.value.open();
}

onMounted(() => {
  if (config.value.editable && value.value === undefined) { // Apply default only if starting as editable
    if (config.value.default !== undefined) {
      value.value = config.value.default;
    }

    if (config.value.type === 'select' && !config.value.multiple && Array.isArray(config.value.options) && config.value.options.length > 0) {
      if (!config.value.options.find((opt) => opt.value === value.value)) {
        value.value = config.value.options.find((opt) => !opt.disabled)?.value;
      }
    }
  }

  if (config.value.type === 'label' && typeof config.value.edwEditor !== 'object') {
    error.value = $edw.missingEdwEditor;
  }
});

const slotsData = computed(() => ({
  config: config.value,
  value: value.value,
  valuePlaintext: plaintextValue.value,
  setValue: (val) => { value.value = val; },
  error: error.value
}));

defineExpose({ isValid: async () => config.value.error == null && await isValid() });
</script>

<script>
function getFirstDefined () {
  for (const el of arguments) {
    if (el !== undefined) {
      return el;
    }
  }
}
</script>

<template>
  <div class="cns-settings-row d-flex flex-wrap align-items-center justify-content-between gap-2 px-2">
    <slot name="row" v-bind="slotsData">
      <!-- Label -->
      <div class="label-container d-flex justify-content-start">
        <cns-div class="label-div d-flex align-items-center gap-2 flex-nowrap" :loading="config.loading">
          <slot name="label" v-bind="slotsData">
            <p class="label m-0" :title="config.label">{{ config.label }}</p>
            <p class="desc m-0" v-if="config.desc">
              <cns-dropdown>
                <template #target="{ toggle }">
                  <cns-icon type="circle-info" class="cursor-pointer" @click="toggle"/>
                </template>

                <div class="p-2" style="max-width: 250px;">{{ config.desc }}</div>
              </cns-dropdown>
            </p>
          </slot>
        </cns-div>
      </div>

      <div class="filler-container"></div>

      <div class="value-container d-flex justify-content-end">
        <cns-div class="value-div d-flex align-items-center justify-content-end" :loading="config.loading" :placeholder="loadingPlaceholder">
          <slot name="value" v-bind="slotsData">
            <!-- Plaintext or non editable value -->
            <slot v-if="config.plaintext || !config.editable" name="value-plaintext" v-bind="slotsData">
              <div class="value-plaintext flex-fill d-flex flex-column justify-content-center text-end align-self-end">
                <!-- Boolean plaintext -->
                <b-checkbox v-if="config.type === 'checkbox'" :modelValue="value" readonly class="float-end align-self-end"/>
                <!-- Hidden plaintext -->
                <div v-else-if="config.plaintext && config.type === 'password'" class="d-flex justify-content-end align-items-center">
                  <p class="m-0 me-2" :title="showPassword ? value : plaintextValue">{{ showPassword ? value : plaintextValue }}</p>
                  <cns-icon :type="showPassword ? 'eye-slash fw' : 'eye fw'" :title="showPassword ? $edw.hidePassword : $edw.showPassword" @click="showPassword = !showPassword" class="show-password-icon mx-1"/>
                </div>
                <!-- Simple plaintext -->
                <p v-else class="m-0" :title="plaintextValue">{{ plaintextValue }}</p>
              </div>
            </slot>
            <!-- Editable value -->
            <slot v-else name="value-editable" v-bind="slotsData">
              <div class="value-input flex-fill d-flex flex-column justify-content-end align-self-end">
                <div>
                  <!-- Select -->
                  <div v-if="config.type === 'select'">
                    <cns-select v-model="value" :options="config.options" :readonly="config.readonly" :placeholder="config.placeholder" :multiple="config.multiple" searchable class="input"/>
                  </div>
                  <!-- Password (https://uxmovement.com/forms/why-the-confirm-password-field-must-die/) -->
                  <div v-else-if="config.type === 'password'" class="position-relative">
                    <cns-input v-model="value" :readonly="config.readonly" :type="showPassword ? 'text' : 'password'" :placeholder="config.placeholder" :state="error ? false : undefined" trim lazy class="input password-input"/>
                    <cns-icon v-if="!config.readonly" :type="showPassword ? 'eye-slash fw' : 'eye fw'" :title="showPassword ? $edw.hidePassword : $edw.showPassword" @click="showPassword = !showPassword" class="show-password-icon"/>
                  </div>
                  <!-- Label -->
                  <div v-else-if="config.type === 'label'" class="position-relative">
                    <template v-if="value != null && config.edwEditor != null">
                      <cns-input v-model="labelValue" :readonly="config.readonly" :placeholder="config.placeholder" :state="error ? false : undefined" trim lazy class="input label-input"/>
                      <template v-if="config.edwEditor">
                        <cns-icon v-if="!config.readonly" type="language fw" :title="$edw.editTranslations" @click="openDictionaryEditorModal()" class="translate-label-icon"/>
                        <cns-settings-dictionary-editor ref="dictionaryEditorModal" :title="config.label" :label="value" :edw-editor="config.edwEditor"/>
                      </template>
                    </template>
                    <template v-else>
                      <cns-input :placeholder="$edw.wrongConfiguration" readonly class="input label-input"/>
                    </template>
                  </div>
                  <!-- Checkbox -->
                  <div v-else-if="config.type === 'checkbox'">
                    <b-checkbox v-model="value" :readonly="config.readonly" class="float-end align-self-end" />
                  </div>
                  <!-- Datepicker -->
                  <div v-else-if="config.type === 'datepicker'">
                    <cns-datepicker v-model="value" :readonly="config.readonly" :placeholder="config.placeholder" :range="config.range" :enable-time-picker="config.time" seconds class="input"/>
                  </div>
                  <!-- Relations -->
                  <div v-else-if="config.type === 'relations'">
                    <div class="d-flex align-items-center justify-content-end gap-2">
                      <div class="text-nowrap" style="overflow: hidden; text-overflow: ellipsis;" :title="value">{{ value }}</div>
                      <cns-button v-if="config.relationsEdit" icon="arrow-up-from-square rotate-90" variant="link" @click="config.relationsEdit()"/>
                    </div>
                  </div>
                  <!-- Generic input -->
                  <div v-else>
                    <cns-input v-model="value" :type="config.type" :readonly="config.readonly" :placeholder="placeholder" :state="error ? false : undefined" :min="config.min" :max="config.max" trim lazy class="input"/>
                  </div>
                </div>
                <!-- Error -->
                <div v-if="error" class="value-error">{{ error }}</div>
              </div>
            </slot>
          </slot>
        </cns-div>
      </div>
    </slot>
  </div>
</template>

<style scoped>
.cns-settings-row :deep(+.cns-settings-row) {
  border-top: 1px solid var(--hig-page-border-light);
}

.cns-settings-row {
  padding-block: 0.2rem;
  min-height: 3rem;
}

.cns-settings-row:hover {
  background-color: var(--hig-page-bg-light);
}

.label-container { flex: 1 0 250px; max-width: 100%; }
.filler-container { flex: 100 1 auto; }
.value-container { flex: 1 0 250px; max-width: 100%; }

.label-div:not(.loading), .value-div:not(.loading) {
  width: 100%;
}

.label {
  overflow: hidden;
  text-overflow: ellipsis;
}

.desc {
  font-size: .6rem;
  opacity: .6;
  transition: .3s ease opacity;
}

.desc:hover {
  opacity: 1;
}

.value-input, .input {
  width: 100%;
}

.password-input,
.label-input {
  padding-right: 2.2rem;
  transition: .3s ease padding-right;
}

.password-input.is-invalid,
.label-input.is-invalid {
  padding-right: 4.5rem;
}

.password-input ~ .show-password-icon,
.label-input ~ .translate-label-icon {
  color: var(--hig-form-text);
  position: absolute;
  font-size: 1.2rem;
  right: .5rem;
  top: 50%;
  transform: translateY(-.5rem);
  cursor: pointer;
  transition: .3s ease right;
}
.password-input.is-invalid ~ .show-password-icon,
.label-input.is-invalid ~ .translate-label-icon  {
  right: 2.6rem;
}

.password-input ~ .show-password-icon:hover,
.label-input ~ .translate-label-icon:hover {
  color: var(--hig-primary);
}

.value-plaintext {
  min-width: 240px;
  text-align: end;
}

.value-plaintext .show-password-icon {
  font-size: 1.2rem;
  cursor: pointer;
}

.value-error {
  font-size: .8rem;
  color: var(--hig-danger);
}
</style>
