import _ from 'lodash';
import Utils from '../../utils';
import { FILTER_NULL_VALUE } from './constants.mjs';

/* eslint-disable eqeqeq */
// Here we want loose checks, let js do it's magic instead of doing it manually
const BASIC_FILTERS = {
  '<': (a, b) => a < b,
  '>': (a, b) => a > b,
  '=': (a, b) => a == b,
  '>=': (a, b) => a >= b,
  '<=': (a, b) => a <= b,
  '!=': (a, b) => a != b
};
/* eslint-enable eqeqeq */

function cast (val, type) {
  if (val == null) { return val; }

  switch (type) {
    case 'int':
      return Math.round(Number(val));
    case 'float':
      return Number(val);
    case 'string':
      return String(val);
    default:
      return val;
  }
}

function getPath (object, path) {
  let res = [object];
  const subPaths = path.split(/\[\*\]/);
  for (const [i, subPath] of subPaths.entries()) {
    const curSubPath = subPath[0] === '.' ? subPath.substring(1) : subPath;
    res = res.map(obj => curSubPath ? _.get(obj, curSubPath) : obj);
    if (i !== subPaths.length - 1) { res = res.filter(element => Array.isArray(element)).flat(1); }
  }
  return res.filter(element => element !== undefined);
}

function applyBuildFilterFunc (filters, concat = 'AND') {
  if (!Array.isArray(filters) || filters.length === 0) { return; }

  const _filters = [];

  for (const filter of filters) {
    if (Array.isArray(filter.or)) {
      const filterFunc = applyBuildFilterFunc(filter.or, 'OR');
      if (filterFunc) { _filters.push(filterFunc); }
    } else if (Array.isArray(filter.and)) {
      const filterFunc = applyBuildFilterFunc(filter.and, 'AND');
      if (filterFunc) { _filters.push(filterFunc); }
    } else if (filter.field != null && filter.op != null && filter.val != null) {
      if ((filter.op === '=' || filter.op === '!=') && Array.isArray(filter.val)) {
        _filters.push((row) => {
          const elements = getPath(row, filter.field);
          const res = filter.val.some(option => {
            option = (option === FILTER_NULL_VALUE ? null : option);
            return elements.some(element => BASIC_FILTERS['='](element, option));
          });
          return filter.op === '=' ? res : !res;
        });
      } else if ((filter.op === '=' || filter.op === '!=') && filter.val === FILTER_NULL_VALUE) {
        _filters.push(filter.op === '='
          ? (row) => getPath(row, filter.field).some(element => BASIC_FILTERS[filter.op](element, null))
          : (row) => getPath(row, filter.field).every(element => BASIC_FILTERS[filter.op](element, null))
        );
      } else if (filter.op === '~' || filter.op === '%') { // regex, contains
        const regex = filter.op === '~'
          ? new RegExp(filter.val)
          : (new RegExp(`.*${filter.val}.*`, 'i'));
        _filters.push((row) => getPath(row, filter.field).some(element => regex.test(String(element))));
      } else if (BASIC_FILTERS[filter.op] && !Array.isArray(filter.val)) {
        _filters.push((row) => getPath(row, filter.field).some(element => BASIC_FILTERS[filter.op](element, filter.val)));
      }
    }
  }

  return (row) => {
    if (concat === 'AND') {
      for (const filter of _filters) {
        if (!filter(row)) { return false; }
      }
      return true;
    } else if (concat === 'OR') {
      for (const filter of _filters) {
        if (filter(row)) { return true; }
      }
      return false;
    } else {
      return false;
    }
  };
}

function applyBuildOrderFunc (order) {
  if (!Array.isArray(order) || order.length === 0) { return; }

  return (a, b) => {
    for (const ord of order) {
      const listA = getPath(a, ord.field);
      const listB = getPath(b, ord.field);
      const maxLength = listA.length > listB.length ? listA.length : listB.length;
      for (let i = 0; i < maxLength; ++i) {
        let res;
        const valA = cast(listA[i], ord.cast);
        const valB = cast(listB[i], ord.cast);
        if (valA == null && valB == null) {
          res = 0;
        } else if ((valA == null || valA === '') && valB != null && valB !== '') {
          res = ord.dir;
        } else if (valA != null && valA !== '' && (valB == null || valB === '')) {
          res = -ord.dir;
        } else if (typeof valA === 'number' && typeof valB === 'number') {
          res = (valA - valB) * ord.dir;
        } else {
          res = String(valA).localeCompare(valB) * ord.dir;
        }
        if (res !== 0) { return res; }
      }
    }
    return 0;
  };
}

function apply (fop, data, opt) {
  if (!fop || !Array.isArray(data)) { return data; }

  const { filter, order, paginate, slice } = fop;
  let res = [];

  // Filter
  const filterFunc = applyBuildFilterFunc(filter);
  if (filterFunc) {
    res = data.filter(filterFunc);
  } else {
    res = [...data];
  }

  // Order
  const orderFunc = applyBuildOrderFunc(order);
  if (orderFunc) {
    res.sort(orderFunc);
  }

  if (slice && Utils.isNum(slice.offset) && Utils.isNum(slice.limit)) { // Pagination can be applied
    res = res.slice(slice.offset, slice.offset + slice.limit); // paginate
  } else if (paginate && Utils.isNum(paginate.page) && Utils.isNum(paginate.size)) { // Pagination can be applied
    res = res.slice(paginate.size * paginate.page, paginate.size * (paginate.page + 1)); // paginate
  }

  return res;
}

function doesFilterPass (fop, data, opt) {
  const filterFunc = applyBuildFilterFunc(fop.filter);
  return filterFunc ? filterFunc(data) : true;
}

function totals (fop, data, fields, opt) {
  throw new Error('totals not implemented yet');
}

export default {
  apply,
  doesFilterPass,
  totals
};
