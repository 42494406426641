<script setup>
import emblaCarouselVue from 'embla-carousel-vue';
import Autoplay from 'embla-carousel-autoplay';
import { addDotBtnsAndClickHandlers } from './EmblaCarouselDotButton';
import { watchEffect, computed } from 'vue';

const props = defineProps({
  imgs: { type: Array, default: () => [] },
  baseOptions: { type: Object, default: undefined },
  autoplayOptions: { type: Object, default: undefined },
  autoplay: { type: [Boolean, Object], default: undefined }
});

let dotsNode = null;
let removeDotBtnsAndClickHandlers = null;

const _imgs = computed(() => props.imgs?.map((img) => typeof img === 'string' ? { url: img, class: '' } : img));

const pluginsList = computed(() => {
  const ret = [];
  if (props.autoplay) {
    ret.push(Autoplay(typeof props.autoplay === 'object' ? props.autoplay : {}));
  }
  return ret;
});

const [emblaRef, emblaApi] = emblaCarouselVue(props.options, pluginsList);
watchEffect(() => {
  if (emblaApi?.value && emblaRef?.value) {
    dotsNode = document.querySelector('.embla__dots');
    removeDotBtnsAndClickHandlers = addDotBtnsAndClickHandlers(
      emblaApi.value,
      dotsNode
    );
    emblaApi.value.on('destroy', removeDotBtnsAndClickHandlers);
  }
});

</script>
<template>
  <div class="embla" ref="emblaRef">
    <div class="embla__container">
      <div v-for="img of _imgs" :key="img.url" class="embla__slide">
        <img class="embla__slide__img" :class="img.class ?? ''" :src="img.url" alt="notFoundImg" />
      </div>
    </div>
    <div class="embla__controls">
      <div class="embla__dots"></div>
    </div>
  </div>
</template>
<style scoped>
.embla {
  margin: auto;
  --slide-height: 100%;
  --slide-spacing: 1rem;
  --slide-size: 100%;
  overflow: hidden;
  border-radius: 10px;
  height: 40vmin;
}

.embla__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y;
  margin-left: calc(var(--slide-spacing) * -1);
  height: 100%;
}

.embla__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
}

.embla__slide__img {
  display: block;
  height: var(--slide-height);
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.embla__controls {
  position: relative;
  top: -3rem;
}

.embla__dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
}

:deep(.embla__dot) {
  -webkit-tap-highlight-color: rgba(var(--hig-primary), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  width: 2.6rem;
  height: 2.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

:deep(.embla__dot:after) {
  box-shadow: inset 0 0 0 0.2rem var(--hig-primary);
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  content: '';
}

:deep(.embla__dot--selected:after) {
  box-shadow: inset 0 0 0 0.2rem var(--hig-brand-primary-text);
}
</style>
