<template>
  <form :class="classes">
    <slot></slot>
  </form>
</template>

<script>
export default {
  name: 'b-form',
  props: {},
  computed: {
    classes: function () {
      return [];
    }
  }
};
</script>

<style scoped>

</style>
