<script setup lang="ts">
import { computed, inject } from 'vue';
import CnsDataWrapper from '../../cns-data/cns-data-wrapper/cns-data-wrapper.vue';
import PostFetchComponentBuilder from './PostFetchComponentBuilder.vue';
import { CnsSmartVariableNode, CnsSmartVariableRequests } from './nodeInterface';
import _ from 'lodash';

const containerController: { timeframe: any } | undefined = inject('containerController', { timeframe: {} });

interface Props {
  node: CnsSmartVariableNode,
  requests: CnsSmartVariableRequests,
  requestStyle: string,
  options?: {
    chart?: {
      height?: number,
      step?: boolean,
      yAxis?: {
        visible?: boolean
      },
      stacking: boolean
    }
  },
  timeZone: string
};

const props = defineProps<Props>();

const config = computed(() => {
  // contains props to access .wrapperProps.props[vn] otherwise there will be a conflict with .wrapperProps[vn]
  const configProps: { props: { [vn: string]: object }, [key: string]: object } = { props: {} };
  Object.keys(props.requests)
    .forEach((vn) => {
      if (props.requests[vn]?.props) {
        configProps.props[vn] = _.clone(props.requests[vn].props);
      }
    });

  const data: { [vn: string]: { interval?: string | object, name?: string } } = {};
  Object.keys(props.requests)
    .forEach((vn) => {
      data[vn] = _.clone(props.requests[vn].variable);
      if (containerController?.timeframe?.value) {
        data[vn].interval = containerController.timeframe.value;
      }
    });
  Object.keys(props.requests)
    .forEach((vn) => {
      if (props.requests?.[vn]?.parameter?.min) {
        if (props.requests?.[vn]?.parameter?.min?.type === 'parameter') {
          data['min-' + vn] = props?.requests?.[vn]?.parameter?.min;
        } else if (props.requests[vn].parameter.min.type === 'value') {
          configProps['min-' + vn] = [[0, props.requests[vn].parameter.min.value]];
        }
      }
      if (props.requests[vn].parameter?.max) {
        if (props.requests[vn].parameter.max.type === 'parameter') {
          data['max-' + vn] = props.requests[vn].parameter.max;
        } else if (props.requests[vn].parameter.max.type === 'value') {
          configProps['max-' + vn] = [[0, props.requests[vn].parameter.max.value]];
        }
      }
    });

  const actions: { [vn: string]: object } = {};
  Object.entries(props.requests)
    .forEach(([vn, value]) => {
      actions['set-' + vn] = {
        type: 'write-raw-dl',
        name: value.variable.name,
        refetchData: [vn],
        refetchDelay: 1000
      };
    });

  return { props: configProps, data, actions };
});

</script>

<template>
  <cns-data-wrapper
    v-slot="wrapperProps"
    :nodes="[props.node]"
    :config="config"
    :time-zone="props.timeZone"
  >
    <PostFetchComponentBuilder
      v-bind="{
        wrapperProps: wrapperProps,
        node: props.node,
        requests: props.requests,
        requestStyle: props.requestStyle,
        options: props.options,
      }"
    />
  </cns-data-wrapper>
</template>
