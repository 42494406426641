const TOKEN_TYPE = {
  Static: 1,
  Param: 2,
  Group: 3
};

const TOKENIZER_STATE = {
  Static: 1,
  Param: 2,
  ParamRegExp: 3, // custom re for a param
  ParamRegExpEnd: 4, // check if there is any ? + *
  EscapeNext: 5
};

const ROOT_TOKEN = {
  type: TOKEN_TYPE.Static,
  value: ''
};

// default pattern for a param: non-greedy everything but /
const BASE_PARAM_PATTERN = '[^/]+?';

const BASE_PATH_PARSER_OPTIONS = {
  sensitive: false,
  strict: true,
  start: true,
  end: true
};

// Scoring values used in tokensToParser
const PATH_SCORE = {};
PATH_SCORE._multiplier = 10;
PATH_SCORE.Root = 9 * PATH_SCORE._multiplier; // just /
PATH_SCORE.Segment = 4 * PATH_SCORE._multiplier; // /a-segment
PATH_SCORE.SubSegment = 3 * PATH_SCORE._multiplier; // /multiple-:things-in-one-:segment
PATH_SCORE.Static = 4 * PATH_SCORE._multiplier; // /static
PATH_SCORE.Dynamic = 2 * PATH_SCORE._multiplier; // /:someId
PATH_SCORE.BonusCustomRegExp = 1 * PATH_SCORE._multiplier; // /:someId(\\d+)
PATH_SCORE.BonusWildcard = -4 * PATH_SCORE._multiplier - PATH_SCORE.BonusCustomRegExp; // /:namedWildcard(.*) we remove the bonus added by the custom regexp
PATH_SCORE.BonusRepeatable = -2 * PATH_SCORE._multiplier; // /:w+ or /:w*
PATH_SCORE.BonusOptional = -0.8 * PATH_SCORE._multiplier; // /:w? or /:w*
// these two have to be under 0.1 so a strict /:page is still lower than /:a-:b
PATH_SCORE.BonusStrict = 0.07 * PATH_SCORE._multiplier; // when options strict: true is passed, as the regex omits \/?
PATH_SCORE.BonusCaseSensitive = 0.025 * PATH_SCORE._multiplier; // when options strict: true is passed, as the regex omits \/?

// Regex to test for valid params names
const VALID_PARAM_RE = /[\w]/;

/**
 * Creates an array of Tokens from a path (a segment is an array of Tokens)
 *
 * @param path - path to parse to extract the Tokens
 * @returns an array of Tokens
 */
function tokenizePath (path) {
  if (!path) return [[]];
  if (path === '/') return [[ROOT_TOKEN]];
  if (!path.startsWith('/')) {
    throw new Error(`Route paths should start with a "/": "${path}" should be "/${path}".`);
  }

  function crash (message) {
    throw new Error(`ERR (${state})/"${buffer}": ${message}`);
  }

  let state = TOKENIZER_STATE.Static;
  let previousState = state;
  const tokens = [];
  // the segment will always be valid because we get into the initial state
  // with the leading /
  let segment;

  function finalizeSegment () {
    if (segment) tokens.push(segment);
    segment = [];
  }

  // index on the path
  let i = 0;
  // char at index
  let char;
  // buffer of the value read
  let buffer = '';
  // custom regexp for a param
  let customRe = '';

  function consumeBuffer () {
    if (!buffer) return;

    if (state === TOKENIZER_STATE.Static) {
      segment.push({
        type: TOKEN_TYPE.Static,
        value: buffer
      });
    } else if (
      state === TOKENIZER_STATE.Param ||
      state === TOKENIZER_STATE.ParamRegExp ||
      state === TOKENIZER_STATE.ParamRegExpEnd
    ) {
      if (segment.length > 1 && (char === '*' || char === '+')) { crash(`A repeatable param (${buffer}) must be alone in its segment. eg: '/:ids+.`); }
      segment.push({
        type: TOKEN_TYPE.Param,
        value: buffer,
        regexp: customRe,
        repeatable: char === '*' || char === '+',
        optional: char === '*' || char === '?'
      });
    } else {
      crash('Invalid state to consume buffer');
    }
    buffer = '';
  }

  function addCharToBuffer () {
    buffer += char;
  }

  while (i < path.length) {
    char = path[i++];

    if (char === '\\' && state !== TOKENIZER_STATE.ParamRegExp) {
      previousState = state;
      state = TOKENIZER_STATE.EscapeNext;
      continue;
    }

    switch (state) {
      case TOKENIZER_STATE.Static:
        if (char === '/') {
          if (buffer) {
            consumeBuffer();
          }
          finalizeSegment();
        } else if (char === ':') {
          consumeBuffer();
          state = TOKENIZER_STATE.Param;
        } else {
          addCharToBuffer();
        }
        break;

      case TOKENIZER_STATE.EscapeNext:
        addCharToBuffer();
        state = previousState;
        break;

      case TOKENIZER_STATE.Param:
        if (char === '(') {
          state = TOKENIZER_STATE.ParamRegExp;
        } else if (VALID_PARAM_RE.test(char)) {
          addCharToBuffer();
        } else {
          consumeBuffer();
          state = TOKENIZER_STATE.Static;
          // go back one character if we were not modifying
          if (char !== '*' && char !== '?' && char !== '+') i--;
        }
        break;

      case TOKENIZER_STATE.ParamRegExp:
        if (char === ')') {
          // handle the escaped )
          if (customRe[customRe.length - 1] === '\\') {
            customRe = customRe.slice(0, -1) + char;
          } else {
            state = TOKENIZER_STATE.ParamRegExpEnd;
          }
        } else {
          customRe += char;
        }
        break;

      case TOKENIZER_STATE.ParamRegExpEnd:
        // same as finalizing a param
        consumeBuffer();
        state = TOKENIZER_STATE.Static;
        // go back one character if we were not modifying
        if (char !== '*' && char !== '?' && char !== '+') i--;
        customRe = '';
        break;

      default:
        crash('Unknown state');
        break;
    }
  }

  if (state === TOKENIZER_STATE.ParamRegExp) { crash(`Unfinished custom RegExp for param "${buffer}"`); }

  consumeBuffer();
  finalizeSegment();

  return tokens;
}

// Special Regex characters that must be escaped in static tokens
const REGEX_CHARS_RE = /[.+*?^${}()[\]/\\]/g;

/**
 * Creates a path parser from an array of Segments (a segment is an array of Tokens)
 *
 * @param segments - array of segments returned by tokenizePath
 * @param extraOptions - optional options for the regexp
 * @returns a PathParser
 */
function tokensToParser (segments, extraOptions) {
  const options = Object.assign({}, BASE_PATH_PARSER_OPTIONS, extraOptions);

  // the amount of scores is the same as the length of segments except for the root segment "/"
  const score = [];
  // the regexp as a string
  let pattern = options.start ? '^' : '';
  // extracted keys
  const keys = [];

  for (const segment of segments) {
    // the root segment needs special treatment
    const segmentScores = segment.length ? [] : [PATH_SCORE.Root];

    // allow trailing slash
    if (options.strict && !segment.length) pattern += '/';
    for (let tokenIndex = 0; tokenIndex < segment.length; tokenIndex++) {
      const token = segment[tokenIndex];
      // resets the score if we are inside a sub-segment /:a-other-:b
      let subSegmentScore = PATH_SCORE.Segment + (options.sensitive ? PATH_SCORE.BonusCaseSensitive : 0);

      if (token.type === TOKEN_TYPE.Static) {
        // prepend the slash if we are starting a new segment
        if (!tokenIndex) pattern += '/';
        pattern += token.value.replace(REGEX_CHARS_RE, '\\$&');
        subSegmentScore += PATH_SCORE.Static;
      } else if (token.type === TOKEN_TYPE.Param) {
        const { value, repeatable, optional, regexp } = token;
        keys.push({
          name: value,
          repeatable,
          optional
        });
        const re = regexp || BASE_PARAM_PATTERN;
        // the user provided a custom regexp /:id(\\d+)
        if (re !== BASE_PARAM_PATTERN) {
          subSegmentScore += PATH_SCORE.BonusCustomRegExp;
          // make sure the regexp is valid before using it
          try {
            new RegExp(`(${re})`); // eslint-disable-line no-new
          } catch (err) {
            throw new Error(`Invalid custom RegExp for param "${value}" (${re}): ` + err.message);
          }
        }

        // when we repeat we must take care of the repeating leading slash
        let subPattern = repeatable ? `((?:${re})(?:/(?:${re}))*)` : `(${re})`;

        // prepend the slash if we are starting a new segment
        if (!tokenIndex) {
          // avoid an optional / if there are more segments e.g. /:p?-static or /:p?-:p2
          subPattern = optional && segment.length < 2 ? `(?:/${subPattern})` : '/' + subPattern;
        }
        if (optional) subPattern += '?';

        pattern += subPattern;

        subSegmentScore += PATH_SCORE.Dynamic;
        if (optional) subSegmentScore += PATH_SCORE.BonusOptional;
        if (repeatable) subSegmentScore += PATH_SCORE.BonusRepeatable;
        if (re === '.*') subSegmentScore += PATH_SCORE.BonusWildcard;
      }

      segmentScores.push(subSegmentScore);
    }

    // an empty array like /home/ -> [[{home}], []]
    // if (!segment.length) pattern += '/'

    score.push(segmentScores);
  }

  // only apply the strict bonus to the last score
  if (options.strict && options.end) {
    const i = score.length - 1;
    score[i][score[i].length - 1] += PATH_SCORE.BonusStrict;
  }

  if (!options.strict) pattern += '/?';

  if (options.end) pattern += '$';
  // allow paths like /dynamic to only match dynamic or dynamic/... but not dynamic_something_else
  else if (options.strict) pattern += '(?:/|$)';

  const re = new RegExp(pattern, options.sensitive ? '' : 'i');

  function parse (path) {
    const match = path.match(re);
    const params = {};

    if (!match) return null;

    for (let i = 1; i < match.length; i++) {
      const value = match[i] || '';
      const key = keys[i - 1];
      params[key.name] = value && key.repeatable ? value.split('/') : value;
    }

    return params;
  }

  function stringify (params) {
    let path = '';
    // for optional parameters to allow to be empty
    let avoidDuplicatedSlash = false;
    for (const segment of segments) {
      if (!avoidDuplicatedSlash || !path.endsWith('/')) path += '/';
      avoidDuplicatedSlash = false;

      for (const token of segment) {
        if (token.type === TOKEN_TYPE.Static) {
          path += token.value;
        } else if (token.type === TOKEN_TYPE.Param) {
          const { value, repeatable, optional } = token;
          const param =
            value in params ? params[value] : '';

          if (Array.isArray(param) && !repeatable) {
            throw new Error(
              `Provided param "${value}" is an array but it is not repeatable (* or + modifiers)`
            );
          }

          const text = Array.isArray(param) ? param.join('/') : String(param);
          if (!text) {
            if (optional) {
              // if we have more than one optional param like /:a?-static we don't need to care about the optional param
              if (segment.length < 2) {
                // remove the last slash as we could be at the end
                if (path.endsWith('/')) path = path.slice(0, -1);
                // do not append a slash on the next iteration
                else avoidDuplicatedSlash = true;
              }
            } else throw new Error(`Missing required param "${value}"`);
          }
          path += text;
        }
      }
    }

    // avoid empty path when we have multiple optional params
    return path || '/';
  }

  return {
    re,
    score,
    keys,
    parse,
    stringify
  };
}

/**
 * Compares an array of numbers as used in PathParser.score and returns a
 * number. This function can be used to `sort` an array
 *
 * @param a - first array of numbers
 * @param b - second array of numbers
 * @returns 0 if both are equal, < 0 if a should be sorted first, > 0 if b
 * should be sorted first
 */
function compareScoreArray (a, b) {
  let i = 0;
  while (i < a.length && i < b.length) {
    const diff = b[i] - a[i];
    // only keep going if diff === 0
    if (diff) return diff;

    i++;
  }

  // if the last subsegment was Static, the shorter segments should be sorted first
  // otherwise sort the longest segment first
  if (a.length < b.length) {
    return a.length === 1 && a[0] === PATH_SCORE.Static + PATH_SCORE.Segment
      ? -1
      : 1;
  } else if (a.length > b.length) {
    return b.length === 1 && b[0] === PATH_SCORE.Static + PATH_SCORE.Segment
      ? 1
      : -1;
  }

  return 0;
}

/**
 * Compare function that can be used with `sort` to sort an array of PathParser
 *
 * @param a - first PathParser
 * @param b - second PathParser
 * @returns 0 if both are equal, < 0 if a should be sorted first, > 0 if b
 */
function comparePathParserScore (a, b) {
  let i = 0;
  const aScore = a.score;
  const bScore = b.score;
  while (i < aScore.length && i < bScore.length) {
    const comp = compareScoreArray(aScore[i], bScore[i]);
    // do not return if both are equal
    if (comp) return comp;

    i++;
  }
  if (Math.abs(bScore.length - aScore.length) === 1) {
    if (isLastScoreNegative(aScore)) return 1;
    if (isLastScoreNegative(bScore)) return -1;
  }

  // if a and b share the same score entries but b has more, sort b first
  return bScore.length - aScore.length;
  // this is the ternary version
  // return aScore.length < bScore.length
  //   ? 1
  //   : aScore.length > bScore.length
  //   ? -1
  //   : 0
}

/**
 * This allows detecting splats at the end of a path: /home/:id(.*)*
 *
 * @param score - score to check
 * @returns true if the last entry is negative
 */
function isLastScoreNegative (score) {
  const last = score[score.length - 1];
  return score.length > 0 && last[last.length - 1] < 0;
}

export function getParser (path, options = {}) { return tokensToParser(tokenizePath(path), options); }
export function sortParsers (parsers) { return parsers.sort(comparePathParserScore); }
