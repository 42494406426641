<!-- eslint-disable max-lines-per-function -->
<script setup lang="ts">
/* global HigJS */
import { computed, inject } from 'vue';
import { isNum } from '../../../libs/utils/index.mjs';
import BasicLabel from './components/basic-label.vue';
import LargeLabel from './components/large-label.vue';
import cnsDataChart from '../../cns-data/cns-data-chart.vue';
import GaugeSimple from './components/gauge-simple.vue';
import GaugeController from './components/gauge-controller.vue';
import GaugeDisplay from './components/gauge-display.vue';
import { CnsSmartVariableNode, CnsSmartVariableRequest, CnsSmartVariableRequests, CnsSmartVariableVariable, CnsSmartVariableVariables, CnsSmartVariableWrapperProps } from './nodeInterface';

const edw: { [key: string]: string } | undefined = inject('$edw');

const containerController: { callbackZoom: (extreams: object) => void } | null = inject('containerController', null);

interface Props {
  wrapperProps: CnsSmartVariableWrapperProps,
  node: CnsSmartVariableNode,
  requests: CnsSmartVariableRequests,
  requestStyle: string,
  options?: any
};
const props = defineProps<Props>();

const stardardName = (request: CnsSmartVariableRequest) =>
  props.node?.parameters?.['customVariableLabel_' + request.variable.name] || edw![request.variable.name];

const placeholder: CnsSmartVariableVariable = {
  name: '---',
  label: '---',
  desc: '---',
  value: 0,
  timestamp: '---',
  icon: '---',
  isValid: true,
  isConfigured: true,
  loading: false,
  set: () => {}
};

const variables = computed(() => {
  const vs: CnsSmartVariableVariables = {};

  switch (props.requestStyle) {
    case 'gauge':
    case 'gaugeController':
    case 'basicLabel':
    case 'badgeLabel':
    case 'largeLabel':
      Object.entries(props.requests).forEach(([vn, request]) => {
        vs[vn] = {
          name: vn,
          label: stardardName(request),
          desc: props.node.desc,
          value: (props.wrapperProps[vn] as number[][])?.[0]?.[1] as number,
          um: props.wrapperProps?.[request.variable.name + 'UnitOfMeasure'] as string,
          timestamp: HigJS.num
            .formatDate(Math.floor(((props.wrapperProps[vn] as number[][])?.[(props.wrapperProps[vn] as number[][]).length - 1]?.[0] as number) / 1000)),
          icon: request.props?.icon || '',
          isValid: props.wrapperProps?.[vn + 'IsValid'] as boolean,
          isConfigured: props.wrapperProps?.[vn + 'IsConfigured'] as boolean,
          loading: props.wrapperProps.loading as boolean,
          set: props.wrapperProps?.['set-' + vn] as () => void
        };

        if (request.props?.color) {
          vs[vn].color = request.props.color;
        }

        const min = Number((props.wrapperProps?.['min-' + vn] as number[][])?.[0]?.[1]);
        const max = Number((props.wrapperProps?.['max-' + vn] as number[][])?.[0]?.[1]);
        if (isNum(min)) { vs[vn].min = min; }
        if (isNum(max)) { vs[vn].max = max; }
      });
      break;
    case 'basicChart':
      if (!Object.keys(props.requests)[0].includes('cmp') && !Object.keys(props.requests)[0].includes('fan') && (props.wrapperProps?.[Object.keys(props.requests)[0]] as number[][]).length === 0) {
        break;
      }
      vs.series = {};
      Object.entries(props.requests).forEach(([vn, request]) => {
        vs.series![vn] = {
          name: stardardName(request) + (props.wrapperProps?.[request.variable.name + 'UnitOfMeasure'] as string ? ' [' + (props.wrapperProps?.[request.variable.name + 'UnitOfMeasure'] as string) + ']' : ''),
          yAxis: props.options?.chart?.yAxis ? '0' : (props.wrapperProps?.[request.variable.name + 'UnitOfMeasure'] || 'default'),
          ...request.props?.serie
        };
      });
      vs.data = {};
      Object.keys(props.requests).forEach((vn) => {
        vs.data![vn] = props.wrapperProps?.[vn] as number[][];
        vs.series![vn].showInLegend = vs.data![vn].filter((array) => array[1] !== null).length > 0;
      });
      vs.isConfigured = Object
        .keys(props.requests)
        .findIndex((vn) => props.wrapperProps?.[vn + 'IsConfigured']) !== -1;

      vs.isValid = Object
        .keys(props.requests)
        .findIndex((vn) => props.wrapperProps?.[vn + 'IsValid'] ? 1 : 0) !== -1;

      if (props.options?.chart?.yAxis) {
        const allValues = Object.values(vs.data).flatMap((d) => d.map((p) => p[1])).filter((p) => p != null); ;
        const min = Math.min(...allValues);
        const max = Math.max(...allValues);

        vs.yAxis = [{
          startOnTick: false,
          endOnTick: false,
          min: min === 0 ? min : (min - Math.abs(max - min) * 0.1),
          max: max === 0 ? max : (max + Math.abs(max - min) * 0.1),
          ...props.options?.chart?.yAxis,
          id: '0'
        }];
      } else {
        vs.yAxis = [];
        Object.entries(props.requests).forEach(([vn, request]) => {
          const reqYId = props.wrapperProps?.[request.variable.name + 'UnitOfMeasure'] as string || 'default';

          const curYAxis = vs.yAxis?.find((y) => y.id === reqYId) ?? {};

          const allValues = vs.data?.[vn]?.map((p) => p[1]).filter((p) => p != null) || [];
          let allValuesMin = Math.min(...allValues);
          let allValuesMax = Math.max(...allValues);
          const diff = allValuesMax - allValuesMin;
          const signMin = allValuesMin > 0 ? 1 : allValuesMin < 0 ? -1 : 1;
          const signMax = allValuesMax > 0 ? 1 : allValuesMax < 0 ? -1 : 1;
          const delta = Math.abs(diff);
          allValuesMin = signMin * (Math.abs(allValuesMin) - (delta * 0.1));
          allValuesMax = signMax * (Math.abs(allValuesMax) + (delta * 0.1));

          if (isNum((props.wrapperProps?.['min-' + vn] as number[][])?.[0]?.[1])) {
            const varMin = Number((props.wrapperProps?.['min-' + vn] as number[][])?.[0]?.[1]);
            curYAxis.min = isNum(curYAxis.min) ? Math.min(curYAxis.min, varMin) : varMin;
          } else {
            curYAxis.min = allValuesMin;
          }

          if (isNum((props.wrapperProps?.['max-' + vn] as number[][])?.[0]?.[1])) {
            const varMax = Number((props.wrapperProps?.['max-' + vn] as number[][])?.[0]?.[1]);
            curYAxis.max = isNum(curYAxis.max) ? Math.max(curYAxis.max, varMax) : varMax;
          } else {
            curYAxis.max = allValuesMax;
          }

          const { afterSetExtreams, ...yAxis } = props.wrapperProps.props?.[vn].yAxis;

          if (afterSetExtreams) {
            curYAxis.max = curYAxis.max * afterSetExtreams;
          }

          if (curYAxis.id) {
            const yAxisIndex = vs.yAxis?.findIndex((y) => y.id === curYAxis.id);
            vs.yAxis[yAxisIndex] = { ...curYAxis };
          } else {
            vs.yAxis?.push({
              ...curYAxis,
              startOnTick: false,
              endOnTick: false,
              ...yAxis,
              id: reqYId,
              um: props.wrapperProps?.[request.variable.name + 'UnitOfMeasure']
            });
          }
        });
      }
      vs.options = {
        legend: {
          enabled: true,
          align: 'left',
          verticalAlign: 'top',
          labelFormatter: function () {
            return this.name;
          }
        },
        plotOptions: {
          line: {
            stacking: props.options?.chart?.stacking ? 'normal' : undefined,
            step: props.options?.chart?.step ? 'left' : undefined
          }
        },
        chart: {
          height: props.options?.chart?.height ?? 200,
          events: {
            selection: function (event: { xAxis: { min: number, max: number }[]}) {
              if (containerController?.callbackZoom) {
                const extremes = { min: event.xAxis[0].min, max: event.xAxis[0].max };
                containerController.callbackZoom(extremes);
                return false;
              }
              return true;
            }
          },
          zoomType: 'x',
          pinchType: 'none',
          marginLeft: 80
        }
      };
      break;
    case 'simpleChart': // cards
      if ((props.wrapperProps?.[Object.keys(props.requests)[0]] as number[][])?.length === 0) {
        break;
      }

      vs.series = {};
      Object.entries(props.requests).forEach(([vn, request]) => {
        vs.series![vn] = {
          name: stardardName(request) + (props.wrapperProps?.[request.variable.name + 'UnitOfMeasure'] as string ? ' [' + (props.wrapperProps?.[request.variable.name + 'UnitOfMeasure'] as string) + ']' : ''),
          yAxis: props.options?.chart?.yAxis ? '0' : props.wrapperProps?.[request.variable.name + 'UnitOfMeasure'] || 'default',
          ...request.props?.serie
        };
      });
      vs.data = {};
      Object.keys(props.requests).forEach((vn) => {
        vs.data![vn] = props.wrapperProps?.[vn] as number[][];
      });

      vs.isConfigured = Object
        .keys(props.requests)
        .findIndex((vn) => props.wrapperProps?.[vn + 'IsConfigured']) !== -1;

      vs.isValid = Object
        .keys(props.requests)
        .findIndex((vn) => props.wrapperProps?.[vn + 'IsValid'] ? 1 : 0) !== -1;

      if (props.options?.chart?.yAxis) {
        const allValues = Object.values(vs.data).flatMap((d) => d.map((p) => p[1])).filter((p) => p != null); ;
        const min = Math.min(...allValues);
        const max = Math.max(...allValues);

        vs.yAxis = [{
          startOnTick: false,
          endOnTick: false,
          min: min === 0 ? min : (min - Math.abs(max - min) * 0.1),
          max: max === 0 ? max : (max + Math.abs(max - min) * 0.1),
          ...props.options?.chart?.yAxis,
          id: '0',
          gridLineWidth: 0,
          minorGridLineWidth: 0
        }];
      } else {
        vs.yAxis = [];
        Object.entries(props.requests).forEach(([vn, request]) => {
          const reqYId = props.wrapperProps?.[request.variable.name + 'UnitOfMeasure'] as string || 'default';
          const curYAxis = vs.yAxis?.find((y) => y.id === reqYId) ?? {};

          const allValues = vs.data?.[vn]?.map((p) => p[1]).filter((p) => p != null) || [];
          let allValuesMin = Math.min(...allValues);
          let allValuesMax = Math.max(...allValues);
          const diff = allValuesMax - allValuesMin;
          const signMin = allValuesMin > 0 ? 1 : allValuesMin < 0 ? -1 : 1;
          const signMax = allValuesMax > 0 ? 1 : allValuesMax < 0 ? -1 : 1;
          const delta = Math.abs(diff);
          allValuesMin = signMin * (Math.abs(allValuesMin) - (delta * 0.1));
          allValuesMax = signMax * (Math.abs(allValuesMax) + (delta * 0.1));

          if (isNum((props.wrapperProps?.['min-' + vn] as number[][])?.[0]?.[1])) {
            const varMin = Number((props.wrapperProps?.['min-' + vn] as number[][])?.[0]?.[1]);
            curYAxis.min = isNum(curYAxis.min) ? Math.min(curYAxis.min, varMin) : varMin;
          } else {
            curYAxis.min = allValuesMin;
          }

          if (isNum((props.wrapperProps?.['max-' + vn] as number[][])?.[0]?.[1])) {
            const varMax = Number((props.wrapperProps?.['max-' + vn] as number[][])?.[0]?.[1]);
            curYAxis.max = isNum(curYAxis.max) ? Math.max(curYAxis.max, varMax) : varMax;
          } else {
            curYAxis.max = allValuesMax;
          }

          const { afterSetExtreams } = props.wrapperProps.props?.[vn].yAxis;

          if (afterSetExtreams) {
            curYAxis.max = curYAxis.max * afterSetExtreams;
          }
          if (curYAxis.id) {
            const yAxisIndex = vs.yAxis?.findIndex((y) => y.id === curYAxis.id);
            vs.yAxis[yAxisIndex] = { ...curYAxis, gridLineWidth: 0, minorGridLineWidth: 0 };
          } else {
            vs.yAxis?.push({
              ...curYAxis,
              startOnTick: false,
              endOnTick: false,
              ...props.wrapperProps.props?.[vn].yAxis,
              id: reqYId,
              um: props.wrapperProps?.[request.variable.name + 'UnitOfMeasure'],
              visible: false,
              gridLineWidth: 0,
              minorGridLineWidth: 0
            });
          }
        });
      }

      vs.options = {
        legend: {
          align: 'left',
          verticalAlign: 'top',
          x: -10,
          y: -10,
          labelFormatter: function () {
            return this.name;
          }
        },
        chart: {
          type: 'area',
          height: 120,
          borderRadius: 5,
          margin: 0,
          marginTop: 25,
          backgroundColor: 'var(--hig-page-bg-medium)',
          zoomType: 'none',
          pinchType: 'none'
        }
      };
      break;
  }
  return vs;
});
</script>
<template>
  <div v-if="props.wrapperProps.loading" class="w-100 d-flex justify-content-center" :style="{ minHeight: '100px' }">
    <div class="spinner-border text-primary" role="status" />
  </div>
  <template v-else>
    <template v-if="props.requestStyle === 'basicLabel'">
      <template v-for="variable in Object.values(variables)" :key="variable.name">
        <template v-if="(variable as CnsSmartVariableVariable).isConfigured && (variable as CnsSmartVariableVariable).isValid">
          <BasicLabel v-bind="(variable as CnsSmartVariableVariable)" />
        </template>
        <template v-if="(variable as CnsSmartVariableVariable).isConfigured && !(variable as CnsSmartVariableVariable).isValid">
          <BasicLabel v-bind="placeholder" />
        </template>
      </template>
    </template>
    <template v-if="props.requestStyle === 'gauge'">
      <template v-for="variable in Object.values(variables)" :key="variable.name">
        <template v-if="(variable as CnsSmartVariableVariable).isConfigured && (variable as CnsSmartVariableVariable).isValid">
          <div class="row w-100" :title="(variable as CnsSmartVariableVariable).timestamp">
            <GaugeSimple v-bind="(variable as CnsSmartVariableVariable)" max-width="350px" />
          </div>
        </template>
        <template v-if="(variable as CnsSmartVariableVariable).isConfigured && !(variable as CnsSmartVariableVariable).isValid">
          <div class="row w-100">
            <GaugeSimple v-bind="placeholder" max-width="350px" />
          </div>
        </template>
      </template>
    </template>
    <template v-if="props.requestStyle === 'gaugeController'">
      <template v-for="variable in Object.values(variables)" :key="variable.name">
        <template v-if="(variable as CnsSmartVariableVariable).isConfigured && (variable as CnsSmartVariableVariable).isValid">
          <GaugeController
            v-bind="(variable as CnsSmartVariableVariable)"
            :decimals="2"
            @set:value="(value) => (variable as CnsSmartVariableVariable).set?.(Number(value))"
          />
        </template>
        <template v-if="(variable as CnsSmartVariableVariable).isConfigured && !(variable as CnsSmartVariableVariable).isValid">
          <GaugeController
            v-bind="placeholder"
            :decimals="2"
            @set:value="(value) => (variable as CnsSmartVariableVariable).set?.(Number(value))"
          />
        </template>
      </template>
    </template>
    <template v-if="props.requestStyle === 'badgeLabel'">
      <template v-for="variable in Object.values(variables)" :key="variable.name">
        <template v-if="(variable as CnsSmartVariableVariable).isConfigured && (variable as CnsSmartVariableVariable).isValid">
          <LargeLabel v-bind="(variable as CnsSmartVariableVariable)" />
        </template>
        <template v-if="(variable as CnsSmartVariableVariable).isConfigured && !(variable as CnsSmartVariableVariable).isValid">
          <LargeLabel v-bind="placeholder" />
        </template>
      </template>
    </template>
    <template v-if="props.requestStyle === 'largeLabel'">
      <template v-for="variable in Object.values(variables)" :key="variable.name">
        <template v-if="(variable as CnsSmartVariableVariable).isConfigured && (variable as CnsSmartVariableVariable).isValid">
          <GaugeDisplay v-bind="(variable as CnsSmartVariableVariable)" />
        </template>
        <template v-if="(variable as CnsSmartVariableVariable).isConfigured && !(variable as CnsSmartVariableVariable).isValid">
          <GaugeDisplay v-bind="placeholder" />
        </template>
      </template>
    </template>
    <template v-if="props.requestStyle === 'basicChart' || props.requestStyle === 'simpleChart'">
      <div class="d-flex flex-column w-100">
        <cns-data-chart v-if="variables.isValid" v-bind="variables" />
        <cns-data-chart
          v-else-if="variables.isConfigured && !variables.isValid"
          v-bind="variables"
          :style="{ opacity: 0.5 }" />
      </div>
    </template>
  </template>
</template>
