/* global HigJS */

import { lib_Utils as Utils } from '@hig/vision-sdk';
import _ from 'lodash';

const TYPE_ARR = 1;
const TYPE_OBJ = 2;

export const useNodesModule = function () {
  function getNodesData (requests) {
    if (typeof requests !== 'object') { console.error('[getNodesData] Requests must be object or Array'); return undefined; }
    const inputType = Array.isArray(requests) ? TYPE_ARR : TYPE_OBJ;
    requests = _.cloneDeep(requests);

    // server-side functions expected timestamps in seconds but interface
    // wants them in milliseconds format (especially for Highcharts).
    for (const r in requests) {
      // if interval is "last", keep it as is
      if (typeof requests[r].interval === 'object') {
        requests[r].interval.start = Math.floor(requests[r].interval.start / 1000);
        requests[r].interval.stop = Math.floor(requests[r].interval.stop / 1000);
      }
    }

    return Utils.ajaxRequest({ act: 'VIGetData', data: { requests: requests } }, '/mccCore')
      .then((data) => {
        const res = inputType === TYPE_ARR ? [] : {};

        for (const key in data) {
          if (data[key].err) {
            console.error('[getNodesData] Error getting data: ' + data[key].err, requests[key]);
            res[key] = undefined;
          } else {
            // reconvert the timestamp to ms before the assignment
            if (Array.isArray(data[key].data)) {
              for (const d of data[key].data) { d[0] *= 1000; }
            }
            res[key] = data[key].data;
          }
        }

        return res;
      })
      .catch((err) => {
        console.error('[getNodesData] ' + err);
        return undefined;
      });
  }

  function getNodesDataDaemon (requests, callback, period) {
    if (typeof requests !== 'object' && typeof requests !== 'function') { console.error('[getNodesDataDaemon] Requests must be object, Array or function'); return undefined; }

    const getData = function (finish) {
      let tmpRequests;
      if (typeof requests === 'function') { tmpRequests = requests(); } else { tmpRequests = _.cloneDeep(requests); }

      if (tmpRequests == null) { finish(); return; }

      getNodesData.call(this, tmpRequests).then((data) => {
        finish(data);
      });
    };

    const daemon = new Utils.Daemon((finish) => {
      getData((data) => {
        if (data != null) { callback(data); }
        finish();
      });
    }, period);

    Object.defineProperty(daemon, 'getData', { value: getData });

    daemon.start();

    return daemon;
  }

  function getNodesStructures (requests) {
    if (typeof requests !== 'object') { console.error('[getNodesStructures] Requests must be object or Array'); return undefined; }
    const inputType = Array.isArray(requests) ? TYPE_ARR : TYPE_OBJ;

    return Utils.ajaxRequest({ act: 'VIGetStructures', data: { requests: requests } }, '/mccCore')
      .then((data) => {
        const res = inputType === TYPE_ARR ? [] : {};

        for (const key in data) {
          if (data[key].err) {
            console.error('[getNodesStructures] Error getting structure: ' + data[key].err, requests[key]);
            res[key] = undefined;
          } else {
            res[key] = data[key].data;
          }
        }

        return res;
      })
      .catch((err) => {
        console.error('[getNodesStructures] ' + err);
        return undefined;
      });
  }

  function getNodesParameters (requests) {
    if (typeof requests !== 'object') { console.error('[getNodesParameters] Requests must be object or Array'); return undefined; }
    const inputType = Array.isArray(requests) ? TYPE_ARR : TYPE_OBJ;

    const realRequests = {};
    for (const key in requests) {
      if (!requests[key].nodeId || !requests[key].parameter) { console.warn('parameter not found', requests[key]); }

      if (!this.state.nodesParameters[requests[key].nodeId] || !this.state.nodesParameters[requests[key].nodeId][requests[key].parameter]) {
        realRequests[requests[key].nodeId + '_' + requests[key].parameter] = requests[key];
      }
    }

    return new Promise((resolve) => {
      if (Object.keys(realRequests).length > 0) {
        return Utils.ajaxRequest({ act: 'VIGetParameters', data: { requests: realRequests } }, '/mccCore')
          .then((data) => {
            for (const key in data) {
              if (data[key].err) {
                console.error('[getNodesParameters] Error getting parameter: ' + data[key].err, realRequests[key]);
              } else {
                if (!this.state.nodesParameters[realRequests[key].nodeId]) { this.state.nodesParameters[realRequests[key].nodeId] = {}; }
                this.state.nodesParameters[realRequests[key].nodeId][realRequests[key].parameter] = data[key].data;
              }
            }
            resolve();
          }).catch((err) => {
            console.error('[getNodesParameters] ' + err);
          });
      } else {
        resolve();
      }
    }).then(() => {
      const res = inputType === TYPE_ARR ? [] : {};

      for (const key in requests) {
        if (this.state.nodesParameters[requests[key].nodeId] && this.state.nodesParameters[requests[key].nodeId][requests[key].parameter]) {
          res[key] = this.state.nodesParameters[requests[key].nodeId][requests[key].parameter];
        } else {
          res[key] = undefined;
        }
      }

      return res;
    }).catch((err) => {
      console.error('[getNodesParameters] Error on VIGetParameters request', err);
      return undefined;
    });
  }

  /**
   * opt => { nodesIds, childs, parameters, variables, structures, force }
   */
  function getNodes (opt = {}) {
    const nodesIds = Array.isArray(opt.nodesIds) ? new Set(opt.nodesIds) : new Set(['all']);

    const parametersToGet = new Set();
    const variablesToGet = new Set();
    const structuresToGet = new Set();
    let getChilds = false;
    let getAllParameters = false;
    let getAllVariables = false;
    let getAllStructures = false;

    if (opt.force) {
      if (opt.childs) { getChilds = true; }

      if (Array.isArray(opt.parameters)) {
        opt.parameters.forEach((parameter) => parametersToGet.add(parameter));
      } else if (opt.parameters === true) { getAllParameters = true; }

      if (Array.isArray(opt.variables)) {
        opt.variables.forEach((variable) => variablesToGet.add(variable));
      } else if (opt.variables === true) { getAllVariables = true; }

      if (Array.isArray(opt.structures)) {
        opt.structures.forEach((structure) => structuresToGet.add(structure));
      } else if (opt.structures === true) { getAllStructures = true; }
    } else {
      nodesIds.forEach((nodeId) => {
        if (opt.childs) {
          if (!this.state.nodesCache[nodeId] || !Array.isArray(this.state.nodesCache[nodeId].childs)) {
            getChilds = true;
          } else {
            this.state.nodesCache[nodeId].childs.forEach((childId) => nodesIds.add(childId));
          }
        }

        if (Array.isArray(opt.parameters)) {
          opt.parameters.forEach((parameter) => {
            if (!this.state.nodesCache[nodeId] || (!this.state.nodesCache[nodeId].allParameters && !this.state.nodesCache[nodeId].parameters.has(parameter))) {
              parametersToGet.add(parameter);
            }
          });
        } else if (opt.parameters === true && (!this.state.nodesCache[nodeId] || !this.state.nodesCache[nodeId].allParameters)) {
          getAllParameters = true;
        }

        if (Array.isArray(opt.variables)) {
          opt.variables.forEach((variable) => {
            if (!this.state.nodesCache[nodeId] || (!this.state.nodesCache[nodeId].allVariables && !this.state.nodesCache[nodeId].variables.has(variable))) {
              variablesToGet.add(variable);
            }
          });
        } else if (opt.variables === true && (!this.state.nodesCache[nodeId] || !this.state.nodesCache[nodeId].allVariables)) {
          getAllVariables = true;
        }

        if (Array.isArray(opt.structures)) {
          opt.structures.forEach((structure) => {
            if (!this.state.nodesCache[nodeId] || (!this.state.nodesCache[nodeId].allStructures && !this.state.nodesCache[nodeId].structures.has(structure))) {
              structuresToGet.add(structure);
            }
          });
        } else if (opt.structures === true && (!this.state.nodesCache[nodeId] || !this.state.nodesCache[nodeId].allStructures)) {
          getAllStructures = true;
        }
      });
    }
    nodesIds.delete('all');

    let resPromise = Promise.resolve();
    if (getChilds || getAllParameters || getAllVariables || getAllStructures || parametersToGet.size > 0 || variablesToGet.size > 0 || structuresToGet.size > 0) {
      const req = {
        nodesIds: nodesIds.size > 0 ? [...nodesIds].sort() : null,
        getChilds: getChilds,
        getParameters: getAllParameters ? true : (parametersToGet.size > 0 ? [...parametersToGet].sort() : false),
        getVariables: getAllVariables ? true : (variablesToGet.size > 0 ? [...variablesToGet].sort() : false),
        getStructures: getAllStructures ? true : (structuresToGet.size > 0 ? [...structuresToGet].sort() : false)
      };

      const reqHash = getHash(HigJS.obj.toString(req));

      if (!this.state.nodesReqsCache[reqHash]) {
        this.state.nodesReqsCache[reqHash] = Utils.ajaxRequest({ act: 'VIGetNodes', data: req }, '/mccCore')
          .then((nodes) => {
            if (nodes != null) {
              nodes.forEach((node) => {
                if (!this.state.nodesCache[node.id]) { this.state.nodesCache[node.id] = { parameters: new Set(), variables: new Set(), structures: new Set() }; }
                if (getChilds) { this.state.nodesCache[node.id].childs = nodes.filter((childNode) => String(node.id) === String(childNode.parentId)).map((childNode) => childNode.id); }
                if (getAllParameters) { this.state.nodesCache[node.id].allParameters = true; }
                if (getAllVariables) { this.state.nodesCache[node.id].allVariables = true; }
                if (getAllStructures) { this.state.nodesCache[node.id].allStructures = true; }
                if (parametersToGet.size) { parametersToGet.forEach((parameter) => this.state.nodesCache[node.id].parameters.add(parameter)); }
                if (variablesToGet.size) { variablesToGet.forEach((variable) => this.state.nodesCache[node.id].variables.add(variable)); }
                if (structuresToGet.size) { structuresToGet.forEach((structure) => this.state.nodesCache[node.id].structures.add(structure)); }

                if (!this.state.nodes[node.id]) {
                  this.state.nodes[node.id] = node;
                } else {
                  _.merge(this.state.nodes[node.id], node);
                }
              });
            }
          })
          .catch((err) => { HigJS.debug.error('[getNodesFull] ' + err); })
          .finally(() => { this.state.nodesReqsCache[reqHash] = null; });
      }

      resPromise = resPromise.then(() => this.state.nodesReqsCache[reqHash]);
    }

    resPromise = resPromise.then(() => {
      if (nodesIds.size > 0) {
        const resNodes = {};
        nodesIds.forEach((nodeId) => {
          resNodes[nodeId] = this.state.nodes[nodeId];
        });

        if (opt.childs) {
          nodesIds.forEach((parentId) => {
            if (this.state.nodes[parentId]) {
              Object.keys(this.state.nodes).filter((nodeId) => { // get all descendants
                if (this.state.nodes[nodeId].path_string &&
                    this.state.nodes[nodeId].path_string.split('.').indexOf(String(parentId)) > -1) {
                  return true;
                }
                return false;
              }).forEach((nodeId) => {
                resNodes[nodeId] = this.state.nodes[nodeId];
              });
            }
          });
        }
        return resNodes;
      } else {
        return this.state.nodes;
      }
    });

    return resPromise;
  }

  function getUnbindedDevices (plantsIds) {
    if (typeof plantsIds !== 'object' && !Array.isArray(plantsIds)) { console.error('[getUnbindedDevices] Requests must be an Array'); return undefined; }

    return Utils.ajaxRequest({ act: 'VIGetUnbindedDevices', data: { requests: plantsIds } }, '/mccCore')
      .then((data) => {
        return data;
      })
      .catch((err) => {
        console.error('[getUnbindedDevices] ' + err);
        return undefined;
      });
  }

  function getPlantsList (nodeVarsToLoad) {
    return Utils.ajaxRequest({
      act: 'getAllPlantNodesDescription',
      data: {
        nodeVarTypes: nodeVarsToLoad || [],
        plantStats: true,
        nodeTree: false
      }
    }).then((plantNodes) => {
      return plantNodes;
    }).catch((err) => {
      HigJS.debug.error('[getPlantsList] ' + err);
      return [];
    });
  }

  function getPlantsInfo (plantsIds, nodeVarsToLoad) {
    const arrayInput = Array.isArray(plantsIds);
    if (!arrayInput) { plantsIds = [plantsIds]; }
    if (plantsIds.length === 0) { return Promise.resolve(arrayInput ? [] : undefined); }

    const reqs = [];
    let reqsNodeVars = [];
    let node;
    for (let i = 0; i < plantsIds.length; i++) {
      node = this.state.plantInfoLoaded[plantsIds[i]];

      if (node && (!nodeVarsToLoad || nodeVarsToLoad.length === 0)) continue;

      reqsNodeVars = [];

      if (nodeVarsToLoad) {
        nodeVarsToLoad.forEach((nodeVar) => {
          if (node && !node[nodeVar]) reqsNodeVars.push(nodeVar);
        });
      }

      if (!node || reqsNodeVars.length > 0) {
        reqs.push({
          act: 'getAllPlantNodesDescription',
          data: {
            impiantiId: plantsIds[i],
            nodeVarTypes: reqsNodeVars,
            plantStats: true,
            nodeTree: false
          }
        });
      }
    }

    if (reqs.length === 0) {
      const info = [];
      plantsIds.forEach(plantId => {
        info.push(this.state.plantInfoLoaded[plantId]);
      });
      return Promise.resolve(arrayInput ? info : info[0]);
    }

    return Utils.ajaxRequest(reqs).then((plantsInfo) => {
      plantsInfo.forEach((val) => {
        if (val[0]) {
          if (!this.state.nodes[val[0].plantRefId]) {
            this.state.plantsInfo[val[0].plantRefId] = val[0];
          } else {
            _.merge(this.state.plantsInfo[val[0].plantRefId], val[0]);
          }
        }
      });

      const info = [];
      plantsIds.forEach(plantId => {
        info.push(this.state.plantInfoLoaded[plantId]);
      });
      return arrayInput ? info : info[0];
    }).catch((err) => {
      HigJS.debug.error('[getPlantsInfo] ' + err);
      return arrayInput ? [] : undefined;
    });
  }

  function loadNodeTypes (opt = {}) {
    if (!opt.force && this.state.nodeTypesProm) { return this.state.nodeTypesProm; }

    this.state.nodeTypesProm = Utils.ajaxRequest({
      act: 'getNodeTypes',
      data: {}
    }).then((data) => {
      // const nodeTypesById = {};
      // for (let i = 0; i < data.length; i++) {
      //   nodeTypesById[data[i].id] = data[i].name;
      // }
      this.state.nodeTypes = data;

      return this.state.nodeTypes;
    });

    return this.state.nodeTypesProm;
  }

  function loadVarPeriods (opt = {}) {
    if (!opt.force && this.state.nodeVarPeriodsProm) { return this.state.nodeVarPeriodsProm; }

    this.state.nodeVarPeriodsProm = Utils.ajaxRequest({
      act: 'getNodeVarPeriods',
      data: {}
    }).then((data) => {
      this.state.nodeVarPeriods = data;
      return this.state.nodeVarPeriods;
    });

    return this.state.nodeVarPeriodsProm;
  }

  function loadVarTypes (opt = {}) {
    if (!opt.force && this.state.nodeVarTypesProm) { return this.state.nodeVarTypesProm; }

    this.state.nodeVarTypesProm = Utils.ajaxRequest({
      act: 'getVarType',
      data: {}
    }).then((data) => {
      this.state.nodeVarTypes = data;
      return this.state.nodeVarTypes;
    });

    return this.state.nodeVarTypesProm;
  }

  return {
    state: {
      nodes: {},
      nodesCache: {},
      nodesReqsCache: {},
      nodesParameters: {},
      plantsInfo: {},
      plantInfoLoaded: {},
      nodeTypesProm: undefined,
      nodeTypes: [],
      nodeVarPeriodsProm: undefined,
      nodeVarPeriods: [],
      nodeVarTypesProm: undefined,
      nodeVarTypes: []
    },
    getters: {
      nodeTypes: (state) => state.nodeTypes,
      nodeTypesById: (state) => state.nodeTypes.reduce((acc, el) => { acc[el.id] = el; return acc; }, {}),
      nodeVarPeriods: (state) => state.nodeVarPeriods,
      nodeVarPeriodsById: (state) => state.nodeTypes.reduce((acc, el) => { acc[el.id] = el; return acc; }, {}),
      nodeVarTypes: (state) => state.nodeVarTypes,
      nodeVarTypesById: (state) => state.nodeTypes.reduce((acc, el) => { acc[el.id] = el; return acc; }, {})
    },
    actions: {
      getNodesData,
      getNodesDataDaemon,
      getNodesStructures,
      getNodesParameters,
      getNodes,
      getUnbindedDevices,
      getPlantsList,
      getPlantsInfo,
      loadNodeTypes,
      loadVarPeriods,
      loadVarTypes
    }
  };
};

function getHash (str) {
  let hash = 0;
  if (str.length === 0) return hash;
  for (let i = 0; i < str.length; i++) {
    const chr = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}
