<template>
  <div role="group" class="form-group" :class="classes">
    <div class="d-flex flex-nowrap align-items-end justify-content-between">
      <label :for="labelFor" class="form-group-label d-block flex-fill"><slot name="label">{{label}}</slot></label>
      <div class="form-group-action">
        <slot name="action">
          <a v-if="action" class="form-group-action-label" @click="actionClick">{{action}}</a>
        </slot>
      </div>
    </div>
    <div>
      <slot></slot>
      <div tabindex="-1" role="alert" aria-live="assertive" aria-atomic="true" :class="['valid-feedback']">{{validFeedback}}</div>
      <div tabindex="-1" role="alert" aria-live="assertive" aria-atomic="true" :class="['invalid-feedback']">{{invalidFeedback}}</div>
      <small tabindex="-1" class="form-group-description form-text text-muted"><slot name="description">{{description}}</slot></small>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: { type: String, default: undefined },
    description: { type: String, default: undefined },
    'label-for': { type: String, default: undefined },
    'valid-feedback': { type: String, default: undefined },
    'invalid-feedback': { type: String, default: undefined },
    action: { type: String, default: undefined }
  },
  emits: ['click:action'],
  computed: {
    classes: function () {
      return [
        'form-group'
      ];
    }
  },
  methods: {
    actionClick () { this.$emit('click:action'); }
  }
};
</script>

<style scoped>
.form-group {
  position: relative;
}

.form-group-action {
}

.form-group-action-label {
  cursor: pointer;
  color: inherit;
}

.form-group-action-label:hover {
  text-decoration: underline;
}

.form-group-label:empty, .form-group-action:empty, .form-group-description:empty {
  display: none !important;
}
</style>
