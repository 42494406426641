<script setup>
import CnsIcon from '../../../cns/cns-icon.vue';
import GaugeSimple from './gauge-simple.vue';

const props = defineProps({
  icon: { type: String, default: null },
  color: { type: String, default: null },
  label: { type: String, default: '' },
  value: { type: [Number, String], default: null },
  timestamp: { type: String, default: null },
  max: { type: Number, default: null },
  min: { type: Number, default: null },
  um: { type: String, default: '' },
  loading: { type: Boolean, default: false },
  decimals: { type: Number, default: undefined }
});

</script>
<template>
  <div class="row">
    <div class="col">
      <cns-icon
        v-if="props.icon"
        :type="props.icon + ' 2xl fw'"
        :color="props.color"
      />
      <p
        v-if="props.label"
        class="mt-3 mb-0 fw-bold"
      >
        {{ props.label }}
      </p>
      <p
        v-if="props.timestamp"
        class="m-0 fs-6"
      >
        {{ props.timestamp }}
      </p>
    </div>
    <div class="col">
      <GaugeSimple
        :value="props.value"
        :max="props.max"
        :min="props.min"
        :um="props.um"
        :loading="props.loading"
        :decimals="props.decimals"
        :color="props.color"
        :title="props.title"
        max-width="200px"
      />
    </div>
  </div>
</template>
