<template>
  <div :class="{ 'd-none-forecast': !props.forecast }" class="w-100 h-100 rounded" id="windy">
  </div>
</template>

<script setup>
import { onMounted } from 'vue';
const options = {
  key: '5VjLy6OrLHrV68kMFZxTUZc21ckJnfIG',
  verbose: false
};

const props = defineProps({
  mapProps: { type: Object, default: () => { } },
  forecast: { type: Boolean, default: false },
  layer: { type: String, default: 'wind' },
  um: { type: String, default: null }
});
onMounted(() => {
  // windyInit is defined in libBootWindy utils by window.windyInit
  import('leaflet-windy').then((Leaflet) => {
    // this is necessary to manage change Leaflet version by other components and then assign correct version for windy
    Object.assign(window.L, Leaflet);
    import('https://api.windy.com/assets/map-forecast/libBoot.js').then(() => {
      window.windyInit({ ...props.mapProps, ...options, lat: props.mapProps.center.lat, lon: props.mapProps.center.lon }, windyAPI => {
        const { store, map, overlays } = windyAPI;

        // overlay set
        if (props.layer && store.getAllowed('overlay').some((overlay) => props.layer === overlay)) {
          store.set('overlay', props.layer);
        }

        // metric set
        const metricList = overlays.wind.listMetrics();
        if (metricList.some(um => um === props.um)) {
          overlays.wind.setMetric(props.um);
        } else {
          overlays.wind.setMetric('m/s');
        }

        if (props.mapProps.marker) {
          const icon = Leaflet.divIcon({
            html: `
              <div class="d-flex primary container-marker">
                <div class="container-icon d-flex">
                  <i class="fa fa-${props.mapProps.iconPlant} align-self-center fa-2x"></i>
                </div>
              </div>
            `
          });
          Leaflet.marker(props.mapProps.center, {
            icon,
            title: props.mapProps.markerTitle
          }).addTo(map);
        }
      });
    });
  });
});

</script>

<style scoped>
:deep(.container-marker) {
  color: white;
  width: fit-content;
  background-color: var(--hig-neutral-dark-700);
  border-radius: 2em;
  height: 3rem;
}

:deep(.container-icon) {
  min-width: 4em;
  place-content: center;
}

:deep(#logo) {
  left: 1em !important;
  margin-left: 0 !important;
}

.d-none-forecast :deep(#bottom #progress-bar) {
  display: none !important;
}
:deep(#embed-zoom), :deep(#mobile-ovr-select), :deep(#plugins), :deep(#logo-wrapper), :deep(#bottom), :deep(#plugin-menu) {
  z-index: 1000 !important;
}
</style>
