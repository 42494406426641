<script setup>
// import { ref, computed } from 'vue';
import BButton from '../bootstrap/b-button.vue';
import BFormInput from '../bootstrap/b-form-input.vue';
import CnsIcon from './cns-icon.vue';
import { vModel } from '../../libs/vue-utils';

const props = defineProps({
  modelValue: { type: String, default: '' },
  icon: { type: String, default: 'magnifying-glass' },
  loading: { type: Boolean, default: false },
  readonly: { type: Boolean, default: false },
  plaintext: { type: Boolean, default: false },
  class: { type: [String, Object, Array], default: undefined },
  style: { type: [String, Object, Array], default: undefined }
});

const emit = defineEmits(['update:modelValue', 'clear']);

const value = vModel({
  get: () => props.modelValue,
  set: (newVal) => emit('update:modelValue', newVal)
});

function clear () {
  value.value = '';
  emit('clear');
}
</script>

<script>
export default {
  inheritAttrs: false
};
</script>

<template>
  <div class="position-relative" style="height: fit-content;" :class="props.class" :style="props.style">
    <div class="h-100 position-absolute start-0 d-flex align-items-center justify-content-center mx-2">
      <cns-icon
        :type="(props.loading ? 'spinner-third spin' : props.icon) + ' fw xs'"
        style="color: var(--hig-form-text); opacity: .5;"
      />
    </div>
    <b-form-input
      v-model="value"
      type="text"
      :style="{ 'padding-left': '2rem', 'padding-right': '2.5rem' }"
      v-bind="$attrs"
      :readonly="props.loading || props.readonly || props.plaintext"
    />
    <b-button
      variant="link"
      :disabled="props.loading || props.readonly || props.plaintext"
      @click="clear()"
      class="position-absolute top-50 end-0 translate-middle-y shadow-none"
      style="color: var(--hig-form-text); line-height: 1.2rem;"
    >
      <cns-icon
        type="xmark fw"
        style="color: var(--hig-form-text); opacity: .5;"
      />
    </b-button>
  </div>
</template>
