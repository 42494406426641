<template>
  <div v-if="show_" :class="classes" role="alert">
    <div class=""><slot></slot></div>
    <button v-if="dismissible || dismissByInterval" @click="dismissible && dismiss()" :disabled="!dismissible" class="btn-close shadow-none d-flex align-items-center justify-content-center" aria-label="Close" tabindex="-1">
      <VIIcon v-if="dismissible" type="times" class="position-absolute top-50 start-50 translate-middle " style="font-size: 1.5em;"/>
      <svg v-if="dismissByInterval" :style="{ '--progress': dismissProgress }" class="dismiss-progress position-absolute top-50 start-50 translate-middle" aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
        <path class="dismiss-progress-bar" d="M 0, 50 a 50,50 0 1,1 100,0 a 50,50 0 1,1 -100,0" fill="none" stroke="currentColor" stroke-width="15" ></path>
      </svg>
    </button>
  </div>
</template>

<script>
import Utils from '../../libs/utils';
import { VIIcon } from '@hig/vision-sdk';

export default {
  name: 'b-alert',
  components: { VIIcon },
  props: {
    show: { type: [Boolean, Number, String], default: false },
    variant: { type: String, default: 'info' },
    dismissible: { type: Boolean, default: false }
  },
  emits: ['dismissed'],
  data () {
    return {
      show_: false,
      dismissByInterval: false,
      dismissIntervalDuration: undefined,
      dismissIntervalStart: undefined,
      dismissInterval: undefined,
      dismissProgress: 0
    };
  },
  computed: {
    classes: function () {
      return [
        'alert',
        'alert-' + this.variant,
        {
          'alert-dismissible': this.dismissible || this.dismissByInterval
        }
      ];
    }
  },
  watch: {
    show: {
      immediate: true,
      handler: function () {
        if (this.show === true) {
          this.show_ = true;
          this.dismissByInterval = false;
        } else if (Utils.isNum(this.show)) {
          this.show_ = true;
          this.dismissByInterval = true;
          this.dismissIntervalDuration = this.show * 1000;
          this.dismissIntervalStart = Date.now();
          clearInterval(this.dismissInterval);
          this.dismissInterval = setInterval(() => {
            this.dismissProgress = Math.min(((Date.now() - this.dismissIntervalStart) / this.dismissIntervalDuration), 1);

            if (this.dismissProgress === 1) {
              this.dismiss();
            }

            // if needed do this each second
            // this.$emit('dismiss-count-down', /* elapsed time in seconds */);
          }, 250);
        } else {
          this.show_ = false;
          this.dismissByInterval = false;
          this.$emit('dismissed');
        }
      }
    }
  },
  methods: {
    dismiss () {
      this.show_ = false;
      this.dismissByInterval = false;
      this.dismissProgress = 0;
      this.$emit('dismissed');
      clearInterval(this.dismissInterval);
    }
  }
};
</script>

<style scoped>
.dismiss-progress {
  --progress: 0;
  width: 2em;
  height: 2em;
  border-radius: 50%;
}

.dismiss-progress > .dismiss-progress-bar {
  stroke-dasharray: 314;
  stroke-dashoffset: calc(314 - var(--progress) * 314);
  transition: .25s linear stroke-dashoffset;
}
</style>
