<script lang="ts">
import { ref, watch, inject } from 'vue';
import GraphQl from '../../../libs/graphql-client/index.mjs';
import CnsButton from '../cns-button.vue';
import CnsListItem from '../cns-list-item.vue';
import CnsSettingsFormRow from '../../cns-settings/cns-settings-form-row.vue';
import Utils from '../../../libs/utils/index.mjs';
import PeripheralSelectorModal from './cns-peripheral-selector-modal.vue';
import { Peripheral } from './types';
import { Fop } from '../../../libs/fop-utils/index.browser.mjs';

type LoadPeripheralDescArg = { id: string; }
const loadPeripheralDesc = Utils.batch<LoadPeripheralDescArg, Peripheral | undefined>((args) => {
  const peripheralsIds = new Set();
  args.map((arg: LoadPeripheralDescArg) => peripheralsIds.add(arg.id));

  return GraphQl.query(`
    DataloggerPeripheral_get(fop: $fop, noCache: true) {
      id
      label
      datalogger { sn name }
    }
  `, {
    fop: { type: 'FilterOrderPaginate', value: new Fop().filter('id', '=', [...peripheralsIds]).serialize() }
  }).then((res: Peripheral[]) =>
    args.map((arg: LoadPeripheralDescArg) =>
      res.find((peripheral: Peripheral) => String(peripheral.id) === String(arg.id))
    )
  );
}, { cacheBy: 'id' });
</script>

<script setup lang="ts">
const $edw = inject<any>('$edw');

defineOptions({ inheritAttrs: false });
const props = defineProps<{
  label: string;
  class?: any;
  style?: any;
}>();
const value = defineModel<string | undefined>();

const modal = ref<any>();
const loading = ref<boolean>(false);
const peripheral = ref<Peripheral | undefined>();

watch(value, async () => {
  peripheral.value = undefined;
  if (!value.value) { return; }

  loading.value = true;
  peripheral.value = await loadPeripheralDesc({ id: value.value }).catch(() => undefined);
  if (!peripheral.value) { value.value = undefined; }
  loading.value = false;
}, { immediate: true });

defineExpose({ getSelected: () => peripheral.value });
</script>

<template>
  <cns-settings-form-row :label="props.label" :class="props.class" :style="props.style">
    <template #label="labelProps">
      <slot name="label" v-bind="labelProps" />
    </template>
    <template #value>
      <slot name="value-prefix" />
      <div class="d-flex align-items-center gap-2">
        <cns-list-item
          style="width: 250px;"
          :icon="loading ? 'spinner-third spin fw' : 'meter-bolt fw'"
          :label="peripheral ? peripheral.label : $edw.noPeripheralSelected"
          :caption="
            peripheral
              ? `@ ${
                peripheral.datalogger.name
              } (${
                peripheral.datalogger.sn
              })`
              : $edw.clickToSelectAPeripheral
          "
          @click="modal.open()"
        >
          <template #suffix>
            <cns-button
              v-if="peripheral"
              icon="xmark"
              variant="link"
              size="sm"
              @click.stop="() => { value = undefined }"
            />
          </template>
        </cns-list-item>
      </div>
      <peripheral-selector-modal ref="modal" v-model="value" :title="props.label" v-bind="$attrs" />
    </template>
  </cns-settings-form-row>
</template>
