'use strict';
import { isBrowser } from '../runtime-detector/index.mjs';

/* eslint-disable no-multi-spaces */

const UNDEFERROR = 'UnidentifiedError';

class HigError extends Error {
  constructor (message, opt = {}) {
    if (!message) { message = UNDEFERROR; }
    if (message instanceof HigError) { return message; }
    if (message instanceof Error) { message = message.message; }
    super(message);
    this.name = this.constructor.name;

    if (opt.print == null) { opt.print = true; }

    const extensions = {
      HigError: true,
      type: this.constructor.name,
      context: opt.context != null ? opt.context : {},
      userError: opt.userError != null ? opt.userError : true,
      warning: opt.warning != null ? opt.warning : false
    };
    Object.defineProperty(this, 'extensions', { value: extensions, enumerable: true });

    if (opt.print && (isBrowser || process.env.NODE_ENV !== 'test')) {
      if (extensions.warning) {
        console.warn(`[${extensions.type}] ${message} ${opt.context != null ?  `\ncontext: ${JSON.stringify(opt.context)}` : ''} ${opt.debug != null ?  `\ndebug: ${JSON.stringify(opt.debug)}` : ''}`);
      } else {
        console.error(`[${extensions.type}] ${message} ${opt.context != null ?  `\ncontext: ${JSON.stringify(opt.context)}` : ''} ${opt.debug != null ?  `\ndebug: ${JSON.stringify(opt.debug)}` : ''} \n${this.stack}`);
      }
    }
  }
}

class HigGenericError extends HigError {
  static PermissionDenied     ({ user, auth, task, details } = {}) { return new this('PermissionDenied', { debug: { user, auth, task, details }, userError: true }); }
  static MalformedQuery       ({ query, key })                     { return new this('MalformedQuery',   { debug: { query, key }, userError: false }); }
  static InputError           ({ details })                        { return new this('InputError',       { debug: { details }, userError: false }); }
  static ServerStart          ({ details })                        { return new this('ServerStart',      { debug: { details }, userError: false }); }
  static ServerError          ({ details })                        { return new this('ServerError',      { debug: { details }, userError: false }); }
  static NoUserService        ()                                   { return new this('NoUserService',    { userError: false }); }
}

export default {
  HigError,
  HigGenericError
};

export {
  HigError,
  HigGenericError
};
