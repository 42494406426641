<script setup>
import { watch } from 'vue';
import CnsCard from '../cns/cns-card.vue';
import { useHigBootstrap } from '../../plugins/hig-bootstrap';
import RouterViewSplit from '../../plugins/cns-router/components/router-view-split.vue';

const props = defineProps({
  title: { type: String, default: '' },
  noTitle: { type: Boolean, default: false },
  backButton: { type: Boolean, default: false },
  noBody: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
  alert: { type: Object, default: undefined },
  alertText: { type: String, default: undefined },
  alertIcon: { type: String, default: undefined },
  alertVariant: { type: String, default: undefined },
  alertActions: { type: Array, default: undefined },
  noScrollX: { type: Boolean, default: false },
  noScrollY: { type: Boolean, default: false },
  fullWidth: { type: Boolean, default: false },
  [RouterViewSplit.PAGE_TYPE]: { type: String, default: undefined },
  [RouterViewSplit.PAGE_POS]: { type: String, default: undefined }
});

const emit = defineEmits(['back']);

const $higBs = useHigBootstrap();

const setRouteTitle = RouterViewSplit.useSetRouteTitle();
if (setRouteTitle) {
  watch(() => props.title, () => { setRouteTitle(props.title); }, { immediate: true });
}
</script>

<template>
  <div
    class="d-flex h-100 p-0 p-lg-4 flex-column align-items-center overflow-hidden"
    :class="{
      'pt-lg-2': props[RouterViewSplit.PAGE_TYPE] && props[RouterViewSplit.PAGE_TYPE] !== RouterViewSplit.IS_ROOT,
      'pe-lg-2': props[RouterViewSplit.PAGE_POS] === RouterViewSplit.IS_LEFT,
      'ps-lg-2': props[RouterViewSplit.PAGE_POS] === RouterViewSplit.IS_RIGHT
    }"
    style="transition: padding .3s ease;"
  >
    <cns-card
      :title="props.noTitle ? '' : props.title"
      class="w-100 h-100 overflow-hidden"
      :class="{ 'border-0': $higBs.maxMd, 'rounded-0': $higBs.maxMd }"
      :no-body="props.noBody"
      :back-button="
        props.backButton &&
        (
          props[RouterViewSplit.PAGE_TYPE] == null ||
          props[RouterViewSplit.PAGE_POS] === RouterViewSplit.IS_FULL
        )
      "
      @back="emit('back')"
      :alert="props.alert"
      :alert-text="props.alertText"
      :alert-icon="props.alertIcon"
      :alert-variant="props.alertVariant"
      :alert-actions="props.alertActions"
    >
      <template #actions>
        <slot v-if="!props.loading" name="actions" />
      </template>

      <div v-if="props.loading" class="w-100 h-100 d-flex align-items-center justify-content-center">
        <div class="spinner-border text-primary" role="status"></div>
      </div>
      <div v-else class="h-100 w-100" :style="{ 'overflow-x': props.noScrollX ? 'hidden' : 'auto', 'overflow-y': props.noScrollY ? 'hidden' : 'auto' }">
        <div :class="{ 'container': !props.fullWidth, 'w-100': props.fullWidth, 'h-100': props.noScrollY }" style="min-height: 100%;">
          <slot />
        </div>
      </div>
    </cns-card>
  </div>
</template>
