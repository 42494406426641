<script setup>
import BFormDatepicker from '../bootstrap/b-form-datepicker.vue';
import { ref, watch } from 'vue';

const emit = defineEmits(['update:modelValue']);
const props = defineProps({
  modelValue: { type: [Number, Object], default: undefined }
});

const val = ref(props.modelValue);

watch(() => props.modelValue, () => {
  val.value = props.modelValue;
}, { deep: true });

function updModel () {
  emit('update:modelValue', val.value);
}

</script>

<template>
  <b-form-datepicker v-model="val" @update:modelValue="updModel">
    <template v-for="(_, name) in $slots" v-slot:[name]="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </b-form-datepicker>
</template>
