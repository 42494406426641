import { DateTime } from 'luxon';
import { lib_Utils as VisionUtils } from '@hig/vision-sdk';
import Utils from '../../../libs/utils/index.mjs';
import GraphQl from '../../../libs/graphql-client/index.mjs';
import { Fop } from '../../../libs/fop-utils/index.browser.mjs';
import { useCns } from '../../../plugins/cns/index.mjs';

import type {
  CnsDataWrapperConfigDataInterval,
  CnsDataWrapperConfigDataIntervalObj,
  CnsDataWrapperNode,
  CnsDataWrapperConfigData,
  CnsDataWrapperConfigAlarms,
  CnsDataWrapperConfigActionWriteRawDl,
  FetchDataResult
} from './types.js';

import type {
  SeriesData
} from '../../../libs/data-utils/index.js';

const parseTimestampTo = {
  s: (t: typeof DateTime) => Math.floor(t.toSeconds()),
  ms: (t: typeof DateTime) => t.toMillis(),
  ISO: (t: typeof DateTime) => t.toISO()
};
function parseTimestamp (timestamp: string | number, type: 's' | 'ms' | 'ISO' = 'ISO', tz?: string) {
  let date;
  if (Utils.isNum(timestamp)) {
    if ((timestamp as number) < 99999999999) {
      date = DateTime.fromSeconds(timestamp).toUTC();
    } else {
      date = DateTime.fromMillis(timestamp).toUTC();
    }
  } else {
    date = DateTime.fromISO(timestamp).toUTC();
  }

  if (tz) {
    date = date.setZone(tz);
    if (date.isValid) {
      date = date.plus({ minutes: date.offset });
    } else {
      throw new Error(`Invalid timezone "${tz}"`);
    }
  }

  return parseTimestampTo[type](date);
}

function parseInterval (interval: CnsDataWrapperConfigDataInterval = 'last', type: 's' | 'ms' | 'ISO' = 'ISO', tz?: string): CnsDataWrapperConfigDataInterval {
  if (interval == null || interval === 'last') { return 'last'; }

  const res: CnsDataWrapperConfigDataIntervalObj = typeof interval === 'string'
    ? Utils.ms.interval(interval, { local: true })
    : interval;

  return {
    start: parseTimestamp(res.start, type, tz),
    stop: parseTimestamp(res.stop, type, tz)
  };
}

const fetchDataAggCns2 = Utils.batch<{ node: CnsDataWrapperNode, request: CnsDataWrapperConfigData, $cns: any, tz?: string }, FetchDataResult>((args, { group }) => {
  const $cns = group.$cns;
  if (!$cns) { throw new Error('Missing $cns'); }

  if (group['request.type'] === 'agg') {
    const requests = args.map(({ node, request, tz }) => ({
      type: 'agg',
      nodeId: node.id,
      variable: request.name,
      period: request.period ?? '15m',
      interval: parseInterval(request.interval, 'ms', tz)
    }));

    const requestsPar = args.map(({ node, request }) => ({
      nodeId: node.id,
      parameter: request.name
    }));

    return Promise.all([
      $cns.getNodesData(requests),
      $cns.getNodesParameters(requestsPar)
    ]).then(([series, parameters]: [SeriesData[], string[]]): FetchDataResult[] =>
      series.map((data: SeriesData, i) => ({
        data,
        isValid: data.length > 0,
        isConfigured: parameters[i] != null && parameters[i] !== '',
        unitOfMeasure: ''
      }))
    );
  } else if (group['request.type'] === 'parameter') {
    const requests = args.map(({ node, request }) => ({
      nodeId: node.id,
      parameter: request.name
    }));

    // Parameters are cached by this function
    return $cns.getNodesParameters(requests)
      .then((res: string[]): FetchDataResult[] =>
        res.map((par) => ({
          data: [[Date.now(), par || null]],
          isValid: par != null && par !== '',
          isConfigured: par != null,
          unitOfMeasure: ''
        }))
      );
  } else {
    console.log('Invalid request type', group['request.type']);
  }
}, {
  groupBy: ['request.type', '$cns'],
  cacheBy: [
    'node.id',
    'request.type',
    'request.name',
    'request.period',
    'request.interval'
  ],
  cacheExp: Utils.ms('5m')
});

const fetchDataAggCns3 = Utils.batch<{ node: CnsDataWrapperNode, request: CnsDataWrapperConfigData, tz?: string }, FetchDataResult>((args) => {
  const requests: { [hash: string]: { filter: any, interval: CnsDataWrapperConfigDataInterval | undefined, decimals: number } } = {};
  args.forEach(({ node, request, tz }) => {
    if (!node.id) { console.log('Invalid node', node); return; }
    if (!request.name) { console.log('Invalid request', request); return; }
    if (request.type !== 'agg' && request.type !== 'parameter') { console.log('Invalid request', request); return; }

    const decimals: number = request.decimals ?? request.nDec ?? 2;
    const interval = parseInterval(request.interval, 'ISO', tz);
    const hash = `${
      interval === 'last' ? 'last' : (interval as CnsDataWrapperConfigDataIntervalObj).start
    }|${
      interval === 'last' ? 'last' : (interval as CnsDataWrapperConfigDataIntervalObj).stop
    }|${decimals}`;

    requests[hash] ??= {
      filter: [],
      interval: interval === 'last'
        ? undefined // to get the last element this query needs `undefined` as interval
        : interval,
      decimals
    };

    requests[hash].filter.push({
      node: node.id,
      name: request.name,
      type: request.type === 'agg'
        ? 'variable'
        : 'parameter',
      timeframe: request.type === 'agg'
        ? request.period ?? '15m'
        : undefined
    });
  });

  return GraphQl.query(
    `
      Data_get (request: $request) {
        results {
          data
          serie {
            node { id }
            varType { name type timeframe unitOfMeasurement }
            source
          }
        }
        interval { start stop }
        decimals
      }
    `,
    {
      request: {
        type: '[DataRequestInput!]!',
        value: Object.values(requests)
      }
    }
  ).then((res: { results: { data: SeriesData, serie: { node: { id: string }, varType: { name: string, type: string, timeframe: string, unitOfMeasurement: string }, source: any } }[], interval: { start: string, stop: string }, decimals: number }[]): FetchDataResult[] => {
    return args.map(({ node, request }): FetchDataResult => {
      if (node.id && request.name) {
        for (const i in res) {
          for (const j in res[i].results) {
            const curResSerie = res[i].results[j].serie;
            if (
              curResSerie.node.id !== node.id ||
              curResSerie.varType.name !== request.name ||
              (request.type === 'agg' && curResSerie.varType.type !== 'variable' && curResSerie.varType.type !== 'kpi') ||
              (request.type === 'parameter' && curResSerie.varType.type !== 'parameter') ||
              (request.type === 'agg' && curResSerie.varType.timeframe !== (request.period ?? '15m'))
            ) { continue; }

            const curResData: SeriesData = res[i].results[j].data?.map((sample) => [
              sample[0] ? DateTime.fromISO(sample[0]).toMillis() : 0,
              request.type === 'parameter' || sample[1] == null ? sample[1] : Number(sample[1])
            ]) ?? [];

            return {
              data: curResData,
              isValid: request.type === 'parameter'
                ? curResData?.[0]?.[1] != null
                : curResData.length > 0,
              isConfigured: request.type === 'parameter'
                ? curResData?.[0]?.[1] != null
                : curResSerie.source != null && curResSerie.source !== '',
              unitOfMeasure: curResSerie.varType.unitOfMeasurement
            };
          }
        }
      }

      return { data: [], isValid: false, isConfigured: false, unitOfMeasure: '' };
    });
  });
}, {
  cacheBy: [
    'node.id',
    'request.type',
    'request.name',
    'request.period',
    'request.decimals',
    'request.interval'
  ],
  cacheExp: Utils.ms('5m')
});

const fetchDataRawCns2Last = Utils.batch<{ parameter: any, fromDatalogger: boolean, force?: boolean }, FetchDataResult>((args, { group }) => {
  let threshold;
  if (group.force) {
    threshold = 1;
  } else if (group.fromDatalogger) {
    threshold = 600;
  } else {
    threshold = 60 * 60 * 24 * 30;
  }

  const requests: { [gwcSn: string]: string[] } = {};
  let requestsCount = 0;
  args.forEach(({ parameter }) => {
    if (!parameter?.gwcSn || !parameter?.grandezzaId) { return; }

    requests[parameter.gwcSn] ??= [];
    requests[parameter.gwcSn].push(parameter.grandezzaId);
    requestsCount++;
  });

  if (requestsCount === 0) {
    return Promise.resolve(args.map(() => ({
      data: [],
      isValid: false,
      isConfigured: false
    })));
  }

  return VisionUtils.ajaxRequest({
    act: 'getItemsDataFromGWCCache',
    data: {
      grByGwcSn: requests,
      threshold // The cache threshold in seconds
    }
  }).then((r: any): FetchDataResult[] => {
    return args.map(({ parameter }) => {
      if (!parameter?.gwcSn || !parameter?.grandezzaId) {
        return {
          data: [],
          isValid: false,
          isConfigured: false,
          unitOfMeasure: ''
        };
      }

      if (!r?.[parameter.gwcSn]?.[parameter.grandezzaId]) {
        console.error(`getItemsDataFromGWCCache invalid response, cannot find ${parameter.gwcSn} -> ${parameter.grandezzaId}`, r);
        return {
          data: [],
          isValid: false,
          isConfigured: true,
          unitOfMeasure: ''
        };
      }

      return {
        data: [[
          (r[parameter.gwcSn][parameter.grandezzaId].utc ?? 0) * 1000,
          r[parameter.gwcSn][parameter.grandezzaId].value
        ]],
        isValid: r[parameter.gwcSn][parameter.grandezzaId].value != null && r[parameter.gwcSn][parameter.grandezzaId].value !== '',
        isConfigured: true,
        unitOfMeasure: parameter.unit || ''
      };
    });
  });
}, { groupBy: ['fromDatalogger', 'force'] });

const fetchDataRawCns2Log = Utils.batch<{ parameter: any, interval: CnsDataWrapperConfigDataIntervalObj, period: number }, FetchDataResult>((args, { group }) => {
  type RawLogDataRequest = { idLog: string, start: number, stop: number, period: number, items: string[] };

  const requests: { [gwcSn: string]: { [devId: string]: RawLogDataRequest } } = {};
  let requestsCount = 0;
  args.forEach(({ parameter }) => {
    if (!parameter?.gwcSn || !parameter?.devId || !parameter?.grandezzaId) { return; }

    requests[parameter.gwcSn] ??= {};
    requests[parameter.gwcSn][parameter.devId] ??= {
      idLog: parameter.devId,
      start: group['interval.start'],
      stop: group['interval.stop'],
      period: group.period,
      items: []
    };
    requests[parameter.gwcSn][parameter.devId].items.push(parameter.grandezzaId);
    requestsCount++;
  });

  if (requestsCount === 0) {
    return Promise.resolve(args.map(() => ({
      data: [],
      isValid: false,
      isConfigured: false
    })));
  }

  const realRequests: { [gwcSn: string]: RawLogDataRequest[] } = {};
  Object.keys(requests).forEach((gwcSn) => {
    realRequests[gwcSn] = Object.values(requests[gwcSn]);
  });

  return VisionUtils.ajaxRequest({
    act: 'getRawLogData',
    data: { grBySn: realRequests }
  }).then((res: any): FetchDataResult[] => {
    const signalIndexes: { [gwcSn: string]: { [devId: string]: any } } = {};
    return args.map(({ parameter }) => {
      if (!parameter?.gwcSn || !parameter?.devId || !parameter?.grandezzaId) {
        return {
          data: [],
          isValid: false,
          isConfigured: false,
          unitOfMeasure: ''
        };
      }

      signalIndexes[parameter.gwcSn] ??= {};
      signalIndexes[parameter.gwcSn][parameter.devId] ??= 2;
      let atLeastOneValidSample = false;
      const resData = res?.[parameter.gwcSn]?.[parameter.devId]?.map((sample: any) => {
        if (Number(sample[1]) === 0) { atLeastOneValidSample = true; }

        return [
          sample[0] * 1000,
          sample[signalIndexes[parameter.gwcSn][parameter.devId]]
        ];
      }) ?? [];
      signalIndexes[parameter.gwcSn][parameter.devId]++;

      if (!resData) {
        console.error(`getRawLogData invalid response, cannot find ${parameter.gwcSn} -> ${parameter?.grandezzaId}`, res);
        return {
          data: [],
          isValid: false,
          isConfigured: true,
          unitOfMeasure: ''
        };
      }

      return {
        data: resData,
        isValid: resData.length > 0 && atLeastOneValidSample,
        isConfigured: true,
        unitOfMeasure: parameter.unit || ''
      };
    });
  });
}, {
  groupBy: ['interval.start', 'interval.stop', 'period'],
  cacheBy: [
    'interval.start',
    'interval.stop',
    'period',
    'parameter.gwcSn',
    'parameter.devId',
    'parameter.grandezzaId'
  ],
  cacheExp: Utils.ms('5m')
});

async function fetchDataRawCns2 ({ node, request, $cns, force, tz }: { node: CnsDataWrapperNode, request: CnsDataWrapperConfigData, $cns: any, force?: boolean, tz?: string}, { cacheExp }: { cacheExp?: number } = {}): Promise<FetchDataResult> {
  // TODO: batch this function to aggregate similar requests and cache the results (ignore cache if force is set to true)

  // Parameters are cached by this function
  const parameter = await fetchDataAggCns2({ node: { id: node.id }, request: { type: 'parameter', name: request.name }, $cns })
    .then((r): any => { try { return JSON.parse(String(r?.data?.[0]?.[1])); } catch {}; });

  const period = Utils.isNum(request.period) ? Number(request.period) : 1;
  const interval = parseInterval(request.interval, 's', tz);
  if (request.type === 'raw') {
    if (interval === 'last') {
      return fetchDataRawCns2Last({ parameter, fromDatalogger: false, force }, { cacheExp });
    } else {
      return fetchDataRawCns2Log({ parameter, interval: interval as CnsDataWrapperConfigDataIntervalObj, period /*, force */ }, { cacheExp });
    }
  } else if (request.type === 'raw-dl') {
    if (interval === 'last') {
      return fetchDataRawCns2Last({ parameter, fromDatalogger: true, force }, { cacheExp });
    } else {
      throw new Error('Cannot load data in a interval directly from datalogger, please use `raw` instead');
    }
  } else {
    throw new Error(`Request type "${request.type}" not supported`);
  }
}

function useFetchData ({ tz = 'UTC', cache = true }: { tz?: string, cache?: boolean }) {
  const $cns = useCns();

  return async function (node: CnsDataWrapperNode, request: CnsDataWrapperConfigData, force?: boolean): Promise<FetchDataResult> {
    if (request.type === 'agg' || request.type === 'parameter') {
      if ((window as any).sysConf.cns3) {
        return fetchDataAggCns3({ node, request /*, force */, tz }, { cacheExp: cache ? undefined : 0 });
      } else {
        return fetchDataAggCns2({ node, request /*, force */, $cns, tz }, { cacheExp: cache ? undefined : 0 });
      }
    } else if (request.type === 'raw' || request.type === 'raw-dl') {
      return fetchDataRawCns2({ node, request, $cns, force, tz }, { cacheExp: cache ? undefined : 0 });
    } else {
      throw new Error(`Request type "${request.type}" not supported`);
    }
  };
}

function fetchAlarmsCns3 ({ node, request /* , force */, tz }: { node: CnsDataWrapperNode, request: CnsDataWrapperConfigAlarms /* , force?: boolean */, tz?: string }) {
  // TODO: batch this function to aggregate similar requests and cache the results (ignore cache if force is set to true)
  // WARN: With the current implementation of the apis it's not possible to batch this function

  let _fop = new Fop()
    .filter('ancestor.id', '=', node.id);

  if (request.status) { _fop = _fop.filter('status', '=', request.status); }
  if (request.level) { _fop = _fop.filter('level', '=', request.level); }
  if (request.interval) {
    const interval = parseInterval(request.interval, 's', tz);
    if (interval !== 'last') {
      _fop = _fop.filter({
        or: [
          {
            and: [
              { field: 'start', op: '>=', value: interval.start },
              { field: 'start', op: '<=', value: interval.stop }
            ]
          },
          {
            and: [
              { field: 'stop', op: '>=', value: interval.start },
              { field: 'stop', op: '<=', value: interval.stop }
            ]
          },
          {
            and: [
              { field: 'start', op: '<=', value: interval.start },
              { field: 'stop', op: '>=', value: interval.stop }
            ]
          }
        ]
      });
    }
  }

  return GraphQl.query(
    `Alarm_get (fop: $fop) {
      id
      name
      datalogger { id }
      node { id }
      timeZone { id }
      start
      stop
      status
      level
      levelType
      type
      dismissed
      ancestor { id }
    }`,
    { fop: { type: 'FilterOrderPaginate', value: _fop.serialize() } }
  );
}

function useFetchAlarms ({ tz = 'UTC' }: { tz?: string }) {
  return async function (node: CnsDataWrapperNode, request: CnsDataWrapperConfigAlarms /* , force?: boolean */) {
    if ((window as any).sysConf.cns3) {
      return fetchAlarmsCns3({ node, request /*, force */, tz });
    } else {
      throw new Error('Cannot fetch alarms, missing CNS3');
    }
  };
}

async function writeDataToDl (parameter: any, value: any) {
  if (!parameter?.gwcSn || !parameter?.devId || !parameter?.grandezzaId) {
    throw new Error('Cannot write value on datalogger, missing some info');
  }

  return VisionUtils.ajaxRequest({
    act: 'setGWCRawData',
    data: {
      gwcSn: parameter.gwcSn,
      items: [{ id: parameter.grandezzaId, value }],
      forceReading: true
    }
  }).then((r: any) => {
    console.log('setGWCRawData', r);
    // if (r.err !== 0) {
    //   throw new Error(`Error writing value on datalogger: ${r.msg}`);
    // }
  });
}

function useWriteDataToDl () {
  const $cns = useCns();

  return async function (node: CnsDataWrapperNode, action: CnsDataWrapperConfigActionWriteRawDl, value: any) {
    // Parameters are cached by this function
    const parameter = await fetchDataAggCns2({ node: { id: node.id }, request: { type: 'parameter', name: action.name }, $cns })
      .then((r): any => { try { return JSON.parse(String(r?.data?.[0]?.[1])); } catch {}; });

    return writeDataToDl(parameter, value);
  };
}

export {
  parseTimestamp,
  parseInterval,
  useFetchData,
  useFetchAlarms,
  useWriteDataToDl
};
