/**
 * Normalize a path, add a / in front and remove any trailing one + remove multiple /
 *
 * @param {String} path - The path to normalize
 * @returns The normalized path
 */
function normalizePath (path) {
  return String(path).replace(/\/?$/, '').replace(/^\/?|\/+/g, '/');
}

function genQueryString (params) {
  const queryParams = [];
  if (typeof params === 'object') {
    Object.keys(params).forEach((key) => {
      if (params[key] == null) { return; }
      queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`);
    });
  }

  return queryParams.length > 0 ? `?${queryParams.join('&')}` : '';
}

function parseQueryString (url) {
  const params = {};
  if (url) {
    const queryStr = (url.match(/[^?\n]*\?(.+)/)?.[1] ?? '').replace(/#.*$/, ''); // take all that's after the ? or the whole query string and remove the hash (if any)
    if (queryStr) {
      const queryParams = queryStr.split('&');

      queryParams.forEach((queryPar) => {
        const [key, val] = queryPar.split('=');
        params[decodeURIComponent(key)] = decodeURIComponent(val);
      });
    }
  }

  return params;
}

export default {
  normalizePath,
  genQueryString,
  parseQueryString
};

export {
  normalizePath,
  genQueryString,
  parseQueryString
};
