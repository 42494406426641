<script lang="ts">
import { inject, watch, ref } from 'vue';
import { Fop } from '../../libs/fop-utils/index.browser.mjs';
import GraphQl from '../../libs/graphql-client/index.mjs';
import BCheckbox from '../bootstrap/b-checkbox.vue';
import CnsAlert from './cns-alert.vue';
import CnsButton from './cns-button.vue';
import CnsModal from './cns-modal.vue';
import CnsList from './cns-list.vue';
import CnsListItem from './cns-list-item.vue';
import CnsFilterInput from './cns-filter-input.vue';

type Datalogger = {
  id: string;
  sn: string;
  name: string;
}

type DataloggerAssociation = {
  id: string;
  datalogger: Datalogger;
  master: boolean;
  showPreview: boolean;
}

const dataloggersAssociationsCache: { [key: string]: DataloggerAssociation[] } = {};
</script>

<script setup lang="ts">
const $edw = inject<any>('$edw');

const props = defineProps<{
  installationId: string;
}>();

const error = ref<string | undefined>();
const dataloggersSelectModal = ref<any>();

const dataloggersAssociationsLoading = ref<boolean>(false);
const dataloggersAssociations = ref<DataloggerAssociation[] | undefined>();
const dataloggersAssociationsAdding = ref<string[] | undefined>();
const dataloggersAssociationsRemoving = ref<string[] | undefined>();
const unassociatedDataloggersFilter = ref<string>('');
const unassociatedDataloggersLoading = ref<boolean>(false);
const unassociatedDataloggers = ref<Datalogger[]>([]);
const unassociatedDataloggersSelected = ref<string[]>([]);
const unassociatedDataloggersShowAll = ref<boolean>(false);

async function loadDataloggersAssociations (force: boolean = false) {
  if (!props.installationId) { return; }

  dataloggersAssociationsLoading.value = true;
  if (force || !dataloggersAssociationsCache[props.installationId]) {
    dataloggersAssociationsCache[props.installationId] = await GraphQl.query(`
      InstallationDatalogger_get(fop:{filter:[{field:"installation.id",op:"=",val:"${props.installationId}"}]}, noCache: true) {
        id
        datalogger { id sn name }
        master
        showPreview
      }
    `).then((res: any) =>
      new Fop().order('datalogger.name', 1).apply(res)
    ).catch((err: Error) => {
      error.value = $edw.translateError(err);
    });
  }

  dataloggersAssociations.value = dataloggersAssociationsCache[props.installationId];
  dataloggersAssociationsLoading.value = false;
}

async function loadUnassociatedDataloggers () {
  if (unassociatedDataloggersLoading.value || !dataloggersAssociations.value) { return; }
  unassociatedDataloggersLoading.value = true;

  let _fop = new Fop().order('name', 1);

  if (!unassociatedDataloggersShowAll.value) {
    _fop = _fop.filter('installations[*].id', '=', Fop.NULL);
  } else {
    _fop = _fop.filter('id', '!=', dataloggersAssociations.value.map((el) => el.datalogger.id));
  }

  if (unassociatedDataloggersFilter.value) {
    const filters = unassociatedDataloggersFilter.value.split(' ').map((el) => el.trim()).filter((el) => el.length > 0);
    filters.forEach((filter) => {
      _fop = _fop.filter({
        or: [
          { field: 'sn', op: '%', val: filter },
          { field: 'name', op: '%', val: filter }
        ]
      });
    });
  }

  unassociatedDataloggers.value = await GraphQl.query(
    'Datalogger_get (fop: $fop, noCache: true) { id sn name }',
    { fop: { type: 'FilterOrderPaginate', value: _fop.serialize() } }
  ).catch((err: Error) => {
    error.value = $edw.translateError(err);
  });

  unassociatedDataloggersLoading.value = false;
}

async function addDataloggersToPlant (dataloggersIds: string[]) {
  if (!props.installationId || !dataloggersAssociations.value) { return; }
  if (dataloggersIds.length === 0) { return; }
  if (dataloggersAssociationsAdding.value != null) { return; }
  dataloggersAssociationsAdding.value = dataloggersIds;

  const masterAlreadyExists = dataloggersAssociations.value.some((el) => el.master);
  const newAssociations = dataloggersIds.map((id, i) => ({
    datalogger: { id },
    installation: { id: props.installationId },
    master: !masterAlreadyExists && i === 0 // If no master exists, the first datalogger will be the master
  }));

  await GraphQl.mutation(
    'InstallationDatalogger_save(entities: $entities) { id }',
    { entities: { type: '[InstallationDataloggerInput!]!', value: newAssociations } }
  );

  await loadDataloggersAssociations(true);
  dataloggersAssociationsAdding.value = undefined;
}

async function removeDataloggersFromPlant (dataloggersIds: string[]) {
  if (!props.installationId || !dataloggersAssociations.value) { return; }
  if (dataloggersIds.length === 0) { return; }
  if (dataloggersAssociationsRemoving.value != null) { return; }
  dataloggersAssociationsRemoving.value = dataloggersIds;

  const masterAssociation = dataloggersAssociations.value
    .filter((association) => !dataloggersIds.includes(association.datalogger.id))
    .find((association) => association.master);

  let newMasterAssociation: DataloggerAssociation | null = null;
  if (!masterAssociation) {
    newMasterAssociation = dataloggersAssociations.value
      .filter((association) => !dataloggersIds.includes(association.datalogger.id))[0];

    if (newMasterAssociation) { newMasterAssociation.master = true; }
  }

  await Promise.all([
    GraphQl.mutation(`
      InstallationDatalogger_del(fop: {filter:[
        {field:"datalogger.id",op:"=",val:[${dataloggersIds}]},
        {field:"installation.id",op:"=",val:${props.installationId}}
      ]})
    `),
    newMasterAssociation
      ? GraphQl.mutation(
        'InstallationDatalogger_save(entities: $entities) { id }',
        { entities: { type: '[InstallationDataloggerInput!]!', value: [newMasterAssociation] } }
      )
      : Promise.resolve()
  ]);

  await loadDataloggersAssociations(true);
  dataloggersAssociationsRemoving.value = undefined;
}

function openAddDataloggersModal () {
  unassociatedDataloggersSelected.value = [];
  loadUnassociatedDataloggers();
  dataloggersSelectModal.value.open();
}

async function closeAddDataloggersModal (save: boolean) {
  if (save) {
    await addDataloggersToPlant(unassociatedDataloggersSelected.value);
  }
  dataloggersSelectModal.value.close();
  unassociatedDataloggersSelected.value = [];
}

function toggleSelectDatalogger (dataloggerId: string) {
  if (unassociatedDataloggersSelected.value.includes(dataloggerId)) {
    unassociatedDataloggersSelected.value = unassociatedDataloggersSelected.value.filter((id) => id !== dataloggerId);
  } else {
    unassociatedDataloggersSelected.value.push(dataloggerId);
  }
}

watch([unassociatedDataloggersFilter, unassociatedDataloggersShowAll], () => { loadUnassociatedDataloggers(); });
watch(() => props.installationId, () => { loadDataloggersAssociations(); }, { immediate: true });
</script>

<template>
  <div>
    <cns-alert v-if="error" variant="danger" :actions="[{ text: $edw.ok, action: () => { error = undefined; } }]">
      {{ error }}
    </cns-alert>
    <div class="d-flex flex-column border border-1 rounded p-2">
      <div class="d-flex justify-content-between border-bottom border-1 px-2">
        <span>{{ $edw.dataloggers }}</span>
        <cns-button
          size="sm"
          icon="plus fw"
          variant="link"
          :disabled="
            dataloggersAssociationsLoading ||
            dataloggersAssociationsRemoving != null ||
            dataloggersAssociationsAdding != null
          "
          :loading="dataloggersAssociationsLoading"
          @click="openAddDataloggersModal"
        />
      </div>
      <div class="d-flex flex-column overflow-auto fs-5 pt-2" style="max-height: 200px;">
        <span v-if="dataloggersAssociations?.length === 0" class="text-muted fs-6 px-2">
          {{ $edw.noDataloggersAssociated }}
        </span>
        <cns-list-item
          v-for="association in (dataloggersAssociations ?? [])"
          :key="association.id"
          class="flex-shrink-0"
          :icon="association.master ? 'meter solid fw' : 'meter fw'"
          :label="association.datalogger.name"
          :caption="association.datalogger.sn"
        >
          <template #suffix>
            <cns-button
              size="sm"
              icon="xmark fw"
              variant="link"
              :disabled="dataloggersAssociationsRemoving != null"
              :loading="dataloggersAssociationsRemoving?.includes(association.datalogger.id)"
              @click="removeDataloggersFromPlant([association.datalogger.id])"
            />
          </template>
        </cns-list-item>
      </div>
    </div>
    <cns-modal ref="dataloggersSelectModal" :title="$edw.dataloggers">
      <div class="d-flex align-items-center gap-2">
        <span class="d-flex align-items-center gap-1 cursor-pointer" @click="unassociatedDataloggersShowAll = !unassociatedDataloggersShowAll">
          {{ $edw.showAll }}
          <b-checkbox :model-value="unassociatedDataloggersShowAll" class="pe-none"/>
        </span>
        <cns-filter-input
          v-model="unassociatedDataloggersFilter"
          class="flex-fill mb-2"
          :placeholder="$edw.search"
          :loading="unassociatedDataloggersLoading"
          lazy
        />
      </div>
      <cns-list v-slot="{ item }" :items="unassociatedDataloggers">
        <cns-list-item
          icon="meter"
          :label="item.name"
          :caption="item.sn"
          :active="unassociatedDataloggersSelected.includes(item.id)"
          checkbox
          @click="() => toggleSelectDatalogger(item.id)"
        />
      </cns-list>
      <div class="d-flex align-items-center justify-content-end gap-2 mt-2">
        <cns-button
          :text="$edw.cancel"
          :disabled="dataloggersAssociationsAdding != null"
          variant="secondary"
          @click="closeAddDataloggersModal(false)"
        />
        <cns-button
          :text="$edw.confirm"
          :loading="dataloggersAssociationsAdding != null"
          variant="primary"
          @click="closeAddDataloggersModal(true)"
        />
      </div>
    </cns-modal>
  </div>
</template>
