<script setup>
/* global HigJS */

import { computed, inject, ref } from 'vue';
import Datepicker from '@vuepic/vue-datepicker'; // See docs here https://vue3datepicker.com/
import Utils from '../../libs/utils';
import { vModel } from '../../libs/vue-utils';
import { DateTime } from 'luxon';
import CnsIcon from '../cns/cns-icon.vue';
const $edw = inject('$edw');

const props = defineProps({
  modelValue: { type: [Number, Object], default: undefined },
  ms: { type: Boolean, default: false },
  local: { type: Boolean, default: false },
  locale: { type: String, default: 'en' },
  range: { type: Boolean, default: false },
  enableTimePicker: { type: Boolean, default: true },
  size: { type: String, default: undefined },
  state: { type: Boolean, default: undefined },
  readonly: { type: Boolean, default: false },
  plaintext: { type: Boolean, default: false },
  yearPicker: { type: Boolean, default: false },
  monthPicker: { type: Boolean, default: false },
  iconOnly: { type: Boolean, default: false },
  presetRanges: { type: Array, default: undefined },
  presetDates: { type: Array, default: undefined }
});

const emit = defineEmits(['update:modelValue']);
const datepicker = ref(null);
const enableTimePicker = computed(() => props.enableTimePicker && !props.yearPicker && !props.monthPicker);
const classes = computed(() => {
  const ret = ['form-control'];

  if (props.size) { ret.push('form-control-' + props.size); }
  if (props.state === true) { ret.push('is-valid'); }
  if (props.state === false) { ret.push('is-invalid'); }
  if (props.plaintext) { ret.push('form-control-plaintext'); }

  return ret.join(' ');
});

const value = vModel({
  get: () => {
    const val = props.modelValue;
    if (props.range && val != null && Utils.isNum(val.start) && Utils.isNum(val.stop)) {
      return [
        parseGetValue(val.start),
        parseGetValue(val.stop)
      ];
    }
    return parseGetValue(val);
  },
  set: (newVal) => {
    let val;
    if (props.range && Array.isArray(newVal)) {
      val = {
        start: parseSetValue(newVal[0]),
        stop: parseSetValue(newVal[1])
      };
    } else {
      val = parseSetValue(newVal);
    }
    emit('update:modelValue', val);
  }
});

const presetDates = computed(() => {
  if (!props.range && props.presetDates == null && props.presetRanges == null) { return undefined; }
  const res = [];

  if (Array.isArray(props.presetDates)) {
    res.push(...props.presetDates);
  }

  if (Array.isArray(props.presetRanges)) {
    res.push(...props.presetRanges.map((val) => ({
      ...val,
      value: val.range,
      range: undefined
    })));
  }
  return res;
});

function parseSetValue (newVal) {
  if (props.yearPicker && Utils.isNum(newVal)) {
    return Math.floor(DateTime[props.local ? 'local' : 'utc'](newVal).startOf('year')[props.ms ? 'toMillis' : 'toSeconds']());
  } else if (props.monthPicker && newVal && Utils.isNum(newVal.year) && Utils.isNum(newVal.month)) {
    return Math.floor(DateTime[props.local ? 'local' : 'utc'](newVal.year, newVal.month + 1)[props.ms ? 'toMillis' : 'toSeconds']());
  } else if (newVal instanceof Date) {
    return Math.floor(DateToDateTime(newVal, props.local)[props.ms ? 'toMillis' : 'toSeconds']());
  }
  return null;
}

function parseGetValue (val) {
  if (props.yearPicker && Utils.isNum(val)) {
    return DateTime[props.ms ? 'fromMillis' : 'fromSeconds'](val)[props.local ? 'toLocal' : 'toUTC']().year;
  } else if (props.monthPicker && Utils.isNum(val)) {
    const d = DateTime[props.ms ? 'fromMillis' : 'fromSeconds'](val)[props.local ? 'toLocal' : 'toUTC']();
    return { year: d.year, month: d.month - 1 };
  } else if (Utils.isNum(val)) {
    return DateTimeToDate(DateTime[props.ms ? 'fromMillis' : 'fromSeconds'](val), props.local);
  }

  return null;
}

function DateTimeToDate (dateTime, local) {
  const _dateTime = local ? dateTime.toLocal() : dateTime.toUTC();
  return new Date(_dateTime.year, _dateTime.month - 1, _dateTime.day, _dateTime.hour, _dateTime.minute);
}

function DateToDateTime (date, local) {
  return DateTime[local ? 'local' : 'utc'](date.getFullYear(), date.getMonth() + 1, date.getDate(), date.getHours(), date.getMinutes());
}

function formatDate (date) {
  if (!date) { return ''; }

  if (props.yearPicker) {
    return String(date.getFullYear());
  } else if (props.monthPicker) {
    return `${date.getMonth() + 1}/${date.getFullYear()}`;
  }

  return HigJS.num.formatDate({ utc: Math.floor((date.getTime() / 1000) / 60) * 60, date: true, time: enableTimePicker.value, local: true });
}

function format (date) {
  if (props.range && Array.isArray(date)) {
    return `${formatDate(date[0])}${date[1] ? ` - ${formatDate(date[1])}` : ''}`;
  }

  return formatDate(date);
}

function previewFormat (date) {
  if (props.range && Array.isArray(date)) {
    // here i optionally add a ` - ` separator between the dates when it's of type month or year.
    // this is needed because the first date isn't long enough to force the second one on the next line.
    // using a `\n` has no effect
    return `${formatDate(date[0])} ${date[1] ? `${props.yearPicker || props.monthPicker ? ' - ' : ''}${formatDate(date[1])}` : ''}`;
  }

  return formatDate(date);
}

</script>

<template>
  <Datepicker
    ref="datepicker"
    v-model="value"
    :range="props.range"
    :preset-dates="presetDates"
    :format="format"
    :previewFormat="previewFormat"
    :enableTimePicker="enableTimePicker"
    :inputClassName="classes"
    :readonly="props.readonly || props.plaintext"
    :year-picker="props.yearPicker"
    :month-picker="props.monthPicker"
    hide-input-icon
    :now-button-label="$edw.today"
    :cancel-text="$edw.close"
    :select-text="$edw.select"
    :locale="props.locale"
    :style="{ margin: props.iconOnly ? 'auto' : undefined }"
  >
    <template v-if="props.iconOnly" #trigger>
      <cns-icon type="calendar fw" class="cursor-pointer"/>
    </template>
    <template v-for="(_, name) in $slots" v-slot:[name]="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </Datepicker>
</template>

<style src="@vuepic/vue-datepicker/dist/main.css"/>

<style>
.dp__main, .dp__menu {
  --dp-primary-color: var(--hig-primary);
  --dp-primary-text-color: var(--hig-primary-text);
  --dp-success-color: var(--hig-primary);
  --dp-success-color-disabled: var(--hig-primary-text);
  --dp-secondary-color: var(--hig-page-text-muted);
  --dp-primary-disabled-color: var(--hig-primary-dim-bg);
  --dp-background-color: var(--hig-form-bg);
  --dp-text-color: var(--hig-form-text);
  --dp-hover-color: var(--hig-form-bg-addon);
  --dp-hover-text-color: var(--hig-form-text-addon);
  --dp-border-color: var(--hig-form-border);
  --dp-border-color-hover: var(--hig-form-border);
  --dp-menu-border-color: var(--hig-page-border-medium);
  --dp-disabled-color: var(--hig-form-bg-disabled);
  --dp-danger-color: rgba(var(--hig-danger-rgb),var(--hig-text-opacity));
  --dp-marker-color: rgba(var(--hig-danger-rgb),var(--hig-text-opacity));
  --dp-tooltip-color: var(--hig-page-bg-light);
  --dp-disabled-color-text: var(--hig-form-text);
}

.dp__menu {
  padding-top: .25rem;
}

.dp__main {
  font-family: inherit;
  outline: 0;
}

.dp__arrow_top, .dp__arrow_bottom {
  display: none;
}

.dp__input {
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.6;
  color: var(--hig-form-text);
  background-color: var(--hig-form-bg);
  background-clip: padding-box;
  border: 1px solid var(--hig-form-border);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: var(--hig-form-control-border-radius);
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.dp__input.form-control-sm {
  min-height: calc(1.6em + 0.8rem + 2px);
  padding: 0.4rem 0.5rem;
  font-size: .75rem;
}

.dp__input.form-control-lg {
  min-height: calc(1.6em + 2rem + 2px);
  padding: 1rem 1.25rem;
  font-size: 1rem;
}

.dp__input.is-valid, .dp__input.is-invalid {
  padding-right: 3.8rem;
}

.dp__input:not(.is-valid):not(.is-invalid):not(.form-control-plaintext):hover {
  border: 1px solid var(--hig-form-border);
}

.dp__input.dp__input_focus:not(.form-control-plaintext) {
  color: var(--hig-form-text);
  background-color: var(--hig-form-bg);
  border-color: var(--hig-form-border-focus);
  box-shadow: 0 0 0 1px var(--hig-primary);
}

.dp__input:not(.form-control-plaintext)[readonly] {
  background-color: var(--hig-form-bg-disabled) !important;
  border-color: var(--hig-form-border-disabled) !important;
  opacity: 1;
}

.dp__input.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.5rem 0;
  margin-bottom: 0;
  line-height: 1.6;
  color: var(--hig-page-text);
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.dp__input.is-valid ~ .dp__icon, .dp__input.is-invalid ~ .dp__icon {
  right: 1.5rem;
}

.dp__input.form-control-plaintext ~ .dp__icon{
  display: none;
}

.dp__menu {
  background: var(--hig-form-bg);
  min-width: 260px;
  font-family: inherit;
  font-size: inherit;
  user-select: none;
  border: 1px solid var(--hig-page-border-medium);
  border-radius: var(--hig-form-control-border-radius);
  transition: opacity .2s ease !important;
}

.dp__menu.dp-menu-appear-enter-from,
.dp__menu.dp-menu-appear-leave-to {
  opacity: 0;
}

.dp__month_year_row {
  padding: 0 .25rem;
}

.dp__action_row {
  padding: .25rem;
  border-radius: var(--hig-form-control-border-radius);
}

</style>
