<template>
  <div class="b-checkbox form-control p-0" :class="{ 'checked': checked, 'unchecked': !checked, 'readonly': readonly, 'round': round, 'no-anim': noAnim }" @click.stop.prevent="toggle" @dblclick.stop.prevent tabindex="0">
    <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <path class="check" d="M15 50 L40 75 L85 25" fill="none" stroke="currentColor" stroke-width="20" stroke-linecap="round"></path>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'b-checkbox',
  props: {
    modelValue: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    round: { type: Boolean, default: false },
    'no-anim': { type: Boolean, default: false }
  },
  data () {
    return {
      checked: this.modelValue
    };
  },
  watch: {
    modelValue: function () {
      this.checked = this.modelValue;
    }
  },
  methods: {
    toggle () {
      if (this.readonly) { return; }
      this.checked = !this.checked;
      this.$emit('update:modelValue', this.checked);
    }
  }
};
</script>

<style scoped>
.b-checkbox{
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  /* background-color: var(--color-secondary); */
  /* border-radius: 2px; */
  cursor: pointer;
  transition: color .2s ease, background-color .2s ease, box-shadow .2s ease;
  position: relative;
  border-radius: min(var(--hig-form-control-border-radius), 3px);
}

.b-checkbox.round{
  border-radius: 50%;
}

.b-checkbox:hover{
}

.b-checkbox.checked{
}

.b-checkbox.checked:hover{
}

.b-checkbox.readonly{
  background-color: var(--hig-form-bg-disabled);
  cursor: initial;
}

.b-checkbox.readonly.checked{
  opacity: .7;
}

.b-checkbox > svg{
  position: absolute;
  top: 2px;
  left: 2px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
}

.b-checkbox.no-anim > svg > .check{
  display: none;
}

.b-checkbox.no-anim.checked > svg > .check{
  display: block;
}

.b-checkbox:not(.no-anim) > svg > .check{
  stroke-dasharray: 110;
  stroke-dashoffset: 110;
}

.b-checkbox:not(.no-anim).checked > svg > .check{
  animation: draw .3s ease .1s forwards;
}

@keyframes draw {
  to { stroke-dashoffset: 0; }
}
</style>
