<template>
  <div class="card">
    <header class="card-header">
      <slot name="header"></slot>
    </header>
    <template v-if="!noBody">
      <div class="card-body">
        <h4 v-if="title" class="card-title">{{title}}</h4>
        <h6 v-if="subTitle" class="card-subtitle text-muted mb-2">{{subTitle}}</h6>
        <slot></slot>
      </div>
    </template>
    <template v-else>
      <slot></slot>
    </template>
    <footer class="card-footer">
      <slot name="footer"></slot>
    </footer>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: undefined },
    'sub-title': { type: String, default: undefined },
    'no-body': { type: Boolean, default: false }
  },
  computed: {}
};
</script>

<style scoped>
.card-header:empty, .card-footer:empty{
  display: none;
}
</style>
