<script setup lang='ts'>
import { ref, computed, inject, watch } from 'vue';
import CnsList from '../cns-list.vue';
import CnsListItem from '../cns-list-item.vue';
import GraphQl from '../../../libs/graphql-client/index.mjs';
import { Fop } from '../../../libs/fop-utils/index.browser.mjs';
import CnsFilterInput from '../cns-filter-input.vue';
import { Installation } from './types';

const props = withDefaults(defineProps<{
  multiple?: boolean;
}>(), {
  multiple: false
});

const value = defineModel<string | string[] | undefined>();
const searchFilter = defineModel<string>('filter', { default: '' });

const $edw: any = inject('$edw');
const error = ref();

const installations = ref<Installation[]>([]);
const installationsLoading = ref<boolean>(false);

async function loadInstallations () {
  installationsLoading.value = true;

  let _fop = new Fop()
    .order('name', 1)
    .paginate(0, 200);

  if (searchFilter.value) {
    const filters = searchFilter.value.split(' ').map((el) => el.trim()).filter((el) => el.length > 0);
    filters.forEach((filter) => {
      _fop = _fop.filter({
        or: [
          { field: 'name', op: '%', val: filter },
          { field: 'desc', op: '%', val: filter }
        ]
      });
    });
  }

  installations.value = await GraphQl.query(`
    Installation_get (fop: $fop, noCache: true) {
      id
      name
      desc
    }
  `, {
    fop: { type: 'FilterOrderPaginate', value: _fop.serialize() }
  }).catch((err) => {
    error.value = $edw.translateError(err);
  });

  installations.value.sort((a, b) => {
    const aSelected = isSelected({ id: a.id });
    const bSelected = isSelected({ id: b.id });
    if (aSelected && !bSelected) { return -1; }
    if (!aSelected && bSelected) { return 1; }
    if (a.name < b.name) { return -1; }
    if (a.name > b.name) { return 1; }
    return 0;
  });

  installationsLoading.value = false;
}

const items = computed(() => installations.value.map((installation) => ({
  id: installation.id,
  label: installation.name,
  caption: installation.desc
})) ?? []);

watch([searchFilter], () => { loadInstallations(); }, { deep: true, immediate: true });

function isSelected (item: any) {
  if (!props.multiple) {
    return item.id === value.value;
  } else {
    return value.value?.includes(item.id);
  }
}

function toggleSelected (item: any) {
  if (!props.multiple) {
    if (item.id === value.value) {
      value.value = undefined;
    } else {
      value.value = item.id;
    }
  } else {
    if (!Array.isArray(value.value)) { value.value = []; }
    if (value.value.includes(item.id)) {
      value.value = value.value.filter((el) => el !== item.id);
    } else {
      value.value = [...value.value, item.id];
    }
  }
}

// function unselectAll () {
//   if (!props.multiple) {
//     value.value = undefined;
//   } else {
//     value.value = [];
//   }
// }
</script>

<template>
  <div class="d-flex flex-column gap-2">
    <div class="">
      <!-- TODO: This is commented cause i cannot test it, uncomment it when tested -->
      <!-- <cns-button v-if="props.multiple" @click="unselectAll" :text="$edw.unselectAll" :disabled="!value?.length" /> -->
      <cns-filter-input
        class="w-100"
        v-model="searchFilter"
        :placeholder="$edw.search + ' (' + $edw.pressEnterToSearch + ')'"
        :loading="installationsLoading"
        :debounce="1500"
      />
    </div>
    <div class="flex-fill" style="height: 60vh;">
      <cns-list :items="items" v-slot="{ item }" class="h-100 overflow-auto">
        <cns-list-item
          icon="meter"
          :label="item.label"
          :caption="item.caption"
          checkbox
          :active="isSelected(item)"
          @click="toggleSelected(item)"
        />
      </cns-list>
    </div>
  </div>
</template>
