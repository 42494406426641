<script setup>
import { computed } from 'vue';
import CnsIcon from './cns-icon.vue';

const props = defineProps({
  text: { type: String, required: true },
  icon: { type: String, default: undefined },
  variant: { type: String, default: 'secondary' },
  size: { type: String, default: 'md' },
  bg: { type: String, default: undefined },
  color: { type: String, default: undefined }
});

const style = computed(() => {
  const s = {};
  if (props.color) { s.color = props.color; }
  if (props.bg) { s.background = props.bg; }
  return s;
});
</script>

<template>
  <span :class="['badge', (props.variant ? `badge-${props.variant}` : ''), (props.size ? `badge-${props.size}` : '')]" :style="style">
    <cns-icon v-if="props.icon" :type="props.icon" class="badge-icon"/>
    <span class="badge-text">{{ props.text }}</span>
  </span>
</template>

<style scoped>
.badge {
  display: flex;
  align-items: center;
  gap: .5em;
  border-radius: 2rem;
  padding: 0 .95em;
  font-size: 0.75em;
  height: 28px;
  font-weight: normal;
  font-variant-numeric: tabular-nums;
}
.badge .badge-icon { font-size: 0.8em; }
.badge .badge-text { line-height: 1rem; }

.badge.badge-lg {
  font-size: 0.85em;
  height: 32px;
}
.badge.badge-lg .badge-icon { font-size: .9em; }

.badge.badge-sm {
  padding: 0 .75em;
  font-size: 0.65em;
  height: 20px;
}
.badge.badge-sm .badge-icon { display: none; }

.badge.badge-xs {
  padding: 0 .48em;
  font-size: 0.55em;
  height: 14px;
}
.badge.badge-xs .badge-icon { display: none; }

.badge.badge-dot {
  padding: 0;
  width: 8px;
  height: 8px;
}
.badge.badge-dot .badge-icon { display: none; }
.badge.badge-dot .badge-text { display: none; }

.badge.badge-primary {
    color: var(--hig-primary-text);
    background: var(--hig-primary);
    border-color: var(--hig-primary-border);
}
.badge.badge-secondary {
    color: var(--hig-secondary-text);
    background: var(--hig-secondary);
    border-color: var(--hig-secondary-border);
}
.badge.badge-success {
    color: var(--hig-success-text);
    background: var(--hig-success);
    border-color: var(--hig-success-border);
}
.badge.badge-info {
    color: var(--hig-info-text);
    background: var(--hig-info);
    border-color: var(--hig-info-border);
}
.badge.badge-warning {
    color: var(--hig-warning-text);
    background: var(--hig-warning);
    border-color: var(--hig-warning-border);
}
.badge.badge-danger {
    color: var(--hig-danger-text);
    background: var(--hig-danger);
    border-color: var(--hig-danger-border);
}
.badge.badge-light {
    color: var(--hig-light-text);
    background: var(--hig-light);
    border-color: var(--hig-light-border);
}
.badge.badge-dark {
    color: var(--hig-dark-text);
    background: var(--hig-dark);
    border-color: var(--hig-dark-border);
}
</style>
