<script setup>
import { computed } from 'vue';
import CnsButton from './cns-button.vue';
import CnsDropdown from './cns-dropdown.vue';

const props = defineProps({
  actions: { type: Array, default: function () { return []; } },
  visible: { type: Number, default: 1 },
  variant: { type: String, default: 'primary' }
});

const visibleButtons = computed(() => props.actions.slice(0, props.visible));
const dropdownButtons = computed(() => props.actions.slice(props.visible));
</script>

<template>
  <div class="d-flex justify-content-end align-items-center gap-1">
    <cns-button
      v-for="(button, i) in visibleButtons" :key="i"
      :text="button.text"
      :icon="button.icon"
      :variant="button.variant || props.variant"
      :disabled="button.disabled"
      :loading="button.loading"
      @click="button.action"
    />

    <cns-dropdown v-if="dropdownButtons.length > 0" :keep-open-on-click="false" opens="left">
      <template #target="{ toggle }">
        <cns-button :variant="props.variant" icon="ellipsis-vertical" @click="toggle"/>
      </template>

      <div class="d-flex flex-column gap-2 p-2">
        <cns-button
          v-for="(button, i) in dropdownButtons" :key="i"
          :text="button.text"
          :icon="button.icon"
          :variant="button.variant || props.variant"
          :disabled="button.disabled"
          :loading="button.loading"
          @click="button.action"
        />
      </div>
    </cns-dropdown>
  </div>
</template>

<style scoped>
.VIIcon {
  font-size: 1.5em;
}
</style>
