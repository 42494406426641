<script lang="ts">
import { inject, ref, computed } from 'vue';
import type { PropType, Ref } from 'vue';

import CnsPage from '../cns-page.vue';
import CnsCard from '../cns-card.vue';
import CnsCardTab from '../cns-card-tab.vue';
import CnsFilterInput from '../cns-filter-input.vue';
import CnsIcon from '../cns-icon.vue';
import CnsDiv from '../cns-div.vue';
import CnsModal from '../cns-modal.vue';
import CnsDataCard from '../../cns-data/cns-data-card.vue';
import { Fop } from '../../../libs/fop-utils/index.browser.mjs';
import CnsList from '../cns-list.vue';
import CnsListItem from '../cns-list-item.vue';
import { CnsTable } from '../cns-table';
import CnsMap from '../cns-map.vue';
import CnsMapMarker from '../cns-map-marker.vue';
import CnsInstallationsListHeader from './cns-installations-list-header.vue';
import CnsInstallationsListStatusIcon from './cns-installations-list-status-icon.vue';
import type {
  CnsInstallationsListAlarm,
  CnsInstallationsListInstallation,
  CnsInstallationsListInstallationWithStats,
  CnsInstallationsListConfig,
  CnsInstallationsListConfigHeader,
  Order
} from './types';
import { useHigBootstrap } from '../../../plugins/hig-bootstrap/index.mjs';

const ICON_BY_ALARM_TYPE = {
  alarm: 'circle-exclamation',
  warning: 'triangle-exclamation'
};
</script>

<script setup lang="ts">
const $edw = inject<any>('$edw');
const $bs = useHigBootstrap();

const props = defineProps({
  installations: { type: Array as PropType<CnsInstallationsListInstallation[]>, required: true },
  closeDataloggerStatusModalAfterClick: { type: Boolean, default: false },
  config: { type: Object as PropType<CnsInstallationsListConfig>, require: false },
  loading: { type: Boolean, default: false }
});

// installation:click is deprecated, use click:installation instead
const emit = defineEmits(['installation:click', 'click:installation', 'click:datalogger-status']);

const headers = computed<CnsInstallationsListConfigHeader[]>(() => {
  const propsHeaders: CnsInstallationsListConfigHeader[] = [...(props.config?.plantList?.headers ?? [])];

  if (!propsHeaders.length) propsHeaders.push({ label: 'installationName', name: 'desc', sortable: true });
  return propsHeaders;
});

const filter = ref('');
const order: Ref<Order> = ref({
  disconnected: props.config?.plantList?.order?.disconnected || 0,
  turnedOff: props.config?.plantList?.order?.turnedOff || 0,
  alarms: props.config?.plantList?.order?.alarms || 0,
  warnings: props.config?.plantList?.order?.warnings || 0,
  ...headers.value.reduce((acc: { [key: string]: number }, curr) => {
    acc[curr.name] = props.config?.plantList?.order?.[curr.name] || 0;
    return acc;
  }, {})
});

const installations = computed<CnsInstallationsListInstallationWithStats[]>(() => props.installations.map((installation) => {
  const dataloggersDisconnected = (installation.dataloggers ?? []).filter((datalogger) => !datalogger.connection.connected);
  const dataloggersTurnedOff = (installation.dataloggers ?? []).filter((datalogger) => !datalogger.onOffState);

  // Remove the alarms coming from disconnected or turned off dataloggers
  // TODO: Keep alarms not associated to any datalogger but associated to the installation
  const realActiveAlarms = (installation.activeAlarms ?? []).filter((alarm) =>
    !dataloggersDisconnected.find((datalogger) => datalogger.sn === alarm?.datalogger?.sn) &&
    !dataloggersTurnedOff.find((datalogger) => datalogger.sn === alarm?.datalogger?.sn)
  );

  // Divide the alarms by type
  const activeAlarms = realActiveAlarms.filter((alarm) => alarm.levelType === 'alarm' || alarm.levelType === 'no-link');
  const activeWarnings = realActiveAlarms.filter((alarm) => alarm.levelType === 'warning');

  return { ...installation, dataloggersDisconnected, dataloggersTurnedOff, activeAlarms, activeWarnings };
}));

const installationsDisplay = computed<CnsInstallationsListInstallationWithStats[]>(() => {
  let _fop = new Fop();

  const filters = [];
  for (const header of headers.value) {
    if (filter.value) { filters.push({ field: header.name, op: '%', val: filter.value }); }
    if (order.value[header.name]) { _fop = _fop.order(header.name, order.value[header.name]); }
  }
  if (filters.length) { _fop = _fop.filter({ or: filters }); }

  if (order.value.disconnected) { _fop = _fop.order('dataloggersDisconnected.length', order.value.disconnected); }
  if (order.value.turnedOff) { _fop = _fop.order('dataloggersTurnedOff.length', order.value.turnedOff); }
  if (order.value.alarms) { _fop = _fop.order('activeAlarms.length', order.value.alarms); }
  if (order.value.warnings) { _fop = _fop.order('activeWarnings.length', order.value.warnings); }

  return _fop.apply(installations.value)
    .filter((installation: CnsInstallationsListInstallationWithStats) => {
      const dlCount = installation.dataloggers?.length ?? 0;
      const dlDisconnectedCount = (installation.dataloggers ?? []).filter((datalogger) => !datalogger.connection.connected).length;
      const isNoLink = dlCount - dlDisconnectedCount === 0;
      return !(props.config?.plantList?.hideNolinks && isNoLink);
    });
});

const installationsCount = computed(() => installations.value?.length ?? 0);
const dataloggersDisconnectedCount = computed(() =>
  installations.value.reduce((acc, installation) => acc + installation.dataloggersDisconnected.length, 0)
);
const installationsAlarmCount = computed(() =>
  installations.value.filter((installation) => installation.activeAlarms.length > 0).length
);
const alarmsCount = computed(() => {
  let res = 0;
  installations.value.forEach((installation) => { res += installation.activeAlarms.length; });
  return res;
});

const dataloggersStatusModal = ref<any>();
async function openDataloggersStatusModal (installation: CnsInstallationsListInstallationWithStats) {
  if ((installation.dataloggers ?? []).length === 0) { return; }
  dataloggersStatusModal.value.open({ installation });
}

const alarmsModal = ref<any>();
async function openActiveAlarms (type: string, alarms: CnsInstallationsListAlarm[]) {
  // if (alarms.length === 0) { return; }
  const title = {
    alarm: $edw.alarmIconTitle,
    warning: $edw.warningIconTitle
  }[type];
  alarmsModal.value.open({ type, title, alarms });
}

const notesModal = ref<any>();
async function openNotes (installation: CnsInstallationsListInstallationWithStats) {
  if (!installation?.notes) { return; }
  notesModal.value.open({ installation });
}

function installationClick (installationId: string) {
  const installation = props.installations.find((installation) => installation.id === installationId);

  emit('installation:click', installation);
  emit('click:installation', { installation });
}

function dataloggerStatusClick (installationId: string, dataloggerSn: string) {
  const installation = props.installations.find((installation) => installation.id === installationId);
  const datalogger = installation?.dataloggers?.find((datalogger) => datalogger.sn === dataloggerSn);

  emit('click:datalogger-status', { installation, datalogger });

  if (props.closeDataloggerStatusModalAfterClick) {
    dataloggersStatusModal.value.close();
  }
}
</script>

<template>
  <cns-page
    full-width
    no-scroll-x
    no-scroll-y
  >
    <template #actions>
      <cns-filter-input
        class="d-none d-lg-block m-1"
        v-model="filter"
        :placeholder="$edw.search + '...'"
        style="width: 200px;"
        :debounce="300"
      />
    </template>
    <cns-card-tab :title="$edw.installationsList">
      <div class="w-100 h-100 d-flex flex-column overflow-hidden gap-2">
        <template v-if="props.loading">
          <div
            class="d-flex justify-content-center align-items-center"
            :style="{ 'min-width': '100%', 'min-height': '100%' }"
          >
            <div class="spinner-border text-primary" role="status" />
          </div>
        </template>
        <template v-else>
          <div class="w-100 d-block d-lg-none p-1">
            <cns-filter-input
                class="w-100"
                v-model="filter"
                :placeholder="$edw.search + '...'"
                style="width: 200px;"
                :debounce="300"
              />
          </div>
          <div v-if="!props.config?.plantList?.hideDataCards" class="w-100 d-none d-lg-flex flex-row gap-3">
            <cns-data-card
              v-if="!props.config?.plantList?.hideInstallationsCount"
              class="flex-grow-1"
              :label="$edw.installationsTotal"
              icon="list"
              :value="installationsCount"
              variant="primary"
              :decimals="0"
            />
            <cns-data-card
              v-if="!props.config?.plantList?.hideDisconnectedDataloggers"
              class="flex-grow-1"
              :label="$edw.dataloggersDisconnectedCount"
              icon="wifi-slash"
              :value="dataloggersDisconnectedCount"
              :decimals="0"
            />
            <cns-data-card
              v-if="!props.config?.plantList?.hideWarnings"
              class="flex-grow-1"
              :label="$edw.installationsAlarm"
              icon="message-exclamation"
              :value="installationsAlarmCount"
              variant="warning"
              :decimals="0"
            />
            <cns-data-card
              v-if="!props.config?.plantList?.hideAlarms"
              class="flex-grow-1"
              :label="$edw.activeAlarms"
              icon="circle-exclamation"
              :value="alarmsCount"
              variant="danger"
              :decimals="0"
            />
          </div>
          <div class="installation-row-header d-flex gap-2 py-2 mt-2">
            <cns-installations-list-header v-for="header in headers" :key="`${header.name}-header`" v-model:order="order[header.name]" :class="{ 'installation-header': header.width == null }" :style="{ width: header.width }">
              <p class="text-truncate m-0">{{ $edw[header.label] }}</p>
            </cns-installations-list-header>
            <cns-installations-list-header v-if="!props.config?.plantList?.hideDisconnectedDataloggers" v-model:order="order.disconnected" class="installation-header-sm">
              <cns-installations-list-status-icon icon="wifi" :title="$edw.connectedIconTitle" />
            </cns-installations-list-header>
            <cns-installations-list-header v-if="!props.config?.plantList?.hideOffDataloggers" v-model:order="order.turnedOff" class="installation-header-sm d-none d-sm-flex">
              <cns-installations-list-status-icon icon="power-off" :title="$edw.onOffIconTitle" />
            </cns-installations-list-header>
            <cns-installations-list-header v-if="!props.config?.plantList?.hideAlarms" v-model:order="order.alarms" class="installation-header-sm">
              <cns-installations-list-status-icon icon="circle-exclamation" :title="$edw.alarmIconTitle" />
            </cns-installations-list-header>
            <cns-installations-list-header v-if="!props.config?.plantList?.hideWarnings" v-model:order="order.warnings" class="installation-header-sm d-none d-sm-flex">
              <cns-installations-list-status-icon icon="triangle-exclamation" :title="$edw.warningIconTitle" />
            </cns-installations-list-header>
          </div>
          <cns-list
            v-slot="{ item }: { item: CnsInstallationsListInstallationWithStats }"
            class="flex-fill"
            :items="installationsDisplay"
            :virtual="true"
            :items-height="42"
            :items-gap="5"
          >
            <cns-card
              no-header
              no-body
              class="installation-row-container overflow-hidden"
              style="height: 42px;"
              @click="installationClick(item.id)"
            >
              <div class="installation-row rounded d-flex gap-2 h-100" :style="{ '--row-margin-color': item.marginColor ?? 'var(--hig-primary)' }">
                <div
                  v-for="header in headers"
                  :key="`${item.id}-${header.name}`"
                  :class="['cursor-pointer', 'd-flex', 'align-items-center', 'overflow-hidden', { 'installation-data': header.width == null }]"
                  :style="{ width: header.width }"
                >
                  <slot :name="`${header.name}-cell`" :item="item">
                    <p class="text-truncate m-0">{{ item[header.name] }}</p>
                  </slot>
                </div>
                <cns-installations-list-status-icon
                  v-if="!props.config?.plantList?.hideNotes && item.notes"
                  class="installation-data-sm h-100 p-2"
                  icon="note"
                  variant="danger"
                  :counter="item.notes ? 1 : 0"
                  :title="$edw.notes"
                  @click.stop="openNotes(item)"
                />
                <cns-installations-list-status-icon
                  v-if="!props.config?.plantList?.hideDisconnectedDataloggers"
                  class="installation-data-sm h-100 p-2"
                  icon="wifi"
                  variant="danger"
                  :counter="(item.dataloggers ?? []).filter((d) => !d.connection.connected).length"
                  :title="$edw.connectedIconTitle"
                  @click.stop="openDataloggersStatusModal(item)"
                />
                <cns-installations-list-status-icon
                v-if="!props.config?.plantList?.hideOffDataloggers"
                class="installation-data-sm h-100 p-2 d-none d-sm-block"
                  icon="power-off"
                  variant="danger"
                  :counter="(item.dataloggers ?? []).filter((d) => !d.onOffState).length"
                  :title="$edw.onOffIconTitle"
                  @click.stop="openDataloggersStatusModal(item)"
                />
                <cns-installations-list-status-icon
                  v-if="!props.config?.plantList?.hideAlarms"
                  class="installation-data-sm h-100 p-2"
                  :icon="ICON_BY_ALARM_TYPE.alarm"
                  :counter="item.activeAlarms.length"
                  :title="$edw.alarmIconTitle"
                  variant="danger"
                  @click.stop="openActiveAlarms('alarm', item.activeAlarms)"
                />
                <cns-installations-list-status-icon
                  v-if="!props.config?.plantList?.hideWarnings"
                  class="installation-data-sm h-100 p-2 d-none d-sm-block"
                  :icon="ICON_BY_ALARM_TYPE.warning"
                  :counter="item.activeWarnings.length"
                  :title="$edw.warningIconTitle"
                  variant="warning"
                  @click.stop="openActiveAlarms('warning', item.activeWarnings)"
                />
              </div>
            </cns-card>
          </cns-list>
        </template>
      </div>
    </cns-card-tab>
    <cns-card-tab :title="$edw.installationsMap">
      <div class="w-100 h-100 d-flex flex-column overflow-hidden gap-3">
        <div class="w-100 d-block d-lg-none p-1">
            <cns-filter-input
              class="w-100"
              v-model="filter"
              :placeholder="$edw.search + '...'"
              style="width: 200px;"
              :debounce="300"
            />
        </div>

        <cns-div class="w-100 flex-fill">
          <template v-if="props.loading">
            <div
              class="d-flex justify-content-center align-items-center"
              :style="{ 'min-width': '100%', 'min-height': '100%' }"
            >
              <div class="spinner-border text-primary" role="status" />
            </div>
          </template>
          <template v-else>
            <cns-map
              :center="{ lat: props.config?.map?.lat || 0, lon: props.config?.map?.lon || 0 }"
              :zoom="props.config?.map?.zoom || 7"
              :options="{ fitBounds: true }"
            >
              <cns-map-marker
                v-for="item in installationsDisplay"
                :key="item.id"
                :pos="{
                  lat: Number(item.lat) || undefined,
                  lon: Number(item.lon) || undefined
                }"
                icon="location-dot"
                :width="props.config?.map?.width ?? 330"
                :color="
                  props.config?.map?.flatColor
                  ? 'var(--hig-primary)'
                  : item.activeAlarms.length > 0
                  ? 'var(--hig-danger)'
                  : item.activeWarnings?.length > 0
                  ? 'var(--hig-warning)'
                  : 'var(--hig-primary)'"
              >
                <slot name="marker-popup" :item="item">
                  <div
                    style="color: var(--hig-black);"
                    class="popup d-flex align-items-center justify-content-start gap-3"
                  >
                    <cns-icon class="cursor-pointer" type="arrow-up-right-from-square" @click="installationClick(item.id)" />
                    <h5 class="m-0 flex-fill text-truncate" :title="item.desc">{{ item.desc }}</h5>
                    <div class="d-flex" style="width: 120px;">
                      <cns-installations-list-status-icon
                        v-if="!props.config?.plantList?.hideNotes"
                        class="installation-status-icon"
                        icon="note"
                        variant="danger"
                        :counter="item.notes?.length || 0"
                        :title="$edw.notes"
                        @click.stop="openNotes(item)"
                      />
                      <cns-installations-list-status-icon
                        class="installation-status-icon"
                        icon="wifi"
                        variant="danger"
                        :counter="(item.dataloggers ?? []).filter((d) => !d.connection.connected).length"
                        :title="$edw.connectedIconTitle"
                        @click.stop="openDataloggersStatusModal(item)"
                      />
                      <cns-installations-list-status-icon
                        class="installation-status-icon"
                        icon="power-off"
                        variant="danger"
                        :counter="(item.dataloggers ?? []).filter((d) => !d.onOffState).length"
                        :title="$edw.onOffIconTitle"
                        @click.stop="openDataloggersStatusModal(item)"
                      />
                      <cns-installations-list-status-icon
                        v-if="!props.config?.plantList?.hideAlarms"
                        class="installation-status-icon"
                        :icon="ICON_BY_ALARM_TYPE.alarm"
                        :counter="item.activeAlarms.length"
                        :title="$edw.alarmIconTitle"
                        variant="danger"
                        @click.stop="openActiveAlarms('alarm', item.activeAlarms)"
                      />
                      <cns-installations-list-status-icon
                        v-if="!props.config?.plantList?.hideWarnings"
                        class="installation-status-icon"
                        :icon="ICON_BY_ALARM_TYPE.warning"
                        :counter="item.activeWarnings.length"
                        :title="$edw.warningIconTitle"
                        variant="warning"
                        @click.stop="openActiveAlarms('warning', item.activeWarnings)"
                      />
                    </div>
                  </div>
                </slot>
              </cns-map-marker>
            </cns-map>
          </template>
        </cns-div>
      </div>
    </cns-card-tab>

    <cns-modal
      ref="dataloggersStatusModal"
      v-slot="{ opt }"
      scrollable
      :title="$edw.dataloggersStatus"
    >
      <div class="d-flex flex-column gap-1">
        <cns-list-item
          v-for="datalogger in (opt?.installation?.dataloggers ?? [])"
          :key="datalogger.sn"
          :label="`${datalogger.name} - ${datalogger.sn}`"
          :caption="`${datalogger.connection.ip ?? '---'}:${datalogger.connection.webPort ?? '---'}`"
          icon="meter"
          @click="dataloggerStatusClick(opt.installation.id, datalogger.sn)"
        >
          <template #suffix>
            <div class="d-flex gap-2">
              <cns-icon
                :type="datalogger.connection.connected ? `wifi fw lg` : `wifi-slash fw lg`"
                :color="datalogger.connection.connected ? 'var(--hig-success)' : 'var(--hig-danger)'"
                :title="datalogger.connection.connected ? $edw.dataloggerConnected : $edw.dataloggerDisconnected"
              />
              <cns-icon
                :type="`power-off fw lg`"
                :color="datalogger.onOffState ? 'var(--hig-success)' : 'var(--hig-danger)'"
                :title="datalogger.onOffState ? $edw.dataloggerOn : $edw.dataloggerOff"
              />
            </div>
          </template>
        </cns-list-item>
      </div>
    </cns-modal>

    <cns-modal
      ref="alarmsModal"
      v-slot="{ opt }"
      size="lg"
    >
      <div :style="{ height: $bs.maxSm ? '80vh' :  '50vh' }">
        <cns-table
          :cols="[
            { name: 'name', label: $edw.name, sortable: true },
            { name: 'desc', label: $edw.desc, sortable: true },
            { name: 'start', label: $edw.start, sortable: true },
          ]"
          :data="opt?.alarms ?? []"
        />
      </div>
    </cns-modal>

    <cns-modal
      ref="notesModal"
      :title="$edw.notes"
      v-slot="{ opt }"
      size="lg"
    >
    <p>{{ opt.installation.notes }}</p>
    </cns-modal>
  </cns-page>
</template>

<style scoped>
.installation-row-header {
  padding-left: 1.3em;
  padding-right: .6em;
  background-color: var(--hig-page-bg);
}

.installation-row {
  cursor: pointer;
  border-left: 10px solid var(--row-margin-color);
  padding-right: .1em;
}

.installation-row:hover {
  background: var(--hig-page-bg-hover);
}

.installation-header {
  flex: 1 1 200px;
}
.installation-header-sm {
  flex: 0 0 40px;
  margin-left: 0.25em;
  justify-content: end; /* space-between */
}

.installation-data {
  flex: 1 1 200px;
  padding-left: 0.5em;
}

.installation-data-sm {
  flex: 0 0 40px;
  margin-left: 0.25em;
  overflow: visible;
  justify-content: flex-end;
}

.installation-status-icon {
  flex: 0 0 30px;
}

.popup h4 {
  width: 170px;
  word-break: break-word;
}

.datalogger-status-row:hover {
  background-color: var(--hig-page-bg-hover);
}
</style>
