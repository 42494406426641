<script setup>
import CnsIcon from '../../../cns/cns-icon.vue';
import { ref, watch } from 'vue';
import GaugeSimple from './gauge-simple.vue';
import Utils from '../../../../libs/utils/index';

const props = defineProps({
  value: { type: [Number, String, Object, Array], default: null },
  step: { type: Number, default: 0.1 },
  min: { type: Number, default: null },
  max: { type: Number, default: null },
  label: { type: String, default: '' },
  icon: { type: String, default: null },
  um: { type: String, default: '' },
  loading: { type: Boolean, default: false },
  decimals: { type: Number, default: undefined },
  title: { type: String, default: '' }
});
const emit = defineEmits(['set:value']);

const _value = ref();
watch(() => props.value, () => { _value.value = props.value; }, { immediate: true });

function increment () {
  if (props.loading) { return; }
  _value.value = _value.value + props.step;
}

function decrement () {
  if (props.loading) { return; }
  _value.value = _value.value - props.step;
}

function reset () {
  if (props.loading) { return; }
  _value.value = props.value;
}

function confirm () {
  if (props.loading) { return; }
  emit('set:value', Utils.round(_value.value, props.decimals));
}
</script>
<template>
  <div class="row w-100 row-cols-3 position-relative">
    <button
      class="btn col-2"
      :disabled="props.loading"
      @click="decrement()"
    >
      <cns-icon type="minus lg fw" />
    </button>
    <div class="col-8 d-flex justify-content-center">
      <GaugeSimple
        :value="_value"
        :max="props.max"
        :min="props.min"
        :label="props.label"
        :icon="props.icon"
        :um="props.um"
        :loading="props.loading"
        :decimals="props.decimals"
        class="m-auto"
        :title="props.title"
        max-width="350px"
      />
    </div>
    <button
      class="btn col-2"
      :disabled="props.loading"
      @click="increment()"
    >
      <cns-icon type="plus lg fw" />
    </button>
    <div class="position-absolute bottom-0 end-0 me-4 d-flex">
      <button
        v-if="props.value !== _value"
        class="btn text-muted ab px-1"
        :disabled="props.loading"
        @click="reset()"
      >
        <cns-icon type="arrow-rotate-left lg fw" />
      </button>
      <button
        v-if="props.value !== _value"
        class="btn text-success ab px-1"
        :disabled="props.loading"
        @click="confirm()"
      >
        <cns-icon type="check lg fw" />
      </button>
    </div>
  </div>
</template>
