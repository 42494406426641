/* ==============================================
                TILE LAYER and WMS
    ================================================ */

export const osm = {
  url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
  id: 'mapbox/streets-v1',
  maxZoom: 19,
  attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
};

export const dark = {
  url: 'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png',
  attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
  subdomains: 'abcd',
  maxZoom: 19
};

export const googleStreets = {
  url: 'http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
  maxZoom: 20,
  subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
};

export const googleSat = {
  url: 'http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
  maxZoom: 20,
  subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
};

export const usgsImageryTopo = {
  url: 'https://basemap.nationalmap.gov/arcgis/rest/services/USGSImageryTopo/MapServer/tile/{z}/{y}/{x}',
  maxZoom: 20,
  attribution: 'Tiles courtesy of the <a href="https://usgs.gov/">U.S. Geological Survey</a>'
};

/* ==============================================
                  LAYER CONTROL
================================================ */
export const baseMaps = {
  OSM: osm,
  Dark: dark,
  'Google Street': googleStreets,
  'Google Satellite': googleSat,
  'USGS Imagery Topo': usgsImageryTopo
};
