import { ref, watch, createApp } from 'vue';
import type { Ref } from 'vue';

interface vModelOptions {
  get: () => any;
  set: (value: any) => void;
  deep?: boolean;
  onSourceUpdate?: (value: any) => void;
}

function vModel <Type> ({ get, set, deep, onSourceUpdate }: vModelOptions): Ref<Type> {
  if (typeof get !== 'function') { throw new Error('get must be a function'); }
  if (typeof set !== 'function') { throw new Error('set must be a function'); }

  let skipWatchMV = false;
  let skipWatchV = false;

  // Initialize value with a unique symbol so that the watcher
  // is triggered on first execution even if the get value is `undefined`
  const value = ref<Type | symbol>(Symbol('A unique symbol'));

  watch(value, (newVal) => {
    if (skipWatchV) { skipWatchV = false; return; }
    skipWatchMV = true;
    set(newVal);
  }, { deep });

  watch(get, (newVal) => {
    if (skipWatchMV) { skipWatchMV = false; return; }
    skipWatchV = true;
    value.value = newVal;
    if (typeof onSourceUpdate === 'function') { onSourceUpdate(value.value); }
  }, { deep, immediate: true });

  return (value as Ref<Type>);
}

function createStandaloneComponent (render: any, provides: any = {}) {
  const app = createApp({ render });

  const provideKeys = [...Object.getOwnPropertyNames(provides), ...Object.getOwnPropertySymbols(provides)];
  for (const key of provideKeys) { app.provide(key, provides[key]); }

  return app;
}

export type {
  vModelOptions
};

export default {
  vModel
};

export {
  vModel,
  createStandaloneComponent
};
