/* eslint-disable no-control-regex, camelcase */
'use strict';

/**
 * @namespace CalculusLibrary
 * @description A library for performing basic arithmetic operations
 * @author Michele Debortoli
 */

/**
 * @typedef {object} calculus
 * @alias calculus
 * @memberof Calculus
 * @description An instance of the {@link Calculus} class.
 */

/**
 * @typedef {number|NumLike|calculus|Array.<calculus,number,NumLike>} NumericInput
 * @alias NumericInput
 * @memberof Calculus
 * @description A valid numeric input. Should pass the control of {@link Calculus.getValid}.
 * @example
 * 234 // valid
 * 234.546 // valid
 * new Calculus(3) // valid, for {@link calculus} objects they're internal cached value is silently taken
 * new Calculus(3,4) // not valid, there's no internal cached value
 * new Calculus(3,4).sum() // valid, internal value is populated by the {@link Calculus#sum} method
 * [3,4,5] // valid
 * [3, new Calculus(4), "5"] // valid
 */

/**
 * @typedef {string} NumLike
 * @alias NumLike
 * @memberof Calculus
 * @description A string that is strictly parsable into a valid number.
 * @example
 * '234.64' // valid
 * '234.64a' // not valid, not strictly parsable into a valid number
 * '234,64' // not valid, not strictly parsable into a valid number
 * '100.000,4353' // not valid, this is a {@link FormattedNumlikeString}
 */

/**
 * @typedef {string} FormattedNumlikeString
 * @alias FormattedNumlikeString
 * @memberof Calculus
 * @description A string formatted according to user preferences.
 * @example
 * '1 000.465' // valid
 * '1 000,654' // valid
 * '1000.654' // valid
 * '1000,4' // valid
 * '1,000,000.2' // valid
 * '1.000.000,007' // valid
 * '1.00,132' // not valid
 * '1 0 000,42 43' // not valid
 */

/**
 * @typedef {string} DecimalFormat
 * @memberof Calculus
 * @alias DecimalFormat
 * @description Decimal format that can be specified for {@link Calculus.format}, {@link Calculus#value}, {@link Calculus#result}.
 * @property {string} 'dyn&ltmin&gt:&ltmax&gt' - A dynamic format where the trailing 0 are trimmed. &ltmin&gt and &ltmax&gt corresponds to the relative values of decimal digits admitted.
 * @property {string} 'raw' - Straightforward conversion of the value to a formatted string but without rounding.
 * @property {string|number} &ltnumber&gt - Any integer number greater than or equal to 0.
 */

/**
 * @typedef {string} Formulae
 * @alias Formulae
 * @memberof Calculus
 * @description A string representing a formulae to be parsed. Can be composed of named parameters that are substitued on evaluation. Named parameters can be composed of letters, digits and <code>_</code> signs. In order not to confuse digits of named parameters with actual numbers, names that comprise numbers must be enclosed in <code>$</code> signs. Operations allowed for now are:
 * <ul>
 * <li><code>+</code> addition</li>
 * <li><code>-</code> subtraction</li>
 * <li><code>/</code> division</li>
 * <li><code>(*|x)</code> multiplication</li>
 * <li><code>(^|**)</code> exponentiation</li>
 * </ul>
 * @example
 * 'n^k'; // parameter n elevated to parameter k
 * '$n$^$k$'; // same as before
 * '1*2'; // (1 times 2) == 2
 * '$1$*2'; // parameter 1 times 2
 * 'abc1+abc_2'; // invalid, parameters with digits must be enclosed in $
 * '[-(n_k-m_k)-{f_k+g_k}]^k' // the use of square, curly or round parenthesis is interchangeable
 */

/**
 * @typedef {string} UserFormat
 * @memberof Calculus
 * @alias UserFormat
 * @description Availiable user formats. Defaults to `'.'`.
 * @property {string} - `'1 000 000.000'`
 * @property {string} - `'1 000 000,000'`
 * @property {string} - `'1,000,000.000'`
 * @property {string} - `'1.000.000,000'`
 * @property {string} - `','`
 * @property {string} - `'.'`
 */

/**
 * @typedef {object} internals
 * @private
 * @alias calculuscache
 * @memberof Calculus
 * @description A hidden cached object containing value and series property of a {@link calculus}.
 * @property {number} value - The value of a {@link calculus} in single-valued mode or the result of the last operation in series mode.
 * @property {number[]} series - The array containing the series values.
 * @property {number} mode - 1 if initialized in series mode, 0 if single-valued mode.
 */

/**
 * @member Calculus
 * @memberof CalculusLibrary
 * @description A preconfigured {@link Calculus} constructor with the default options.
 */

/**
 * @member WeakCalculus
 * @memberof CalculusLibrary
 * @description A preconfigured {@link Calculus} constructor with the default options.
 */

const DYN_SLICE = /0+$/;
const WHITE_SPACES = /\s+/g;
const DEFAULT_TOLERANCE = 1e-3;
const TRIM_SPACES = /^\s+|\s+$/g;
const SIGN_DETECTION = /^(\+|-)$/;
const DYN_FORMAT = /dyn(\d+):(\d+)/i;
const IMPLICIT_PLUSMINUS = /(^|\[)(\+|-)/g;
const NULLSET = new Set([null, undefined]);
const PRIMITIVE = new Set(['string', 'number']);
const PARAMETERS = /(\$[a-zA-Z0-9_]+\$|[a-zA-Z_]+)/g;
const PARENTHESIS = { LEFT: /[({]/g, RIGHT: /[)}]/g };
const FALSY = new Set(['', '0', 0, null, undefined, false]);
const OPERATIONS = /(\*\*?|\+|-|\/|:|\^|x(?![a-z_\]$]+))/gi;
const FORMATTER_REGEXP = /(\d)(?=(?:\d{3})+(?!\d))|(?:(\.)\d+)/g;
const MODES = { 1: 'series', 0: 'value', value: 0, series: 1 };
const SEPARATOR_RE = new Map([['.', /\./g], [',', /,/g], ['', /\x00/g], [' ', / /g]]);
const SEPARATOR_TEST = new Map([[' .', /^-?\d{1,3}( \d{3})*(\.\d+)?$/], [' ,', /^-?\d{1,3}( \d{3})*(,\d+)?$/], [',.', /^-?\d{1,3}(,\d{3})*(\.\d+)?$/], ['.,', /^-?\d{1,3}(\.\d{3})*(,\d+)?$/], ['.', /^-?\d+(\.\d+)?$/], [',', /^-?\d+(,\d+)?$/]]);

/**
 * @description Function used to create a {@link Calculus} constructor with specific formatting and parsing options.
 * @method
 * @alias CalculusFactory
 * @memberof CalculusLibrary
 * @param {string} numFormat
 * @param {number} decimals
 * @param {(null|undefined|string|number)} nullFormat
 * @param {Boolean} invalidOperandsRaiseErrors
 * @returns Returns a new {@link Calculus} class.
 */
function CalculusGenerator (numFormat = '.', decimals = 2, nullFormat = '---', invalidOperandsRaiseErrors = false) {
  let DEFAULT_DECIMALS, UNDEFINED, THOU_SEP, DEC_SEP, SKIP_INVALID_OPERANDS;

  UNDEFINED = '---';
  DEFAULT_DECIMALS = 2;
  SKIP_INVALID_OPERANDS = true;

  const STORAGE = new WeakMap();

  /**
   * Constructor for {@link calculus}. If a single parameter is supplied the returned obj operates in single-valued mode.
   * If multiple parameters or an array are supplied instead, the calculus operate in series mode. The `new` keyword can be omitted.
   * @constructor
   * @alias Calculus
   * @param {...NumericInput} [num]
   * @return {calculus}
   * @example
   * new Calculus(1); // single-valued mode
   * new Calculus(new Calculus(12,3,5).sum()); // single-valued mode
   * new Calculus(1,2,3); // series mode
   * new Calculus([1,2,3]); // series mode
   * new Calculus(3,[1],[7,8,5]) // series mode
   */
  function Calculus () {
    let valid, value, series, mode;

    if (arguments.length > 1) {
      series = valid = core_methods.getValid(arguments);
      mode = MODES.series;
    } else {
      if (arguments[0] instanceof Calculus) return arguments[0];

      if (core_methods.isNumber(arguments[0])) {
        value = valid = arguments[0];
        mode = MODES.value;
      } else if (Array.isArray(arguments[0])) {
        series = valid = core_methods.getValid(arguments[0]);
        mode = MODES.series;
      }
    }

    if (!(this instanceof Calculus)) {
      // If the constructor is called without the "new" keyword try to instantiate a new object with almost the same arguments.
      // As a matter of fact, arguments are already purged in this case.
      return new Calculus(valid);
    } else {
      /**
       * @member {internals} cache
       * @memberof Calculus
       * @instance
       * @private
       */
      STORAGE.set(this, { value: value, series: series || [], mode: mode });
      return this;
    }
  }

  // Static methods. Mostly return a Calculus object.
  // Make no reference to "this" scope keyword.
  const static_methods = {
    /**
     * Compute the maximum value upon a list of numeric values supplied. Returns a {@link calculus} holding the result.
     * @method
     * @memberof Calculus
     * @param {...NumericInput} num
     * @return {calculus}
     * @example
     * Calculus.max(1,1,[2,7,3]).value() === 7;
     * Calculus.max(1,1,2,7,3).value() === 7;
     * Calculus.max([1,1,2,7,3]).value() === 7;
     */
    max () {
      const valid = core_methods.getValid(arguments);
      if (!valid.length) return new Calculus();
      return new Calculus(Math.max.apply(undefined, valid));
    },

    /**
     * Compute the minimum value upon a list of numeric values supplied. Returns a {@link calculus} holding the result.
     * @method
     * @memberof Calculus
     * @param {...NumericInput} num
     * @return {calculus}
     * @example
     * Calculus.min(-1,1,[2,7,3]).value() === -1;
     * Calculus.min(1,1,-2,7,3).value() === -2;
     * Calculus.min([1,1,2,-7,3]).value() === -7;
     */
    min () {
      const valid = core_methods.getValid(arguments);
      if (!valid.length) return new Calculus();
      return new Calculus(Math.min.apply(undefined, valid));
    },

    /**
     * Compute the sum of the numeric values supplied in the list. Returns a {@link calculus} holding the result.
     * @method
     * @memberof Calculus
     * @param {...NumericInput} num
     * @return {calculus}
     * @example
     * gfdsf
     */
    sum () {
      const valid = core_methods.getValid(arguments);
      if (!valid.length) return new Calculus();
      return new Calculus(core_methods.sum(valid));
    },

    /**
     * Compute the subtraction of the numeric values supplied in the list. Returns a {@link calculus} holding the result.
     * @method
     * @memberof Calculus
     * @param {...NumericInput} num
     * @return {calculus}
     */
    deduct () {
      const valid = core_methods.getValid(arguments);
      if (!valid.length) return new Calculus();
      return new Calculus(core_methods.deduct(valid));
    },

    /**
     * Divide multiple operands and returns a {@link calculus} holding the result.
     * @method
     * @memberof Calculus
     * @param {...NumericInput} num
     * @return {calculus}
     * @example
     * Calculus.divide([100,5,20]) // result is 1
     * Calculus.divide(100,20,2) // result is 2.5
     */
    divide () {
      const valid = core_methods.getValid(arguments);
      if (!valid.length) return new Calculus();
      return new Calculus(core_methods.divide(valid));
    },

    /**
     * Multiply the operands supplied as parameters and returns a {@link calculus} holding the result.
     * @method
     * @memberof Calculus
     * @param {...NumericInput} num
     * @return {calculus}
     */
    multiply () {
      const valid = core_methods.getValid(arguments);
      if (!valid.length) return new Calculus();
      return new Calculus(core_methods.multiply(valid));
    },

    /**
     * Compute exponentiation and returns a {@link calculus} holding the result.
     * @method
     * @memberof Calculus
     * @alias Calculus.exp
     * @param {number} base
     * @param {number} exponent
     * @return {calculus}
     */
    exponentiation (base, exponent) {
      const valid = core_methods.getValid(base, exponent);
      if (valid.length !== 2) return new Calculus();
      return new Calculus(core_methods.exponentiation(valid[0], valid[1]));
    },

    /**
     * Compute the average upon a list of numeric values supplied. Returns a {@link calculus} holding the result.
     * @method
     * @memberof Calculus
     * @param {...NumericInput} num
     * @return {calculus}
     */
    average () {
      const valid = core_methods.getValid(arguments);
      if (!valid.length) return new Calculus();
      return new Calculus(core_methods.average(valid));
    },

    /**
     * Compute the variance upon a list of numeric values supplied. Returns a {@link calculus} holding the result.
     * @method
     * @memberof Calculus
     * @param {...NumericInput} num
     * @return {calculus}
     */
    variance () {
      const valid = core_methods.getValid(arguments);
      if (!valid.length) return new Calculus();
      return new Calculus(core_methods.variance(valid));
    },

    /**
     * Compute the standard deviation upon a list of numeric values supplied. Returns a {@link calculus} holding the result.
     * @method
     * @memberof Calculus
     * @param {...NumericInput} num
     * @return {calculus}
     */
    deviation () {
      const valid = core_methods.getValid(arguments);
      if (!valid.length) return new Calculus();
      return new Calculus(core_methods.deviation(valid));
    },

    /**
     * Compute a summation using the formula parameter as operand for every integer index comprised between startIndex and stopIndex. The current value of the index can be accessed through the `k` literal. If the formula contains literal expressions the value are supplied through the corresponding value of the parameters option. Returns a {@link calculus} holding the result.
     * @method
     * @memberof Calculus
     * @param {Formulae} formula - Expression to compute at every iteration.
     * @param {number} startIndex - Start index.
     * @param {number} stopIndex - Stop index.
     * @param {object} [parameters] - An array of numerical values.
     * @return {calculus}
     *//**
      * Compute a summation cycling over the operands option and parsing the formula parameter for every iteration. The current iteration can be accessed through the `k` literal. If the formula contains literal expressions the value are supplied through the corresponding value of the k-th operand. Fixed values can be accessed through the fixed optional parameter. Returns a {@link calculus} holding the result.
      * @method
      * @memberof Calculus
      * @param {Formulae} formula - Multiple numeric parameters.
      * @param {object[]} operands - An array of numerical values.
      * @param {object} [fixed] - An array of numerical values.
      * @return {calculus}
      */
    summation (formula) {
      if (typeof formula === 'string' && core_methods.isNumber(arguments[1]) && core_methods.isNumber(arguments[2])) {
        const parameters = arguments[3];
        const i1 = parseInt(arguments[1]);
        const i2 = parseInt(arguments[2]);
        return new Calculus(core_methods.summation(formula, Math.min(i1, i2), Math.max(i1, i2), parameters));
      } else if (typeof formula === 'string' && arguments[1] && Array.from(arguments[1]).length) {
        const parameters = arguments[2];
        const operands = arguments[1];
        return new Calculus(core_methods.summation2(formula, operands, parameters));
      } else {
        return new Calculus();
      }
    },

    /**
     * Parses an expression substituting literal expressions with the corresponding value in parameters option and compute the result. Returns a {@link calculus} holding the result.
     * @function
     * @memberof Calculus
     * @param {Formulae} formula - Expression to be resolved.
     * @param {object} [parameters] - A key value object containing the literal values to substitute during the parsing process.
     * @return {calculus}
     */
    resolve (expression) {
      if (typeof expression === 'string') arguments[0] = support_functions.parseFormula(expression, arguments[1]);
      return new Calculus(core_methods.resolve.apply(null, arguments));
    },

    /**
     * Compare to operands and determine if they are equal up to configurable amount of tolerance.
     * @function
     * @memberof Calculus
     * @alias equals
     * @static
     * @param {number} member1 - One of the member of the comparison.
     * @param {number} member2 - One of the member of the comparison.
     * @param {number} [tolerance=0.001] - Configurable tolerance.
     * @return {boolean}
     */
    safeEquals (member1, member2) {
      let members, tolerance;
      members = Array.prototype.slice.call(arguments, 0, 2);
      members = core_methods.getValid(members);

      if (members.length !== 2) return undefined;

      if (arguments.length > 2) {
        if (core_methods.isNumber(arguments[2])) {
          tolerance = +arguments[2];
        } else {
          return undefined;
        }
      } else {
        tolerance = DEFAULT_TOLERANCE;
      }

      return Math.abs(members[0] - members[1]) <= tolerance;
    },

    /**
     * Round a numeric value to the desired degree. Rounding is implemented away from 0.
     * @method
     * @memberof Calculus
     * @param {number} num - The value to round.
     * @param {number} decimals - Number of decimal digits to keep, must be greater than or equal to 0.
     * @returns {calculus}
     */
    round (num, decimals) {
      if (!(core_methods.isNumber(num) && core_methods.isNumber(decimals) && +decimals >= 0)) return new Calculus();
      return new Calculus(core_methods.round(num, parseInt(decimals)));
    },

    /**
     * Format a number upon user preferences specificied with {@link CalculusFactory}.
     * @method
     * @memberof Calculus
     * @param {number} num - The numerical value to format.
     * @param {DecimalFormat} [options] - The number of decimal digits.
     * @returns {string}
     */
    format (num) {
      if (!core_methods.isNumber(num)) return UNDEFINED;
      return core_methods.format.apply(undefined, arguments);
    }
  };

  // Static methods useful to configure the global behaviour of a specific Calculus instance.
  const conf_methods = {
    /**
     * Set the default value of decimal digits common to every {@link calculus} generated by the {@link Calculus} constructor upon which the method is called.
     * @method
     * @memberof Calculus
     * @param {number} decimals - Number of decimal digits to keep, must be greater than or equal to 0.
     * @returns {Calculus}
     */
    setDecimals (num) {
      if (core_methods.isNumber(num)) DEFAULT_DECIMALS = +num;
      return Calculus;
    },

    /**
     * Set the format used when displaying values through the {@link Calculus.format}, {@link Calculus#result} or {@link Calculus#value} methods. Returns the same {@link Calculus} constructor the method is called upon.
     * @method
     * @memberof Calculus
     * @param {UserFormat} format - User format from the {@link Format} type. If invalid defaults to ".".
     * @returns {Calculus}
     */
    setFormat (format) {
      switch (format) {
        case '1 000 000.000': THOU_SEP = ' '; DEC_SEP = '.'; // SEPARATOR_TEST = /^\d{1,3}( \d{3})?(\.\d+)?$/;
          break;
        case '1 000 000,000': THOU_SEP = ' '; DEC_SEP = ','; // SEPARATOR_TEST = /^\d{1,3}( \d{3})?(,\d+)?$/;
          break;
        case '1,000,000.000': THOU_SEP = ','; DEC_SEP = '.'; // SEPARATOR_TEST = /^\d{1,3}(,\d{3})?(\.\d+)?$/;
          break;
        case '1.000.000,000': THOU_SEP = '.'; DEC_SEP = ','; // SEPARATOR_TEST = /^\d{1,3}(\.\d{3})?(,\d+)?$/;
          break;
        case ',':
        case '.': THOU_SEP = ''; DEC_SEP = format; // SEPARATOR_TEST = format == "." ? /^\d+(\.\d+)?$/ : /^\d+(,\d+)?$/;
          break;
        default: THOU_SEP = ''; DEC_SEP = '.'; // SEPARATOR_TEST = /^\d+(\.\d+)?$/;
      }
      return Calculus;
    },

    /**
     * Set the format used when displaying invalid values through the {@link Calculus.format}, {@link Calculus#result} or {@link Calculus#value} methods. Returns the same {@link Calculus} constructor the method is called upon.
     * @method
     * @memberof Calculus
     * @param {string} nullable - Is converted to string anyway.
     * @returns {Calculus}
     */
    setNullFormat (nullable) {
      UNDEFINED = String(nullable);
      return Calculus;
    },

    /**
     * Get the current {@link UserFormat} as a string that can be transformed into a regex via `new Regexp` or used as the pattern attribute for an <code>&ltinput&gt</code> tag.
     * @method
     * @memberof Calculus
     * @returns {string}
     */
    getFormatRegex () {
      return SEPARATOR_TEST.get(THOU_SEP + DEC_SEP).toString().slice(1, -1);
    },

    /**
     * Get the current {@link UserFormat} as an object with the properties `thou` and `dec` indicating respectively the thousands separator and the decimals separator (possibly in Regex format).
     * @method
     * @memberof Calculus
     * @param {boolean} [regex=false] - Return `thou` and `dec` properties in regulare expression format.
     * @returns {object}
     */
    getSeparators (regex) {
      if (regex) return { thou: new RegExp(SEPARATOR_TEST.get(THOU_SEP)), dec: new RegExp(SEPARATOR_RE.get(DEC_SEP)) };
      return { thou: THOU_SEP, dec: DEC_SEP };
    },

    /**
     * Set the internal flag that controls the behaviour when validating numeric inputs. If true an error is raised upon finding an invalid numerica value. If false invalid values are simply discarded. When called without parameters get the internal flag that controls the behaviour when validating numeric inputs.
     * @method
     * @memberof Calculus
     * @param {boolean} [flag] - If true an error is raised upon finding an invalid numeric value.
     * @returns {boolean}
     */
    safetyChecks (flag) {
      if (!arguments.length) return SKIP_INVALID_OPERANDS;
      SKIP_INVALID_OPERANDS = FALSY.has(flag);
      return SKIP_INVALID_OPERANDS;
    }
  };

  // Prototype methods. Strongly rely on "this" scope keyword and therefore must be called with the right scope
  const proto_methods = {
    /**
     * Identify the max value of the series when {@link calculus} is initialized in series-mode. It store the result in value cache. Chainable method. Has no effect in single-valued mode.
     * @instance
     * @memberof Calculus
     * @return {calculus} The same {@link calculus} the method is called on.
     */
    max () {
      if (STORAGE.get(this).mode === MODES.series) {
        STORAGE.get(this).value = Math.max.apply(undefined, STORAGE.get(this).series);
      }
      return this;
    },

    /**
     * Identify the min value of the series when {@link calculus} is initialized in series-mode. It store the result in value cache. Chainable method. Has no effect in single-valued mode.
     * @instance
     * @memberof Calculus
     * @return {calculus} The same {@link calculus} the method is called on.
     */
    min () {
      if (STORAGE.get(this).mode === MODES.series) {
        STORAGE.get(this).value = Math.min.apply(undefined, STORAGE.get(this).series);
      }
      return this;
    },

    /**
     * Computes the sum of the valid parameters with the value held in the cache (if any valid) and overwrite that cached value with the result of the operation. When called without parameters, the method takes the sum of the operands in the series when {@link calculus} is initialized in series-mode and stores the result in the value cache; has no effect in single-valued mode. Chainable method.
     * @instance
     * @memberof Calculus
     * @param {...NumericInput} [num]
     * @return {calculus} The same {@link calculus} the method is called on.
     */
    sum () {
      if (arguments.length) {
        let args;
        const current = STORAGE.get(this).value;
        if (core_methods.isNumber(current)) {
          args = core_methods.getValid(arguments);
          args.splice(0, 0, current);
        } else {
          return this;
        }
        STORAGE.get(this).value = core_methods.sum(args);
      } else if (STORAGE.get(this).mode === MODES.series) {
        STORAGE.get(this).value = core_methods.sum(STORAGE.get(this).series);
      }
      return this;
    },

    /**
     * Computes the difference of the valid parameters and the value held in the cache (if any valid) and overwrite that cached value with the result of the operation. When called without parameters, the method computes the difference of the operands in the series when {@link calculus} is initialized in series-mode and stores the result in the value cache. Chainable method.
     * @instance
     * @memberof Calculus
     * @param {...NumericInput} [num]
     * @return {calculus} The same {@link calculus} the method is called on.
     *//**
      * Same as {@link Calculus#deduct}.
      * @instance
      * @memberof Calculus
      * @alias sub
      */
    deduct () {
      if (arguments.length) {
        let args;
        const current = STORAGE.get(this).value;
        if (core_methods.isNumber(current)) {
          args = core_methods.getValid(arguments);
          args.splice(0, 0, current);
        } else {
          return this;
        }
        STORAGE.get(this).value = core_methods.deduct(args);
      } else if (STORAGE.get(this).mode === MODES.series) {
        STORAGE.get(this).value = core_methods.deduct(STORAGE.get(this).series);
      }
      return this;
    },

    /**
     * Computes the division of the valid parameters with the value held in the cache (if any valid) and overwrite that cached value with the result of the operation. When called without parameters, the method computes the division of the operands in the series when {@link calculus} is initialized in series-mode and stores the result in the value cache. Without parameters has no effect in single-valued mode. Chainable method.
     * @instance
     * @memberof Calculus
     * @param {...NumericInput} [num]
     * @return {calculus} The same {@link calculus} the method is called on.
     */
    divide () {
      if (arguments.length) {
        let args;
        const current = STORAGE.get(this).value;
        if (core_methods.isNumber(current)) {
          args = core_methods.getValid(arguments);
          args.splice(0, 0, current);
        } else {
          return this;
        }
        STORAGE.get(this).value = core_methods.divide(args);
      } else if (STORAGE.get(this).mode === MODES.series) {
        STORAGE.get(this).value = core_methods.divide(STORAGE.get(this).series);
      }
      return this;
    },

    /**
     * Computes the multiplication of the valid parameters with the value held in the cache (if any valid) and overwrite that cached value with the result of the operation.  When called without parameters, the method computes the multiplication of the operands in the series when {@link calculus} is initialized in series-mode and stores the result in the value cache. Without parameters has no effect in single-valued mode. Chainable method.
     * @instance
     * @memberof Calculus
     * @param {...NumericInput} [num]
     * @return {calculus} The same {@link calculus} the method is called on.
     */
    multiply () {
      if (arguments.length) {
        let args;
        const current = STORAGE.get(this).value;
        if (core_methods.isNumber(current)) {
          args = core_methods.getValid(arguments);
          args.splice(0, 0, current);
        } else {
          return this;
        }
        STORAGE.get(this).value = core_methods.multiply(args);
      } else if (STORAGE.get(this).mode === MODES.series) {
        STORAGE.get(this).value = core_methods.multiply(STORAGE.get(this).series);
      }
      return this;
    },

    /**
     * Computes the raise of the internal cached value to the power of exp parameter and store the result in the value cache. Chainable method.
     * @instance
     * @memberof Calculus
     * @alias Calculus#exp
     * @param {number} exp - The exponent to raise the cached value to.
     * @return {calculus} The same {@link calculus} the method is called on.
     */
    exponentiation (exp) {
      const current = STORAGE.get(this).value;
      if (core_methods.getValid(current, exp).length !== 2) {
        STORAGE.get(this).value = undefined;
      } else {
        STORAGE.get(this).value = core_methods.exponentiation(current, exp);
      }
      return this;
    },

    /**
     * When {@link calculus} is initialized in series-mode computes the average of the values in the internal cached series and saves the result as the cached value. Chainable method. Has no effect in single-valued mode.
     * @instance
     * @memberof Calculus
     * @return {calculus} The same {@link calculus} the method is called on.
     */
    average () {
      if (STORAGE.get(this).mode === MODES.series) {
        STORAGE.get(this).value = core_methods.average(STORAGE.get(this).series);
      }
      return this;
    },

    /**
     * When {@link calculus} is initialized in series-mode computes the variance of the values in the internal cached series and saves the result as the cached value. Chainable method. Has no effect in single-valued mode.
     * @instance
     * @memberof Calculus
     * @return {calculus} The same {@link calculus} the method is called on.
     */
    variance () {
      if (STORAGE.get(this).mode === MODES.series) {
        STORAGE.get(this).value = core_methods.variance(STORAGE.get(this).series);
      }
      return this;
    },

    /**
     * When {@link calculus} is initialized in series-mode computes the deviation of the values (root square of the variance) in the internal cached series and saves the result as the cached value. Chainable method. Has no effect in single-valued mode.
     * @instance
     * @memberof Calculus
     * @return {calculus} The same {@link calculus} the method is called on.
     */
    deviation () {
      if (STORAGE.get(this).mode === MODES.series) {
        STORAGE.get(this).value = core_methods.deviation(STORAGE.get(this).series);
      }
      return this;
    },

    /**
     * Resolve the expression provided like {@link Calculus.resolve} but without parameters options. We can access internal cached values using the keyword `result` for the internal cached value and the relative index for elements in the internal cached series.
     * @instance
     * @memberof Calculus
     * @param {Formulae} expression - The expression to be calculated at every iteration.
     * @return {calculus} The same {@link calculus} the method is called on.
     */
    resolve (expression) {
      if (typeof expression === 'string') {
        let i;
        const data = { result: STORAGE.get(this).value };
        i = 0;
        for (const el of STORAGE.get(this).series) { data[i++] = el; }
        arguments[0] = support_functions.parseFormula(expression, data);
      }
      STORAGE.get(this).value = core_methods.resolve.apply(null, arguments);
      return this;
    },

    /**
     * Rounds the internal cached value to the decimals specified as parameter and overwrite the cached value. Chainable method.
     * @instance
     * @memberof Calculus
     * @return {calculus} The same {@link calculus} the method is called on.
     */
    round (decimals) {
      const num = STORAGE.get(this).value;
      if (!(core_methods.isNumber(num) && core_methods.isNumber(decimals) && +decimals >= 0)) return this;
      STORAGE.get(this).value = core_methods.round(num, parseInt(decimals));
      return this;
    },

    /**
     * Returns the internal cached value like it was supplied to {@link Calculus.format}. Can also be called as {@link Calculus#value}.
     * @instance
     * @memberof Calculus
     * @param {DecimalFormat} options - Same options as {@link Calculus.format}.
     * @return {string|number|undefined}
     *//**
      * Exactly the same method as {@link Calculus#result}.
      * @instance
      * @memberof Calculus
      * @alias value
      */
    result () {
      if (!arguments.length) {
        return STORAGE.get(this).value;
      } else {
        const current = STORAGE.get(this).value;
        if (!core_methods.isNumber(current)) return UNDEFINED;
        const args = Array.prototype.slice.call(arguments);
        args.splice(0, 0, STORAGE.get(this).value);
        return core_methods.format.apply(undefined, args);
      }
    },

    /**
     * Same as calling {@link Calculus.isValid} upon the internal cached value.
     * @instance
     * @memberof Calculus
     * @alias Calculus#isValid
     * @returns {boolean}
     */
    isValid () {
      return core_methods.isNumber(STORAGE.get(this).value);
    },

    /**
     * Clear both internal cached value and series. Chainable method.
     * @instance
     * @memberof Calculus
     * @return {calculus} The same {@link calculus} the method is called on.
     */
    clear () {
      STORAGE.get(this).value = undefined;
      STORAGE.get(this).series.splice(0);
      return this;
    },

    /**
     * Clear only the internal cached value. Same as {@link Calculus#clear_value}. Chainable method.
     * @instance
     * @alias clearResult
     * @memberof Calculus
     * @return {calculus} The same {@link calculus} the method is called on.
     *//**
      * Clear only the internal cached value. Same as {@link Calculus#clear_result}. Chainable method.
      * @instance
      * @alias clearValue
      * @memberof Calculus
      * @return {calculus} The same {@link calculus} the method is called on.
      */
    clear_result () {
      STORAGE.get(this).value = undefined;
      return this;
    },

    /**
     * Clear only the internal cached series. Chainable method.
     * @instance
     * @alias clearSeries
     * @memberof Calculus
     * @return {calculus} The same {@link calculus} the method is called on.
     */
    clear_series () {
      STORAGE.get(this).series.splice(0);
      return this;
    },

    /**
     * Add a list of numerical number to the internal cached series. Chainable method.
     * @instance
     * @memberof Calculus
     * @param {...NumericInput} [num]
     * @return {calculus} The same {@link calculus} the method is called on.
     */
    push () {
      const list = core_methods.getValid(arguments);
      Array.prototype.push.apply(STORAGE.get(this).series, list);
      return this;
    },

    ValueOf () {
      return proto_methods.result.call(this);
    },

    ToString () {
      return proto_methods.result.call(this, DEFAULT_DECIMALS);
    }
  };

  // lost reference to "this" scope keyword. These methods are standalonea and can be used everywhere provided that
  // if there is a list as input, that list should only contain valid numbers.
  const core_methods = {
    sum (list) {
      let result;
      for (const el of list) {
        result === undefined
          ? (result = el)
          : (result += (el));
      }
      return result;
    },

    deduct (list) {
      let result;
      for (const el of list) {
        result === undefined
          ? (result = +el)
          : (result -= (+el));
      }
      return result;
    },

    divide (list) {
      let result;
      for (const el of list) {
        if (+el === 0) {
          return undefined;
        } else {
          result === undefined
            ? (result = +el)
            : (result /= (+el));
        }
      }
      return result;
    },

    multiply (list) {
      let result;
      for (const el of list) {
        result === undefined
          ? (result = +el)
          : (result *= (+el));
      }
      return result;
    },

    exponentiation (base, exponent) {
      if (!(core_methods.isNumber(base) && core_methods.isNumber(exponent))) return undefined;
      return Math.pow(base, exponent);
    },

    average (list) {
      if (!list.length) return undefined;
      const tmp = core_methods.sum(list);
      if (tmp === undefined) return undefined;
      return core_methods.divide([tmp, list.length]);
    },

    variance (list) {
      if (!list.length) return undefined;
      const squared = [];
      for (const el of list) { squared.push(core_methods.exponentiation(el, 2)); }

      const operands = {
        squared_avg: core_methods.average(squared),
        list_avg: core_methods.average(list)
      };

      if (!(operands.squared_avg !== undefined && operands.list_avg !== undefined)) return undefined;
      return operands.squared_avg - core_methods.exponentiation(operands.list_avg, 2);
    },

    deviation (list) {
      if (!list.length) return undefined;
      const tmp = core_methods.variance(list);
      if (tmp === undefined) return tmp;
      return core_methods.exponentiation(tmp, 1 / 2);
    },

    summation (formula, min, max, parameters) {
      const series = [];
      let parsed;
      for (let k = min; k <= max; k++) {
        parsed = support_functions.parseFormula(formula, Object.assign({ k: k }, parameters));
        series.push(core_methods.resolve(parsed));
      }
      return core_methods.sum(series);
    },

    summation2 (formula, operands, parameters) {
      const series = [];
      let parsed, k;
      k = 0;
      for (const operand of operands) {
        parsed = support_functions.parseFormula(formula, Object.assign({ k: k++ }, operand, parameters));
        series.push(core_methods.resolve(parsed));
      }
      return core_methods.sum(series);
    },

    /**
     * Determine if the input can be parsed to a valid number.
     * @method
     * @static
     * @memberof Calculus
     * @alias isValid
     * @param {*} data - The input value.
     * @return {boolean}
     */
    isNumber (data) {
      core_methods.isCalculus(data) && (data = +data);
      return PRIMITIVE.has(typeof data) && // accepts only strings and numbers primitives
        !isNaN(parseFloat(data)) && // prevent empty for being detected as numbers since ( + "" === 0 )
        !isNaN(+data) && // prevent strings like "1.32a" for being detected since they pass the previous control
        isFinite(+data); // check finiteness
    },

    isCalculus (data) {
      if (data instanceof Calculus) return true;
      return data && data.constructor && data.constructor.name === 'Calculus'; // support cross-scope Calculus identification
    },

    /**
     * Extract all valid numbers from a list.
     * @method
     * @static
     * @memberof Calculus
     * @alias getValid
     * @param {...NumericInput} [num]
     * @return {number[]}
     */
    getValid (list) {
      if (NULLSET.has(list)) return [];

      if (arguments.length > 1) {
        list = support_functions.flattenArguments(arguments);
      } else {
        if (Array.from(list).length) {
          list = support_functions.flattenArguments(list);
        } else {
          list = support_functions.flattenArguments([list]);
        }
      }
      const valid = [];
      for (const el of list) {
        if (core_methods.isNumber(el)) {
          valid.push(+el);
        } else if (!SKIP_INVALID_OPERANDS) {
          throw new Error('Invalid operand');
        }
      }
      return valid;
    },

    format (num) {
      let dec, tmp;
      num = Number(num);
      if ((tmp = DYN_FORMAT.exec(arguments[1]))) {
        tmp = [+tmp[1], +tmp[2]];
        num = core_methods.dyn_format(num, tmp);
      } else if (arguments[1] === 'raw') {
        num = num.toString();
      } else {
        dec = (arguments.length === 2 && core_methods.isNumber(arguments[1])) ? +arguments[1] : DEFAULT_DECIMALS;
        num = num.toFixed(dec);
      }
      return num.replace(FORMATTER_REGEXP, support_functions.replacer);
    },

    dyn_format (num, specs) {
      let flag, cut;
      const diff = specs[1] - specs[0];
      num = num.toFixed(specs[1]);
      if (diff === 0) return num;
      flag = DYN_SLICE.exec(num.slice(-diff));
      if (flag) {
        flag = flag[0].length;
        cut = Math.min(diff, flag);
        specs[0] === 0 && diff === flag && cut++;
        num = num.slice(0, -cut);
      }
      return num;
    },

    /**
     * Try to parse a user formatted string into a valid number following the internal rules and configured user format.
     * @method
     * @static
     * @memberof Calculus
     * @alias decode
     * @param {FormattedNumlikeString} numericalString
     * @return {number|undefined}
     */
    decodeFromString (numericalString) {
      numericalString = String(numericalString);
      numericalString = numericalString.replace(TRIM_SPACES, '');
      if (!SEPARATOR_TEST.get(THOU_SEP + DEC_SEP).test(numericalString)) return undefined;
      numericalString = numericalString.replace(SEPARATOR_RE.get(THOU_SEP), '');
      numericalString = numericalString.replace(SEPARATOR_RE.get(DEC_SEP), '.');
      return core_methods.getValid([numericalString])[0];
    },

    resolve (expression) {
      let result, p, subexpr, operation;

      if (!expression || !expression.length) return new Calculus();

      if (SIGN_DETECTION.exec(expression[0])) { expression.splice(0, 0, 0); }
      expression = support_functions.shrinkToExpression(expression);
      while ((p = support_functions.operationPriority(expression)) > -1) {
        operation = expression[p];
        Array.isArray(expression[p - 1]) && (expression[p - 1] = core_methods.resolve(expression[p - 1]));
        Array.isArray(expression[p + 1]) && (expression[p + 1] = core_methods.resolve(expression[p + 1]));
        subexpr = core_methods.getValid([expression[p - 1], expression[p + 1]]);

        if (subexpr.length !== 2) {
          console.warn('Invalid operands');
          result = new Calculus();
        } else {
          switch (operation) {
            case '**': case '^':
              result = subexpr[0] ** subexpr[1];
              break;
            case '*': case 'x':
              result = subexpr[0] * subexpr[1];
              break;
            case '/': case ':':
              result = subexpr[0] / subexpr[1];
              break;
            case '+':
              result = subexpr[0] + subexpr[1];
              break;
            case '-':
              result = subexpr[0] - subexpr[1];
              break;
          }
          result = new Calculus(result);
        }

        expression.splice(p - 1, 3, result.result());
      }
      return expression[0];
    },

    round (num, decimals) {
      // This methods implements rounding away from zero, javascript Math.round rounds towards positive infinite.
      // The question is relevant only when rounding fractional parts that are exactly 0.5
      return Math.sign(num) * Math.round(Math.abs(num) * Math.pow(10, decimals)) / Math.pow(10, decimals);
    }
  };

  const support_functions = {
    replacer (match, p1, p2) {
      if (p1) return match + THOU_SEP;
      if (p2) return DEC_SEP + match.slice(1);
    },

    parser (parameters, key, value) {
      if (PARAMETERS.test(value)) {
        if (value.charCodeAt(0) === 36) {
          value = value.slice(1, -1); // removes start and final $ sign from the match
        }
        return new Calculus(parameters[value]).value();
      }
      return value;
    },

    /**
     * Parse a literal formula into an array expression directly usable in {@link Calculus.}
     * @function
     * @memberof Calculus
     * @alias parse
     * @static
     * @param {Formulae} formula - Literal formula to parse.
     * @param {object} parameters - Parameters to inject during parsing.
     * @return {Array[]}
     */
    parseFormula (formula, parameters) {
      let parsed;
      formula = formula.replace(WHITE_SPACES, '');
      formula = formula.replace(IMPLICIT_PLUSMINUS, '$10$2');
      formula = formula.replace(OPERATIONS, ',"$1",');
      formula = formula.replace(PARAMETERS, '"$1"');
      formula = formula.replace(PARENTHESIS.LEFT, '[');
      formula = formula.replace(PARENTHESIS.RIGHT, ']');
      formula = '[' + formula + ']';
      try {
        parsed = JSON.parse(formula, support_functions.parser.bind(null, parameters));
      } catch (e) {
        console.warn('Parse failed!', e);
      }
      return parsed;
    },

    operationPriority (expression) {
      let tmp;
      const index = [];
      for (const ops of support_functions.PRIORITY_LEVELS) {
        for (const op of ops) {
          tmp = expression.indexOf(op);
          tmp >= 0 && index.push(tmp);
        }
        if (index.length) return Math.min.apply(undefined, index);
      }
      return -1;
    },

    PRIORITY_LEVELS: new Set([['^', '**'], ['*', 'x', '/', ':'], ['+', '-']]),

    shrinkToExpression (expression) {
      if (Array.isArray(expression)) {
        if (expression.length === 1) return support_functions.shrinkToExpression(expression[0]);
        return expression;
      }
      return [expression];
    },

    flattenArguments (args) {
      return Array.from(args).flat(1);
    }
  };

  /* STATIC METHODS ASSIGNEMENT */

  /* Calculations */
  Object.defineProperty(Calculus, 'max', { value: static_methods.max });
  Object.defineProperty(Calculus, 'min', { value: static_methods.min });
  Object.defineProperty(Calculus, 'sum', { value: static_methods.sum });
  Object.defineProperty(Calculus, 'deduct', { value: static_methods.deduct });
  Object.defineProperty(Calculus, 'divide', { value: static_methods.divide });
  Object.defineProperty(Calculus, 'average', { value: static_methods.average });
  Object.defineProperty(Calculus, 'resolve', { value: static_methods.resolve });
  Object.defineProperty(Calculus, 'multiply', { value: static_methods.multiply });
  Object.defineProperty(Calculus, 'variance', { value: static_methods.variance });
  Object.defineProperty(Calculus, 'exp', { value: static_methods.exponentiation });
  Object.defineProperty(Calculus, 'deviation', { value: static_methods.deviation });
  Object.defineProperty(Calculus, 'summation', { value: static_methods.summation });

  /* Validation, parsing and formatting */
  Object.defineProperty(Calculus, 'round', { value: static_methods.round });
  Object.defineProperty(Calculus, 'format', { value: static_methods.format });
  Object.defineProperty(Calculus, 'isValid', { value: core_methods.isNumber });
  Object.defineProperty(Calculus, 'getValid', { value: core_methods.getValid });
  Object.defineProperty(Calculus, 'equals', { value: static_methods.safeEquals });
  Object.defineProperty(Calculus, 'decode', { value: core_methods.decodeFromString });
  Object.defineProperty(Calculus, 'parse', { value: support_functions.parseFormula });

  /* Instance Configuration */
  Object.defineProperty(Calculus, 'setFormat', { value: conf_methods.setFormat });
  Object.defineProperty(Calculus, 'setDecimals', { value: conf_methods.setDecimals });
  Object.defineProperty(Calculus, 'safetyChecks', { value: conf_methods.safetyChecks });
  Object.defineProperty(Calculus, 'getSeparators', { value: conf_methods.getSeparators });
  Object.defineProperty(Calculus, 'setNullFormat', { value: conf_methods.setNullFormat });
  Object.defineProperty(Calculus, 'getFormatRegex', { value: conf_methods.getFormatRegex });

  /* PROTO METHODS ASSIGNEMENT */

  /* Calculations */
  Object.defineProperty(Calculus.prototype, 'max', { value: proto_methods.max });
  Object.defineProperty(Calculus.prototype, 'min', { value: proto_methods.min });
  Object.defineProperty(Calculus.prototype, 'sum', { value: proto_methods.sum });
  Object.defineProperty(Calculus.prototype, 'sub', { value: proto_methods.deduct });
  Object.defineProperty(Calculus.prototype, 'deduct', { value: proto_methods.deduct });
  Object.defineProperty(Calculus.prototype, 'divide', { value: proto_methods.divide });
  Object.defineProperty(Calculus.prototype, 'average', { value: proto_methods.average });
  Object.defineProperty(Calculus.prototype, 'resolve', { value: proto_methods.resolve });
  Object.defineProperty(Calculus.prototype, 'multiply', { value: proto_methods.multiply });
  Object.defineProperty(Calculus.prototype, 'variance', { value: proto_methods.variance });
  Object.defineProperty(Calculus.prototype, 'exp', { value: proto_methods.exponentiation });
  Object.defineProperty(Calculus.prototype, 'deviation', { value: proto_methods.deviation });

  /* Validation, parsing and formatting */
  Object.defineProperty(Calculus.prototype, 'round', { value: proto_methods.round });
  Object.defineProperty(Calculus.prototype, 'value', { value: proto_methods.result });
  Object.defineProperty(Calculus.prototype, 'result', { value: proto_methods.result });
  Object.defineProperty(Calculus.prototype, 'isValid', { value: proto_methods.isValid });

  /* Management */
  Object.defineProperty(Calculus.prototype, 'push', { value: proto_methods.push });
  Object.defineProperty(Calculus.prototype, 'clear', { value: proto_methods.clear });
  Object.defineProperty(Calculus.prototype, 'valueOf', { value: proto_methods.ValueOf });
  Object.defineProperty(Calculus.prototype, 'toString', { value: proto_methods.ToString });
  Object.defineProperty(Calculus.prototype, 'clearValue', { value: proto_methods.clear_result });
  Object.defineProperty(Calculus.prototype, 'clearResult', { value: proto_methods.clear_result });
  Object.defineProperty(Calculus.prototype, 'clearSeries', { value: proto_methods.clear_series });

  conf_methods.setFormat(numFormat);
  conf_methods.setDecimals(decimals);
  conf_methods.safetyChecks(invalidOperandsRaiseErrors);
  conf_methods.setNullFormat(nullFormat);

  return Calculus;
}

const CalculusFactory = CalculusGenerator;
const Calculus = CalculusGenerator('.', 2, '---');
const WeakCalculus = CalculusGenerator('.', 2, '---');

export default {
  CalculusFactory,
  Calculus,
  WeakCalculus
};

export {
  CalculusFactory,
  Calculus,
  WeakCalculus
};
