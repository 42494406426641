<script setup>
import { h, ref, getCurrentInstance, inject, onBeforeUnmount, onMounted, useSlots, computed, nextTick, useAttrs, onBeforeMount } from 'vue';
import CnsIcon from './cns-icon.vue';
import { createStandaloneComponent } from '../../libs/vue-utils';

const props = defineProps({
  pos: { type: Object, required: true },
  title: { type: String, default: undefined },
  desc: { type: String, default: undefined },
  width: { type: Number, default: undefined },
  color: { type: String, default: undefined },
  icon: { type: String, default: undefined },
  interactive: { type: Boolean, default: false }
});

const emit = defineEmits(['click']);
const attrs = useAttrs();

const id = getCurrentInstance().uid;
const opened = ref(false);
const $slots = useSlots();
const canOpen = computed(() => $slots.default || props.desc);
const parentScopeId = getCurrentInstance().parent?.type?.__scopeId;
const scopeId = getCurrentInstance().type?.__scopeId;

const addMarker = inject('__cnsMap_addMarker');
const remMarker = inject('__cnsMap_remMarker');
const openPopup = inject('__cnsMap_openPopup');

const html = document.createElement('div');
html.addEventListener('click', e => onMarkerClicked(e));

function onMarkerClicked (e) {
  emit('click', e);
  if (canOpen.value) {
    opened.value = true;
    openPopup(id);
  }
}

onBeforeMount(() => {
  createStandaloneComponent(
    () => {
      if (opened.value) {
        return [
          $slots.default
            ? $slots.default({
              [parentScopeId]: '',
              [scopeId]: ''
            })
            : h('div', {
              [parentScopeId]: '',
              [scopeId]: '',
              class: 'cns-map-marker-default-popup m-0'
            }, [
              props.title ? h('h4', { [scopeId]: '', class: 'm-0' }, props.title) : null,
              props.desc ? h('p', { [scopeId]: '', class: 'm-0' }, props.desc) : null
            ])
        ];
      } else {
        return [
          h('div', {
            [parentScopeId]: '',
            [scopeId]: '',
            class: [
              'cns-map-marker-marker-container',
              'd-flex',
              'align-items-center',
              'justify-content-center',
              props.interactive || canOpen.value ? 'cursor-pointer' : 'cursor-default',
              attrs.class || {}
            ],
            style: attrs.style
          }, [
            h('div', {
              [scopeId]: '',
              class: 'cns-map-marker-marker p-2 d-flex justify-content-center align-items-center'
            }, [
              $slots.icon
                ? $slots.icon({ [scopeId]: '' })
                : h(CnsIcon, {
                  [scopeId]: '',
                  type: `${props.icon || 'location-dot'} solid 2xl`,
                  style: { color: props.color }
                }),
              props.title
                ? h('p', { [scopeId]: '' }, props.title)
                : null
            ])
          ])
        ];
      }
    },
    getCurrentInstance()?.appContext.provides
  ).mount(html);
});

onMounted(() => {
  nextTick(() => {
    addMarker({
      id,
      html,
      pos: props.pos,
      width: props.width,
      get opened () { return opened.value; },
      set opened (newVal) {
        opened.value = newVal;
        if (newVal) {
          openPopup(id);
        }
      }
    });
  });
});

onBeforeUnmount(() => { remMarker(id); });
</script>

<template>
  <div v-if="false" />
</template>

<style scoped>
.cns-map-marker-marker-container {
  width: 0;
  height: 0;
}

.cns-map-marker-marker {
  position: relative;
}

.cns-map-marker-marker i {
  color: var(--hig-primary);
  text-shadow:
    -1px 0 var(--hig-black),
    0 1px var(--hig-black),
    1px 0 var(--hig-black),
    0 -1px var(--hig-black);
}

.cns-map-marker-marker p {
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  max-width: 120px;
  line-height: normal;
  text-align: start;
  overflow: visible;
  color: var(--hig-black);
  font-size: var(--hig-font-size-m);
  font-weight: bold;
  text-shadow:
    1px 0 var(--hig-white),
    -1px 0 var(--hig-white),
    0 1px var(--hig-white),
    0 -1px var(--hig-white),
    1px 1px var(--hig-white),
    -1px -1px var(--hig-white),
    1px -1px var(--hig-white),
    -1px 1px var(--hig-white);
}

.cns-map-marker-default-popup h4 {
  word-break: break-word;
}

.cns-map-marker-default-popup p {
  font-size: var(--hig-font-size-m);
}
</style>
