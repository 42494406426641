<script lang="ts">
import { ref, watch, inject } from 'vue';
import GraphQl from '../../../libs/graphql-client/index.mjs';
import CnsButton from '../cns-button.vue';
import CnsListItem from '../cns-list-item.vue';
import CnsSettingsFormRow from '../../cns-settings/cns-settings-form-row.vue';
import Utils from '../../../libs/utils/index.mjs';
import SignalSelectorModal from './cns-signal-selector-modal.vue';
import { Signal } from './types';
import { Fop } from '../../../libs/fop-utils/index.browser.mjs';

type LoadSignalDescArg = { id: string; }
const loadSignalDesc = Utils.batch<LoadSignalDescArg, Signal | undefined>((args) => {
  const signalsIds = new Set();
  args.map((arg: LoadSignalDescArg) => signalsIds.add(arg.id));

  return GraphQl.query(`
    DataloggerPeripheralSignal_get(fop: $fop, noCache: true) {
      id
      label
      datalogger { sn name }
      peripheral { label }
    }
  `, {
    fop: { type: 'FilterOrderPaginate', value: new Fop().filter('id', '=', [...signalsIds]).serialize() }
  }).then((res: Signal[]) =>
    args.map((arg: LoadSignalDescArg) =>
      res.find((signal: Signal) => String(signal.id) === String(arg.id))
    )
  );
}, { cacheBy: 'id' });
</script>

<script setup lang="ts">
const $edw = inject<any>('$edw');

defineOptions({ inheritAttrs: false });
const props = defineProps<{
  label: string;
  class?: any;
  style?: any;
}>();
const value = defineModel<string | undefined>();

const modal = ref<any>();
const loading = ref<boolean>(false);
const signal = ref<Signal | undefined>();

watch(value, async () => {
  signal.value = undefined;
  if (!value.value) { return; }

  loading.value = true;
  signal.value = await loadSignalDesc({ id: value.value }).catch(() => undefined);
  if (!signal.value) { value.value = undefined; }
  loading.value = false;
}, { immediate: true });

defineExpose({ getSelected: () => signal.value });
</script>

<template>
  <cns-settings-form-row :label="props.label" :class="props.class" :style="props.style">
    <template #label="labelProps">
      <slot name="label" v-bind="labelProps" />
    </template>
    <template #value>
      <slot name="value-prefix" />
      <div class="d-flex align-items-center gap-2">
        <cns-list-item
          style="width: 250px;"
          :icon="loading ? 'spinner-third spin fw' : 'wave-square fw'"
          :label="signal ? signal.label : $edw.noSignalSelected"
          :caption="
            signal
              ? `${signal.peripheral.label} @ ${signal.datalogger.name} (${signal.datalogger.sn})`
              : $edw.clickToSelectASignal
          "
          @click="modal.open()"
        >
          <template #suffix>
            <cns-button
              v-if="signal"
              icon="xmark"
              variant="link"
              size="sm"
              @click.stop="() => { value = undefined }"
            />
          </template>
        </cns-list-item>
      </div>
      <signal-selector-modal ref="modal" v-model="value" :title="props.label" v-bind="$attrs" />
    </template>
  </cns-settings-form-row>
</template>
