import graphQlClient from '../../../libs/graphql-client';

export const useUtilsModule = function () {
  function loadSysLanguages (opt = {}) {
    if (!opt.force && this.state.sysLanguagesProm) { return this.state.sysLanguagesProm; }

    this.state.sysLanguagesProm = graphQlClient.query(`
      SysLanguage_get {
        id
        name
      }
    `).then((sysLanguages) => {
      this.state.sysLanguages = sysLanguages;
      return this.state.sysLanguages;
    }).catch((err) => {
      this.state.sysLanguagesProm = undefined;
      return err;
    });

    return this.state.sysLanguagesProm;
  }

  function loadSysNumFormats (opt = {}) {
    if (!opt.force && this.state.sysNumFormatsProm) { return this.state.sysNumFormatsProm; }

    this.state.sysNumFormatsProm = graphQlClient.query(`
      SysNumFormat_get {
        id
        format
      }
    `).then((sysNumFormats) => {
      this.state.sysNumFormats = sysNumFormats;
      return this.state.sysNumFormats;
    }).catch((err) => {
      this.state.sysNumFormatsProm = undefined;
      return err;
    });

    return this.state.sysNumFormatsProm;
  }

  function loadSysDateFormats (opt = {}) {
    if (!opt.force && this.state.sysDateFormatsProm) { return this.state.sysDateFormatsProm; }

    this.state.sysDateFormatsProm = graphQlClient.query(`
      SysDateFormat_get {
        id
        format
        name
      }
    `).then((sysDateFormats) => {
      this.state.sysDateFormats = sysDateFormats;
      return this.state.sysDateFormats;
    }).catch((err) => {
      this.state.sysDateFormatsProm = undefined;
      return err;
    });

    return this.state.sysDateFormatsProm;
  }

  function loadSysTimeZones (opt = {}) {
    if (!opt.force && this.state.sysTimeZonesProm) { return this.state.sysTimeZonesProm; }

    this.state.sysTimeZonesProm = graphQlClient.query(`
      SysTimeZone_get {
        id
        name
      }
    `).then((sysTimeZones) => {
      this.state.sysTimeZones = sysTimeZones;
      return this.state.sysTimeZones;
    }).catch((err) => {
      this.state.sysTimeZonesProm = undefined;
      return err;
    });

    return this.state.sysTimeZonesProm;
  }

  function loadUserRoles (opt = {}) {
    if (!opt.force && this.state.userRolesProm) { return this.state.userRolesProm; }

    this.state.userRolesProm = graphQlClient.query(`
      UserRole_get {
        id
        name
        description
      }
    `).then((userRoles) => {
      this.state.userRoles = userRoles;
      return this.state.userRoles;
    }).catch((err) => {
      this.state.userRolesProm = undefined;
      return err;
    });

    return this.state.userRolesProm;
  }

  function loadPluginRoles (opt = {}) {
    if (!opt.force && this.state.pluginRolesProm) { return this.state.pluginRolesProm; }

    this.state.pluginRolesProm = graphQlClient.query(`
      PluginRole_get {
        id
        name
        pluginType
      }
    `).then((pluginRoles) => {
      this.state.pluginRoles = pluginRoles;
      return this.state.pluginRoles;
    }).catch((err) => {
      this.state.pluginRolesProm = undefined;
      return err;
    });

    return this.state.pluginRolesProm;
  }

  return {
    state: {
      sysLanguagesProm: undefined,
      sysLanguages: [],
      sysTimeZonesProm: undefined,
      sysTimeZones: [],
      sysNumFormatsProm: undefined,
      sysNumFormats: [],
      sysDateFormatsProm: undefined,
      sysDateFormats: [],
      userRolesProm: undefined,
      userRoles: [],
      pluginRolesProm: undefined,
      pluginRoles: []
    },
    getters: {
      sysLanguages: (state) => state.sysLanguages,
      sysNumFormats: (state) => state.sysNumFormats,
      sysDateFormats: (state) => state.sysDateFormats,
      sysTimeZones: (state) => state.sysTimeZones,
      userRoles: (state) => state.userRoles,
      pluginRoles: (state) => state.pluginRoles
    },
    actions: {
      loadSysLanguages,
      loadSysNumFormats,
      loadSysDateFormats,
      loadSysTimeZones,
      loadUserRoles,
      loadPluginRoles
    }
  };
};
