import GraphQl from '../../../libs/graphql-client/index.mjs';
import { Fop } from '../../../libs/fop-utils/index.browser.mjs';
import { edw } from '../../edw/index.mjs';
import _ from 'lodash';
import Utils from '../../../libs/utils';

export const usePagesConfigsModule = function () {
  // ### PAGES CONFIGS ### //
  function getConfig (name, opt = {}) {
    if (!name) { console.error('Cannot load a configuration without an name'); return Promise.reject(new Error('DataLoadError')); }
    opt.context = typeof opt.context === 'object' ? opt.context : {};

    const confHash = name + (opt.nodeId || '') + (opt.nodeTypeId || '');
    if (opt.force || !this.state.configurationsProm[confHash]) {
      const _fop = new Fop().filter('name', '=', name);
      this.state.configurationsProm[confHash] = GraphQl.query(`
        Config_get (fop: $fop) {
          id
          name
          config
          label
          node { id }
          nodeType { id }
          user { id }
          userRole { id }
          type
          public
        }
      `, {
        fop: { type: 'FilterOrderPaginate', value: _fop.serialize() }
      }).then((configs) => {
        const contextCns = {};

        Object.defineProperty(contextCns, 'sysConf', { get: function () { return _.cloneDeep(this.state.sysConf); } });

        configs = Utils.sortConfigs(configs);
        return Utils.evalConfigs(configs, {
          context: {
            edw: (lbl) => { return edw[lbl]; },
            cns: contextCns,
            ...opt.context
          }
        });
      }).catch((err) => {
        this.state.configurationsProm[confHash] = undefined;
        throw err;
      });
    }

    return this.state.configurationsProm[confHash].then((res) => _.cloneDeep(res));
  }
  // ### PAGES CONFIGS ### //

  return {
    state: { configurationsProm: {} },
    getters: {},
    actions: { getConfig }
  };
};
