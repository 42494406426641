<script setup>
import { ref, computed } from 'vue';
import SettingsRow from './cns-settings-form-row.vue';
import _ from 'lodash';

const props = defineProps({
  modelValue: { type: Object, default: undefined },
  rows: { type: Array, default: function () { return []; } },
  editable: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },

  // Deprecated
  editing: { type: Boolean, default: false }
});
const emit = defineEmits(['change', 'update:modelValue']);

const _value = ref({});
const value = computed(() => {
  const origVal = typeof props.modelValue !== 'undefined' ? props.modelValue : _value.value;
  const res = {};
  props.rows.forEach((row) => { res[row.field] = _.get(origVal, row.field); });
  return res;
});

function setVal (field, val) {
  const origVal = typeof props.modelValue !== 'undefined' ? props.modelValue : _value.value;
  _.set(origVal, field, val);

  emit('change', origVal);
  emit('update:modelValue', origVal);
  _value.value = origVal;
}

const rowsRefs = ref([]);
defineExpose({
  isValid: () => Promise.all(
    rowsRefs.value.map((row) => row.isValid())
  ).then((rowsResults) => !rowsResults.some((isValid) => !isValid))
});

</script>

<template>
  <settings-row class="settings-form-row" v-for="row in rows" :key="row.field" ref="rowsRefs" :modelValue="value[row.field]" @update:modelValue="setVal(row.field, $event)" :config="row" :editable="props.editable || props.editing" :loading="props.loading">
    <template #row="opt">
      <slot :name="row.field + '-row'" v-bind="opt">
        <slot :name="row.type + '-row'" v-bind="opt"/>
      </slot>
    </template>
    <template #label="opt">
      <slot :name="row.field + '-label'" v-bind="opt">
        <slot :name="row.type + '-label'" v-bind="opt"/>
      </slot>
    </template>
    <template #value="opt">
      <slot :name="row.field + '-value'" v-bind="opt">
        <slot :name="row.type + '-value'" v-bind="opt"/>
      </slot>
    </template>
    <template #value-plaintext="opt">
      <slot :name="row.field + '-value-plaintext'" v-bind="opt">
        <slot :name="row.type + '-value-plaintext'" v-bind="opt"/>
      </slot>
    </template>
    <template #value-editable="opt">
      <slot :name="row.field + '-value-editable'" v-bind="opt">
        <slot :name="row.type + '-value-editable'" v-bind="opt"/>
      </slot>
    </template>
  </settings-row>
</template>
