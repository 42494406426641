<template>
  <div v-if="pagesCount > 0" class="Custom-Form d-flex flex-column">
    <h3 v-if="curPage.title">{{$edw[curPage.title]}}</h3>
    <div v-if="pagesCount > 1 || curPage.title" class="progress mb-2" style="height: 2px">
      <div class="progress-bar" role="progressbar" :style="{ 'width': ((curPageNum + 1) * 100 / pagesCount) + '%' }" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
    </div>
    <b-alert :show="!!genError || !!genError_" variant="danger" dismissible>
      {{(genError && $edw[genError]) || (genError_ && $edw[genError_])}}
    </b-alert>
    <div class="flex-grow-1">
      <template v-for="input in curPage.inputs" :key="input.id">
        <cns-custom-form-input :config="input" v-model="inputValue[input.id]" :error="inputErrors[input.id] || inputErrors_[input.id]" lazy :plaintext="!!input.plaintext" :readonly="!!input.readonly"/>
      </template>
    </div>
    <div class="d-flex justify-content-end">
      <b-button v-if="!isFirstPage" variant="secondary" class="d-flex align-items-center me-1" @click="goToPrevPage"><VI-Icon type="angle-left" class="me-2" style="font-size: 1.5em;"/>{{(curPage.prev && $edw[curPage.prev]) || $edw.prev}}</b-button>
      <b-button v-if="!isLastPage" variant="primary" class="d-flex align-items-center" @click="goToNextPage">{{(curPage.next && $edw[curPage.next]) || $edw.next}}<VI-Icon type="angle-right" class="ms-2" style="font-size: 1.5em;"/></b-button>
      <b-button v-if="isLastPage" variant="primary" class="" @click="submit">
        <div v-if="submitLoading" class="spinner-border spinner-border-sm" role="status"></div>
        <span v-else>{{submitLabel || $edw.submit}}</span>
      </b-button>
    </div>
  </div>
</template>

<script>
import CnsCustomFormInput from './cns-custom-form-input.vue';
import BAlert from '../bootstrap/b-alert.vue';
import BButton from '../bootstrap/b-button.vue';
import { VIIcon } from '@hig/vision-sdk';
import _ from 'lodash';

export default {
  name: 'cns-custom-form',
  components: { CnsCustomFormInput, BAlert, BButton, VIIcon },
  props: {
    modelValue: { type: Object, default: undefined },
    config: { type: Object, default: undefined },
    submitLabel: { type: String, default: undefined },
    submitLoading: { type: Boolean, default: false },
    inputErrors: { type: Object, default: function () { return {}; } },
    genError: { type: String, default: undefined }
  },
  data () {
    return {
      inputValue: {},
      ouputValueByPage: {},
      ouputValue: {},
      inputErrors_: {},
      genError_: '',
      curPageNum: 0,
      skipInputValueWatch: false,
      skipModelValueWatch: false
    };
  },
  computed: {
    pagesCount: function () { return (this.config && Array.isArray(this.config.pages) && this.config.pages.length) || 0; },
    isFirstPage: function () { return this.curPageNum === 0; },
    isLastPage: function () { return this.curPageNum === this.pagesCount - 1; },
    curPage: function () { return (this.config && Array.isArray(this.config.pages) && this.config.pages[this.curPageNum]) || { title: '', inputs: [] }; }
  },
  watch: {
    config: {
      deep: true,
      handler: function () {
        // check all the pages before the current one to see if it's all ok, if not focus on the first page with errors
        for (let i = 0; i < this.curPageNum && i < this.pagesCount; i++) {
          if (!this.checkPage(this.config.pages[i], i)) {
            this.curPageNum = i;
            return;
          }
        }
      }
    },
    inputValue: {
      deep: true,
      handler: function () {
        if (this.skipInputValueWatch) { this.skipInputValueWatch = false; return; }
        this.skipModelValueWatch = true;
        this.$emit('update:modelValue', _.cloneDeep(this.inputValue));
      }
    },
    modelValue: {
      deep: true,
      immediate: true,
      handler: function () {
        if (this.skipModelValueWatch) { this.skipModelValueWatch = false; return; }
        this.skipInputValueWatch = true;
        this.inputValue = _.cloneDeep(this.modelValue);
        // check all the pages before the current one to see if it's all ok, if not focus on the first page with errors
        for (let i = 0; i < this.curPageNum && i < this.pagesCount; i++) {
          if (!this.checkPage(this.config.pages[i], i)) {
            this.curPageNum = i;
            return;
          }
        }
      }
    },
    inputErrors: {
      deep: true,
      immediate: true,
      handler: function () {
        // Focus on the first page with errors
        for (let i = 0; i <= this.curPageNum && i < this.pagesCount; i++) {
          for (let j = 0; j < this.config.pages[i].inputs.length; j++) {
            if (this.inputErrors[this.config.pages[i].inputs[j].id]) {
              this.curPageNum = Math.min(this.curPageNum, i);
              return;
            }
          }
        }
      }
    }
  },
  methods: {
    goToPrevPage () {
      this.curPageNum = Math.max(this.curPageNum - 1, 0);
    },
    goToNextPage () {
      if (this.checkPage(this.curPage, this.curPageNum)) {
        this.curPageNum = Math.min(this.curPageNum + 1, this.pagesCount - 1);
      }
    },
    submit () {
      if (this.submitLoading) { return; }
      if (this.checkPage(this.curPage, this.curPageNum) && this.checkForm()) {
        this.$emit('submit', this.ouputValue);
      }
    },
    checkInput (input) {
      if (input.mandatory && input.type === 'checkbox' && this.inputValue[input.id] !== true) {
        this.inputErrors_[input.id] = this.$edw.thisCheckIsMandatory;
        return false;
      } else if (input.mandatory && (this.inputValue[input.id] == null || this.inputValue[input.id] === '' || (Array.isArray(this.inputValue[input.id]) && this.inputValue[input.id].length === 0))) {
        this.inputErrors_[input.id] = this.$edw.fieldIsMandatory;
        return false;
      }

      if (input.type === 'number') {
        if (input.min != null && this.inputValue[input.id] < input.min) {
          this.inputErrors_[input.id] = this.$edw.valueMustBeGreaterOrEqualTo + ' ' + input.min;
          return false;
        }

        if (input.max != null && this.inputValue[input.id] > input.max) {
          this.inputErrors_[input.id] = this.$edw.valueMustBeLowerOrEqualTo + ' ' + input.max;
          return false;
        }
      }

      if (input.isValid) {
        if (typeof input.isValid === 'function') {
          this.inputErrors_[input.id] = input.isValid(this.inputValue[input.id], this.inputValue);
          this.inputErrors_[input.id] = typeof this.inputErrors_[input.id] === 'string' ? this.$edw[this.inputErrors_[input.id]] : this.inputErrors_[input.id];
          return this.inputErrors_[input.id] == null;
        } else if (typeof input.isValid === 'string' || (typeof input.isValid === 'object' && input.isValid instanceof RegExp)) {
          this.inputErrors_[input.id] = String(this.inputValue[input.id]).match(this.isValid) ? null : this.$edw.wrongFormat;
          return this.inputErrors_[input.id] == null;
        }
      }

      this.inputErrors_[input.id] = null;
      return true;
    },
    checkPage (page, index) {
      let pageOk = true;
      this.genError_ = '';

      const curPageInput = {};
      page.inputs.forEach((input) => {
        if (!this.checkInput(input)) {
          pageOk = false;
        }
        curPageInput[input.id] = this.inputValue[input.id];
      });

      let curPageOutput = curPageInput;
      if (pageOk && page.onSubmit) {
        const tmpCurPageOutput = page.onSubmit(curPageInput, (error) => { // cancel submit callback
          pageOk = false;
          this.genError_ = error;
        });

        if (tmpCurPageOutput != null) { curPageOutput = tmpCurPageOutput; }
      }

      this.ouputValueByPage[index] = pageOk ? curPageOutput : null;

      return pageOk;
    },
    checkForm () {
      let formOk = true;
      this.genError_ = '';

      let curFormOutput = {};
      for (let i = 0; i < this.pagesCount; i++) {
        Object.assign(curFormOutput, this.ouputValueByPage[i]);
      }

      if (this.config.onSubmit) {
        const tmpCurFormOutput = this.config.onSubmit(curFormOutput, (error) => { // cancel submit callback
          formOk = false;
          this.genError_ = error;
        });

        if (tmpCurFormOutput != null) { curFormOutput = tmpCurFormOutput; }
      }
      this.ouputValue = formOk ? curFormOutput : null;

      return formOk;
    }
  },
  mounted () {
    // reset input errors triggered on mounting
    this.inputErrors_ = {};
  }
};
</script>

<style scoped>
</style>
