<template>
  <input :class="classes" ref="input" :type="type" :placeholder="placeholder" :value="modelValue" @change="change" @keyup="keyup" :readonly="readonly || plaintext">
</template>

<script>
const TYPES = [
  'text',
  'password',
  'email',
  'number',
  'url',
  'tel',
  'search',
  'range',
  'color',
  'date',
  'time',
  // 'datetime', // deprecated
  'datetime-local',
  'month',
  'week'
];

const LAZY_ONLY = [
  'range',
  'color',
  'date',
  'time',
  // 'datetime', // deprecated
  'datetime-local',
  'month',
  'week'
];

export default {
  name: 'b-form-input',
  props: {
    type: { type: String, default: 'text', validator: function (value) { return TYPES.indexOf(value) > -1; } },
    modelValue: { type: [String, Number], default: '' },
    placeholder: { type: String, default: '' },
    debounce: { type: Number, default: 0 },
    size: { type: String, default: undefined },
    state: { type: Boolean, default: undefined },
    lazy: { type: Boolean, default: false },
    trim: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    plaintext: { type: Boolean, default: false }
  },
  data () {
    return {
      updateDebounceTo: undefined
    };
  },
  computed: {
    classes: function () {
      return [
        'form-control',
        {
          ['form-control-' + this.size]: !!this.size,
          'is-valid': this.state === true,
          'is-invalid': this.state === false,
          'form-control-plaintext': this.plaintext
        }
      ];
    }
  },
  methods: {
    change (e) {
      this.emitUpdate();
      e.stopPropagation();
    },
    keyup (e) {
      if (!this.lazy && LAZY_ONLY.indexOf(this.type) === -1) { this.emitUpdateDebounced(); }
      e.stopPropagation();
    },
    emitUpdateDebounced () {
      if (this.debounce > 0) {
        clearTimeout(this.updateDebounceTo);
        this.updateDebounceTo = setTimeout(() => { this.emitUpdate(); }, this.debounce);
      } else {
        this.emitUpdate();
      }
    },
    emitUpdate () {
      let val = String(this.$refs.input.value);
      if (this.trim) { val = val.trim(); }
      if (this.type === 'number') { val = Number(val); }

      this.$emit('update:modelValue', val);
    },
    focus () {
      this.$refs.input.focus();
    }
  }
};
</script>

<style>

</style>
