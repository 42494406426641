import { Calculus } from '../calculus/index.mjs';
import { rollups } from 'd3-array';
import Utils from '../utils';
import type {
  DataSample,
  SeriesData,
  TidyFormatDataByNodeInput,
  TidyFormatDataByNodeOutput,
  TidyAggregateNodeDataAggregator
} from './types.js';

const TYPE_NUM = 'num';
const TYPE_STR = 'str';

function cleanValue (val: number | string | null | undefined): number | string | null {
  if (val === '' || val === '---' || val === 'null' || val == null || (typeof val === 'string' && val.indexOf('#') === 0)) {
    return null;
  }
  return Calculus.isValid(val) ? Number(val) : val;
}

function compress (data: SeriesData, { decimals = 6 } = {}): SeriesData {
  if (!Array.isArray(data) || data.length === 0) { return []; }

  /* Differential compression */
  const mul = Math.pow(10, decimals);

  let prevUtc = data[0][0];
  let prevVal = cleanValue(data[0][1]);
  prevVal = prevVal == null ? prevVal : Math.round((Number(prevVal) + Number.EPSILON) * mul) / mul;
  const res: SeriesData = [[prevUtc, prevVal]];

  for (let i = 1; i < data.length; i++) {
    const curUtc = data[i][0];
    const curVal = cleanValue(data[i][1]);

    // Here we need to do some magic tricks to avoid rounding errors
    // cause if we generate errors here they will propagate
    // throughout all the data thanks to the differential compression
    // The trick is to do the subtraction between integers and then return to floating point numbers
    // the Number.EPSILON is there to avoid errors with Math.round (see https://www.codingem.com/javascript-how-to-limit-decimal-places/)
    res.push([
      curUtc - prevUtc,
      curVal != null ? Math.round(Math.round((Number(curVal) + Number.EPSILON) * mul) - Math.round(((Number(prevVal) || 0) + Number.EPSILON) * mul)) / mul : null
    ]);

    prevUtc = curUtc;
    prevVal = curVal;
  }
  /* Differential compression */

  return res;
}

function decompress (data: SeriesData, { decimals = 6 } = {}): SeriesData {
  if (!Array.isArray(data) || data.length === 0) { return []; }

  const mul = Math.pow(10, decimals);

  const decompressedData: SeriesData = [[data[0][0], data[0][1]]];
  let prevUtc = data[0][0];
  let prevVal = cleanValue(data[0][1]);

  for (let i = 1; i < data.length; i++) {
    prevUtc = data[i][0] + (prevUtc || 0);
    const curVal = cleanValue(data[i][1]);
    prevVal = curVal != null ? (Number(curVal) + (Number(prevVal) || 0)) : null;
    decompressedData.push([
      prevUtc,
      prevVal !== null ? Math.round((prevVal + Number.EPSILON) * mul) / mul : null
    ]);
  }

  return decompressedData;
}

function clean (data: SeriesData, { decimals = 6 } = {}): SeriesData {
  if (!Array.isArray(data) || data.length === 0) { return []; }

  const res: SeriesData = [];
  const mul = Math.pow(10, decimals);

  for (let i = 0; i < data.length; i++) {
    if (data[i][0] == null) { continue; }
    let curVal = cleanValue(data[i][1]);
    curVal = curVal == null ? curVal : Math.round((Number(curVal) + Number.EPSILON) * mul) / mul;

    res.push([data[i][0], curVal]);
  }

  return res;
}

function tidyFormatDataByNode (dataByNodeId: TidyFormatDataByNodeInput): TidyFormatDataByNodeOutput[] {
  if (dataByNodeId) {
    return Object.entries(dataByNodeId).flatMap(([node, data]) => {
      if (Array.isArray(data)) {
        return clean(data).map(([timestamp, value]) => ({ node, timestamp, value }));
      }
      return [];
    });
  }
  return [];
}

function tidyAggregateNodeData (
  tidyData: TidyFormatDataByNodeOutput[],
  aggregator: TidyAggregateNodeDataAggregator,
  { groupBy, decimals = 2 }: { groupBy?: keyof TidyFormatDataByNodeOutput | undefined; decimals?: number } = {}
) {
  if (!Array.isArray(tidyData)) { return 0; }
  if (groupBy) {
    return rollups(
      tidyData,
      (D: TidyFormatDataByNodeOutput[]) => D.filter(v => v.value !== null).length > 0
        ? aggregator(D, (d) => Utils.isNum(d.value) ? Utils.round(d.value, decimals) : d.value)
        : null,
      (d: TidyFormatDataByNodeOutput) => d[groupBy]
    );
  } else {
    return tidyData.filter(d => d.value !== null).length > 0
      ? aggregator(tidyData, (d) => Utils.isNum(d.value) ? Utils.round(d.value, decimals) : d.value)
      : null;
  }
}

export default {
  compress,
  decompress,
  clean,
  tidyFormatDataByNode,
  tidyAggregateNodeData,
  TYPE_NUM,
  TYPE_STR
};

export {
  compress,
  decompress,
  clean,
  tidyFormatDataByNode,
  tidyAggregateNodeData,
  TYPE_NUM,
  TYPE_STR
};

export type {
  DataSample,
  SeriesData,
  TidyFormatDataByNodeInput,
  TidyFormatDataByNodeOutput,
  TidyAggregateNodeDataAggregator
};
