const sandboxProxies = new WeakMap();
const saferEvalHas = function () { return true; };
const saferEvalGet = function (target, key) { if (key === Symbol.unscopables) return undefined; return target[key]; };
function saferEval (codeStr, context) {
  context = typeof context === 'object' ? context : {};
  codeStr = 'with (sandbox) { return ' + codeStr + '}';
  // eslint-disable-next-line no-new-func
  const code = new Function('sandbox', codeStr);

  if (!sandboxProxies.has(context)) {
    const sandboxProxy = new Proxy(context, { has: saferEvalHas, get: saferEvalGet });
    sandboxProxies.set(context, sandboxProxy);
  }
  return code(sandboxProxies.get(context));
}

export default saferEval;
