import CnsModule from './views/cns-module.vue';
import _ from 'lodash';

function createCnsModuleRoute (opt) {
  if (!opt || typeof opt.path !== 'string' || !opt.module) { return; }

  return Object.assign({}, _.cloneDeep(opt), {
    path: opt.path,
    children: true, // (typeof opt.module === 'function' ? opt.module()?.type : opt.module?.type) === 'systemjs', // only systemjs modules can have children routes
    props: () => ({
      props: typeof opt.props === 'function' ? opt.props() : opt.props,
      module: typeof opt.module === 'function' ? opt.module() : opt.module,
      isRoute: true
    }),
    component: CnsModule
  });
}

function createCnsModule ({ initApp }) {
  let appInstance;
  const bootstrap = function (props) {
    return Promise.resolve().then(() => {
      return initApp(props);
    }).then((app) => {
      if (!app) {
        throw new Error('[bootstrap] No app returned by initApp function');
      }
      appInstance = app;
    });
  };

  const mount = function (el) {
    if (!appInstance) {
      throw new Error('[mount] No app to mount');
    }

    appInstance.mount(el);
  };

  const unmount = function () {
    if (!appInstance) {
      throw new Error('[unmount] No app to unmount');
    }

    appInstance.unmount();
  };

  const start = function (el, props) {
    return bootstrap(props).then(() => {
      mount(el);
    });
  };

  return { start, bootstrap, mount, unmount };
}

export default {
  createCnsModule,
  createCnsModuleRoute,
  CnsModule
};

export {
  createCnsModule,
  createCnsModuleRoute,
  CnsModule
};
