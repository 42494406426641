<script setup>
import { computed, inject, ref } from 'vue';
import CnsActions from '../cns/cns-actions.vue';
import CnsModal from '../cns/cns-modal.vue';
import CnsSettingsForm from './cns-settings-form.vue';

const $edw = inject('$edw');
const $cns = inject('$cns');

const props = defineProps({
  title: { type: String },
  label: { type: String, required: true },
  edwEditor: { type: Object, required: true }
});
const emit = defineEmits(['confirm']);

const loading = ref(true);
const modal = ref(null);

const rows = ref([]);
const value = ref({});

const actions = computed(() => ([
  {
    text: $edw.cancel,
    icon: 'cancel',
    variant: 'secondary',
    action: cancel
  },
  {
    text: $edw.confirm,
    icon: 'check',
    variant: 'primary',
    action: confirm
  }
]));

function cancel () {
  close();
}

function confirm () {
  Object.keys(value.value).forEach((langId) => {
    props.edwEditor?.setOnLanguage?.(langId, props.label, value.value[langId]);
  });
  close();
  emit('confirm');
}

async function open () {
  loading.value = true;
  modal.value.open();
  await $cns.loadSysLanguages();
  const tmpRows = [];
  const tmpValue = {};

  $cns.sysLanguages.forEach((lang) => {
    tmpRows.push({ label: lang.name, field: lang.id });
    tmpValue[lang.id] = props.edwEditor?.getOnLanguage?.(lang.id, props.label);
  });

  rows.value = tmpRows;
  value.value = tmpValue;
  loading.value = false;
}
function close () { modal.value.close(); }
defineExpose({ open, close });
</script>

<template>
  <cns-modal ref="modal" :title="props.title" scrollable>
    <template #actions>
      <cns-actions :visible="2" :actions="actions" />
    </template>
    <cns-settings-form :editable="true" :rows="rows" v-model="value" :loading="loading"/>
  </cns-modal>
</template>
