<script lang="ts">
import { ref, watch, inject } from 'vue';
import GraphQl from '../../../libs/graphql-client/index.mjs';
import CnsButton from '../cns-button.vue';
import CnsListItem from '../cns-list-item.vue';
import CnsSettingsFormRow from '../../cns-settings/cns-settings-form-row.vue';
import Utils from '../../../libs/utils/index.mjs';
import DataloggerSelectorModal from './cns-datalogger-selector-modal.vue';
import { Datalogger } from './types';

type LoadDataloggerDescArg = { id: string; }
const loadDataloggerDesc = Utils.batch<LoadDataloggerDescArg, Datalogger | undefined>((args) => {
  const dataloggersIds = new Set();
  args.map((arg: LoadDataloggerDescArg) => dataloggersIds.add(arg.id));

  return GraphQl.query(`
    Datalogger_get(id: [${[...dataloggersIds]}]) {
      id
      sn
      name
    }
  `).then((res: Datalogger[]) =>
    args.map((arg: LoadDataloggerDescArg) =>
      res.find((datalogger: Datalogger) => String(datalogger.id) === String(arg.id))
    )
  );
}, { cacheBy: 'id' });
</script>

<script setup lang="ts">
const $edw = inject<any>('$edw');

defineOptions({ inheritAttrs: false });
const props = defineProps<{
  label: string;
  class?: any;
  style?: any;
}>();
const value = defineModel<string | undefined>();

const modal = ref<any>();
const loading = ref<boolean>(false);
const datalogger = ref<Datalogger | undefined>();

watch(value, async () => {
  datalogger.value = undefined;
  if (!value.value) { return; }

  loading.value = true;
  datalogger.value = await loadDataloggerDesc({ id: value.value }).catch(() => undefined);
  loading.value = false;
}, { immediate: true });

defineExpose({ getSelected: () => datalogger.value });
</script>

<template>
  <cns-settings-form-row :label="props.label" :class="props.class" :style="props.style">
    <template #value>
      <div class="d-flex align-items-center gap-2">
        <cns-list-item
          style="width: 250px;"
          :icon="loading ? 'spinner-third spin fw' : 'meter-bolt fw'"
          :label="datalogger ? datalogger.name : $edw.noDataloggerSelected"
          :caption="
            datalogger
              ? datalogger.sn
              : $edw.clickToSelectADatalogger
          "
          @click="modal.open()"
        >
          <template #suffix>
            <cns-button
              v-if="datalogger"
              icon="xmark"
              variant="link"
              size="sm"
              @click.stop="() => { value = undefined }"
            />
          </template>
        </cns-list-item>
      </div>
      <datalogger-selector-modal ref="modal" v-model="value" :title="props.label" v-bind="$attrs" />
    </template>
  </cns-settings-form-row>
</template>
