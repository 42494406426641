<script lang="ts">
import { inject, watch, ref } from 'vue';
import { Fop } from '../../libs/fop-utils/index.browser.mjs';
import GraphQl from '../../libs/graphql-client/index.mjs';
import CnsAlert from './cns-alert.vue';
import CnsButton from './cns-button.vue';
import CnsModal from './cns-modal.vue';
import CnsList from './cns-list.vue';
import CnsListItem from './cns-list-item.vue';
import CnsFilterInput from './cns-filter-input.vue';

type NodeUser = {
  id: string;
  username: string;
  name: string;
  surname: string;
}

const usersCache: { [key: string]: NodeUser[] } = {};
</script>

<script setup lang="ts">
const $edw = inject<any>('$edw');

const props = defineProps<{
  nodeId?: string;
  installationId?: string;
}>();

const error = ref<string | undefined>();
const userSelectModal = ref<any>();

const associatedUsersLoading = ref<boolean>(false);
const associatedUsers = ref<NodeUser[]>([]);
const associatedUsersAdding = ref<string[] | undefined>();
const associatedUsersRemoving = ref<string[] | undefined>();
const unassociatedUsersFilter = ref<string>('');
const unassociatedUsersLoading = ref<boolean>(false);
const unassociatedUsers = ref<NodeUser[]>([]);
const unassociatedUsersSelected = ref<string[]>([]);

async function loadAssociatedUsers (force: boolean = false) {
  if (!props.nodeId && !props.installationId) { return; }

  associatedUsersLoading.value = true;
  if (props.nodeId) {
    if (force || !usersCache[props.nodeId]) {
      usersCache[props.nodeId] = await GraphQl.query(`
        Node_get(id: ${props.nodeId}, noCache: true) {
          id
          usersWrite {
            id
            username
            name
            surname
          }
        }
      `).then((res: any) =>
        new Fop().order('username', 1).apply(res?.[0]?.usersWrite)
      ).catch((err: Error) => {
        error.value = $edw.translateError(err);
      });
    }
    associatedUsers.value = usersCache[props.nodeId];
  } else if (props.installationId) {
    if (force || !usersCache[props.installationId]) {
      usersCache[props.installationId] = await GraphQl.query(`
        Installation_get(id: ${props.installationId}, noCache: true) {
          id
          users {
            id
            username
            name
            surname
          }
        }
      `).then((res: any) =>
        new Fop().order('username', 1).apply(res?.[0]?.users)
      ).catch((err: Error) => {
        error.value = $edw.translateError(err);
      });
    }
    associatedUsers.value = usersCache[props.installationId];
  }

  associatedUsersLoading.value = false;
}

async function loadUnassociatedUsers () {
  if (unassociatedUsersLoading.value) { return; }
  unassociatedUsersLoading.value = true;

  let _fop = new Fop()
    .order('username', 1)
    .filter('userType', '!=', 'superadmin')
    .filter('id', '!=', associatedUsers.value?.map((user) => user.id) ?? []);

  if (unassociatedUsersFilter.value) {
    const filters = unassociatedUsersFilter.value.split(' ').map((el) => el.trim()).filter((el) => el.length > 0);
    filters.forEach((filter) => {
      _fop = _fop.filter({
        or: [
          { field: 'username', op: '%', val: filter },
          { field: 'name', op: '%', val: filter },
          { field: 'surname', op: '%', val: filter }
        ]
      });
    });
  }

  unassociatedUsers.value = await GraphQl.query(
    `User_get(fop: $fop, noCache: true) {
      id
      username
      name
      surname
    }`,
    { fop: { type: 'FilterOrderPaginate', value: _fop.serialize() } }
  )
    .catch((err: Error) => {
      error.value = $edw.translateError(err);
    });
  unassociatedUsersLoading.value = false;
}

async function addUsersToNode (usersIds: string[]) {
  if (!props.nodeId && !props.installationId) { return; }
  if (usersIds.length === 0) { return; }
  if (associatedUsersAdding.value != null) { return; }

  associatedUsersAdding.value = usersIds;
  await Promise.all([
    props.nodeId
      ? GraphQl.mutation(`
        Node_save(entities: $entities) { id }
      `, {
        entities: {
          type: '[NodeInput!]!',
          value: [{
            id: props.nodeId,
            usersWrite: { add: usersIds }
          }]
        }
      })
      : Promise.resolve(),
    props.installationId
      ? GraphQl.mutation(`
        Installation_save(entities: $entities) { id }
      `, {
        entities: {
          type: '[InstallationInput!]!',
          value: [{
            id: props.installationId,
            users: { add: usersIds }
          }]
        }
      })
      : Promise.resolve()
  ]);

  await loadAssociatedUsers(true);
  associatedUsersAdding.value = undefined;
}

async function removeUsersFromNode (usersIds: string[]) {
  if (!props.nodeId && !props.installationId) { return; }
  if (usersIds.length === 0) { return; }
  if (associatedUsersRemoving.value != null) { return; }

  associatedUsersRemoving.value = usersIds;
  await Promise.all([
    props.nodeId
      ? GraphQl.mutation(`
        Node_save(entities: $entities) { id }
      `, {
        entities: {
          type: '[NodeInput!]!',
          value: [{
            id: props.nodeId,
            usersWrite: { delete: usersIds }
          }]
        }
      })
      : Promise.resolve(),
    props.installationId
      ? GraphQl.mutation(`
        Installation_save(entities: $entities) { id }
      `, {
        entities: {
          type: '[InstallationInput!]!',
          value: [{
            id: props.installationId,
            users: { delete: usersIds }
          }]
        }
      })
      : Promise.resolve()
  ]);

  // No need to forcefully reload all associated users, just remove them from cache
  if (props.nodeId) {
    usersCache[props.nodeId] = usersCache[props.nodeId].filter((user) => !usersIds.includes(user.id));
  } else if (props.installationId) {
    usersCache[props.installationId] = usersCache[props.installationId].filter((user) => !usersIds.includes(user.id));
  }
  await loadAssociatedUsers();
  associatedUsersRemoving.value = undefined;
}

function openAddUsersModal () {
  unassociatedUsersSelected.value = [];
  loadUnassociatedUsers();
  userSelectModal.value.open();
}

async function closeAddUsersModal (save: boolean) {
  if (save) {
    await addUsersToNode(unassociatedUsersSelected.value);
  }
  userSelectModal.value.close();
  unassociatedUsersSelected.value = [];
}

function toggleSelectUser (userId: string) {
  if (unassociatedUsersSelected.value.includes(userId)) {
    unassociatedUsersSelected.value = unassociatedUsersSelected.value.filter((id) => id !== userId);
  } else {
    unassociatedUsersSelected.value.push(userId);
  }
}

watch(unassociatedUsersFilter, () => loadUnassociatedUsers());
watch(() => [props.nodeId, props.installationId], () => { loadAssociatedUsers(); }, { immediate: true });
</script>

<template>
  <div>
    <cns-alert v-if="error" variant="danger" :actions="[{ text: $edw.ok, action: () => { error = undefined; } }]">
      {{ error }}
    </cns-alert>
    <div class="d-flex flex-column border border-1 rounded p-2">
      <div class="d-flex justify-content-between border-bottom border-1 px-2">
        <span>{{ $edw.users }}</span>
        <cns-button
          size="sm"
          icon="plus"
          variant="link"
          :disabled="associatedUsersLoading || associatedUsersRemoving != null || associatedUsersAdding != null"
          :loading="associatedUsersLoading"
          @click="openAddUsersModal"
        />
      </div>
      <div class="d-flex flex-column overflow-auto fs-5 pt-2" style="max-height: 200px;">
        <span v-if="associatedUsers.length === 0" class="text-muted fs-6 px-2">
          {{ $edw.noUsersAssociated }}
        </span>
        <cns-list-item
          v-for="user in associatedUsers"
          :key="user.id"
          class="flex-shrink-0"
          icon="user"
          :label="user.username"
          :caption="user.name + ' ' + user.surname"
        >
          <template #suffix>
            <cns-button
              size="sm"
              icon="xmark"
              variant="link"
              :disabled="associatedUsersRemoving != null"
              :loading="associatedUsersRemoving?.includes(user.id)"
              @click="removeUsersFromNode([user.id])"
            />
          </template>
        </cns-list-item>
      </div>
    </div>
    <cns-modal ref="userSelectModal" :title="$edw.users">
      <cns-filter-input
        v-model="unassociatedUsersFilter"
        class="w-100 mb-2"
        :placeholder="$edw.search"
        :disabled="unassociatedUsersLoading"
        lazy
      />
      <cns-list v-slot="{ item }" :items="unassociatedUsers">
        <cns-list-item
          icon="user"
          :label="item.username"
          :caption="item.name + ' ' + item.surname"
          :active="unassociatedUsersSelected.includes(item.id)"
          checkbox
          @click="() => toggleSelectUser(item.id)"
        />
      </cns-list>
      <div class="d-flex align-items-center justify-content-end gap-2 mt-2">
        <cns-button
          :text="$edw.cancel"
          variant="secondary"
          :disabled="associatedUsersAdding != null"
          @click="closeAddUsersModal(false)"
        />
        <cns-button
          :text="$edw.confirm"
          variant="primary"
          :loading="associatedUsersAdding != null"
          @click="closeAddUsersModal(true)"
        />
      </div>
    </cns-modal>
  </div>
</template>
