<script setup>
import { ref, nextTick, onUnmounted } from 'vue';
import _ from 'lodash';

const props = defineProps({
  target: { type: Object, default: undefined },
  keepOpenOnClick: { type: Boolean, default: false },
  opens: { type: String, default: 'auto' },
  drops: { type: String, default: 'auto' }
});

const distanceFromBorder = 20;
const dropdown = ref(null);
const isOpen = ref(false);
const style = ref({});
const targetSpan = ref(null);
const target = ref(null);

function toggle () {
  if (isOpen.value) {
    close();
  } else {
    open();
  }
}

function open () {
  if (props.target) {
    if (props.target.$el) {
      target.value = props.target.$el;
    } else if (props.target instanceof Element) {
      target.value = props.target;
    }
  } else {
    target.value = targetSpan.value;
  }

  isOpen.value = true;

  repositionDropdown();

  target.value && target.value.addEventListener && target.value.addEventListener('mousedown', stopPropagation);
  document.body.addEventListener('mousedown', close);
  document.body.addEventListener('scroll', close);
  window.addEventListener('resize', throttledRepositionDropdown);
}

function close (e) {
  if (!isOpen.value) { return; }

  if (e) { // e is only present if the event comes from the body listeners
    const dropdownBCR = dropdown.value.getBoundingClientRect();
    if (e.clientX >= dropdownBCR.left &&
        e.clientX <= dropdownBCR.left + dropdownBCR.width &&
        e.clientY >= dropdownBCR.top &&
        e.clientY <= dropdownBCR.top + dropdownBCR.height
    ) {
      return;
    }
  }

  isOpen.value = false;

  target.value && target.value.removeEventListener && target.value.removeEventListener('mousedown', stopPropagation);
  document.body.removeEventListener('mousedown', close);
  document.body.removeEventListener('scroll', close);
  window.removeEventListener('resize', throttledRepositionDropdown);

  target.value = null;
}

function repositionDropdown () {
  nextTick(() => {
    style.value = {};
    const dropdownBCR = dropdown.value.getBoundingClientRect();

    if (target.value) {
      const targetBCR = target.value.getBoundingClientRect();
      const scrollbarOffset = window.innerHeight < window.document.body.scrollHeight ? 16 : 0;

      if (props.opens === 'left') {
        style.value.right = (window.innerWidth - (targetBCR.left + targetBCR.width) - scrollbarOffset) + 'px';
      } else if (props.opens === 'right') {
        style.value.left = targetBCR.left + 'px';
      } else {
        if ((targetBCR.left + dropdownBCR.width) >= (window.innerWidth - distanceFromBorder)) {
          style.value.right = (window.innerWidth - (targetBCR.left + targetBCR.width) - scrollbarOffset) + 'px';
        } else {
          style.value.left = targetBCR.left + 'px';
        }
      }

      const distanceFromTop = targetBCR.top;
      const distanceFromBottom = window.innerHeight - (targetBCR.top + targetBCR.height);
      if (props.drops === 'up') {
        style.value.bottom = window.innerHeight - targetBCR.top + 'px';
        style.value['max-height'] = (distanceFromTop - distanceFromBorder) + 'px';
      } else if (props.drops === 'down') {
        style.value.top = (targetBCR.top + targetBCR.height) + 'px';
        style.value['max-height'] = (distanceFromBottom - distanceFromBorder) + 'px';
      } else {
        if (distanceFromBottom > 300 || distanceFromBottom > distanceFromTop) {
          style.value.top = (targetBCR.top + targetBCR.height) + 'px';
          style.value['max-height'] = (distanceFromBottom - distanceFromBorder) + 'px';
        } else {
          style.value.bottom = window.innerHeight - targetBCR.top + 'px';
          style.value['max-height'] = (distanceFromTop - distanceFromBorder) + 'px';
        }
      }
    } else {
      style.value.left = (window.innerWidth / 2 - dropdownBCR.width / 2) + 'px';
      style.value.top = (window.innerHeight / 2 - dropdownBCR.height / 2) + 'px';
      style.value['max-height'] = (window.innerHeight - distanceFromBorder * 2) + 'px';
    }
  });
}
const throttledRepositionDropdown = _.debounce(repositionDropdown, 500);
const stopPropagation = (e) => e.stopPropagation();

onUnmounted(() => { close(); });

defineExpose({
  toggle,
  open,
  close,
  get isOpen () { return isOpen.value; }
});
</script>

<!-- <script>
export default {
  inheritAttrs: false
};
</script> -->

<template>
  <span ref="targetSpan" class="d-inline-block">
    <slot name="target" :open="() => open()" :close="() => close()" :toggle="() => toggle()" :isOpen="isOpen"/>
    <teleport to="body">
      <transition>
        <div v-if="isOpen" ref="dropdown" class="cns-dropdown my-1 shadow" :style="style" @click.stop="() => props.keepOpenOnClick || close()">
          <slot name="content" :open="() => open()" :close="() => close()" :toggle="() => toggle()" :isOpen="isOpen">
            <slot :open="() => open()" :close="() => close()" :toggle="() => toggle()" :isOpen="isOpen"/>
          </slot>
        </div>
      </transition>
    </teleport>
  </span>
</template>

<style scoped>
.cns-dropdown {
  position: fixed;
  background-color: var(--hig-page-bg-light);
  z-index: 1060; /* modal z-index */
  transition: transform .2s ease, opacity .2s ease;
  border: 1px solid var(--hig-page-border-medium);
  border-radius: var(--hig-card-border-radius);
  overflow: auto;
}

.cns-dropdown.v-enter-from,
.cns-dropdown.v-leave-to {
  transform: translateY(-0.5rem);
  opacity: 0;
}
</style>
