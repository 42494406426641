<script setup>
import { ref, computed, inject } from 'vue';
import CnsNumber from '../cns/cns-number.vue';
import Utils from '../../libs/utils';
import CnsIcon from '../cns/cns-icon.vue';
const $edw = inject('$edw');

const props = defineProps({
  value: { type: [Number, String, Object, Array], default: null },
  max: { type: Number, default: null },
  label: { type: String, default: '' },
  icon: { type: String, default: null },
  um: { type: String, default: '' },
  loading: { type: Boolean, default: false },
  decimals: { type: Number, default: undefined },
  alwaysShowLegend: { type: Boolean, default: false }
});

const hoverIndex = ref(null);

const values = computed(() => {
  const propsValues = Array.isArray(props.value) ? props.value : [props.value];
  let colorIndex = 0;

  return propsValues.map((value) => {
    colorIndex = (colorIndex % 20) + 1;
    if (typeof value === 'object' && !Array.isArray(value)) {
      return {
        name: value?.name,
        label: value?.label,
        value: Array.isArray(value?.value) ? value?.value[value?.value?.length - 1]?.[1] : value?.value,
        timestamp: Array.isArray(value?.value) ? value?.value[value?.value?.length - 1]?.[0] : undefined,
        color: value?.color || `var(--hig-color-${colorIndex})`
      };
    } else {
      return {
        name: 'value',
        label: null,
        value: Array.isArray(value) ? value[value.length - 1]?.[1] : value,
        timestamp: Array.isArray(value) ? value[value.length - 1]?.[0] : undefined,
        color: `var(--hig-color-${colorIndex})`
      };
    }
  });
});

const valuesPerc = computed(() => {
  const max = props.max != null ? props.max : valuesTot.value;
  let offset = 0;
  return values.value.map((el) => {
    const res = {
      value: Utils.isNum(el.value) && Utils.isNum(max) && max > 0 ? Math.max(0, Math.min(1, Number(el.value) / max)) : 0,
      offset
    };
    offset += res.value;

    return res;
  });
});

const valuesTot = computed(() => {
  let atLeastOneValidValue = false;
  const tot = values.value.reduce((tot, el) => {
    if (Utils.isNum(el.value)) {
      atLeastOneValidValue = true;
      return tot + Number(el.value);
    }
    return tot;
  }, 0);

  return atLeastOneValidValue ? tot : null;
});

const centerLabel = computed(() => values.value[hoverIndex.value]?.label ?? props.label);
const centerValue = computed(() => values.value[hoverIndex.value]?.value ?? valuesTot.value ?? '---');
</script>

<template>
  <div class="d-flex align-items-end">
    <div class="value-circle d-flex flex-column align-items-center" :class="{ 'loading': props.loading }">
    <!-- <div v-if="props.label" class="label w-100 text-break text-center">{{ props.label }}</div> -->
    <div class="svg-container position-relative w-100">
      <svg class="value-svg" aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
        <circle class="progress-line" cx="50" cy="50" r="45" stroke-width="2" fill="none"/>
        <template v-if="props.loading">
          <path
            class="progress-bar loading"
            d="M 5 50 a 45 45 0 1 1 90 0 a 45 45 0 1 1 -90 0"
            fill="none"
            stroke="currentColor"
            stroke-width="8"
            stroke-linecap="round"
          />
        </template>
        <template v-else v-for="(value, i) in values" :key="i">
          <path
            class="progress-bar"
            d="M 5 50 a 45 45 0 1 1 90 0 a 45 45 0 1 1 -90 0"
            fill="none"
            :stroke="value.color"
            :stroke-width="hoverIndex === i ? 10 : 8"
            :stroke-dasharray="283"
            :stroke-dashoffset="283 - valuesPerc[i].value * 283"
            :transform="`rotate(${90 + valuesPerc[i].offset * 360} 50 50)`"
            :stroke-linecap="values.length > 1 ? 'butt' : 'round'"
            @mouseenter="hoverIndex = i"
            @mouseleave="hoverIndex = null"
          />
        </template>
      </svg>
      <div v-if="!props.loading" class="value-container position-absolute top-50 start-50 translate-middle d-flex flex-column align-items-center justify-content-center">
        <cns-icon v-if="props.icon" :type="`${props.icon} 2xl`"/>
        <span class="px-3 pt-3 w-100 fs-5 text-center" v-if="centerLabel">{{ $edw[centerLabel] }}</span>
        <cns-number class="value" :value="centerValue" :anim-start-delay="0" :decimals="props.decimals"/>
        <div class="um text-muted">{{ props.um }}</div>
      </div>
    </div>
  </div>
  <div v-if="values.length > 1 || props.alwaysShowLegend" class="d-flex flex-column">
    <template v-for="(value, i) in values" :key="i">
      <div v-if="value.label" class="legend-row d-flex gap-2 align-items-center" @mouseenter="hoverIndex = i" @mouseleave="hoverIndex = null">
        <slot :name="'label-' + value.name">
          <div class="legend-dot rounded-circle" :style="{ 'background-color': value.color, 'box-shadow': '0 0 0 1px ' + (hoverIndex === i ? value.color : 'transparent') }"></div>
          <span>{{ $edw[value.label] }}</span>
        </slot>
      </div>
    </template>
  </div>
  </div>
</template>

<style scoped>
.legend {
  transform: translate(50%, -50%);
}
.label {
  font-size: 1.5rem;
}

.svg-container {
  max-width: 200px;
}

.value-svg {
  --progress: 0;
  width: 100%;
  height: 100%;
}

.progress-line {
  stroke: var(--hig-page-text-muted);
}

.progress-bar {
  cursor: pointer;
  transition: .25s linear stroke-dashoffset, .25s linear stroke-dasharray, .15s linear stroke-width;
}

.legend-row {
  cursor: pointer;
}

.legend-dot {
  width: 10px;
  height: 10px;
  transition: .15s linear box-shadow;
}

.value-container {
  width: calc(100% - 45px);
  height: calc(100% - 45px);
  overflow: hidden;
  border-radius: 50%;
}

.value {
  font-size: 2rem;
  line-height: 3rem;
  font-weight: bold;
}

.progress-bar.loading {
  cursor: auto;
  stroke-dasharray: 35;
  animation: loading 2s linear infinite;
}

@keyframes loading {
  0% { stroke-dashoffset: 283; }
  100% { stroke-dashoffset: 0; }
}
</style>
