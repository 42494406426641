<script setup>
/* global HigJS */

import { watch, ref, nextTick, onMounted, onUnmounted } from 'vue';
import Utils from '../../libs/utils';

const props = defineProps({
  value: { type: [Number, String], default: 0 },
  format: { type: Function, default: undefined },
  prefix: { type: String, default: undefined },
  suffix: { type: String, default: undefined },
  decimals: { type: Number, default: 2 },
  animDuration: { type: Number, default: 300 },
  animStartDelay: { type: Number, default: 200 }
});

const currValDiv = ref(null);
const valueSpan = ref(null);

const value = ref(0);
const displayValCurr = ref('0');
const displayValPrev = ref('0');
let nextValue;
let animationRunning = true; // this will be set to false once the animationend lister was setup
let animationBackupTo;

watch(() => props.value, () => {
  nextValue = props.value;
  animateNextNumChange();
});

function formatValue (val) {
  if (typeof props.format !== 'function') {
    return HigJS.num.format(Utils.round(val, props.decimals, true));
  } else {
    return props.format(val);
  }
}

function animateNextNumChange ({ noAnimation } = {}) {
  if (animationRunning) { return; }
  if (nextValue == null) { return; }

  if (noAnimation || props.animDuration <= 0) {
    displayValCurr.value = Utils.isNum(nextValue) ? formatValue(nextValue) : nextValue;
    value.value = nextValue;
    nextValue = undefined;
  } else {
    animationRunning = true;
    requestAnimationFrame(() => {
      const valueNext = nextValue;
      nextValue = undefined;
      if (valueNext === value.value) { animationRunning = false; return; }

      displayValPrev.value = displayValCurr.value;
      displayValCurr.value = Utils.isNum(valueNext) ? formatValue(valueNext) : valueNext;

      if (!Utils.isNum(valueNext) || valueNext > value.value) {
        valueSpan.value && valueSpan.value.classList.add('enterFromTop');
      } else {
        valueSpan.value && valueSpan.value.classList.add('enterFromBot');
      }
      value.value = valueNext;

      animationBackupTo = setTimeout(animationEndCb, props.animDuration + 50);
    });
  }
}

function animationEndCb () {
  clearTimeout(animationBackupTo);
  valueSpan.value && valueSpan.value.classList.remove('enterFromTop');
  valueSpan.value && valueSpan.value.classList.remove('enterFromBot');
  requestAnimationFrame(() => {
    animationRunning = false;
    animateNextNumChange();
  });
}

onMounted(() => {
  nextValue = props.value;
  nextTick(() => {
    currValDiv.value && currValDiv.value.addEventListener('animationend', animationEndCb);

    animationRunning = false;
    setTimeout(() => animateNextNumChange({ noAnimation: props.animStartDelay <= 0 }), props.animStartDelay);
  });
});

onUnmounted(() => {
  currValDiv.value && currValDiv.value.removeEventListener('animationend', animationEndCb);
});
</script>

<template>
  <span class="cns-number" :style="{ '--animation-duration': `${props.animDuration}ms` }">
    <span class="prefix" v-if="props.prefix"><span>{{props.prefix}}</span></span>
    <span class="value" ref="valueSpan">
      <span class="prev">{{displayValPrev}}</span>
      <span class="curr" ref="currValDiv">{{displayValCurr}}</span>
    </span>
    <span class="suffix" v-if="props.suffix"><span>{{props.suffix}}</span></span>
  </span>
</template>

<style scoped>
.cns-number{
  --animation-duration: 300ms;
}

.cns-number > .value, .cns-number > .prefix, .cns-number > .suffix {
  position: relative;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
}

.cns-number > .value > .prev {
  font-variant-numeric: tabular-nums;
  display: none;
  position: absolute;
  left: 0;
}

.cns-number > .value > .curr {
  font-variant-numeric: tabular-nums;
  display: inline-block;
}

.cns-number > .value.enterFromTop > .prev,
.cns-number > .value.enterFromBot > .prev {
  display: inline-block;
}

.cns-number > .value.enterFromTop > .curr { transform: translateY(-100%); animation: currFromTop var(--animation-duration) ease; }
.cns-number > .value.enterFromTop > .prev { transform: translateY(0); animation: prevFromTop var(--animation-duration) ease; }

.cns-number > .value.enterFromBot > .curr { transform: translateY(100%); animation: currFromBot var(--animation-duration) ease; }
.cns-number > .value.enterFromBot > .prev { transform: translateY(0); animation: prevFromBot var(--animation-duration) ease; }

@keyframes currFromTop {
  from { transform: translateY(-100%); }
  to { transform: translateY(0); }
}

@keyframes prevFromTop {
  from { transform: translateY(0); }
  to { transform: translateY(100%); }
}

@keyframes currFromBot {
  from { transform: translateY(100%); }
  to { transform: translateY(0); }
}

@keyframes prevFromBot {
  from { transform: translateY(0); }
  to { transform: translateY(-100%); }
}
</style>
