<script setup>
import CnsDatepicker from '../../../cns/cns-datepicker.vue';
import CnsCard from '../../../cns/cns-card.vue';
import cnsButton from '../../../cns/cns-button.vue';
import cnsActions from '../../../cns/cns-actions.vue';
import { ref, watch, provide } from 'vue';
import { DateTime } from 'luxon';

const props = defineProps({
  timeZone: { type: String, default: 'UTC' }
});

// timeframe selezionato
const timeframe = ref('currDay');

// variabile di supporto per il calendario
const daterange = ref();

// variabile di supporto per lo zoom,
// conserva il valore per poterlo ripristizare dopo lo zoom
const resetValues = ref(timeframe.value);

// quando cambio il daterange aggiorno il timeframe selezionato
watch(daterange, (d) => {
  let start = DateTime.fromSeconds(d.start, { zone: props.timeZone });
  let stop = DateTime.fromSeconds(d.stop, { zone: props.timeZone });
  start = start.minus({ minutes: start.offset });
  stop = stop.minus({ minutes: stop.offset });

  if (start.toSeconds() === stop.toSeconds()) {
    start = start.startOf('day');
    stop = stop.endOf('day');
  }

  timeframe.value = { start: start.toSeconds(), stop: stop.toSeconds() };
  resetValues.value = timeframe.value;
});

// quando cambio lo zoom aggiorno il timeframe
const callbackZoom = (e) => {
  const nowTz = DateTime.utc().setZone(props.timeZone);
  timeframe.value = {
    start: e.min - nowTz.offset * 60 * 1000,
    stop: e.max - nowTz.offset * 60 * 1000
  };
};

const resetZoom = () => {
  if (!resetValues.value) {
    return;
  }
  timeframe.value = resetValues.value;
};
const initDateTime = DateTime.utc().toMillis();
const currDate = ref(initDateTime);

const step = ref({ days: 1 });

const move = (type) => {
  if (timeframe.value === 'currDay') {
    step.value = { days: 1 };
  } else if (timeframe.value === 'currWeek') {
    step.value = { weeks: 1 };
  } else if (timeframe.value === 'currMonth') {
    step.value = { months: 1 };
  }

  if (type === 'back') {
    const stop = currDate.value;
    currDate.value = Math.trunc(DateTime.fromMillis(currDate.value).minus(step.value).startOf('day').toMillis());
    const start = currDate.value;
    timeframe.value = { start, stop };
  } else {
    currDate.value = Math.trunc(DateTime.fromMillis(currDate.value).plus(step.value).startOf('day').toMillis());
    if (currDate.value > DateTime.utc()) {
      return;
    }
    const start = currDate.value;
    const stop = Math.trunc(DateTime.fromMillis(currDate.value).plus(step.value).startOf('day').toMillis());
    timeframe.value = { start, stop };
  }
};

provide('containerController', {
  timeframe,
  callbackZoom
});
</script>

<template>
  <cns-card class="rounded w-100 position-relative">
    <template #actions>
      <cns-button icon-only icon="arrow-left" @click="() => move('back')" />
      <cns-button
        icon-only
        icon="arrow-right"
        :disabled="currDate >= initDateTime"
        @click="() => move('forward')"
      />
      <cns-datepicker v-model="daterange" range icon-only :timezone="props.timeZone" />
      <cns-actions
        :actions="[
          { text: $edw.currDay, action: () => {
            timeframe = 'currDay';
            resetValues.value = timeframe.value;
          }
          },
          { text: $edw.currWeek, action: () => {
            timeframe = 'currWeek';
            resetValues.value = timeframe.value;
          }
          },
          { text: $edw.currMonth, action: () => {
            timeframe = 'currMonth';
            resetValues.value = timeframe.value;
          }
          },
          { text: $edw.resetZoom, action: resetZoom },
        ]"
        :visible="0"
      />
    </template>
    <slot />
  </cns-card>
</template>
