<template>
  <input v-if="plaintext" :value="curValueText" class="form-control form-control-plaintext" type="text" :placeholder="placeholder" readonly>
  <VueMultiselect v-else
    ref="multiselect"
    class="b-form-select form-select"
    :class="{ 'is-valid': state === true, 'is-invalid': state === false, 'form-select-sm': size === 'sm', 'form-select-lg': size === 'lg' }"
    :modelValue="value"
    @update:modelValue="emitUpdate"
    :options="options_"
    :multiple="multiple"
    :closeOnSelect="!multiple"
    :allowEmpty="multiple"
    :noOptionsText="$edw.noOptions"
    :noResultsText="$edw.noMatches"
    :selectLabel="''"
    :selectGroupLabel="''"
    :selectedLabel="''"
    :deselectLabel="''"
    :deselectGroupLabel="''"
    :placeholder="(curValueText && !multiple) ? curValueText : placeholder"
    :disabled="readonly"
    :searchable="searchable"
    trackBy="value"
    :custom-label="({ text, desc }) => text + (desc ? ' - ' + desc : '')"
    :limit="multipleDisplayLimit"
    @open="open"
  >
    <template #limit>
      <template v-if="val && Array.isArray(val) && val.length > multipleDisplayLimit">
        <strong class="multiselect__strong">{{ '+ ' + (val.length - multipleDisplayLimit) }}</strong>
      </template>
    </template>
    <template #option="optionProps">
      <slot name="option" :optionProps="optionProps"></slot>
    </template>
    <template #singleLabel="singleLabelProps">
      <slot name="singleLabel" :singleLabelProps="singleLabelProps"></slot>
    </template>
  </VueMultiselect>
</template>

<script>
import VueMultiselect from 'vue-multiselect';
import lodash from 'lodash';

export default {
  name: 'b-form-select',
  components: {
    VueMultiselect
  },
  inject: ['$edw'],
  props: {
    modelValue: { type: [String, Number, Array, Object], default: undefined },
    options: { type: Array, default: function () { return []; } },
    size: { type: String, default: undefined },
    state: { type: Boolean, default: undefined },
    multiple: { type: Boolean, default: false },
    placeholder: { type: String, default: '' },
    readonly: { type: Boolean, default: false },
    plaintext: { type: Boolean, default: false },
    searchable: { type: Boolean, default: false },
    multipleDisplayLimit: { type: Number, default: 999999 },
    idField: { type: String, default: 'id' }
  },
  emits: ['update:modelValue'],
  data () {
    return { val: this.modelValue };
  },
  computed: {
    options_: function () {
      return this.options.map((opt) => ({ value: opt.value, text: opt.text, desc: opt.desc, $isDisabled: opt.disabled }));
    },
    value: function () {
      if (this.val == null) {
        return null;
      } else if (this.multiple) {
        return this.getOps(this.val, this.options_);
      } else {
        return this.getOp(this.val, this.options_);
      }
    },
    curValueText: function () {
      if (this.val == null) {
        return '';
      } else if (this.multiple) {
        return this.getOps(this.val, this.options_)
          .map((opt) => opt.text).join(', ');
      } else {
        const curOpt = this.getOp(this.val, this.options_);
        return curOpt ? curOpt.text : '';
      }
    }
  },
  watch: {
    modelValue (v) {
      this.val = v;
    }
  },
  methods: {
    getOp (val, options) {
      return options.find((opt) => {
        let value = val;
        let optValue = opt.value;
        if (typeof val === 'object') {
          if (val && !lodash.get(val, this.idField)) { return false; }
          value = lodash.get(val, this.idField);
          optValue = lodash.get(opt.value, this.idField);
        }
        return String(optValue) === String(value);
      });
    },
    getOps (val, options) {
      return val.map((v) => {
        return this.getOp(v, options);
      }).filter((v) => v != null);
    },
    emitUpdate (newVal) {
      let emitVal = null;
      if (newVal != null) {
        if (this.multiple) {
          emitVal = newVal.map((opt) => opt.value);
        } else {
          emitVal = newVal.value;
        }
      }
      this.val = emitVal;
      this.$emit('update:modelValue', emitVal);
    },
    open () {
      // this stops the cns-dropdown from closing when the list extents outside it's boundaries
      this.$refs.multiselect?.$refs.list.addEventListener('mousedown', (e) => { e.stopPropagation(); });
    }
  }
};
</script>

<style lang="scss" scoped>
@use "sass:math";

$vue-multiselect-padding-x: 0.35rem !default;
$vue-multiselect-padding-x-sm: 0.3rem !default;
$vue-multiselect-padding-x-lg: 1.05rem !default;
$vue-multiselect-padding-y: 0.35rem !default;
$vue-multiselect-padding-y-sm: 0.2rem !default;
$vue-multiselect-padding-y-lg: 0.8rem !default;
$vue-multiselect-min-height: 2.5rem !default;
$vue-multiselect-min-height-sm: 2.15rem !default;
$vue-multiselect-min-height-lg: 3.75rem !default;
$vue-multiselect-border-radius: var(--hig-form-control-border-radius) !default;
$vue-multiselect-border-color: var(--hig-form-border) !default;
$vue-multiselect-border-width: 1px !default;
$vue-multiselect-disabled-opacity: 0.65 !default;
$vue-multiselect-placeholder-color: var(--hig-form-text) !default;
$vue-multiselect-bg: var(--hig-form-bg) !default;
$vue-multiselect-color: var(--hig-form-text) !default;
$vue-multiselect-disabled-bg: var(--hig-form-bg-disabled) !default;
$vue-multiselect-disabled-color: var(--hig-form-text) !default;
$vue-multiselect-active-shadow: 0 0 0 1px var(--hig-primary) !default;

$vue-multiselect-option-padding-x: 0.75rem !default;
$vue-multiselect-option-padding-x-sm: 0.4rem !default;
$vue-multiselect-option-padding-x-lg: 1.25rem !default;
$vue-multiselect-option-padding-y: 0.5rem !default;
$vue-multiselect-option-padding-y-sm: 0.5rem !default;
$vue-multiselect-option-padding-y-lg: 0.5rem !default;
$vue-multiselect-option-selected-bg: var(--hig-primary) !default;
$vue-multiselect-option-selected-color: var(--hig-primary-text) !default;
$vue-multiselect-option-highlight-color: var(--hig-neutral-light-900) !default;
$vue-multiselect-option-highlight-bg: var(--hig-neutral-light-200) !default;
$vue-multiselect-option-selected-highlight-color: var(--hig-primary-text) !default;
$vue-multiselect-option-selected-highlight-bg: var(--hig-primary) !default;

$vue-multiselect-tag-color: var(--hig-dark) !default;
$vue-multiselect-tag-bg: var(--hig-light) !default;
$vue-multiselect-tag-font-size: 0.75em !default;
$vue-multiselect-tag-font-weight: normal !default;
$vue-multiselect-tag-icon-size: 1.5rem !default;
$vue-multiselect-tag-icon-color: $vue-multiselect-tag-color !default;
$vue-multiselect-tag-icon-color-hover: white !default;
$vue-multiselect-tag-icon-bg-hover: var(--hig-danger) !default;
$vue-multiselect-tag-padding-x: .4rem !default;
$vue-multiselect-tag-padding-y: .25rem !default;
$vue-multiselect-tag-margin-x: .25rem !default;
$vue-multiselect-tag-margin-y: .25rem !default;
$vue-multiselect-tag-border-radius: calc(var(--hig-form-control-border-radius) / 2) !default;

$vue-multiselect-arrow-color: var(--hig-form-text) !default;
$vue-multiselect-arrow-size: 5px !default;
$vue-multiselect-arrow-padding: 8px !default;
$vue-multiselect-arrow-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>");

$vue-multiselect-spinner-size: 16px !default;
$vue-multiselect-spinner-thickness: 2px !default;
$vue-multiselect-spinner-margin: math.div(-$vue-multiselect-spinner-size, 2) !default;
$vue-multiselect-spinner-color: var(--hig-dark) !default;

$vue-multiselect-form-validation-states: (
  "valid": (
    "color": var(--hig-success),
    "shadow": 0 0 0 0.25rem var(--hig-form-shadow-valid),
    "icon": url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e")
  ),
  "invalid": (
    "color": var(--hig-danger),
    "shadow": 0 0 0 0.25rem var(--hig-form-shadow-invalid),
    "icon": url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e")
  )
);
$vue-multiselect-enable-validation-icons: true;
$vue-multiselect-form-select-feedback-icon-padding-end: 4.125rem;
$vue-multiselect-form-select-feedback-icon-position: top 0.55rem right 2.25rem; // center right 2.25rem;
$vue-multiselect-form-select-feedback-icon-size: calc(0.8em + 0.5rem) calc(0.8em + 0.5rem);
$vue-multiselect-input-focus-width: 0.25rem;

// --------------------------------
// Select field
//--

fieldset[disabled] .multiselect {
  pointer-events: none;
}

.multiselect,
:deep(.multiselect__input),
:deep(.multiselect__single) {
  font-family: inherit;
  font-size: inherit;
  touch-action: manipulation;
}

.multiselect {
  box-sizing: content-box;
  display: block;
  position: relative;
  width: 100%;
  min-height: $vue-multiselect-min-height;
  text-align: left;
  background: none;
  transition: border-color 0.15s ease-in-out;
  padding: 0 !important;
  border: none;

  & * {
    box-sizing: border-box;
  }

  &:focus {
    outline: none;
  }
}

.multiselect.form-select-sm {
  min-height: $vue-multiselect-min-height-sm;
}

.multiselect.form-select-lg {
  min-height: $vue-multiselect-min-height-lg;
}

.multiselect--active {
  z-index: 1060;
  box-shadow: none !important;

  // &:not(.multiselect--above) :deep(.multiselect__current),
  // &:not(.multiselect--above) :deep(.multiselect__input),
  // &:not(.multiselect--above) :deep(.multiselect__tags) {
  //   border-bottom-left-radius: 0;
  //   border-bottom-right-radius: 0;
  // }

  // &.multiselect--above :deep(.multiselect__current),
  // &.multiselect--above :deep(.multiselect__input),
  // &.multiselect--above :deep(.multiselect__tags) {
  //   border-top-left-radius: 0;
  //   border-top-right-radius: 0;
  // }

  & :deep(.multiselect__select) {
    transform: rotateZ(180deg);
  }
}

:deep(.multiselect__input),
:deep(.multiselect__single) {
  position: relative;
  display: inline-block;
  min-height: calc($vue-multiselect-min-height / 2);
  line-height: calc($vue-multiselect-min-height / 2);
  border: none;
  border-radius: $vue-multiselect-border-radius;
  background: $vue-multiselect-bg;
  padding: 0 0 0 $vue-multiselect-padding-x;
  width: calc(100%);
  transition: border 0.1s ease;
  box-sizing: border-box;
  margin-bottom: $vue-multiselect-padding-y;
  vertical-align: top;
  margin-top: 4px;
  color: inherit;
}

.multiselect.form-select-sm :deep(.multiselect__single),
.multiselect.form-select-sm :deep(.multiselect__input) {
  min-height: calc($vue-multiselect-min-height-sm / 2);
  line-height: calc($vue-multiselect-min-height-sm / 2);
  padding: 0 0 0 $vue-multiselect-padding-x-sm;
  margin-bottom: $vue-multiselect-padding-y-sm;
}

.multiselect.form-select-lg :deep(.multiselect__single),
.multiselect.form-select-lg :deep(.multiselect__input) {
  min-height: calc($vue-multiselect-min-height-lg / 2);
  line-height: calc($vue-multiselect-min-height-lg / 2);
  padding: 0 0 0 $vue-multiselect-padding-x-lg;
  margin-bottom: $vue-multiselect-padding-y-lg;
}

:deep(.multiselect__input)::placeholder {
  color: $vue-multiselect-placeholder-color;
  opacity: .4;
}

:deep(.multiselect__tag ~ .multiselect__input),
:deep(.multiselect__tag ~ .multiselect__single) {
  width: auto;
}

:deep(.multiselect__input:hover),
:deep(.multiselect__single:hover) {
  border-color: #cfcfcf;
}

:deep(.multiselect__input:focus),
:deep(.multiselect__single:focus) {
  border-color: #a8a8a8;
  outline: none;
}

:deep(.multiselect__tags-wrap) {
  display: inline
}

:deep(.multiselect__tags) {
  color: $vue-multiselect-color;
  background: $vue-multiselect-bg;
  min-height: $vue-multiselect-min-height;
  display: block;
  padding: $vue-multiselect-padding-y $vue-multiselect-min-height 0 $vue-multiselect-padding-x;
  border-radius: $vue-multiselect-border-radius;
  border: $vue-multiselect-border-width solid $vue-multiselect-border-color;
  // background: $vue-multiselect-bg;
  font-family: inherit;
  font-size: inherit;
  transition: box-shadow 0.15s ease-in-out;
}

.multiselect.form-select :deep(.multiselect__tags) {
  font-size: 1.1rem;
}

.multiselect.form-select-sm :deep(.multiselect__tags) {
  font-size: 0.75rem;
  min-height: $vue-multiselect-min-height-sm;
  padding: $vue-multiselect-padding-y-sm $vue-multiselect-min-height-sm 0 $vue-multiselect-padding-x-sm;
}

.multiselect.form-select-lg :deep(.multiselect__tags) {
  font-size: 1rem;
  min-height: $vue-multiselect-min-height-lg;
  padding: $vue-multiselect-padding-y-lg $vue-multiselect-min-height-lg 0 $vue-multiselect-padding-x-lg;
}

.multiselect--active :deep(.multiselect__tags) {
  box-shadow: $vue-multiselect-active-shadow;
}

:deep(.multiselect__tag) {
  // position: relative;
  // display: inline-block;
  // padding: $vue-multiselect-tag-padding-y ($vue-multiselect-tag-icon-size + $vue-multiselect-tag-padding-x) $vue-multiselect-tag-padding-y $vue-multiselect-tag-padding-x;
  border-radius: $vue-multiselect-tag-border-radius;
  margin-right: $vue-multiselect-tag-margin-x;
  margin-bottom: $vue-multiselect-tag-margin-y;
  color: $vue-multiselect-tag-color;
  background: $vue-multiselect-tag-bg;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  font-size: $vue-multiselect-tag-font-size;
  font-weight: $vue-multiselect-tag-font-weight;

  // custom
  display: inline-flex;
  align-items: center;
  padding: 0;
  line-height: 1;
  vertical-align: baseline;
}

:deep(.multiselect__tag > span) {
  margin: $vue-multiselect-tag-padding-y $vue-multiselect-tag-padding-x;
}

:deep(.multiselect__tag-icon) {
  cursor: pointer;
  margin-left: $vue-multiselect-tag-padding-y;
  // position: absolute;
  // right: 0;
  // top: 0;
  // bottom: 0;
  font-style: initial;
  width: $vue-multiselect-tag-icon-size;
  // text-align: center;
  // line-height: $vue-multiselect-tag-icon-size;
  transition: all 0.2s ease;
  font-size: $vue-multiselect-tag-font-size;
  font-weight: $vue-multiselect-tag-font-weight;

  // custom
  min-height: $vue-multiselect-tag-icon-size;
  align-self: stretch;
}

:deep(.multiselect__tag-icon:after) {
  content: "×";
  color: $vue-multiselect-tag-icon-color;
  font-size: 2.6em;

  // custom
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

:deep(.multiselect__tag-icon:focus),
:deep(.multiselect__tag-icon:hover) {
  background: $vue-multiselect-tag-icon-bg-hover;
}

:deep(.multiselect__tag-icon:focus:after),
:deep(.multiselect__tag-icon:hover:after) {
  color: $vue-multiselect-tag-icon-color-hover;
}

:deep(.multiselect__current) {
  line-height: calc($vue-multiselect-min-height / 2);
  min-height: $vue-multiselect-min-height;
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  padding: 8px 30px 0 12px;
  white-space: nowrap;
  margin: 0;
  text-decoration: none;
  border-radius: $vue-multiselect-border-radius;
  border: 1px solid $vue-multiselect-border-color;
  cursor: pointer;
}

.multiselect.form-select-sm :deep(.multiselect__current) {
  line-height: calc($vue-multiselect-min-height-sm / 2);
  min-height: $vue-multiselect-min-height-sm;
}

.multiselect.form-select-lg :deep(.multiselect__current) {
  line-height: calc($vue-multiselect-min-height-lg / 2);
  min-height: $vue-multiselect-min-height-lg;
}

:deep(.multiselect__select) {
  line-height: calc($vue-multiselect-min-height / 2);
  display: block;
  position: absolute;
  box-sizing: border-box;
  width: $vue-multiselect-min-height;
  height: $vue-multiselect-min-height;
  right: 0;
  top: 0;
  padding: ($vue-multiselect-arrow-padding) $vue-multiselect-arrow-padding;
  margin: 0;
  text-decoration: none;
  text-align: center;
  // cursor: pointer;
  transition: transform 0.2s ease;
  background-image: $vue-multiselect-arrow-icon;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px 12px;
}

.multiselect.form-select-sm :deep(.multiselect__current) {
  line-height: calc($vue-multiselect-min-height-sm / 2);
  width: $vue-multiselect-min-height-sm;
  height: $vue-multiselect-min-height-sm;
}

.multiselect.form-select-lg :deep(.multiselect__current) {
  line-height: calc($vue-multiselect-min-height-lg / 2);
  width: $vue-multiselect-min-height-lg;
  height: $vue-multiselect-min-height-lg;
}

// :deep(.multiselect__select:before) {
//   position: relative;
//   right: 0;
//   top: 50%;
//   color: $vue-multiselect-arrow-color;
//   border-style: solid;
//   border-width: $vue-multiselect-arrow-size $vue-multiselect-arrow-size 0 $vue-multiselect-arrow-size;
//   border-color: $vue-multiselect-arrow-color transparent transparent transparent;
//   content: "";
// }

:deep(.multiselect__placeholder) {
  color: $vue-multiselect-placeholder-color;
  opacity: .4;
  display: inline-block;
  margin-bottom: 0;
  padding-top: 0;
  margin-left: $vue-multiselect-padding-x;
  margin-top: 0px;
}

.multiselect.form-select-sm :deep(.multiselect__placeholder) {
  margin-left: $vue-multiselect-padding-x-sm;
}

.multiselect.form-select-lg :deep(.multiselect__placeholder) {
  margin-left: $vue-multiselect-padding-x-lg;
}

.multiselect--active :deep(.multiselect__placeholder) {
  display: none;
}

:deep(.multiselect__content-wrapper) {
  color: $vue-multiselect-color;
  margin-top: .25rem;
  margin-bottom: .25rem;
  position: absolute;
  display: block;
  background: $vue-multiselect-bg;
  width: 100%;
  max-height: 240px;
  overflow: auto;
  border: $vue-multiselect-border-width solid $vue-multiselect-border-color;
  border-radius: $vue-multiselect-border-radius;
  z-index: 1060;
  -webkit-overflow-scrolling: touch;
  padding: 0 .25rem .25rem .25rem; /* No padding on top cause it's already on multiselect__element */
}

.multiselect.form-select-sm :deep(.multiselect__content-wrapper) {
  font-size: 0.75rem;
}

.multiselect.form-select-lg :deep(.multiselect__content-wrapper) {
  font-size: 1rem;
}

:deep(.multiselect__content) {
  list-style: none;
  display: inline-block;
  padding: 0;
  margin: 0;
  min-width: 100%;
  vertical-align: top;
}

.multiselect--above :deep(.multiselect__content-wrapper) {
  bottom: 100%;
  border-radius: $vue-multiselect-border-radius;
  border-top: $vue-multiselect-border-width solid $vue-multiselect-border-color;
}

:deep(.multiselect__content::-webkit-scrollbar) {
  display: none;
}

:deep(.multiselect__element) {
  display: block;
  margin-top: .25rem;
}

:deep(.multiselect__option) {
  display: block;
  padding: $vue-multiselect-option-padding-y $vue-multiselect-option-padding-x;
  // min-height: $vue-multiselect-min-height;
  line-height: calc($vue-multiselect-min-height / 2);
  text-decoration: none;
  text-transform: none;
  // vertical-align: middle;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
  border-radius: $vue-multiselect-border-radius;
}

.multiselect.form-select-sm :deep(.multiselect__option) {
  padding: $vue-multiselect-option-padding-y-sm $vue-multiselect-option-padding-x-sm;
  // min-height: $vue-multiselect-min-height-sm;
  line-height: calc($vue-multiselect-min-height-sm / 2);
}

.multiselect.form-select-lg :deep(.multiselect__option) {
  padding: $vue-multiselect-option-padding-y-lg $vue-multiselect-option-padding-x-lg;
  // min-height: $vue-multiselect-min-height-lg;
  line-height: calc($vue-multiselect-min-height-lg / 2);
}

:deep(.multiselect__option:after) {
  top: 0;
  right: 0;
  position: absolute;
  line-height: $vue-multiselect-min-height;
  padding-right: 12px;
  padding-left: 20px;
  font-family: inherit;
  font-size: vcar(--hig-font-size-s);
}

.multiselect.form-select-sm :deep(.multiselect__option:after) {
  line-height: $vue-multiselect-min-height-sm;
}

.multiselect.form-select-lg :deep(.multiselect__option:after) {
  line-height: $vue-multiselect-min-height-lg;
}

:deep(.multiselect__option--highlight) {
  background: $vue-multiselect-option-highlight-bg;
  outline: none;
  color: $vue-multiselect-option-highlight-color;
}

:deep(.multiselect__option--highlight:after) {
  content: attr(data-select);
  background: $vue-multiselect-option-highlight-bg;
  color: $vue-multiselect-option-highlight-color;
}

:deep(.multiselect__option--selected) {
  background: $vue-multiselect-option-selected-bg;
  color: $vue-multiselect-option-selected-color;
}

:deep(.multiselect__option--selected:after) {
  content: attr(data-selected);
  color: silver;
}

:deep(.multiselect__option--selected.multiselect__option--highlight) {
  background: $vue-multiselect-option-selected-highlight-bg;
  color: $vue-multiselect-option-selected-highlight-color;
}

:deep(.multiselect__option--selected.multiselect__option--highlight:after) {
  content: attr(data-deselect);
  background: $vue-multiselect-option-selected-highlight-bg;
  color: $vue-multiselect-option-selected-highlight-color;
}

.multiselect--disabled {
  background: $vue-multiselect-disabled-bg;
  pointer-events: none;

  --hig-form-bg: var(--hig-form-bg-disabled);
}

.multiselect--disabled :deep(.multiselect__tags) {
  border-color: var(--hig-form-border-disabled);
}

:deep(.multiselect__option--disabled) {
  background: $vue-multiselect-disabled-bg;
  color: $vue-multiselect-disabled-color;
  cursor: text;
  pointer-events: none;
  opacity: .5;
}

:deep(.multiselect__option--group) {
  background: $vue-multiselect-disabled-bg;
  color: $vue-multiselect-disabled-color;

  &.multiselect__option--highlight {
    background: $vue-multiselect-disabled-color;
    color: $vue-multiselect-disabled-bg;
  }

  &.multiselect__option--highlight:after {
    background: $vue-multiselect-disabled-color;
  }
}

:deep(.multiselect__option--disabled.multiselect__option--highlight) {
  background: $vue-multiselect-disabled-bg;
}

:deep(.multiselect__option--group-selected.multiselect__option--highlight) {
  background: $vue-multiselect-option-selected-highlight-bg;
  color: $vue-multiselect-option-selected-highlight-color;
}

:deep(.multiselect__option--group-selected.multiselect__option--highlight:after) {
  content: attr(data-deselect);
  background: $vue-multiselect-option-selected-highlight-bg;
  color: $vue-multiselect-option-selected-highlight-color;
}

:deep(.multiselect-enter-active),
:deep(.multiselect-leave-active) {
  transition: all 0.15s ease;
}

:deep(.multiselect-enter),
:deep(.multiselect-leave-active) {
  opacity: 0;
}

:deep(.multiselect__strong) {
  margin-bottom: $vue-multiselect-padding-y;
  line-height: calc($vue-multiselect-min-height / 2);
  // display: inline-block;
  vertical-align: top;
}

.multiselect.form-select-sm :deep(.multiselect__strong) {
  margin-bottom: $vue-multiselect-padding-y-sm;
  line-height: calc($vue-multiselect-min-height-sm / 2);
}

.multiselect.form-select-lg :deep(.multiselect__strong) {
  margin-bottom: $vue-multiselect-padding-y-lg;
  line-height: calc($vue-multiselect-min-height-lg / 2);
}

// --------------------------------
// Spinner
//--

:deep(.multiselect__spinner) {
  position: absolute;
  right: 0;
  top: 0;
  width: $vue-multiselect-min-height;
  height: $vue-multiselect-min-height;
  display: block;

  &:before,
  &:after {
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    margin: $vue-multiselect-spinner-margin 0 0 $vue-multiselect-spinner-margin;
    width: $vue-multiselect-spinner-size;
    height: $vue-multiselect-spinner-size;
    border-radius: 100%;
    border: $vue-multiselect-spinner-thickness solid transparent;
    border-top-color: $vue-multiselect-spinner-color;
    box-shadow: 0 0 0 1px transparent;
    background: $vue-multiselect-bg;
  }

  &:before {
    animation: spinning 2.4s cubic-bezier(0.41, 0.26, 0.2, 0.62);
    animation-iteration-count: infinite;
  }

  &:after {
    animation: spinning 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
    animation-iteration-count: infinite;
  }
}

.multiselect.form-select-sm :deep(.multiselect__strong) {
  width: $vue-multiselect-min-height-sm;
  height: $vue-multiselect-min-height-sm;
}

.multiselect.form-select-lg :deep(.multiselect__strong) {
  width: $vue-multiselect-min-height-lg;
  height: $vue-multiselect-min-height-lg;
}

@keyframes spinning {
  from {
    transform: rotate(0)
  }

  to {
    transform: rotate(2turn)
  }
}

// --------------------------------
// Loading state
//--

:deep(.multiselect__loading-enter-active),
:deep(.multiselect__loading-leave-active) {
  transition: opacity 0.4s ease-in-out;
  opacity: 1;
}

:deep(.multiselect__loading-enter),
:deep(.multiselect__loading-leave-active) {
  opacity: 0;
}

// --------------------------------
// RTL adjustments
//--

*[dir="rtl"] {
  :deep(.multiselect) {
    text-align: right;
  }

  :deep(.multiselect__select) {
    right: auto;
    left: 1px;
  }

  :deep(.multiselect__tags) {
    padding: $vue-multiselect-padding-y $vue-multiselect-padding-x $vue-multiselect-padding-y $vue-multiselect-min-height;
  }

  .multiselect.form-select-sm :deep(.multiselect__tags) {
    padding: $vue-multiselect-padding-y-sm $vue-multiselect-padding-x-sm $vue-multiselect-padding-y-sm $vue-multiselect-min-height-sm;
  }

  .multiselect.form-select-lg :deep(.multiselect__tags) {
    padding: $vue-multiselect-padding-y-lg $vue-multiselect-padding-x-lg $vue-multiselect-padding-y-lg $vue-multiselect-min-height-lg;
  }

  :deep(.multiselect__content) {
    text-align: right;
  }

  :deep(.multiselect__option:after) {
    right: auto;
    left: 0;
  }

  :deep(.multiselect__clear) {
    right: auto;
    left: 12px;
  }

  :deep(.multiselect__spinner) {
    right: auto;
    left: 1px;
  }
}

// is-valid / is-invalid state handling
// @see node_modules/bootstrap/scss/_forms.scss:262
@each $state, $data in $vue-multiselect-form-validation-states {
  $color: map-get($data, color);
  $shadow: map-get($data, shadow);
  $icon: map-get($data, icon);

  .form-select.is-#{$state} :deep(.multiselect__tags) {
    border-color: $color;

    @if $vue-multiselect-enable-validation-icons {
      padding-right: $vue-multiselect-form-select-feedback-icon-padding-end;

      background-size: $vue-multiselect-form-select-feedback-icon-size;
      background-position: $vue-multiselect-form-select-feedback-icon-position;
      background-repeat: no-repeat;
      background-image: $icon;

      // position: relative;
      // &:after {
      //   content: "";
      //   width: 100%;
      //   height: 100%;
      //   position: absolute;
      //   top: 0;
      //   left: 0;

      //   background-color: $color;

      //   -webkit-mask-image: $icon;
      //   -webkit-mask-repeat: no-repeat;
      //   -webkit-mask-size: $vue-multiselect-form-select-feedback-icon-size;
      //   -webkit-mask-position: $vue-multiselect-form-select-feedback-icon-position;

      //   mask-image: $icon;
      //   mask-repeat: no-repeat;
      //   mask-size: $vue-multiselect-form-select-feedback-icon-size;
      //   mask-position: $vue-multiselect-form-select-feedback-icon-position;
      // }
    }
  }

  .form-select.multiselect--active.is-#{$state} {
    border-color: $color;
    box-shadow: $shadow;
  }
}
</style>
