import { ref, inject, nextTick, markRaw, defineAsyncComponent, defineComponent } from 'vue';
import RouterView from './components/router-view.vue';
import RouterViewSplit from './components/router-view-split.vue';
import RouterLink from './components/router-link.vue';
import { getParser, sortParsers } from './libs/parser.mjs';
import { normalizePath, parseQueryString, genQueryString } from './libs/utils.mjs';
import EventEmitterFactory from './libs/event-emitter.mjs';
import _ from 'lodash';

let instance = window.cnsRouterInstance;
if (!instance) {
  instance = {
    basePath: null,
    routes: ref([]),
    route: ref(null),
    injectKeys: {
      router: Symbol('__cns-router_router'),
      routerDepth: Symbol('__cns-router_routerDepth')
    },
    beforeEach: EventEmitterFactory(),
    afterEach: EventEmitterFactory()
  };

  // History
  instance.history = {
    push: (to, basePath) => {
      return instance.navigateTo(to, basePath).then((navRes) => {
        if (navRes) {
          window.history.pushState(_.cloneDeep(instance.route.value.state) || {}, '', instance.route.value.url + genQueryString(instance.route.value.query));
        }
      }).catch((err) => { console.error(`[push] Error: ${err.message}`); });
    },
    replace: (to, basePath) => {
      return instance.navigateTo(to, basePath).then((navRes) => {
        if (navRes) {
          window.history.replaceState(_.cloneDeep(instance.route.value.state) || {}, '', instance.route.value.url + genQueryString(instance.route.value.query));
        }
      }).catch((err) => { console.error(`[replace] Error: ${err.message}`); });
    },
    go: (index) => { window.history.go(index); },
    back: () => { window.history.go(-1); },
    forward: () => { window.history.go(1); }
  };
  window.addEventListener('popstate', async (event) => {
    instance.recalcRoute().then((navRes) => {
      if (!navRes) { // the navigation was interrupted, so return back to the previous route
        window.history.pushState(_.cloneDeep(instance.route.value.state) || {}, '', instance.route.value.url + genQueryString(instance.route.value.query));
      }
    }).catch((err) => { console.error(`[popstate] Error: ${err.message}`); });
  });

  instance.navigateTo = async function (to, basePath) {
    const _from = instance.route.value;
    const _to = instance.parseToRoute(to, basePath);

    if (!_to) { console.error(`Cannot find a route for: (${basePath}) ${JSON.stringify(to)}`); return; }

    // Handle the redirect (redirect can be a "to" or a function that must return a "to")
    let toRedirect = _to.matched[0].redirect;
    if (toRedirect != null) {
      if (typeof toRedirect === 'function') {
        toRedirect = await Promise.resolve().then(() => toRedirect(_to, _from));
      }

      if (toRedirect != null) {
        return instance.navigateTo(toRedirect, _to.matched[0].basePath);
      }
    }

    // Check if "to" and "from" are equal
    let fromAndToAreEqual = _from && _to.url === _from.url && _to.matched.length === _from.matched.length;
    if (fromAndToAreEqual) {
      for (let i = 0; i < _to.matched.length; i++) {
        if (_to.matched[i] !== _from.matched[i]) {
          fromAndToAreEqual = false;
          break;
        }
      }
    }
    if (fromAndToAreEqual) { fromAndToAreEqual = _.isEqual(_to.query, _from.query); }
    if (fromAndToAreEqual) { fromAndToAreEqual = _.isEqual(_to.state, _from.state); }

    // If they are really equal do nothing
    if (fromAndToAreEqual) { return false; }

    // beforeEach
    const beforeEachEmitGen = instance.beforeEach.emit(_to, _from);
    let beforeEachEmitDone = false;
    do {
      const genRes = beforeEachEmitGen.next();
      beforeEachEmitDone = genRes.done;
      const beforeEachRes = await genRes.value;

      if (beforeEachRes?.to === false) { // if a beforeEach returns false cancel the navigation
        return false;
      } else if (beforeEachRes?.to) { // if a beforeEach returns something else navigate to it
        return instance.navigateTo(beforeEachRes.to, beforeEachRes.basePath);
      }
    } while (!beforeEachEmitDone);

    // Draw the interface
    instance.route.value = _to;

    // afterEach (On next tick so that the interface is rendered)
    nextTick(() => {
      const afterEachEmitGen = instance.afterEach.emit(_to, _from);
      let afterEachEmitDone = false;
      do {
        const genRes = afterEachEmitGen.next();
        afterEachEmitDone = genRes.done;
      } while (!afterEachEmitDone);
    });

    return { url: _to.url, state: _to.state };
  };

  let recalcRouteProm;
  let recalcRoutePromResolve;
  let recalcRoutePromReject;
  let recalcRouteTo;
  instance.recalcRoute = function () {
    clearTimeout(recalcRouteTo);
    recalcRouteTo = setTimeout(() => {
      instance.navigateTo({ path: window.document.location.pathname.replace(instance.basePath, '') + window.document.location.search, absolute: true, state: window.history.state })
        .then((navRes) => {
          if (navRes) {
            window.history.replaceState(_.cloneDeep(instance.route.value.state) || {}, '', instance.route.value.url + genQueryString(instance.route.value.query));
          }
          return navRes;
        })
        .then(recalcRoutePromResolve)
        .catch(recalcRoutePromReject);
    }, 1);

    if (!recalcRouteProm) {
      recalcRouteProm = new Promise((resolve, reject) => {
        recalcRoutePromResolve = resolve;
        recalcRoutePromReject = reject;
      }).finally(() => {
        recalcRouteProm = null;
        recalcRoutePromResolve = null;
        recalcRoutePromReject = null;
      });
    }

    return recalcRouteProm;
  };

  instance.parseToRoute = function (to, basePath) {
    let toRoute = null;
    const toRouteMatches = [];
    let toProps = {};
    let toQuery = {};
    let toState = {};
    let toUrl = '';

    let parentToFind = null;
    let urlToFind = null;
    let nameToFind = null;
    if (typeof to === 'object' && to.name) {
      nameToFind = to.name;
    } else if (typeof to === 'object' && to.path) {
      toUrl = normalizePath((to.absolute ? instance.basePath : basePath) + to.path.split('?')[0]);
      urlToFind = toUrl;
    } else if (typeof to === 'string') {
      toUrl = normalizePath(basePath + to.split('?')[0]);
      urlToFind = toUrl;
    }

    if (!nameToFind && !urlToFind) { return; } // No route to search

    for (let i = 0; i < instance.routes.value.length; i++) {
      if (parentToFind) {
        if (parentToFind === (instance.routes.value[i].basePath + instance.routes.value[i].path)) {
          toRouteMatches.push(instance.routes.value[i]);
          parentToFind = instance.routes.value[i].basePath;
        }
      } else {
        if (urlToFind) {
          if (urlToFind.match(instance.routes.value[i].re)) {
            toRoute = instance.routes.value[i];
            parentToFind = instance.routes.value[i].basePath;
          }
        } else if (nameToFind) {
          if (nameToFind === instance.routes.value[i].name) {
            toRoute = instance.routes.value[i];
            urlToFind = instance.routes.value[i].stringify(to.props || {});
            parentToFind = instance.routes.value[i].basePath;
          }
        }
      }
    }

    if (toRoute == null) { return; } // No route found

    if (typeof to === 'object' && to.name) {
      toProps = {};
      toRoute.keys.forEach((key) => {
        if (to.props && key.name in to.props) { // take only the props that can be put in the url
          toProps[key.name] = to.props[key.name];
        }
      });
      toQuery = to.query || {};
      toState = to.state || {};
      toUrl = toRoute.stringify(toProps || {});
    } else {
      toProps = toRoute.parse(urlToFind);
      if (typeof to === 'object' && to.path) {
        toQuery = { ...parseQueryString(to.path), ...to.query };
      } else if (typeof to === 'string') {
        toQuery = parseQueryString(to);
      }
      toState = to.state || {};
    }

    // remove null values from state
    toState = Object.keys(toState).reduce((acc, key) => {
      if (toState[key] != null) {
        acc[key] = toState[key];
      }
      return acc;
    }, {});

    const toMeta = {};
    [toRoute, ...toRouteMatches].reverse().forEach((matchedRoute) => {
      if (typeof matchedRoute.meta === 'object') {
        Object.assign(toMeta, matchedRoute.meta);
      }
    });

    const parsedTo = {
      name: toRoute.name,
      url: normalizePath(toUrl), // absolute path
      fullPath: normalizePath(toUrl.replace(instance.basePath, '')), // path relative to the global base path
      path: normalizePath(toUrl.replace(toRoute.basePath, '')), // path relative to the $router
      basePath: toRoute.basePath,
      meta: toMeta,
      props: toProps,
      query: toQuery,
      state: toState,
      matched: [toRoute, ...toRouteMatches]
    };

    return parsedTo;
  };

  instance.findRouteByPath = function (path, basePath) {
    for (let i = 0; i < instance.routes.value.length; i++) {
      if (instance.routes.value[i].path === path && instance.routes.value[i].basePath === basePath) {
        return instance.routes.value[i];
      }
    }
  };

  instance.findRouteByName = function (name, basePath, absolute) {
    for (let i = 0; i < instance.routes.value.length; i++) {
      if (instance.routes.value[i].name === name && (absolute || instance.routes.value[i].basePath === basePath)) {
        return instance.routes.value[i];
      }
    }
  };

  instance.findRoute = function (route, basePath = '/') {
    if (route != null) {
      if (typeof route === 'object' && route.name) { // Find the route by name
        return instance.findRouteByName(route.name, basePath, route.absolute);
      } else if (typeof route === 'object' && route.path) { // Find the route by path
        return instance.findRouteByPath(route.path, route.absolute ? instance.basePath : basePath);
      } else if (typeof route === 'string') { // Find the route by path
        return instance.findRouteByPath(route, basePath);
      }
    }
  };

  instance.addRoute = function (route, parent, basePath = '/') {
    if (route.path == null) { console.error('Cannot add route without a path', route); return; }

    basePath = normalizePath(basePath);

    // Find the base path from parent or basePath
    let _basePath;
    if (parent != null) {
      const parentRoute = instance.findRoute(parent, basePath);
      if (!parentRoute) { console.error('Cannot find parent route ' + parent, basePath, route); return; }
      if (!parentRoute.isParent) {
        Object.assign(parentRoute, getParser(parentRoute.fullPath, { end: false }));
        parentRoute.isParent = true;
      }
      _basePath = parentRoute.fullPath;
    } else {
      _basePath = basePath;
    }

    let curRoute;
    if (route.name) {
      curRoute = instance.findRoute({ name: route.name }, _basePath);
    }
    if (!curRoute) {
      curRoute = instance.findRoute(route.path, _basePath);
    }

    // Add the base path to the route path and normalize it
    const fullPath = normalizePath(_basePath + route.path);

    // Transform the component into a real vue component (if any)
    let _routeComponent;
    if (route.component) {
      _routeComponent = markRaw(typeof route.component === 'function' ? defineAsyncComponent(route.component) : defineComponent(route.component));
    }

    // Create the new route
    const newRoute = {
      ...getParser(fullPath, { end: !route.children }),
      path: route.path,
      basePath: _basePath,
      fullPath: fullPath,
      isParent: !!route.children,
      name: route.name || null, // filter out empty strings
      redirect: route.redirect, // no need to add basePath to this, it will be added in the navigateTo function if necessary
      component: _routeComponent,
      meta: typeof route.meta === 'function' ? route.meta : (route.meta != null && _.cloneDeep(route.meta)), // this can be an object, so clone it
      props: typeof route.props === 'function' ? route.props : (route.props != null && _.cloneDeep(route.props)) // same as meta
    };

    if (curRoute) {
      Object.assign(curRoute, newRoute);
    } else {
      instance.routes.value.unshift(newRoute); // Add new routes at the start of the array
    }

    instance.routes.value = sortParsers(instance.routes.value);
    // TODO: Sorting all routes (with sortParsers) every time a route is added might be heavy,
    //       so time those and replace with a scoped insertion if it's too slow

    if (Array.isArray(route.children) && route.children.length > 0) {
      // Add children too with the current route as parent
      for (let i = 0; i < route.children.length; i++) {
        instance.addRoute(route.children[i], route.path, basePath);
      }
    }
  };

  instance.remRoute = function (route, basePath = '/') {
    const _route = instance.findRoute(route, basePath);
    if (!_route) { return; }

    instance.routes.value = instance.routes.value.filter((route) => route !== _route);
  };

  instance.hasRoute = function (route, basePath = '/') {
    return instance.findRoute(route, basePath);
  };

  instance.remAllRoutes = function (basePath) {
    instance.routes.value = instance.routes.value.filter((route) => route.basePath.indexOf(basePath) !== 0);
  };

  Object.defineProperty(window, 'cnsRouterInstance', { value: instance });
}

function createCnsRouter ({ routes, basePath } = {}) {
  const _depth = instance.route.value?.matched?.length ?? 0;
  const _parentRoute = instance.route.value?.matched?.[0];
  const _basePath = normalizePath((_parentRoute?.fullPath ?? '') + (basePath || '/'));

  if (Array.isArray(routes)) {
    for (let i = 0; i < routes.length; i++) {
      instance.addRoute(routes[i], null, _basePath);
    }
  }

  // First router creation
  if (instance.basePath == null) { instance.basePath = _basePath; } // save in the instance the absolute base path

  // isReady promise
  let isReadyResolve;
  const isReady = new Promise((resolve) => { isReadyResolve = resolve; });

  // Every time a route is added or removed this is instanciated
  // and it's only resolved when the route has been recalculated
  // taking into consideration the new changes, this is the used in the router.isReady
  let recalcRouteProm;

  const beforeEachUnsubs = [];
  const afterEachUnsubs = [];

  const router = {
    get basePath () { return _basePath; },
    get routes () { return instance.routes.value; },
    get route () { return instance.route.value; },

    // Programmatic navigation functions
    push: (to) => instance.history.push(to, _basePath),
    replace: (to) => instance.history.replace(to, _basePath),
    go: (index) => instance.history.go(index),
    back: () => instance.history.back(),
    forward: () => instance.history.forward(),

    // Routes CRUD
    addRoute: (route, parent) => {
      instance.addRoute(route, parent, _basePath);
      recalcRouteProm = instance.recalcRoute().catch((err) => { console.error(`[addRoute] Error: ${err.message}`); });
    },
    remRoute: (route) => {
      instance.remRoute(route, _basePath);
      recalcRouteProm = instance.recalcRoute().catch((err) => { console.error(`[remRoute] Error: ${err.message}`); });
    },
    hasRoute: (route) => instance.hasRoute(route, _basePath),

    // Navigation guards
    beforeEach (f, { global = false, descendants = false } = {}) {
      const unsubscribe = instance.beforeEach.subscribe(async function (_to, _from) {
        if (global ||
            (descendants && _from.basePath.indexOf(_basePath) === 0) ||
            _from.basePath === _basePath
        ) {
          return { to: await f(_to, _from), basePath: _basePath };
        }
      });
      beforeEachUnsubs.push(unsubscribe);
      return unsubscribe;
    },
    afterEach (f, { global = false, descendants = false } = {}) {
      const unsubscribe = instance.afterEach.subscribe(async function (_to, _from) {
        if (global ||
            (descendants && _to.basePath.indexOf(_basePath) === 0) ||
            _to.basePath === _basePath
        ) {
          return { to: await f(_to, _from), basePath: _basePath };
        }
      });
      afterEachUnsubs.push(unsubscribe);
      return unsubscribe;
    },
    isReady: () => Promise.all([isReady, recalcRouteProm])
  };

  return {
    install: function (app) {
      app.component('router-view', RouterView);
      app.component('router-view-split', RouterViewSplit);
      app.component('router-link', RouterLink);

      app.provide(instance.injectKeys.router, router);
      app.provide(instance.injectKeys.routerDepth, _depth);

      const appMount = app.mount;
      app.mount = function () {
        appMount(...arguments);

        // Navigate to the first route
        nextTick(() => {
          instance.recalcRoute()
            .then(() => { isReadyResolve(); })
            .catch((err) => { console.error(`[firstNavigation] Error: ${err.message}`); });
        });
      };

      const appUnmount = app.unmount;
      app.unmount = function () {
        // unsubscribe all subscriptions made within this app
        beforeEachUnsubs.forEach((unsubscribe) => unsubscribe());
        afterEachUnsubs.forEach((unsubscribe) => unsubscribe());

        // Remove all routes added by this router
        instance.remAllRoutes(_basePath);

        appUnmount(...arguments);
      };
    }
  };
}

function useCnsRouter () { return inject(instance.injectKeys.router); }

const cnsRouterInjectKey = instance.injectKeys.router;

export {
  createCnsRouter,
  useCnsRouter,
  cnsRouterInjectKey,
  RouterView,
  RouterViewSplit,
  RouterLink
};
