<script setup>
import { computed } from 'vue';

const props = defineProps({
  type: { type: String, default: 'notdef' },
  color: { type: String, default: undefined },
  colorAlt: { type: String, default: undefined }
});

const classes = computed(() => {
  const types = props.type.split(' ');

  if (!types.find((type) => ['solid', 'regular', 'light', 'thin', 'duotone'].includes(type))) {
    types.push('light');
  }

  return [
    'cns-icon',
    ...types.map((type) => `fa-${type}`),
    {
      'duotone-solid-color-2': types.includes('duotone') && props.colorAlt
    }
  ];
});

const style = computed(() => {
  const types = props.type.split(' ');
  const style = {};

  if (types.includes('duotone')) {
    if (props.color) { style['--fa-primary-color'] = props.color; }
    if (props.colorAlt) { style['--fa-secondary-color'] = props.colorAlt; }
  } else {
    if (props.color) { style.color = props.color; }
  }

  return style;
});
</script>

<template>
  <i :class="classes" :style="style"></i>
</template>

<style scoped>
  .cns-icon{ font-size: 1.5em; line-height: 1em; vertical-align: -0.125em; }
  .cns-icon.fa-2xs { font-size: 0.625em; line-height: 1em; vertical-align: -0.125em; }
  .cns-icon.fa-xs { font-size: 1em; }
  .cns-icon.fa-sm { font-size: 1.25em; }
  .cns-icon.fa-lg { font-size: 1.75em; }
  .cns-icon.fa-xl { font-size: 2em; }
  .cns-icon.fa-2xl { font-size: 2.5em; }

  .duotone-solid-color-2 { --fa-secondary-opacity: 1; }
</style>
