<script setup>
import { ref, watch, nextTick, onBeforeUnmount } from 'vue';
import _ from 'lodash';

const props = defineProps({
  module: { type: String, default: undefined },
  props: { type: Object, default: undefined },
  path: { type: String, default: undefined }
});
const emit = defineEmits(['error']);

const moduleInstance = ref(null);
const loading = ref(false);
const mountDiv = ref(null);
let unmounted = false;

watch(() => [props.module, props.path, props.props], async ([currModule] = [], [prevModule] = []) => {
  loading.value = true;
  if (currModule !== prevModule) {
    if (moduleInstance.value) {
      moduleInstance.value.unmount();
      moduleInstance.value = null;
    }
    moduleInstance.value = await window.System.import(props.module);

    if (!moduleInstance.value ||
      typeof moduleInstance.value.bootstrap !== 'function' ||
      typeof moduleInstance.value.mount !== 'function' ||
      typeof moduleInstance.value.unmount !== 'function'
    ) {
      console.error('[module error] The requested module does not exists or it\'s malformed', moduleInstance.value);
      moduleInstance.value = null;
      emit('error', 'moduleNotFound');
    }
  }

  bootstrapAndMountModule();
}, { immediate: true });

function bootstrapAndMountModule () {
  loading.value = true;

  // if for some reason this instance is unmounted while the module was loading do nothing here,
  // same when for some reason the moduleInstance is null (for example the module changes and then after some time but before the new module finishes loading something else changes)
  if (unmounted || !moduleInstance.value) { loading.value = false; return; }

  return moduleInstance.value.bootstrap(Object.assign({}, _.cloneDeep(props.props), { path: props.path }))
    .then(() => { loading.value = false; })
    .then(() => nextTick())
    .then(() => moduleInstance.value.mount(mountDiv.value))
    .catch((err) => {
      loading.value = false;
      console.error('[module error]', err);
      emit('error', 'moduleNotFound');
    });
}

onBeforeUnmount(() => {
  if (moduleInstance.value) {
    moduleInstance.value.unmount();
    unmounted = true;
  }
});
</script>

<template>
  <div v-if="loading" class="d-flex flex-row align-items-center justify-content-center w-100 h-100">
    <div class="spinner-border text-primary" role="status"></div>
  </div>
  <div v-else-if="moduleInstance" ref="mountDiv" class="w-100 h-100"></div>
</template>

<style scoped>
</style>
