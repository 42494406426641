import { DateTime } from 'luxon';
import _ from 'lodash';
import { UtilsError } from './UtilsError.mjs';
import fetch from 'cross-fetch';
import { isBrowser, isNode } from '../runtime-detector/index.mjs';
import saferEval from '../safer-eval/index.mjs';

/* #IF isNode */
import https from 'https';
/* #FI */

const iterableSet = new Set(['[object Object]', '[object Array]']);
export type RecursiveKeyOf<TObj> = {
  [TKey in keyof TObj & (string | number)]:
    TObj[TKey] extends any[] ? `${TKey}` :
    TObj[TKey] extends object
      ? `${TKey}` | `${TKey}.${RecursiveKeyOf<TObj[TKey]>}`
      : `${TKey}`;
}[keyof TObj & (string | number)];

/**
 * This function checks if a `value` is valid based on a list of `rules`
 *
 * @param {String|Number} value - The value to check
 * @param {Array} rules - The list of rules to check perform
 * @param {RegExp|Function} rules[].check - The Regexp to match the value against or a function to which the value is passed, this must return false if the value is invalid
 * @param {String} rules[].error - The error to return if the validity check fails
 *
 * @returns The array of error strings or undefined if no error was found
 */
async function checkValue (value, rules) {
  const errors = [];
  let invalidValueAdded = false;

  for (const rule of rules) {
    let isValid = true;
    if (rule.check && rule.check instanceof RegExp && !String(value).match(rule.check)) {
      isValid = false;
    } else if (rule.check && rule.check instanceof Function && (await rule.check(value)) === false) {
      isValid = false;
    }

    if (!isValid) {
      if (rule.error) {
        if (rule.error instanceof Function) {
          errors.push(await rule.error(value));
        } else {
          errors.push(rule.error);
        }
      } else if (!invalidValueAdded) {
        errors.push('invalidValue');
        invalidValueAdded = true;
      }
    }
  }

  return errors.length > 0 ? errors : undefined;
}

/**
 * Check if a value is numeric, if it is return true, false otherwise
 *
 * @param {Number|String} num
 *
 * @returns true if `num` is a number, false otherwise
 */
function isNum (num: any): num is number {
  return !isNaN(parseFloat(num)) && // prevent empty for being detected as numbers since ( Number("") === 0 )
    !isNaN(Number(num)) && // prevent strings like "1.32a" for being detected since they pass the previous control
    isFinite(Number(num)); // check finiteness
}

function parseDescDuration (desc) {
  if (typeof desc !== 'string') {
    throw new Error('Malformed desc: not a string');
  }

  const matches = desc.match(/^(\d+(\.\d+)?)\s*([a-zA-Z]+)$/);
  if (!matches) {
    throw new Error(`Malformed desc: ${desc}`);
  }
  const duration = Number(matches[1]);
  const unit = matches[3].toLowerCase();

  switch (unit) {
    case 'ms':
    case 'millis':
    case 'millisecond':
    case 'milliseconds':
      return { duration, unit: 'millisecond' };
    case 's': // seconds
    case 'sec':
    case 'second':
    case 'seconds':
      return { duration, unit: 'second' };
    case 'm': // minutes
    case 'min':
    case 'minute':
    case 'minutes':
      return { duration, unit: 'minute' };
    case 'h': // hours
    case 'hour':
    case 'hours':
      return { duration, unit: 'hour' };
    case 'd': // days
    case 'day':
    case 'days':
      return { duration, unit: 'day' };
    case 'w': // weeks
    case 'week':
    case 'weeks':
      return { duration, unit: 'week' };
    case 'mo':
    case 'month':
    case 'months':
      return { duration, unit: 'month' };
    case 'y': // years
    case 'year':
    case 'years':
      return { duration, unit: 'year' };
    default:
      throw new Error(`Malformed desc: Unit "${unit}" not supported`);
  }
}

/**
 * Given a timestamp and a period, returns another timestamp that identifies the time bucket the given timestamp falls into.
 * If `utc` is false, all bucketing is performed in the timezone of the input timestamp: this means that, for example,
 * `getTimeBucket ('2023-12-18T08:36:00.000+01', '1day') === '2023-12-18T00:00:00.000+01' !== '2023-12-18T00:00:00.000Z'`.
 * Otherwise, bucketing is performed in UTC, so that e.g.
 * `getTimeBucket ('2023-12-18T08:36:00.000+01', '1day', { utc: true }) === '2023-12-18T00:00:00.000Z' !== '2023-12-18T00:00:00.000+01'`.
 * @param {DateTime|Number|String} timestamp - The timestamp to be put in a bucket.
 * If it is a number, it is assumed to be the milliseconds since epoch in UTC.
 * If it is a string, it is assumed to be in ISO 8601 representation. If no timezone is included in the string, UTC is assumed.
 * It can also be a Luxon DateTime object.
 * @param {String|Object} period - The "size" of the bucket.
 * It can either be a string (e.g., `'15m'`) or an object (e.g., `{ unit: 'minute', duration: 15 }`)
 * @param {Boolean} utc - Whether to perform all computations in UTC or in the input timezone.
 * @returns {DateTime} A DateTime representing the bucket for the input timestamp
 */
function getTimeBucket (timestamp, period, { utc = false } = {}) {
  let unit, duration;
  try {
    (
      { unit, duration } = typeof period === 'string'
        ? parseDescDuration(period)
        : period
    );
  } catch {}
  if (unit == null || duration == null) {
    throw Error(`The given period does not represent a period: ${JSON.stringify(period)}`);
  }

  try {
    timestamp = timestamp instanceof DateTime
      ? timestamp
      : (typeof timestamp === 'string'
          ? DateTime.fromISO(timestamp, { setZone: true })
          : DateTime.fromMillis(timestamp, { zone: 'UTC' })
        );
  } catch {}
  if (!timestamp?.isValid) {
    throw Error(`The given timestamp does not represent a timestamp: ${JSON.stringify(timestamp)}`);
  }

  if (utc) {
    timestamp = timestamp.toUTC();
  }

  return timestamp
    .startOf(unit)
    .minus({
      [unit]: timestamp[unit] % duration
    });
}

class AlignedInterval { // TODO: add tests, internally switch to luxon interval?
  #unitToAlignTo;
  #durationToAlignTo;
  #start;
  #stop;

  constructor (interval, { period = '1ms' } = {}) {
    const { unit, duration } = typeof period === 'string'
      ? parseDescDuration(period)
      : period;
    this.#unitToAlignTo = unit;
    this.#durationToAlignTo = duration;

    if (typeof interval === 'string') {
      interval = ms.interval(interval);
    }

    interval ??= {};

    const start = interval.start == null
      ? this.#addPeriods(DateTime.now(), -1)
      : (interval.start instanceof DateTime
          ? interval.start
          : (typeof interval.start === 'string'
              ? DateTime.fromISO(interval.start)
              : DateTime.fromMillis(interval.start)
            )
        );

    const stop = interval.stop == null
      ? this.#addPeriods(start, 1)
      : (interval.stop instanceof DateTime
          ? interval.stop
          : (typeof interval.stop === 'string'
              ? DateTime.fromISO(interval.stop)
              : DateTime.fromMillis(interval.stop)
            )
        );

    this.#start = this.#alignTimeTo(start);
    this.#stop = this.#alignTimeTo(stop);
  }

  growLeft ({ amount = 1 } = {}) {
    return new AlignedInterval({
      interval: {
        start: this.#addPeriods(this.#start, -1 * amount),
        stop: this.#stop
      },
      period: {
        duration: this.#durationToAlignTo,
        unit: this.#unitToAlignTo
      }
    });
  }

  growRight ({ amount = 1 } = {}) {
    return new AlignedInterval({
      interval: {
        start: this.#start,
        stop: this.#addPeriods(this.#stop, amount)
      },
      period: {
        duration: this.#durationToAlignTo,
        unit: this.#unitToAlignTo
      }
    });
  }

  toISO () {
    return { start: this.#start.toISO(), stop: this.#stop.toISO() };
  }

  toMillis () {
    return { start: this.#start.toMillis(), stop: this.#stop.toMillis() };
  }

  toSeconds () {
    return { start: this.#start.toSeconds(), stop: this.#stop.toSeconds() };
  }

  #alignTimeTo (time) {
    return time
      .startOf(this.#unitToAlignTo)
      .minus({
        [this.#unitToAlignTo]: time[this.#unitToAlignTo] % this.#durationToAlignTo
      });
  }

  #addPeriods (time, amount = 1) {
    return time.plus({ [this.#unitToAlignTo]: this.#durationToAlignTo * amount });
  }
}

function ms (desc) {
  const { duration, unit } = parseDescDuration(desc);
  switch (unit) {
    case 'millisecond':
      return duration;
    case 'second':
      return duration * 1000;
    case 'minute':
      return duration * 60 * 1000;
    case 'hour':
      return duration * 60 * 60 * 1000;
    case 'day':
      return duration * 24 * 60 * 60 * 1000;
    case 'week':
      return duration * 7 * 24 * 60 * 60 * 1000;
    case 'month':
      return duration * 30 * 24 * 60 * 60 * 1000;
    case 'year':
      return duration * 365 * 24 * 60 * 60 * 1000;
    default:
      throw new Error(`Malformed desc: Unit "${unit}" not supported`);
  }
}

function s (desc) {
  return Math.floor(ms(desc) / 1000);
}

Object.defineProperty(ms, 'interval', {
  value: function (desc, { local = false, from } = {}) {
    if (typeof desc !== 'string') {
      throw new Error('Malformed desc: not a string');
    }

    const pos = desc.substring(0, 4);
    const int = desc.substring(4).trim().toLowerCase();

    let now;
    if (from) {
      if (typeof from === 'string') {
        now = DateTime.fromISO(from);
      } else {
        now = DateTime.fromMillis(from, { zone: local ? 'local' : 'utc' });
      }
    } else {
      now = DateTime[local ? 'local' : 'utc']();
    }
    let millis;

    if (desc.match(/^((last|next|curr|prev|succ)\s*([Mm]inute|[Hh]our|[Dd]ay|[Ww]eek|[Mm]onth|[Yy]ear))$/)) {
      switch (pos) {
        case 'curr':
          return { start: now.startOf(int).toMillis(), stop: now.endOf(int).toMillis() };
        case 'prev':
          now = now.minus({ [int + 's']: 1 });
          return { start: now.startOf(int).toMillis(), stop: now.endOf(int).toMillis() };
        case 'succ':
          now = now.plus({ [int + 's']: 1 });
          return { start: now.startOf(int).toMillis(), stop: now.endOf(int).toMillis() };
        case 'last':
          return { start: now.minus({ [int + 's']: 1 }).toMillis(), stop: now.toMillis() };
        case 'next':
          return { start: now.toMillis(), stop: now.plus({ [int + 's']: 1 }).toMillis() };
      }
    } else if (desc.match(/^(last|next)(\d+(\.\d+)?)\s*([a-zA-Z]+)$/)) {
      switch (pos) {
        case 'last':
          millis = ms(int);
          return { start: now.minus({ milliseconds: millis }).toMillis(), stop: now.toMillis() };
        case 'next':
          millis = ms(int);
          return { start: now.toMillis(), stop: now.plus({ milliseconds: millis }).toMillis() };
      }
    } else if (desc.match(/^(all)$/)) {
      return { start: 0, stop: now.toMillis() };
    } else {
      throw new Error(`Malformed desc: ${desc}`);
    }
  }
});

Object.defineProperty(s, 'interval', {
  value: function (desc, { local = false } = {}) {
    const msInterval = ms.interval(desc, { local });
    return { start: Math.floor(msInterval.start / 1000), stop: Math.floor(msInterval.stop / 1000) };
  }
});

const CAPTURE_REGEX = /(?:\w+:)?\w+([.]\w+)*/i;
const COMPILE_REGEX = /{{((?:\w+:)?\w+([.]\w+)*)}}/gi;
const TRASFORMER_REGEX = /(?:(\w+):)(.*)/i;
const TRANSFORMATIONS = {
  u: function (string) {
    return string.toUpperCase();
  },
  l: function (string) {
    return string.toLowerCase();
  },
  c: function (string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
};

function replace (info, match, capture) {
  if (!CAPTURE_REGEX.test(capture) || info == null) {
    return match;
  }

  let transform = capture.match(TRASFORMER_REGEX);
  if (transform) {
    capture = transform[2];
    transform = TRANSFORMATIONS[transform[1]];
  }

  let render = _.get(info, capture);
  if (render == null) {
    return match;
  }

  if (typeof transform === 'function') {
    render = transform(render, info, capture);
  }

  return render;
}

function compile (string, info) {
  if (typeof string !== 'string') { return string; }
  return string.replace(COMPILE_REGEX, replace.bind(null, info));
}

function round (num, decimals = 2, asString = false) {
  if (!isNum(num)) { return num; }
  const ret = Math.round(num * Math.pow(10, decimals)) / Math.pow(10, decimals);
  return asString ? ret.toFixed(decimals) : ret;
}

function floor (num, decimals = 2, asString = false) {
  if (!isNum(num)) { return num; }
  const ret = Math.floor(num * Math.pow(10, decimals)) / Math.pow(10, decimals);
  return asString ? ret.toFixed(decimals) : ret;
}

function ceil (num, decimals = 2, asString = false) {
  if (!isNum(num)) { return num; }
  const ret = Math.ceil(num * Math.pow(10, decimals)) / Math.pow(10, decimals);
  return asString ? ret.toFixed(decimals) : ret;
}

/* Internal use only */
function _diffObjRec (obj1, obj2, opt = {}, path = '') {
  obj1 = obj1 != null ? obj1 : {};
  obj2 = obj2 != null ? obj2 : {};
  opt.exclude = Array.isArray(opt.exclude) ? opt.exclude : [];

  const fieldsToCheck = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);

  const diffFields = {};
  fieldsToCheck.forEach((el) => {
    const elPath = (path ? path + '.' : '') + el;
    if (opt.exclude.includes(elPath)) { return; }

    if (typeof obj1[el] === 'object' && typeof obj2[el] === 'object') {
      Object.assign(diffFields, _diffObjRec(obj1[el], obj2[el], opt, elPath));
    } else if (obj1[el] != obj2[el]) { /* eslint-disable-line eqeqeq */
      diffFields[elPath] = { from: obj1[el], to: obj2[el] };
    }
  });

  return diffFields;
}

function diffObj (obj1, obj2, { exclude = [] } = {}) {
  return _diffObjRec(...arguments);
}

function sortConfigs (configs) {
  // order configs based on priority
  // first the more generic ones
  return [...configs].sort((a, b) => {
    if (a.node?.id === b.node?.id) {
      if (a.nodeType?.id === b.nodeType?.id) {
        if (a.user?.id === b.user?.id) {
          if (a.role?.id === b.role?.id) {
            return 0;
          } else {
            return a.role?.id == null ? -1 : 1;
          }
        } else {
          return a.user?.id == null ? -1 : 1;
        }
      } else {
        return a.nodeType?.id == null ? -1 : 1;
      }
    } else {
      return a.node?.id == null ? -1 : 1;
    }
  });
}

function evalConfigs (configs, opt = {}) {
  opt.context = typeof opt.context === 'object' ? opt.context : {};

  let finalConfig;
  let objConf = false; let arrConf = false;
  configs.forEach((conf) => {
    let curConf;

    try {
      curConf = saferEval(conf.config, opt.context);
    } catch (err) { console.error('Error evaluating a configuration object: "' + JSON.stringify(conf.config) + '"'); console.error(err); }

    if (!objConf && !arrConf) {
      if (Array.isArray(curConf)) { arrConf = true; finalConfig = []; } else { objConf = true; finalConfig = {}; }
    }

    if (objConf) {
      finalConfig = _.merge(finalConfig, curConf);
    } else if (Array.isArray(curConf)) {
      for (let i = 0; i < curConf.length; i++) {
        finalConfig[i] = _.merge(finalConfig[i], curConf[i]);
      }
    }
  });

  return finalConfig;
}

const PASSWORD_SPECIAL_CHARS = '!"#$%&\'()*+,-.\\/:;<=>?@[\\]^_{|}~\\\\';
function checkPasswordConstraints (password, username, passwordConstraints = {}) {
  const config = [
    {
      active: passwordConstraints.upperCaseChar,
      regex: '[A-Z]', // if not match
      error: () => UtilsError.PasswordNoUpperCase()
    },
    {
      active: passwordConstraints.lowerCaseChar,
      regex: '[a-z]',
      error: () => UtilsError.PasswordNoLowerCase()
    },
    {
      active: passwordConstraints.digit,
      regex: '\\d',
      error: () => UtilsError.PasswordNoDigit()
    },
    {
      active: passwordConstraints.specialChar,
      regex: `[${PASSWORD_SPECIAL_CHARS}]`,
      error: () => UtilsError.PasswordNoSpecialCharacter({ specialCharList: PASSWORD_SPECIAL_CHARS.split('').join(' ') })
    },
    {
      active: true,
      regex: `^[a-zA-Z0-9${PASSWORD_SPECIAL_CHARS}]*$`,
      error: () => UtilsError.PasswordCharacterNotAllowed({ charNotAllowed: [...new Set(password.match(new RegExp(`[^a-zA-Z0-9${PASSWORD_SPECIAL_CHARS}]`)) || [])].join(', ') })
    },
    {
      active: passwordConstraints.minLength,
      regex: `^.{${passwordConstraints.minLength},}$`,
      error: () => UtilsError.PasswordMinLength({ minLength: passwordConstraints.minLength })
    },
    {
      active: passwordConstraints.maxLength,
      regex: `^.{0,${passwordConstraints.maxLength}}$`,
      error: () => UtilsError.PasswordMaxLength({ maxLength: passwordConstraints.maxLength })
    },
    {
      active: passwordConstraints.noUsername && !!username,
      regex: `^(?!.*${username}).*$`,
      error: () => UtilsError.PasswordNoUsername()
    }
  ];

  const errors = [];

  config.forEach(constraint => {
    if (constraint.active && !password.match(new RegExp(constraint.regex))) {
      errors.push(constraint.error());
    }
  });

  return errors;
}

function getRandomPassword ({ constraints = undefined } = {}) {
  const length = Math.min((constraints?.minLength ?? 10) + 2, constraints?.maxLength ?? 12);
  const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
  const numbers = '0123456789';
  const symbols = PASSWORD_SPECIAL_CHARS;
  const allCharacters = uppercaseLetters + lowercaseLetters + numbers + symbols;

  let password = '';

  // Ensure at least one character from each category
  password += uppercaseLetters[Math.floor(Math.random() * uppercaseLetters.length)];
  password += numbers[Math.floor(Math.random() * numbers.length)];
  password += symbols[Math.floor(Math.random() * symbols.length)];

  // Generate the rest of the password
  for (let i = 0; i < length - 3; i++) {
    password += allCharacters[Math.floor(Math.random() * allCharacters.length)];
  }

  // Shuffle the password characters randomly
  password = password.split('').sort(() => Math.random() - 0.5).join('');

  return password;
}

function assertType (value, type) {
  let valid;
  const expectedType = assertType.getType(type);
  if (['String', 'Number', 'Boolean', 'Function', 'Symbol', 'BigInt'].includes(expectedType)) {
    const t = typeof value;
    valid = t === expectedType.toLowerCase();
    // for primitive wrapper objects
    if (!valid && t === 'object') {
      valid = value instanceof type;
    }
  } else if (expectedType === 'Object') {
    valid = value !== null && typeof value === 'object';
  } else if (expectedType === 'Array') {
    valid = Array.isArray(value);
  } else if (expectedType === 'null') {
    valid = value === null;
  } else {
    valid = value instanceof type;
  }
  return { valid, expectedType };
}

assertType.getType = (ctor) => {
  const match = ctor && ctor.toString().match(/^\s*(function|class) (\w+)/);
  return match ? match[2] : ctor === null ? 'null' : '';
};

const IDREQ_PREFIX = '__cns2Request_';
/**
 * Make a http/s request to a cns2 daemon
 *
 * @param {Number|String} to - The target for the request, can be a port (only node), the name of a daemon (only browser) or the full url of the daemon
 * @param {Array|Object} request - The request to send, this can be an object or an array of objects, the return value will reflect the input type of this. Each object must have at leas the `act` field
 * @param {Object} options - An object containing all the options
 * @param {String} options.authorization - The authorization string, generally the user `sid` (old `ids`) (undefined by default)
 * @param {Boolean} options.acceptSelfSignedCert - If true it will accept self signed certificates, else it will throw an error if it encounters one of those (false by default)
 * @param {Number} options.timeout - The request timeout in ms
 *
 * @returns A promise that resolves with the daemon reply, an array if the request was an array (the replies will be in the same positions as the request) or a single value if the request was a single object
 */
async function cns2Request (to, request, options = {}) {
  let url;
  if (isNum(to) && isNode) {
    url = `http://127.0.0.1:${to}`;
  } else if (typeof to === 'string' && to.indexOf('http') === 0) {
    url = to;
  } else if (typeof to === 'string' && isBrowser) {
    url = `${window.location.origin}/${to}`;
  } else {
    throw new Error('Ivalid to');
  }

  const arrayReq = Array.isArray(request);
  request = arrayReq ? request : [request];
  request.forEach((request, i) => {
    if (typeof request !== 'object') { throw new Error('Request is not an object'); }
    if (!request.act) { throw new Error('Missing act'); }

    request.idReq = `${IDREQ_PREFIX}${i}`;
    request.DATI = request.DATI || request.data || {};
    delete request.data;
  });

  const fetchOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Accept: 'application/json, text/javascript, */*; q=0.01'
    }
  };

  if (options.authorization) {
    request.forEach((request) => {
      request.ids = options.authorization;
    });
  }

  /* #IF isNode */
  if (options.acceptSelfSignedCert && url.indexOf('https') === 0) {
    fetchOptions.agent = new https.Agent({ rejectUnauthorized: false });
  }
  /* #FI */

  let timeoutTo;
  if (isNum(options.timeout) && options.timeout > 0) {
    const controller = new AbortController();
    timeoutTo = setTimeout(() => controller.abort(), options.timeout);
    fetchOptions.signal = controller.signal;
  }

  fetchOptions.body = `query=${encodeURIComponent(JSON.stringify(request))}`;

  return fetch(url, fetchOptions)
    .then((resp) => resp.json())
    .then((resp) => {
      if (resp.ERR !== 0) { throw new Error(resp.STRERR); }
      const res = [];

      resp.DATI.forEach((resp) => {
        if (resp.ERR !== 0) { throw new Error(resp.STRERR); }
        const resIndex = Number(resp.idReq.substring(IDREQ_PREFIX.length));

        res[resIndex] = resp.DATI;
      });

      return arrayReq ? res : res[0];
    })
    .catch((err) => {
      throw err.name === 'AbortError' ? new Error('Request timeout') : err;
    })
    .finally(() => {
      clearTimeout(timeoutTo);
    });
}

// Check __option rules
const checkRule = (rule, filterData) => {
  if (Object.prototype.toString.call(rule) !== '[object Object]') {
    return false;
  }

  const inverted = !!rule.invert;

  for (const ruleKey in rule) {
    if (ruleKey === 'value' || ruleKey === 'invert') continue;
    // if inverted and key from the rule is missing in the filter then the check automatically fails
    if (inverted && !(ruleKey in filterData)) return false;

    const ruleValue = rule[ruleKey];
    const dataValue = filterData[ruleKey];

    if (
      (
        Array.isArray(dataValue) &&
        (
          (Array.isArray(ruleValue) && ruleValue.some(el => dataValue.find(data => String(data).toLowerCase() === String(el).toLowerCase()))) ||
          dataValue.map(el => String(el).toLowerCase()).includes(String(ruleValue).toLowerCase())
        )
      ) ||
      (
        Array.isArray(ruleValue) &&
        ruleValue.map((el) => String(el).toLowerCase()).includes(String(dataValue).toLowerCase())
      ) ||
      String(ruleValue).toLowerCase() === String(dataValue).toLowerCase()
    ) {
      if (inverted) return false;
      continue;
    }

    if (inverted) continue;
    return false;
  }

  // If all rules are true, then the rule is valid
  return true;
};

// Parses __option objects and returns the appropriate value
function getOptionsValue (optionsObj, filterData) {
  if (Object.prototype.toString.call(optionsObj) !== '[object Object]') {
    return optionsObj;
  }

  const rules = optionsObj.rules;
  const defaultValue = optionsObj.default;

  // return the `default` value if no `rules` or `filters` are defined
  if (
    !Array.isArray(rules) ||
    rules.length === 0 ||
    filterData === undefined
  ) {
    return defaultValue;
  }

  // return the value from the first rule that satisfies all the filters
  const validRule = rules.find((rule) => checkRule(rule, filterData));

  // if no valid rules are found, then return the `default`
  return validRule ? validRule.value : defaultValue;
}

/**
 * Recursively parse a configuration object and apply the requested filters to each value has the propery `__options`.
 * This function is thought specifically for the configurations in the pagesConfigs.
 * With this function it's possible to have a specific value be different for the application based of the current filters
 *
 * A limitation of this function is that it has been developed for parsing only json configurations,
 * the function has never been tested and used with objects that are not Array, Objects or primitives.
 *
 * @param {Array|Object} conf - The request to send, this can be an object or an array of objects, the return value will reflect the input type of this.
 * @param {Object} filters - An object containing the filters to apply. For example { nodeType: 'plant', userType: 'direct' }
 *
 * @returns {Object} the configuration parsed with the filters
 */
function parseConfigOptionsRec ({ conf, filters = {}, _cache = new Map() } = {}) {
  // if the value is not an object or an array,
  // return it immediately as it does not need to be iterated upon
  if (!iterableSet.has(Object.prototype.toString.call(conf))) {
    return conf;
  }

  // If the object has already been parsed, return it
  if (_cache.has(conf)) {
    return _cache.get(conf);
  }

  // If the value has the property `__options`
  // then it means that it's a value that must be parsed immediately
  const __options = conf?.__options;
  const hasOptionsField = Object.prototype.toString.call(__options) === '[object Object]';
  if (hasOptionsField) {
    const parsedConf = parseConfigOptionsRec({
      conf: getOptionsValue(__options, filters),
      filters,
      _cache
    });

    _cache.set(conf, parsedConf);
    return parsedConf;
  }

  // Otherwise, iterate over its properties and try to parse them
  const parsedConf = Array.isArray(conf) ? [] : {};

  for (const key in conf) {
    parsedConf[key] = parseConfigOptionsRec({
      conf: conf[key],
      filters,
      _cache
    });
  }

  _cache.set(conf, parsedConf);
  return parsedConf;
}

function batchGetKeyedIndexAndKey (
  obj: any,
  keys: string[],
  collection: { key: { [key: string]: any }, [field: string]: any }[]
): [number, { [key: string]: any }] {
  const objKey: any = {};
  keys.forEach((key) => { objKey[key] = _.get(obj, key); });

  const index = collection.findIndex((entry) => {
    for (const key in objKey) {
      const isEqual = _.isEqualWith(objKey[key], entry.key[key], (a: any, b: any) => {
        if (Array.isArray(a) && Array.isArray(b)) {
          if (a.length !== b.length) { return false; }
          return _.isEqual(a.toSorted(), b.toSorted());
        } else if (typeof a === 'object' && typeof b === 'object') {
          return a === b;
        }
      });
      if (!isEqual) { return false; }
    }
    return true;
  });

  return [index, objKey];
}

function batch <In, Out> (
  fn: (
    args: In[],
    options: {
      group: { [key: string]: any }
    }
  ) => Promise<Out[]> | Out[],
  options: {
    // The time to wait before executing the function (50ms by default)
    wait?: number;

    // The max number of elements to batch before executing the function (Infinite by default)
    max?: number;

    // If set then the arguments are divided in batches based on the value of the fields
    groupBy?: RecursiveKeyOf<In> | RecursiveKeyOf<In>[];

    // If set then the result of the function is cached based on the value of the fields
    cacheBy?: RecursiveKeyOf<In> | RecursiveKeyOf<In>[];

    // The time in ms after which the cache is considered expired (5 minutes by default)
    cacheExp?: number;
  } = {}
): (arg: In, options?: { cacheExp?: number }) => Promise<Out> {
  // Options parsing
  const wait = options.wait ?? 50;
  const max = options.max;
  const cacheExp = options.cacheExp ?? ms('5m');
  const cacheBy = options.cacheBy
    ? Array.isArray(options.cacheBy) ? options.cacheBy : [options.cacheBy]
    : undefined;
  const groupBy = options.groupBy
    ? Array.isArray(options.groupBy) ? options.groupBy : [options.groupBy]
    : undefined;

  // Init variables
  type QueueEntry = {
    prom: Promise<Out>;
    promResolve: (res: Out) => void;
    promReject: (err: Error) => void;
    arg: In;
  };
  let queue: QueueEntry[] = [];
  let to: ReturnType<typeof setTimeout>;
  let batching: boolean = false;
  const cache: { key: any, timestamp: number, prom: Promise<Out> }[] = [];

  const flush = () => {
    clearTimeout(to);
    const queueCopy = queue;
    queue = [];
    batching = false;

    if (queueCopy.length === 0) { return; }

    const groups: { key: any, queue: QueueEntry[] }[] = [];
    if (groupBy) {
      queueCopy.forEach((entry: QueueEntry) => {
        const [groupIndex, groupKey] = batchGetKeyedIndexAndKey(entry.arg, groupBy, groups);
        if (groupIndex >= 0) {
          groups[groupIndex].queue.push(entry);
        } else {
          groups.push({ key: groupKey, queue: [entry] });
        }
      });
    } else {
      groups.push({ key: {}, queue: queueCopy });
    }

    const promises = groups.map((group) =>
      Promise.resolve(
        fn(
          group.queue.map((el) => el.arg),
          { group: group.key }
        )
      )
        .then((res) => {
          if (res.length !== group.queue.length) { throw new Error('Invalid result length'); }
          group.queue.forEach((entry, i) => { entry.promResolve(res[i]); });
        }).catch((err) => {
          group.queue.forEach((entry) => { entry.promReject(err); });
        })
    );

    return Promise.all(promises);
  };

  const func = (arg: In, options: { cacheExp?: number } = {}) => {
    let promResolve = (res: Out) => { console.log('No resolve function', res); };
    let promReject = (err: Error) => { console.log('No reject function', err); };
    const prom = new Promise<Out>((resolve, reject) => {
      promResolve = (res) => { resolve(res); };
      promReject = (err) => { reject(err); };
    });

    if (cacheBy && (options.cacheExp ?? cacheExp) > 0) {
      const now = Date.now();
      const [cacheIndex, cacheKey] = batchGetKeyedIndexAndKey(arg, cacheBy, cache);
      if (cacheIndex >= 0) {
        if (now - cache[cacheIndex].timestamp < (options.cacheExp ?? cacheExp)) {
          return cache[cacheIndex].prom;
        } else {
          cache[cacheIndex].timestamp = now;
          cache[cacheIndex].prom = prom;
        }
      } else {
        cache.push({ key: cacheKey, timestamp: now, prom });
      }
    }

    queue.push({ prom, promResolve, promReject, arg });

    if (!batching) {
      batching = true;
      to = setTimeout(flush, wait);
    } else if (isNum(max) && queue.length >= max) {
      flush();
    }

    return prom;
  };
  func.flush = flush;

  return func;
}

/**
 * Scales the font size based on the size of the parent and child.
 *
 * @param {String} parentClass - defines the parents via the passed class (required, default is "parent")
 * @param {String} childClass - defines the children via the passed class (required, default is "child")
 * @param {Number} scaleFactor - defines the scale factor that affects the maximum font size (required, default 0.13)
 * @param {Number} minFontSize - the minimum for the font size (required, default 20)
 * @param {Boolean} useArea - if true, the maximum font size is calculated based on the area of the parent, otherwise it is calculated by taking the minimum value between the height and width of the parent component (optional)
 * */
function rescaleChildFontSize ({
  parentClass = '.parent',
  childClass = '.child',
  scaleFactor = 1,
  minFontSize = 12,
  useArea
}: {
  parentClass: string;
  childClass: string;
  scaleFactor: number;
  minFontSize: number;
  useArea?: boolean
}): void {
  document.querySelectorAll<HTMLElement>(parentClass).forEach((parentElement: HTMLElement) => {
    // Calculation of baseFontSize (below) with area is hinted by chatGPT. This can provide a more balanced scaling for larger or irregularly shaped containers.
    const baseFontSize = useArea ? Math.sqrt(parentElement.scrollWidth * parentElement.scrollHeight) : Math.min(parentElement.scrollWidth, parentElement.scrollHeight);
    const maxFontSize = Math.max(minFontSize, baseFontSize * scaleFactor);

    parentElement.querySelectorAll<HTMLElement>(childClass).forEach((child: HTMLElement) => {
      const newFontSize = baseFontSize / Math.sqrt(child.innerText.length); // Calculation of newFontSize is hinted by chatGPT
      child.style.fontSize = `${Math.min(maxFontSize, Math.max(minFontSize, newFontSize))}px`;
    });
  });
}

export default {
  checkValue,
  isNum,
  getTimeBucket,
  AlignedInterval,
  ms,
  s,
  compile,
  round,
  floor,
  ceil,
  diffObj,
  sortConfigs,
  evalConfigs,
  checkPasswordConstraints,
  getRandomPassword,
  cns2Request,
  assertType,
  parseConfigOptions: parseConfigOptionsRec,
  batch,
  rescaleChildFontSize
};

export {
  checkValue,
  isNum,
  getTimeBucket,
  AlignedInterval,
  ms,
  s,
  compile,
  round,
  floor,
  ceil,
  diffObj,
  sortConfigs,
  evalConfigs,
  checkPasswordConstraints,
  getRandomPassword,
  cns2Request,
  assertType,
  parseConfigOptionsRec as parseConfigOptions,
  batch
};
