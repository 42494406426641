<script setup lang="ts">
import { nextTick, computed, ref, watch } from 'vue';
import { RecycleScroller } from 'vue-virtual-scroller';

type Props = {
  items: any[];
  idField?: string;
  virtual?: boolean;
  itemsGap?: number;
  itemsHeight?: number;
  scrollTopOffset?: number;
  scrollBottomOffset?: number;
}

const props = withDefaults(defineProps<Props>(), {
  idField: 'id',
  virtual: false,
  itemsGap: 0,
  itemsHeight: 42,
  scrollTopOffset: 500,
  scrollBottomOffset: 500
});

const emit = defineEmits(['scroll:top', 'scroll:bottom']);

const scrollerRef = ref<RecycleScroller | HTMLElement | undefined>();
const scrollerEl = computed(() => props.virtual ? scrollerRef.value?.$el : scrollerRef.value);

let isTop = false;
let isBottom = false;
function onScroll () {
  if (!scrollerEl.value) { return; }

  if (scrollerEl.value.scrollTop <= props.scrollTopOffset) {
    if (!isTop) {
      isTop = true;
      emit('scroll:top');
    }
  } else {
    if (isTop) {
      isTop = false;
    }
  }

  if (scrollerEl.value.scrollTop + scrollerEl.value.clientHeight >= scrollerEl.value.scrollHeight - props.scrollBottomOffset) {
    if (!isBottom) {
      isBottom = true;
      emit('scroll:bottom');
    }
  } else {
    if (isBottom) {
      isBottom = false;
    }
  }
}

watch(() => props.items, () => {
  if (!props.items || props.items.length === 0) { return; }
  nextTick(() => { onScroll(); });
}, { immediate: true });
</script>

<template>
  <div class="cns-list">
    <template v-if="props.virtual">
      <recycle-scroller
        ref="scrollerRef"
        style="height: 100%;"
        :items="items"
        :item-size="props.itemsHeight + props.itemsGap"
        :key-field="props.idField"
        v-slot="{ item, index }"
        @scroll="() => { onScroll(); }"
      >
        <div :style="{ 'padding-bottom': props.itemsGap + 'px' }">
          <slot :item="item" :index="index">
          </slot>
        </div>
      </recycle-scroller>
    </template>
    <template v-else>
      <div
        ref="scrollerRef"
        class="h-100 overflow-auto"
        @scroll="() => { onScroll(); }"
      >
        <div v-for="(item, index) in items" :key="item[props.idField]" :style="{ 'padding-bottom': props.itemsGap + 'px' }">
          <slot :item="item" :index="index">
          </slot>
        </div>
      </div>
    </template>
  </div>
</template>

<style src="vue-virtual-scroller/dist/vue-virtual-scroller.css" />

<style scoped>
.cns-list {
  overflow: auto;
}
</style>
