<script setup>
import { ref, computed } from 'vue';
import CnsIcon from '../../../components/cns/cns-icon.vue';
import { useHigBootstrap } from '../index';

const $higBs = useHigBootstrap();

const browserPreferredType = ref('light');
if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
  browserPreferredType.value = 'dark';
}
window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
  browserPreferredType.value = event.matches ? 'dark' : 'light';
});

const _themeType = computed(() => $higBs.themeType === 'auto' ? browserPreferredType.value : $higBs.themeType);
</script>

<template>
  <div
    v-if="!$higBs.theme.forceThemeType"
    class="switchContainer d-flex align-items-center justify-content-evenly position-relative rounded-pill m-2 btn btn-light p-0"
    @click="() => { if (_themeType === 'light') { $higBs.themeType = 'dark' } else { $higBs.themeType = 'light' } }"
    >
    <cns-icon
      class="switchIcon"
      :type="_themeType === 'light' ? 'moon' : 'sun-bright'"
      :title="_themeType === 'light' ? $edw.switchToDarkTheme : $edw.switchToLightTheme"
    />
  </div>
</template>

<style scoped>
.switchContainer{
  z-index: 100;
  cursor: pointer;
  width: 2rem;
  height: 2rem;
}

.switchIcon{
  font-size: 1rem;
  transition: opacity .3s ease, color .3s ease;
}
</style>
