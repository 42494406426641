<script setup lang='ts'>
import BCheckbox from '../bootstrap/b-checkbox.vue';
import CnsIcon from './cns-icon.vue';

type Props = {
  label: string;
  caption?: string;
  icon?: string;
  iconColor?: string;
  active?: boolean;
  checkbox?: boolean;
};

const props = defineProps<Props>();
</script>

<template>
  <div class="cns-list-item d-flex align-items-center overflow-hidden cursor-pointer rounded px-2 gap-2" :class="{ 'active': !props.checkbox && props.active }">
    <b-checkbox v-if="props.checkbox" :modelValue="props.active" class="pe-none" />
    <slot name="prefix">
      <cns-icon
        v-if="props.icon"
        :type="props.icon"
        :color="props.iconColor ?? ''"
      />
    </slot>
    <div class="flex-fill overflow-hidden">
      <slot>
        <div v-if="props.label" class="cns-list-item-label w-100 fw-bold text-truncate" :title="props.label">{{ props.label }}</div>
        <div v-if="props.caption" class="cns-list-item-caption w-100 fs-6 text-muted text-truncate" :title="props.caption">{{ props.caption }}</div>
      </slot>
    </div>
    <slot name="suffix"></slot>
  </div>
</template>

<style scoped>
.cns-list-item {
  height: 42px;
  overflow: hidden;
}

.cns-list-item-label,
.cns-list-item-caption {
  line-height: 1.3em;
}

.cns-list-item:hover {
  background-color: var(--hig-page-bg-hover);
  color: var(--hig-page-text);
}

.cns-list-item.active {
  background-color: var(--hig-page-bg-active);
  color: var(--hig-page-text-active);
}

.cns-list-item.active .cns-list-item-caption {
  color: var(--hig-page-text-active) !important;
  opacity: .8 !important;
}
</style>
