<script setup lang="ts">

interface Props {
  label?: string,
  value?: string | number,
  timestamp?: string,
  um?: string,
  min?: number,
  max?: number,
}

const props = withDefaults(defineProps<Props>(), {
  label: '-',
  value: '-',
  timestamp: '-',
  um: '-',
  min: 0,
  max: undefined // TODO: infinity,
});

</script>

<template>
  <div
    class="text-truncate"
    :title="props.timestamp"
  >
    <small class="fs-6" :style="{ color: 'var(--hig-page-text-muted)' }">{{ props.label }}</small>
    <div class="d-flex gap-1">
      <h1><strong>{{ props.value }}</strong></h1>
      <small :style="{ color: 'var(--hig-page-text-muted)' }">{{ props.um }}</small>
    </div>
  </div>
</template>
