<script setup>
import { ref, computed, watch, nextTick, onUnmounted } from 'vue';
import BButton from './b-button.vue';
import { VIIcon } from '@hig/vision-sdk';

const props = defineProps({
  modelValue: { type: [Array, Object], default: undefined },
  multiple: { type: Boolean, default: false },
  'no-drop': { type: Boolean, default: false }
});

const emits = defineEmits(['update:modelValue']);

const mainDiv = ref(null);
const fileInput = ref(null);
const dragging = ref(false);
const hovering = ref(false);

const classes = computed(() => [
  {
    'no-drop': props.noDrop,
    multiple: props.multiple,
    dragging: dragging.value,
    hovering: hovering.value
  }
]);

function openFileChooser () {
  if (!fileInput.value) { return; }

  fileInput.value.click();
}

function newFilesSelected (newFiles) {
  if (newFiles.length > 0) {
    if (props.multiple) {
      let outFiles = Array.isArray(props.modelValue) ? [...props.modelValue, ...newFiles] : newFiles;

      // Filter out duplicates
      outFiles = outFiles.filter((file, i, filesArr) => {
        for (let j = 0; j < i; j++) {
          if (file.name === filesArr[j].name &&
              file.size === filesArr[j].size &&
              file.lastModified === filesArr[j].lastModified
          ) {
            return false;
          }
        }
        return true;
      });

      emits('update:modelValue', outFiles);
    } else {
      emits('update:modelValue', newFiles[0]);
    }
  }
}

function remFile (i) {
  if (props.multiple) {
    i = Number(i);
    emits('update:modelValue', Array.isArray(props.modelValue) ? props.modelValue.filter((_, fileI) => fileI !== i) : undefined);
  } else {
    emits('update:modelValue', undefined);
  }
}

function fileInputChange (e) {
  if (e.target.files.length > 0) {
    newFilesSelected([...e.target.files]);
  }
  e.target.value = null;
}

function drop (e) {
  hovering.value = false;
  dragging.value = false;

  const droppedFiles = [];
  if (e.dataTransfer.items) {
    // Use DataTransferItemList interface to access the file(s)
    for (let i = 0; i < e.dataTransfer.items.length; i++) {
      if (e.dataTransfer.items[i].kind === 'file') {
        droppedFiles.push(e.dataTransfer.items[i].getAsFile());
      }
    }
  } else {
    // Use DataTransfer interface to access the file(s)
    for (let i = 0; i < e.dataTransfer.files.length; i++) {
      droppedFiles.push(e.dataTransfer.files[i]);
    }
  }

  newFilesSelected(droppedFiles);

  e.preventDefault();
}

function dragover (e) {
  e.preventDefault();
  e.stopPropagation();
}
function dragenter () { hovering.value = true; }
function dragleave () { hovering.value = false; }

function pageDragenter () { dragging.value = true; }
function pageDragleave (e) { if (e.relatedTarget == null) { dragging.value = false; } }
function pageDragover (e) { e.dataTransfer.dropEffect = 'none'; e.preventDefault(); }
function pageDrop () { hovering.value = false; dragging.value = false; }

function addDragListeners () {
  if (!mainDiv.value) { return; }
  mainDiv.value.addEventListener('dragenter', dragenter);
  mainDiv.value.addEventListener('dragleave', dragleave);
  mainDiv.value.addEventListener('dragover', dragover);
  mainDiv.value.addEventListener('drop', drop);
  window.addEventListener('dragenter', pageDragenter);
  window.addEventListener('dragleave', pageDragleave);
  window.addEventListener('dragover', pageDragover);
  window.addEventListener('drop', pageDrop);
}

function remDragListeners () {
  if (!mainDiv.value) { return; }
  mainDiv.value.removeEventListener('dragenter', dragenter);
  mainDiv.value.removeEventListener('dragend', dragleave);
  mainDiv.value.removeEventListener('dragover', dragover);
  mainDiv.value.removeEventListener('drop', drop);
  window.removeEventListener('dragenter', pageDragenter);
  window.removeEventListener('dragend', pageDragleave);
  window.removeEventListener('dragover', pageDragover);
  window.removeEventListener('drop', pageDrop);
}

watch(() => props.noDrop, () => {
  nextTick(() => {
    remDragListeners();
    hovering.value = false;
    dragging.value = false;
    if (!props.noDrop) { addDragListeners(); }
  });
}, { immediate: true });

onUnmounted(() => {
  remDragListeners();
});

defineExpose({ openFileChooser });
</script>

<template>
  <div class="file-selector w-100 h-100" :class="classes" ref="mainDiv">
    <slot :openFileChooser="openFileChooser">
      <div class="h-100 overflow-auto">
        <template v-if="multiple">
          <ul v-if="Array.isArray(props.modelValue)" class="list-group">
            <li v-for="(file, fileI) in props.modelValue" :key="fileI" class="list-group-item d-flex align-items-center py-0 ps-2 pe-0">
              <span class="flex-fill text-truncate" :title="file.name">{{file.name}}</span>
              <b-button class="text-danger text-decoration-none shadow-none" variant="link" size="sm" @click="remFile(fileI)">
                <VIIcon type="times"/>
              </b-button>
            </li>
          </ul>
        </template>
        <template v-else>
          <div class="h-100 d-flex align-items-center">
            <div class="form-control d-flex align-items-center p-0 overflow-hidden">
              <b-button class="me-1 shadow-none" variant="secondary" @click="openFileChooser">{{$edw.selectFile}}</b-button>
              <template v-if="props.modelValue == null">
                <span class="text-muted flex-fill ps-1 text-truncate">{{$edw.noFileSelected}}</span>
              </template>
              <template v-else>
                <span class="flex-fill ps-1 text-truncate" :title="props.modelValue.name">{{props.modelValue.name}}</span>
                <b-button class="text-danger text-decoration-none shadow-none" variant="link" size="sm" @click="remFile()">
                  <VIIcon type="times"/>
                </b-button>
              </template>
            </div>
          </div>
        </template>
      </div>
    </slot>
    <span class="file-selector-drop-prompt text-center">
      <span class="pe-none" v-if="dragging">{{$edw.releaseFilesHere}}</span>
      <span class="cursor-pointer" @click="openFileChooser" v-else>{{$edw.dragAndDropFilesHere}}</span>
    </span>
    <input ref="fileInput" type="file" class="d-none" :multiple="multiple" @change="fileInputChange($event)">
  </div>
</template>

<style>
.file-selector {
  position: relative;
}

.file-selector.multiple {
  min-height: 50px;
}

.file-selector:not(.no-drop) {
  padding-bottom: 1rem !important;
}

.file-selector > .file-selector-drop-prompt {
  z-index: 1030;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  font-size: .7em;
  transition: bottom .3s ease, font-size .3s ease;
  color: var(--hig-page-text-muted);
}

.file-selector.no-drop > .file-selector-drop-prompt {
  display: none;
}

.file-selector::before, .file-selector::after{
  content: '';
  z-index: 1030;
  position: absolute;
  top: 0px;
  left: 0px;
  width: calc(100% - 0px);
  height: calc(100% - 0px);
  display: none;
  opacity: 0;
  transition: opacity .3s ease;
}

.file-selector::before {
  background-color: var(--hig-page-bg);
}

.file-selector::after {
  top: 5px;
  left: 5px;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  color: var(--hig-page-text-muted);
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%238F979EFF' stroke-width='10' stroke-dasharray='25%2c 30' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 20px;
}

.file-selector.dragging > .file-selector-drop-prompt {
  display: block;
  bottom: 50%;
  transform: translate(-50%, 50%);
  font-size: 1.5em;
}

.file-selector.dragging::before, .file-selector.dragging::after {
  display: block;
  opacity: 0.9;
}

.file-selector.hovering > .file-selector-drop-prompt {
  color: var(--hig-primary);
}

.file-selector.hovering::before, .file-selector.hovering::after {
  opacity: 1;
}

.cursor-pointer {
  cursor: pointer !important;
}
</style>
