<script setup lang='ts'>
import { ref, computed, onMounted, inject, watch } from 'vue';
import CnsList from '../cns-list.vue';
import CnsListItem from '../cns-list-item.vue';
import GraphQl from '../../../libs/graphql-client/index.mjs';
import { Fop } from '../../../libs/fop-utils/index.browser.mjs';
import CnsSelect from '../cns-select.vue';
import CnsFilterInput from '../cns-filter-input.vue';
import { Installation, Datalogger } from './types';
import CnsButton from '../cns-button.vue';

const props = withDefaults(defineProps<{
  noInstallationsFilter?: boolean;
  multiple?: boolean;
  useSn?: boolean;
}>(), {
  noInstallationsFilter: false,
  multiple: false,
  useSn: false
});

const value = defineModel<string | string[] | undefined>();
const installationsSelected = defineModel<string[]>('installations', { default: () => [] });
const searchFilter = defineModel<string>('filter', { default: '' });

const $edw: any = inject('$edw');
const error = ref();

const installations = ref<Installation[]>();
const installationsOptions = computed(() => installations.value?.map((el) => ({
  value: el.id,
  text: el.name,
  desc: el.desc
})) ?? []);

const dataloggers = ref<Datalogger[]>([]);
const dataloggersLoading = ref<boolean>(false);

async function loadInstallations () {
  installations.value = await GraphQl.query(`
    Installation_get {
      id
      name
      desc
    }
  `).catch((err) => {
    error.value = $edw.translateError(err);
  });
}

async function loadDataloggers () {
  dataloggersLoading.value = true;

  let _fop = new Fop()
    .order('name', 1)
    .paginate(0, 200);

  if (installationsSelected.value.length > 0) {
    _fop = _fop.filter('installations[*].id', '=', installationsSelected.value);
  }

  if (searchFilter.value) {
    const filters = searchFilter.value.split(' ').map((el) => el.trim()).filter((el) => el.length > 0);
    filters.forEach((filter) => {
      _fop = _fop.filter({
        or: [
          { field: 'sn', op: '%', val: filter },
          { field: 'name', op: '%', val: filter }
        ]
      });
    });
  }

  dataloggers.value = await GraphQl.query(`
    Datalogger_get (fop: $fop) {
      id
      sn
      name
    }
  `, {
    fop: { type: 'FilterOrderPaginate', value: _fop.serialize() }
  }).catch((err) => {
    error.value = $edw.translateError(err);
  });

  dataloggers.value.sort((a, b) => {
    const aSelected = isSelected({ id: a.id, sn: a.sn });
    const bSelected = isSelected({ id: b.id, sn: b.sn });
    if (aSelected && !bSelected) { return -1; }
    if (!aSelected && bSelected) { return 1; }
    if (a.name < b.name) { return -1; }
    if (a.name > b.name) { return 1; }
    return 0;
  });

  dataloggersLoading.value = false;
}

const items = computed(() => dataloggers.value.map((datalogger) => ({
  id: datalogger.id,
  sn: datalogger.sn,
  label: datalogger.name,
  caption: datalogger.sn
})) ?? []);

onMounted(() => { loadInstallations(); });
watch([installationsSelected, searchFilter], () => { loadDataloggers(); }, { deep: true, immediate: true });

function isSelected (item: any) {
  const key = props.useSn ? 'sn' : 'id';

  if (!props.multiple) {
    return item[key] === value.value;
  } else {
    return value.value?.includes(item[key]);
  }
}

function toggleSelected (item: any) {
  const key = props.useSn ? 'sn' : 'id';

  if (!props.multiple) {
    if (item[key] === value.value) {
      value.value = undefined;
    } else {
      value.value = item[key];
    }
  } else {
    if (!Array.isArray(value.value)) { value.value = []; }
    if (value.value.includes(item[key])) {
      value.value = value.value.filter((el) => el !== item[key]);
    } else {
      value.value = [...value.value, item[key]];
    }
  }
}

function unselectAll () {
  if (!props.multiple) {
    value.value = undefined;
  } else {
    value.value = [];
  }
}
</script>

<template>
  <div class="d-flex flex-column gap-2">
    <div class="d-flex align-items-center justify-content-end gap-2">
      <cns-button v-if="props.multiple" @click="unselectAll" :text="$edw.unselectAll" :disabled="!value?.length" />
      <cns-select
        v-if="!props.noInstallationsFilter"
        style="width: 50%;"
        v-model="installationsSelected"
        :options="installationsOptions"
        :placeholder="$edw.installation"
        multiple
        searchable
      />
    </div>
    <div class="">
      <cns-filter-input
        class="w-100"
        v-model="searchFilter"
        :placeholder="$edw.search + ' (' + $edw.pressEnterToSearch + ')'"
        :loading="dataloggersLoading"
        :debounce="1500"
      />
    </div>
    <div class="flex-fill" style="height: 60vh;">
      <cns-list :items="items" v-slot="{ item }" class="h-100 overflow-auto">
        <cns-list-item
          icon="meter"
          :label="item.label"
          :caption="item.caption"
          checkbox
          :active="isSelected(item)"
          @click="toggleSelected(item)"
        />
      </cns-list>
    </div>
  </div>
</template>
