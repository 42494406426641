<script setup>
import { ref, nextTick } from 'vue';
import BModal from './b-modal.vue';

const modal = ref(null);
const content = ref(null);
const opt_ = ref(null);
const isOpen = ref(false);
let closeCb = null;

function open (opt) {
  if (!modal.value) { return; }
  if (isOpen.value) { return; }

  isOpen.value = true;
  opt_.value = opt;
  modal.value.open();
  nextTick(() => {
    content.value.querySelector('[default]')?.focus();
  });
  return new Promise((resolve) => { closeCb = resolve; });
}

function close () {
  if (!modal.value) { return; }
  if (!isOpen.value) { return; }

  isOpen.value = false;
  modal.value.close();
}

function confirm (opt) {
  if (!isOpen.value) { return; }
  opt = opt instanceof Event ? null : opt;

  close();
  closeCb && closeCb(opt != null ? opt : true);
}

function cancel (opt) {
  if (!isOpen.value) { return; }
  opt = opt instanceof Event ? null : opt;

  close();
  closeCb && closeCb(opt != null ? opt : false);
}

defineExpose({ open, close });
</script>

<template>
  <b-modal ref="modal" @close="cancel()">
    <div ref="content">
      <slot :confirm="confirm" :cancel="cancel" :opt="opt_"></slot>
    </div>
  </b-modal>
</template>

<script scoped>
</script>
