<script setup>
import { computed } from 'vue';
import CnsIcon from './cns-icon.vue';
import BButton from '../bootstrap/b-button.vue';

const props = defineProps({
  icon: { type: String, default: 'circle-info' },
  actions: { type: Array, default: undefined },
  variant: { type: String, default: 'info' }
});

const classes = computed(() => (['alert', 'alert-' + props.variant]));
</script>

<template>
  <div :class="classes" class="d-flex align-items-center gap-2" role="alert">
    <cns-icon :type="props.icon"/>
    <div class="flex-fill"><slot/></div>
    <b-button v-for="action in actions" :key="action.text" @click="() => action.action && action.action()" variant="link" class="action-button py-0 px-1">{{action.text}}</b-button>
  </div>
</template>

<style scoped>
.alert {
  position: relative;
  margin-bottom: 0;
}

.action-button {
  color: inherit;
}

.action-button:hover {
  opacity: .8;
}
</style>
