/* global File, FileList, FormData */
import { isBrowser, isNode } from '../runtime-detector/index.mjs';
import fetch from 'cross-fetch';
import EventEmitter from 'events';
import { createClient } from 'graphql-ws';
import _ from 'lodash';

/* #IF isNode */
import https from 'https';
/* #FI */

/* CONFIGURATION */
const CONF = {
  ACCEPT_SELF_SIGNED_CERT: false,
  AUTHORIZATION: null,
  GRAPHQL_HTTP_URL: null,
  GRAPHQL_WS_URL: null,
  QUERY_MAX_AGG: 1, // The server it's faster if we make n requests instead of aggregating them
  QUERY_DEBOUNCE_DELAY: 0,
  MUTATION_MAX_AGG: 1,
  MUTATION_DEBOUNCE_DELAY: 0
};
/* CONFIGURATION */

class HigGraphQlError extends Error {
  constructor (error = {}) {
    let message = null;
    let context = {};
    let userError = false;

    if (error.message) {
      message = error.message;

      if (error.extensions && error.extensions.HigError) {
        context = error.extensions.context || {};
        userError = !!error.extensions.userError;
      }
    }

    if (!userError) { message = 'ServerError'; }
    if (!message) { message = 'UnknownError'; }
    super(message);

    Object.defineProperty(this, 'extensions', {
      value: {
        HigError: true,
        context,
        userError
      },
      enumerable: true
    });
  }
}

// This function searches the object for File or FileList objects recursively
// all found files are returned indexed by their path in the source object
// their original position it's set to null (this method mutates `source`)
function extractFilesDeep (source, path = '') {
  if (!source) { return; }

  const files = {};
  for (const key in source) {
    const curKeyPath = path + (path ? `|${key}` : `${key}`);
    const val = source[key];
    if (val instanceof File || val instanceof FileList) {
      files[curKeyPath] = val;
      source[key] = null;
    } else if (typeof val === 'object') {
      Object.assign(files, extractFilesDeep(val, curKeyPath));
    }
  }
  return Object.keys(files).length > 0 ? files : undefined;
}

/**
 * Do a request to a GraphQL endpoint
 *
 * @param {String} url - the url of the enpoint
 * @param {String} query - the query to send
 * @param {Object} variables - variables to include with the request, if (on browser) it contains instances of File or FileList the request content-type is converted to multipart/form-data so server should accept it.
 * @param {String} sid - The session id to use to make the request
 * @param {Object} opt - Additional options
 * @param {Object} opt.acceptSelfSignedCert - If true all unsigned certificates for https will be accepted
 *
 * @returns A promise that resolves with the json response
 */
function requestTo (url, query, variables, sid, opt = {}) {
  if (!url) { throw new Error('Cannot make GraphQl request without a url'); }

  variables = _.cloneDeep(variables || {});
  const upload = isBrowser ? extractFilesDeep(variables) : null;

  let options;
  if (upload) {
    const body = new FormData();

    body.append('graphql', JSON.stringify({ query, variables }));
    for (const key in upload) {
      body.append(key, upload[key]);
    }

    options = {
      method: 'POST',
      headers: {},
      body: body
    };
  } else {
    options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ query, variables })
    };
  }

  if (sid) { options.headers.authorization = sid; }

  /* #IF isNode */
  if (opt.acceptSelfSignedCert && url.indexOf('https') === 0) {
    options.agent = new https.Agent({ rejectUnauthorized: false });
  }
  /* #FI */

  return fetch(url, options)
    .then((res) => res.json());
}

/**
 * Do a subscription request to a GraphQL endpoint (that probably must use `graphql-ws` to handle the subscriptions)
 *
 * @param {String} url - the url of the enpoint
 * @param {String} query - the subscription query to send
 * @param {Object} variables - eventual variablesto include with the request
 * @param {String} sid - The session id to use to make the request
 * @param {Object} opt - Additional options
 *
 * @returns An event emitter that can emit 'error', 'data' and 'complete' plus it has a unsubscribe function to terminate the subscription
 */
function subscribeTo (url, query, variables, sid, opt = {}) {
  if (!url) { throw new Error('Cannot make GraphQl request without a url'); }

  const subscription = new EventEmitter();

  subscription.client = createClient({
    url,
    connectionParams: {
      headers: {
        authorization: sid
      }
    }
  });

  // Give time to the caller to subscribe for events on the eventEmitter before sending the request
  const subscriptionTimeout = setTimeout(() => {
    subscription.unsubscribe = subscription.client.subscribe(
      { query, variables },
      {
        next: (res) => { subscription.emit('data', res); },
        error: (err) => {
          if (err instanceof Error) {
            subscription.emit('error', err);
          } else if (err.constructor && err.constructor.name === 'CloseEvent') {
            if (err.code === 1006) {
              subscription.emit('error', new HigGraphQlError('UNREACHABLE'));
            } else {
              subscription.emit('error', new HigGraphQlError('CLOSED'));
            }
          } else if (err.type === 'error') {
            subscription.emit('error', new HigGraphQlError(err));
          } else if (Array.isArray(err) && err.length > 0) { // GraphQLError[]
            subscription.emit('error', new HigGraphQlError(err[0]));
          } else {
            subscription.emit('error', new HigGraphQlError());
          }
        },
        complete: () => { subscription.emit('complete'); }
      }
    );
  }, 0);

  // while the subscription is not active give the caller a "fake" unsubscribe function so that the behavior doesn't change whether it's connected or not
  subscription.unsubscribe = () => {
    clearTimeout(subscriptionTimeout);
    subscription.emit('complete');
  };

  return subscription;
}

/**
 * Do a query request with some requests aggregation logic and some simplification
 *
 * @param {String} url - the url of the enpoint
 * @param {String} query - the query to send
 * @param {Object} variables - variables to include with the request,
 *  if it contains instances of File or FileList the request content-type is converted to multipart/form-data so server should accept it.
 *  Each variable must have a `type` and a `value`, with `type` being the graphql type that describes the `value`
 * @param {String} sid - The session id to use to make the request
 * @param {Object} opt - Additional options, those are directly passed to requestTo so take a look there for details
 *
 * @returns A promise that resolves with the query response
 */
function queryTo (url, query, variables, sid, opt = {}) {
  if (!url) { throw new Error('Cannot make GraphQl request without a url'); }

  const queueHash = `${opt.group || 'all'}:${sid || 'generic'}@${url}`;

  let promResolve; let promReject;
  const prom = new Promise((resolve, reject) => { promResolve = resolve; promReject = reject; });

  if (!queryQueue[queueHash]) { queryQueue[queueHash] = []; }
  queryQueue[queueHash].push({ query, variables, promResolve, promReject });

  clearTimeout(queryDebounceTo[queueHash]);
  if (queryQueue[queueHash].length < CONF.QUERY_MAX_AGG) {
    queryDebounceTo[queueHash] = setTimeout(() => _execRequestsQueue(url, 'query', queryQueue[queueHash].splice(0), sid, opt), CONF.QUERY_DEBOUNCE_DELAY);
  } else {
    _execRequestsQueue(url, 'query', queryQueue[queueHash].splice(0), sid, opt);
  }

  return prom;
}

/**
 * Do a mutation request with some requests aggregation logic and some simplification
 *
 * @param {String} url - the url of the enpoint
 * @param {String} mutation - the mutation to send
 * @param {Object} variables - variables to include with the request,
 *  if it contains instances of File or FileList the request content-type is converted to multipart/form-data so server should accept it.
 *  Each variable must have a `type` and a `value`, with `type` being the graphql type that describes the `value`
 * @param {String} sid - The session id to use to make the request
 * @param {Object} opt - Additional options, those are directly passed to requestTo so take a look there for details
 *
 * @returns A promise that resolves with the mutation response
 */
function mutationTo (url, mutation, variables, sid, opt = {}) {
  if (!url) { throw new Error('Cannot make GraphQl request without a url'); }

  const queueHash = `${opt.group || 'all'}:${sid || 'generic'}@${url}`;

  let promResolve; let promReject;
  const prom = new Promise((resolve, reject) => { promResolve = resolve; promReject = reject; });

  if (!mutationQueue[queueHash]) { mutationQueue[queueHash] = []; }
  mutationQueue[queueHash].push({ mutation, variables, promResolve, promReject });

  clearTimeout(mutationDebounceTo[queueHash]);
  if (mutationQueue[queueHash].length < CONF.MUTATION_MAX_AGG) {
    mutationDebounceTo[queueHash] = setTimeout(() => _execRequestsQueue(url, 'mutation', mutationQueue[queueHash].splice(0), sid, opt), CONF.MUTATION_DEBOUNCE_DELAY);
  } else {
    _execRequestsQueue(url, 'mutation', mutationQueue[queueHash].splice(0), sid, opt);
  }

  return prom;
}

/**
 * This function is used by queryTo and mutationTo functions, do not use it standalone
 */
function _execRequestsQueue (url, operation, queue, sid, opt = {}) {
  if (queue.length === 0) { return; }

  const variables = {};
  const variablesHeaders = [];
  const queries = [];

  queue.forEach((req, i) => {
    let curQuery = `req_${i}: ${req[operation]}\n`;
    if (req.variables) {
      Object.keys(req.variables).forEach((key) => {
        const graphQlKey = `var_${i}_${key}`;
        variables[graphQlKey] = req.variables[key].value;
        variablesHeaders.push(`$${graphQlKey}: ${req.variables[key].type}`);
        // curQuery = curQuery.replace(new RegExp(`(?<=\\((?:.|\\n)*\\$)${key}(?=(?:[^\\w](?:.|\\n)*\\)|\\)))`), graphQlKey);
        curQuery = curQuery.replace(new RegExp(`\\$${key}(?!\\w)`), `$${graphQlKey}`);
      });
    }
    queries.push(curQuery);
  });

  requestTo(url, `
    ${operation} ${variablesHeaders.length ? '(' + variablesHeaders.join(',') + ')' : ''} {
      ${queries.join('\n')}
    }
  `, variables, sid, opt).then((res) => {
    if (res.errors) { console.error(res.errors); }

    queue.forEach((req, i) => {
      if (res.errors && (res.data == null || res.data[`req_${i}`] == null)) {
        req.promReject(new HigGraphQlError(res.errors[0]));
      } else {
        req.promResolve(res.data[`req_${i}`]);
      }
    });
  }).catch((err) => {
    console.error(err);
    queue.forEach((req, i) => { req.promReject(new HigGraphQlError()); });
  });
}

const queryCompositor = (function () {
  const AVAILABLE_TYPES = new Set(['query', 'mutation', 'subscription']);
  const KEY_VALID_CHARS = /[a-zA-Z0-9._-|]+/i;

  /**
   * Compose a query for GraphQL
   *
   * @param {String} type - query type, can be query, mutation or subscription
   * @param {RequestTree} requestTree - the actual requests names with their return field
   * @param {varList} varList - injected variables
   * @returns {String} A string with the actual query
   */
  function simpleQueryCompositor (type, requestTree, varList) {
    if (!AVAILABLE_TYPES.has(type)) throw new Error('Invalid query type');

    const vars = [];

    if (varList) {
      let list;
      if (Array.isArray(varList)) {
        list = {};
        for (const v of varList) {
          if ('name' in v && 'type' in v) {
            if (isValidKey(v.name) && isValidKey(v.type)) {
              list[v.name] = v.type;
            }
          } else {
            if (isValidKey(v[0]) && isValidKey(v[1])) {
              list[v[0]] = v[1];
            }
          }
        }
      } else {
        list = varList;
      }

      for (const v in list) {
        if (isValidKey(list[v]) && isValidKey(v)) {
          vars.push(`$${v}: ${list[v]}`);
        }
      }
    }

    const requestString = parseRequestTree(requestTree);
    if (!requestString) throw new Error('Empty request');

    let query;
    query = `${type} `;
    if (vars.length) {
      query += `(${vars.join(',')}) `;
    }

    query += `{${'\n'}${requestString}${'\n'}}`;

    return query;
  }

  function parseRequestTree (tree) {
    const _tree = [];

    for (let name in tree) {
      let subtree;
      const vars = [];
      const parsedSubtree = [];

      subtree = tree[name];

      if (Array.isArray(subtree)) {
        subtree = { fields: subtree };
      }

      if (isValidKey(subtree.as)) {
        name = `${subtree.as}:${name}`;
      }

      if (!subtree.fields) subtree.fields = [];

      for (const field of subtree.fields) {
        if (typeof field === 'string') {
          isValidKey(field) && parsedSubtree.push(field);
        } else {
          parsedSubtree.push(parseRequestTree(field));
        }
      }

      if (Array.isArray(subtree.vars)) {
        for (const v of subtree.vars) {
          let pair;
          if ('name' in v && 'ref' in v) {
            pair = [v.name, v.ref];
          } else {
            pair = v;
          }

          if (Array.isArray(pair) && pair.length === 2) {
            if (isValidKey(pair[0]) && isValidKey(pair[1])) {
              pair[1] = `$${pair[1]}`;
              vars.push(pair.join(': '));
            }
          }
        }
      } else if (typeof subtree.vars === 'object' && Object.keys(subtree.vars).length) {
        for (const name in subtree.vars) {
          const ref = subtree.vars[name];
          if (isValidKey(name) && isValidKey(ref)) {
            vars.push([name, `$${ref}`].join(': '));
          }
        }
      }

      let query;
      query = `${name} `;
      if (vars.length) {
        query += `(${vars.join(', ')}) `;
      }

      if (parsedSubtree.length) {
        query += `{${'\n'}${parsedSubtree.join('\n')}${'\n'}}`;
      }

      _tree.push(query);
    }

    return _tree.join('\n');
  }

  function isValidKey (key) {
    if (typeof key === 'string' && key) {
      return KEY_VALID_CHARS.test(key);
    }
    return false;
  }

  return simpleQueryCompositor;
}());

function encodeVars (variables) {
  if (variables == null) { return null; }

  const vars = [];
  for (const el in variables) {
    if (variables[el] != null) {
      if (typeof variables[el] !== 'object' || Array.isArray(variables[el])) {
        vars.push(`${el}: ${JSON.stringify(variables[el])}`);
      } else {
        vars.push(`${el}: ${encodeVars(variables[el])}`);
      }
    }
  }
  return `{${vars.join(',')}}`;
}

const queryDebounceTo = {};
const queryQueue = {};
const mutationDebounceTo = {};
const mutationQueue = {};

class GraphQl {
  constructor ({ httpUrl, wsUrl, authorization, acceptSelfSignedCert = false } = {}) {
    this.isInstance = true;
    this.httpUrl = httpUrl;
    this.wsUrl = wsUrl;
    this.authorization = authorization;
    this.acceptSelfSignedCert = acceptSelfSignedCert;
  }

  getRequestUrl () { return GraphQl.getRequestUrl.call(this); }
  static getRequestUrl () {
    if (this.isInstance && this.httpUrl) {
      return this.httpUrl;
    } else if (!this.isInstance && CONF.GRAPHQL_HTTP_URL) {
      return CONF.GRAPHQL_HTTP_URL;
    } else if (isBrowser) { // browser
      return window.location.origin + '/graphql';
    } else { // node
      return 'https://127.0.0.1/graphql';
    }
  }

  getSubscribeUrl () { return GraphQl.getSubscribeUrl.call(this); }
  static getSubscribeUrl () {
    if (this.isInstance && this.wsUrl) {
      return this.wsUrl;
    } else if (!this.isInstance && CONF.GRAPHQL_WS_URL) {
      return CONF.GRAPHQL_WS_URL;
    } else if (isBrowser) { // browser
      return (window.location.protocol === 'https:' ? 'wss://' : 'ws://') + window.location.host + '/graphqlws';
    } else { // node
      return 'ws://127.0.0.1/graphqlws';
    }
  }

  static requestTo (url, query, variables, sid, opt = {}) { return requestTo(url, query, variables, sid || CONF.AUTHORIZATION, { acceptSelfSignedCert: CONF.ACCEPT_SELF_SIGNED_CERT, ...opt }); }
  static subscribeTo (url, query, variables, sid, opt = {}) { return subscribeTo(url, query, variables, sid || CONF.AUTHORIZATION, { acceptSelfSignedCert: CONF.ACCEPT_SELF_SIGNED_CERT, ...opt }); }

  /****/ request (query, variables, sid) { return requestTo(this.getRequestUrl(), query, variables, sid || this.authorization, { acceptSelfSignedCert: this.acceptSelfSignedCert }); }
  static request (query, variables, sid) { return requestTo(this.getRequestUrl(), query, variables, sid || CONF.AUTHORIZATION, { acceptSelfSignedCert: CONF.ACCEPT_SELF_SIGNED_CERT }); }

  /****/ subscribe (query, variables, sid) { return subscribeTo(this.getSubscribeUrl(), query, variables, sid || this.authorization, { acceptSelfSignedCert: this.acceptSelfSignedCert }); }
  static subscribe (query, variables, sid) { return subscribeTo(this.getSubscribeUrl(), query, variables, sid || CONF.AUTHORIZATION, { acceptSelfSignedCert: CONF.ACCEPT_SELF_SIGNED_CERT }); }

  /****/ query (query, variables, sid, { group } = {}) { return queryTo(this.getRequestUrl(), query, variables, sid || this.authorization, { acceptSelfSignedCert: this.acceptSelfSignedCert, group }); }
  static query (query, variables, sid, { group } = {}) { return queryTo(this.getRequestUrl(), query, variables, sid || CONF.AUTHORIZATION, { acceptSelfSignedCert: CONF.ACCEPT_SELF_SIGNED_CERT, group }); }

  /****/ mutation (mutation, variables, sid, { group } = {}) { return mutationTo(this.getRequestUrl(), mutation, variables, sid || this.authorization, { acceptSelfSignedCert: this.acceptSelfSignedCert, group }); }
  static mutation (mutation, variables, sid, { group } = {}) { return mutationTo(this.getRequestUrl(), mutation, variables, sid || CONF.AUTHORIZATION, { acceptSelfSignedCert: CONF.ACCEPT_SELF_SIGNED_CERT, group }); }

  static CONF = CONF;
  static queryCompositor = queryCompositor;
  static encodeVars = encodeVars;
}

export default GraphQl;
