<script setup lang="ts">
import { ref, inject, watch } from 'vue';
import CnsModal from '../cns-modal.vue';
import CnsPeripheralSelector from './cns-peripheral-selector.vue';
import CnsButton from '../cns-button.vue';

const $edw = inject<any>('$edw');

defineOptions({ inheritAttrs: false });
const props = defineProps<{
  title: string;
  size?: string;
}>();

const peripheral = defineModel<string | undefined>();
const _peripheral = ref<string | undefined>();
watch(peripheral, () => { _peripheral.value = peripheral.value; }, { immediate: true });

const modal = ref<any>();

defineExpose({
  open: () => modal.value.open?.(),
  close: () => modal.value.close?.()
});
</script>

<template>
  <cns-modal ref="modal" :title="props.title" :size="props.size">
    <cns-peripheral-selector
      v-model="_peripheral"
      v-bind="$attrs"
    />
    <div class="d-flex justify-content-end gap-2 mt-2">
      <cns-button :text="$edw.cancel" variant="secondary" @click="() => modal.close()" />
      <cns-button :text="$edw.confirm" variant="primary" @click="() => { peripheral = _peripheral; modal.close() }" />
    </div>
  </cns-modal>
</template>
