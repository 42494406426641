const genQueueName = 'general';

class RequestsAggregator {
  constructor (executor, { debounce = 25, limit = 50 } = {}) {
    this.debounceTo = {};
    this.queues = {};
    this.debounce = debounce;
    this.limit = limit;
    this.execute = executor;

    const res = (req, reqHash) => this.add(req, reqHash, genQueueName);
    res.addTo = (queueName, req, reqHash) => this.add(req, reqHash, queueName || genQueueName);

    return res;
  }

  add (req, reqHash, queueName) {
    if (!reqHash) { reqHash = this.queues.size; }
    if (!this.queues[queueName]) { this.queues[queueName] = new Map(); }

    let prom;
    if (this.queues[queueName].has(reqHash)) {
      const curReq = this.queues[queueName].get(reqHash);
      curReq.req = req;
      prom = curReq.prom;
    } else {
      let promResolve; let promReject;
      prom = new Promise((resolve, reject) => { promResolve = resolve; promReject = reject; });
      this.queues[queueName].set(reqHash, { req, prom, resolve: promResolve, reject: promReject });
    }

    clearTimeout(this.debounceTo[queueName]);
    if (this.queues[queueName].size < this.limit) {
      this.debounceTo[queueName] = setTimeout(() => { this.exec(queueName); }, this.debounce);
    } else {
      this.exec(queueName);
    }

    return prom;
  }

  async exec (queueName) {
    const curQueue = [...this.queues[queueName].values()];
    this.queues[queueName].clear();
    delete this.queues[queueName];

    const rejectAll = (error) => {
      curQueue.forEach((el) => { el.reject(error); });
    };

    if (!this.execute) {
      rejectAll(new Error('No executor function'));
    }

    const results = await Promise.resolve(curQueue.map((el) => el.req)).then((reqs) => this.execute(reqs, queueName !== genQueueName ? queueName : undefined)).catch((err) => err);

    if (results instanceof Error) {
      rejectAll(results);
    } else if (!Array.isArray(results) || results.length !== curQueue.length) {
      rejectAll(new Error('Results error'));
    } else {
      curQueue.forEach((el, i) => {
        if (results[i] instanceof Error) {
          el.reject(results[i]);
        } else {
          el.resolve(results[i]);
        }
      });
    }
  }
}

export default RequestsAggregator;
