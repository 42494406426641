<template>
  <b-form-group :state="error ? false : null" :invalid-feedback="typeof error === 'string' ? $edw[error] : ''">
    <template v-if="label && !plaintext && type !== 'checkbox' && type !== 'title'" #label><span class="label-span" v-html="label"/></template>

    <h4 v-if="type === 'title'" v-html="label" />
    <div v-else-if="plaintext" class="d-flex justify-content-between">
      <span class="label-span" v-html="label"></span>
      <div v-if="type === 'password'" class="d-flex align-items-center gap-1">
        <span>{{ showPass ? value : '• • • • •' }}</span>
        <b-button variant="link" @click="showPass = !showPass" class="shadow-none text-decoration-none d-flex align-items-center justify-content-center" style="color: var(--hig-page-text);"><VI-Icon :type="showPass ? 'eye' : 'eye-slash'" style="width: 20px;"/></b-button>
      </div>
      <div v-else-if="type === 'checkbox'" class="d-flex align-items-center justify-content-center" style="width: 20px;">
        <VI-Icon :type="value ? 'check' : 'times'"/>
      </div>
      <div v-else>
        <span>{{ value }}</span>
      </div>
    </div>
    <template v-else>
      <b-form-select v-if="type === 'select'" :modelValue="value" @update:modelValue="emitUpdate" :state="error ? false : null" :options="options" :multiple="multiple" :placeholder="placeholder" :readonly="readonly"/>
      <div v-else-if="type === 'password'" class="password-input-container position-relative" :class="{ 'is-invalid': error }">
        <VI-Icon v-if="icon" :type="icon" class="position-absolute top-50 start-0 translate-middle-y text-center mx-2" style="width: 20px; color: var(--hig-form-text); opacity: .5;"/>
        <b-form-input :modelValue="value" @update:modelValue="emitUpdate" :state="error ? false : null" :type="showPass ? 'text' : 'password'" :style="{ 'padding-left': !!icon && '2rem', 'padding-right': '2.5rem' }" :lazy="lazy" :debounce="debounce" :placeholder="placeholder" :readonly="readonly"/>
        <b-button variant="link" @click="showPass = !showPass" class="Password-Show-Toggle position-absolute top-50 end-0 translate-middle-y shadow-none" style="color: var(--hig-form-text);"><VI-Icon :type="showPass ? 'eye' : 'eye-slash'" style="width: 20px;"/></b-button>
      </div>
      <div v-else-if="type === 'checkbox'" class="d-flex align-items-center" :class="{ 'is-invalid': error }">
        <b-checkbox :modelValue="value" @update:modelValue="emitUpdate" class="me-1" :readonly="readonly"/>
        <span v-if="label" class="label-span" v-html="label" @click="(e) => { if (e.target.nodeName !== 'A') { emitUpdate(!value); } }" :style="{ 'cursor': readonly ? 'default' : 'pointer' }"/>
      </div>
      <div v-else class="position-relative" :class="{ 'is-invalid': error }">
        <VI-Icon v-if="icon" :type="icon" class="position-absolute top-50 start-0 translate-middle-y text-center mx-2" style="width: 20px; color: var(--hig-form-text); opacity: .5;"/>
        <b-form-input :modelValue="value" @update:modelValue="emitUpdate" :state="error ? false : null" :type="type" :style="{ 'padding-left': !!icon && '2rem' }" :lazy="lazy" :debounce="debounce" :placeholder="placeholder" :readonly="readonly"/>
      </div>
    </template>

    <template v-if="desc" #description><span class="description-span" v-html="desc"/></template>
  </b-form-group>
</template>

<script>
import BFormSelect from '../bootstrap/b-form-select.vue';
import BFormGroup from '../bootstrap/b-form-group.vue';
import BFormInput from '../bootstrap/b-form-input.vue';
import BCheckbox from '../bootstrap/b-checkbox.vue';
import BButton from '../bootstrap/b-button.vue';
import Utils from '../../libs/utils';
import { VIIcon, lib_Marked as marked } from '@hig/vision-sdk';
import { DateTime } from 'luxon';
import _ from 'lodash';

export default {
  name: 'cns-custom-form-input',
  components: {
    BFormSelect,
    BFormGroup,
    BFormInput,
    BCheckbox,
    BButton,
    VIIcon
  },
  props: {
    modelValue: { type: [String, Number, Array, Boolean], default: undefined },
    config: { type: Object, default: () => { return {}; } },
    debounce: { type: Number, default: 0 },
    lazy: { type: Boolean, default: false },
    error: { type: [Boolean, String], default: false },
    readonly: { type: Boolean, default: false },
    plaintext: { type: Boolean, default: false }
  },
  data () {
    return {
      showPass: false
    };
  },
  computed: {
    // type => Any
    value: function () {
      if (this.modelValue != null) {
        if (this.type === 'date') {
          return Utils.isNum(this.modelValue) ? DateTime.fromSeconds(this.modelValue).toISODate() : null;
        } else if (this.type === 'datetime-local') {
          return Utils.isNum(this.modelValue) ? DateTime.fromSeconds(this.modelValue).toISO().substring(0, 19) : null;
        } else if (this.type === 'month') {
          return Utils.isNum(this.modelValue) ? DateTime.fromSeconds(this.modelValue).toISO().substring(0, 7) : null;
        } else if (this.type === 'week') {
          return Utils.isNum(this.modelValue) ? DateTime.fromSeconds(this.modelValue).toISOWeekDate().substring(0, 8) : null;
        }
      }
      return this.modelValue;
    },
    type: function () { return this.config.type || 'text'; },
    label: function () { return this.config.label ? marked.parse(this.$edw[this.config.label] + (this.mandatory ? '*' : '')) : null; },
    desc: function () { return this.config.desc ? marked.parse(this.$edw[this.config.desc]) : null; },
    default: function () {
      if (this.type === 'text') {
        return (this.config.default && this.$edw[this.config.default]) || '';
      }
      return this.config.default || null;
    },
    mandatory: function () { return this.config.mandatory || false; },
    placeholder: function () { return (this.config.placeholder && this.$edw[this.config.placeholder]) || ''; },
    icon: function () { return this.config.icon || null; },
    // type => 'select'
    options: function () {
      return this.config.options
        ? this.config.options.map((opt) => {
          opt = _.clone(opt);
          opt.text = this.$edw[opt.text];
          return opt;
        })
        : null;
    },
    multiple: function () { return this.config.multiple || false; }
  },
  methods: {
    emitUpdate (newVal) {
      if (this.type === 'date' || this.type === 'datetime-local' || this.type === 'month' || this.type === 'week') {
        this.$emit('update:modelValue', newVal ? DateTime.fromISO(newVal).toSeconds() : null);
        return;
      }
      this.$emit('update:modelValue', newVal);
    }
  },
  mounted () {
    if (this.default != null && this.modelValue == null) {
      this.$emit('update:modelValue', this.default);
    }
  }
};
</script>

<style scoped>
.password-input-container.is-invalid > :deep(.form-control){
  padding-right: 4rem !important;
}

.password-input-container > .Password-Show-Toggle{
  transition: right .3s ease;
}

.password-input-container.is-invalid > .Password-Show-Toggle{
  right: 1.5rem !important;
}

.label-span :deep(p), .description-span :deep(p){
  margin-bottom: 0;
}

.label-span :deep(a), .description-span :deep(a){
  color: inherit;
  text-decoration: underline
}
</style>
