import { h, ref } from 'vue';
import { createStandaloneComponent } from '../../../libs/vue-utils';

function getCustomHeaderRenderer (provides = {}) {
  return class CustomHeaderRenderer {
    // static maxHeaderHeight = 0;

    params = ref(null);
    eGui;
    component;

    filterActive = ref(false);
    sortDirection = ref(null);

    onFilterChangedListener;
    onSortChangedListener;

    init (params) {
      this.params.value = params;

      this.eGui = document.createElement('div');
      this.eGui.className = 'head-slot-wrapper';

      this.component = createStandaloneComponent(() => {
        const res = [];

        if (this.params.value.enableMenu) {
          res.push(
            h('div', {
              class: 'menu ' + (this.filterActive.value ? 'active' : ''),
              onClick: (e) => { this.params.value.showColumnMenu(e.target); }
            }, [
              h('i', { class: 'fa fa-filter' })
            ])
          );
        }

        const slot = this.params.value?.getSlot?.();
        if (slot) {
          const col = this.params.value?.getCol?.();
          res.push(slot({ label: this.params.value.displayName, col }));
        } else {
          res.push(h('span', { class: 'head-name-text' }, this.params.value.displayName));
        }

        if (this.params.value.enableSorting) {
          res.push(
            h('div', {
              class: 'sort ' + (this.sortDirection.value ? 'active' : ''),
              onClick: (e) => { this.params.value.progressSort(e.shiftKey); }
            }, [
              h('i', { class: 'fa ' + (this.sortDirection.value == null ? 'fa-arrow-down-arrow-up' : (this.sortDirection.value === 'asc' ? 'fa-arrow-down-short-wide' : 'fa-arrow-up-short-wide')) })
            ])
          );
        }

        return res;
      }, provides);
      this.component.mount(this.eGui);

      this.onFilterChangedListener = () => { this.filterActive.value = this.params.value.column.isFilterActive(); };
      this.onFilterChangedListener();
      this.params.value.column.addEventListener('filterChanged', this.onFilterChangedListener);

      this.onSortChangedListener = () => { this.sortDirection.value = this.params.value.column.getSort(); };
      this.onSortChangedListener();
      this.params.value.column.addEventListener('sortChanged', this.onSortChangedListener);

      // setTimeout(() => { // FIXME can be done better
      //   if (this.eGui.offsetHeight > CustomHeaderRenderer.maxHeaderHeight) {
      //     CustomHeaderRenderer.maxHeaderHeight = this.eGui.offsetHeight + 30;
      //     clearTimeout(CustomHeaderRenderer.maxHeaderHeightTo);
      //     CustomHeaderRenderer.maxHeaderHeightTo = setTimeout(() => {
      //       this.params.api.setHeaderHeight(CustomHeaderRenderer.maxHeaderHeight);
      //       // gridColApi.autoSizeColumns(gridColApi.getColumns().map(c => c.colId), false);
      //     }, 10);
      //   }
      // }, 2000);
    }

    getGui () { return this.eGui; }

    refresh (params) {
      this.params.value = params;
      return true;
    }

    destroy () {
      this.params.value.column.removeEventListener('filterChanged', this.onFilterChangedListener);
      this.params.value.column.removeEventListener('sortChanged', this.onSortChangedListener);
      this.component.unmount();
      this.component = null;
    }
  };
}

export {
  getCustomHeaderRenderer
};
