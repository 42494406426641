<script setup>
import { inject, computed } from 'vue';
import CnsModalNotice from './cns-modal-notice.vue';
import { HigError } from '../../libs/hig-error/index.mjs';

const props = defineProps({
  error: { type: HigError, default: undefined },
  actions: { type: Array, default: undefined }
});

const $edw = inject('$edw');

const variant = computed(() => props.error?.extensions?.warning ? 'warning' : 'danger');
const icon = computed(() => props.error?.extensions?.warning ? 'triangle-exclamation' : 'circle-exclamation');
const title = computed(() => props.error?.extensions?.warning ? $edw.warning : $edw.error);
const desc = computed(() => {
  if (props.error?.extensions?.userError) {
    if (typeof $edw.translateError === 'function') {
      return $edw.translateError(props.error);
    } else if (props.error?.message) {
      return $edw[props.error?.message];
    }
  }
  return $edw.UnidentifiedError;
});
</script>

<template>
  <cns-modal-notice v-if="props.error" :variant="variant" :icon="icon" :title="title" :desc="desc" :actions="props.actions" />
</template>
