<script setup>
import { inject, computed, ref, watch } from 'vue';
import { genQueryString } from '../libs/utils';

const props = defineProps({
  to: { type: [String, Object], default: undefined }, // => /index/12 != /index/32
  replace: { type: Boolean, default: false },
  custom: { type: Boolean, default: false },
  activeClass: { type: String, default: 'router-link-active' },
  exactActiveClass: { type: String, default: 'router-link-exact-active' },
  ariaCurrentValue: { type: String, default: 'page' } // => 'page' | 'step' | 'location' | 'date' | 'time' | 'true' | 'false'
});

const $router = inject(window.cnsRouterInstance.injectKeys.router);

const toUrl = computed(() => {
  const toRoute = window.cnsRouterInstance.parseToRoute(props.to, $router.basePath);
  return toRoute.url + genQueryString(toRoute.query);
});

const isActive = ref(false);
const isExactActive = ref(false);

watch(() => $router.route, () => {
  const [toPathname, toQuery = ''] = toUrl.value.split('?');
  const curUrlPathname = $router.route.url;
  const curUrlQuery = genQueryString($router.route.query);

  isActive.value = curUrlPathname.indexOf(toPathname) === 0 && curUrlQuery === toQuery;
  isExactActive.value = $router.route.url === toUrl.value;
}, { immediate: true });

const classes = computed(() => ({
  [props.activeClass]: isActive.value,
  [props.exactActiveClass]: isExactActive.value
}));

function navigate () {
  if (props.replace) {
    $router.replace(props.to);
  } else {
    $router.push(props.to);
  }
}

function navigateNewTab () {
  window.open(toUrl.value, '_blank');
}
</script>

<template>
  <template v-if="custom">
    <slot :href="toUrl" :isActive="isActive" :isExactActive="isExactActive" :navigate="navigate"></slot>
  </template>
  <template v-else>
    <a :href="toUrl" :class="classes" @click.left.prevent="navigate" @click.middle.prevent="navigateNewTab" :aria-current="props.ariaCurrentValue">
      <slot :href="toUrl" :isActive="isActive" :isExactActive="isExactActive"></slot>
    </a>
  </template>
</template>
