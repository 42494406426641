<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import CnsIcon from './cns-icon.vue';

const props = defineProps({
  title: { type: String, default: '' },
  noHeader: { type: Boolean, default: false },
  noBody: { type: Boolean, default: false },
  variant: { type: String, default: undefined },
  centered: { type: Boolean, default: false },
  scrollable: { type: Boolean, default: false },
  size: { type: String, default: undefined },
  'no-close': { type: Boolean, default: false }
});
const emit = defineEmits(['close']);

const modal = ref();
const show = ref(false);
const opt = ref();
const _props = computed(() => ({
  ...props,
  ...(opt.value || {})
}));

let closeTimeout;

const classes = computed(() => ({
  'modal-dialog-centered': _props.value.centered,
  'modal-dialog-scrollable': _props.value.scrollable,
  ['modal-' + _props.value.size]: !!_props.value.size
}));

function open (options) {
  clearTimeout(closeTimeout);
  show.value = true;
  opt.value = options;

  this.$nextTick(() => {
    requestAnimationFrame(() => {
      modal.value.classList.add('show');
    });
  });
}
function close () {
  if (modal.value) { modal.value.classList.remove('show'); };
  closeTimeout = setTimeout(() => {
    show.value = false;
    opt.value = {};
  }, 150);
  emit('close');
}

defineExpose({ open, close });

// trigger close when clicking escape button
onMounted(() => {
  document.body.addEventListener('keyup', getEscape);
});

onUnmounted(() => {
  document.body.removeEventListener('keyup', getEscape);
});

function getEscape (e) {
  if (e.key === 'Escape') close();
}
</script>

<template>
  <teleport to="body">
    <div v-if="show" ref="modal" class="cns-modal modal fade" :class="{ [`cns-modal-${_props.variant}`]: !!_props.variant }" tabindex="-1" role="dialog" style="display: block;">
      <div class="modal-dialog px-0" :class="classes">
        <div class="modal-content">
          <header class="modal-header py-0 px-4 border-0" style="z-index: 1;" v-if="!_props.noHeader">
            <div class="modal-header-container d-flex align-items-center border-2 border-bottom py-3 w-100">
              <h3 class="flex-fill my-1 fw-normal">{{_props.title}}</h3>
              <div v-if="!_props.noClose" class="modal-actions d-flex algin-items-center justify-content-end gap-2">
                <slot name="actions" :opt="opt" :close="close">
                  <cns-icon class="close-button" type="times" @click="close()"/>
                </slot>
              </div>
            </div>
          </header>
          <div v-if="!_props.noBody" class="modal-body mx-0 mx-lg-2 mb-2 d-flex flex-column" :class="{ 'mt-2': noHeader }">
            <slot :opt="opt" :close="close"/>
          </div>
          <template v-else>
            <slot :opt="opt" :close="close"/>
          </template>
        </div>
      </div>
    </div>
  </teleport>
</template>

<style scoped>
.modal{
  transition: opacity .15s ease, background-color .15s ease;
  /* background-color: #21212100; */
  background-color: rgba(var(--hig-light-rgb), 0);
}

.modal.show{
  /* background-color: #212121AA; */
  background-color: rgba(var(--hig-light-rgb), 0.7);
}

.cns-modal .modal-header-container {
  border-color: var(--hig-primary) !important;
}

.cns-modal .close-button {
  cursor: pointer;
}

/* Change the background of the child cards to a different shade */
.cns-modal:deep(.cns-card) {
  background-color: var(--hig-page-bg-light);
}

/* Variants */
.cns-modal.cns-modal-primary .modal-content {
  color: var(--hig-primary-text);
  background: var(--hig-primary);
  border-color: var(--hig-primary-border);
}
.cns-modal.cns-modal-primary .modal-content .modal-header-container { border-color: var(--hig-primary-text) !important; }
.cns-modal.cns-modal-primary:deep(.cns-card.cns-card-primary) {
  background-color: var(--hig-primary-accent);
  border-color: var(--hig-primary-border-active);
}

.cns-modal.cns-modal-secondary .modal-content {
  color: var(--hig-secondary-text);
  background: var(--hig-secondary);
  border-color: var(--hig-secondary-border);
}
.cns-modal.cns-modal-secondary .modal-content .modal-header-container { border-color: var(--hig-secondary-text) !important; }
.cns-modal.cns-modal-secondary:deep(.cns-card.cns-card-secondary) {
  background-color: var(--hig-secondary-accent);
  border-color: var(--hig-secondary-border-active);
}

.cns-modal.cns-modal-success .modal-content {
  color: var(--hig-success-text);
  background: var(--hig-success);
  border-color: var(--hig-success-border);
}
.cns-modal.cns-modal-success .modal-content .modal-header-container { border-color: var(--hig-success-text) !important; }
.cns-modal.cns-modal-success:deep(.cns-card.cns-card-success) {
  background-color: var(--hig-success-accent);
  border-color: var(--hig-success-border-active);
}

.cns-modal.cns-modal-info .modal-content {
  color: var(--hig-info-text);
  background: var(--hig-info);
  border-color: var(--hig-info-border);
}
.cns-modal.cns-modal-info .modal-content .modal-header-container { border-color: var(--hig-info-text) !important; }
.cns-modal.cns-modal-info:deep(.cns-card.cns-card-info) {
  background-color: var(--hig-info-accent);
  border-color: var(--hig-info-border-active);
}

.cns-modal.cns-modal-warning .modal-content {
  color: var(--hig-warning-text);
  background: var(--hig-warning);
  border-color: var(--hig-warning-border);
}
.cns-modal.cns-modal-warning .modal-content .modal-header-container { border-color: var(--hig-warning-text) !important; }
.cns-modal.cns-modal-warning:deep(.cns-card.cns-card-warning) {
  background-color: var(--hig-warning-accent);
  border-color: var(--hig-warning-border-active);
}

.cns-modal.cns-modal-danger .modal-content {
  color: var(--hig-danger-text);
  background: var(--hig-danger);
  border-color: var(--hig-danger-border);
}
.cns-modal.cns-modal-danger .modal-content .modal-header-container { border-color: var(--hig-danger-text) !important; }
.cns-modal.cns-modal-danger:deep(.cns-card.cns-card-danger) {
  background-color: var(--hig-danger-accent);
  border-color: var(--hig-danger-border-active);
}

.cns-modal.cns-modal-light .modal-content {
  color: var(--hig-light-text);
  background: var(--hig-light);
  border-color: var(--hig-light-border);
}
.cns-modal.cns-modal-light .modal-content .modal-header-container { border-color: var(--hig-light-text) !important; }
.cns-modal.cns-modal-light:deep(.cns-card.cns-card-light) {
  background-color: var(--hig-light-accent);
  border-color: var(--hig-light-border-active);
}

.cns-modal.cns-modal-dark .modal-content {
  color: var(--hig-dark-text);
  background: var(--hig-dark);
  border-color: var(--hig-dark-border);
}
.cns-modal.cns-modal-dark .modal-content .modal-header-container { border-color: var(--hig-dark-text) !important; }
.cns-modal.cns-modal-dark:deep(.cns-card.cns-card-dark) {
  background-color: var(--hig-dark-accent);
  border-color: var(--hig-dark-border-active);
}

.cns-modal.cns-modal-muted .modal-content {
  color: var(--hig-page-text-muted);
}
.cns-modal.cns-modal-muted .modal-content .modal-header-container { border-color: var(--hig-page-text-muted) !important; }

</style>
