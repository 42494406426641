<script setup>
import { inject } from 'vue';
import CnsIcon from './cns-icon.vue';
import BButton from '../bootstrap/b-button.vue';

const $edw = inject('$edw');
const props = defineProps({
  icon: { type: String, default: undefined },
  text: { type: String, default: undefined },
  loading: { type: Boolean, default: false }
});
</script>

<template>
  <b-button class="cns-button py-0 d-flex align-items-center justify-content-center" :class="{ 'text-only': props.text && !props.icon && !loading, 'icon-only': props.icon && !props.text }">
    <span class="icon me-2" v-if="loading">
      <div class="loader spinner-border" role="status"><span class="visually-hidden">{{$edw.loading}}</span></div>
    </span>
    <span class="icon me-2" v-else-if="props.icon">
      <cns-icon :type="props.icon"/>
    </span>
    <span class="text">{{props.text}}</span>
  </b-button>
</template>

<style scoped>
.cns-button {
  height: 2.5rem;
}

.cns-button.btn-sm {
  height: 2rem;
}

.cns-button.btn-lg {
  height: 3rem;
}

.cns-button > .icon {
  width: 1rem;
  height: 1rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.cns-button > .icon > .loader {
  min-width: 1rem;
  min-height: 1rem;
  width: 1rem;
  height: 1rem;
  border-width: 2px;
}

.cns-button > .text {
  /* font-size: 20px; */
  transition: width .3s ease;
}

.cns-button.icon-only {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.cns-button.btn-sm.icon-only {
  padding-left: .2rem !important;
  padding-right: .2rem !important;
}

.cns-button.btn-lg.icon-only {
  padding-left: .7rem !important;
  padding-right: .7rem !important;
}

.cns-button.icon-only > .icon {
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1.5rem;
  margin-right: 0 !important;
}

.cns-button.icon-only > .icon > .loader {
  min-width: 1.5rem;
  min-height: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
}

.cns-button.icon-only > .text {
  display: none;
}

.cns-button.text-only > .icon {
  display: none;
}

.cns-button.text-only > .text {
}

.cns-button > .icon > .cns-icon {
  font-size: 1em; /* Sizing fix */
}

.cns-button.btn-link {
  text-decoration: none;
}

.cns-button.btn-link > .text {
  text-decoration: underline;
}
</style>
