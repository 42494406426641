import tinycolor from 'tinycolor2';

function rgb (color) { const rgb = color.clone().toRgb(); return `${rgb.r}, ${rgb.g}, ${rgb.b}`; }
function accent (color) { return color.isLight() ? color.clone().darken(7.5).toString() : color.clone().brighten(10).toString(); }
function dim (color) { return color.clone().setAlpha(0.5).toString(); }
function text (color, black, white) { return tinycolor.mostReadable(color, [black, white]); }

function getColorComplements (color, { white, black } = {}) {
  const color_ = tinycolor(color);
  const white_ = tinycolor(white || window.getComputedStyle(document.documentElement).getPropertyValue('--hig-white'));
  const black_ = tinycolor(black || window.getComputedStyle(document.documentElement).getPropertyValue('--hig-black'));

  return {
    color: color_.toString(),
    rgb: rgb(color_),
    accent: accent(color_),
    dim: dim(color_),
    text: text(color_, black_, white_)
  };
}

function getColorShades (color, { amounts = [0, 4, 8, 16, 22, 29, 38, 48, 58] } = {}) {
  const color_ = tinycolor(color);

  if (!Array.isArray(amounts) || amounts.length === 0) { throw new Error(`[getShades] Invalid amounts: ${amounts}`); }

  const ret = [];

  for (let i = 0; i < amounts.length; i++) {
    const currColor = color_.clone()[amounts[i] < 0 ? 'darken' : 'lighten'](Math.abs(amounts[i]));
    ret.push({ hex: currColor.toString(), rgb: rgb(currColor) });
  }

  return ret;
}

export default {
  getColorComplements,
  getColorShades
};

export {
  getColorComplements,
  getColorShades
};
