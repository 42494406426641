import { h, ref } from 'vue';
import BCheckbox from '../../bootstrap/b-checkbox.vue';
import { createStandaloneComponent } from '../../../libs/vue-utils';

function getCustomSelectCellRenderer (provides = {}) {
  return class CustomSelectCellRenderer {
    params = ref(null);
    eGui;
    component;

    selected = ref(false);

    onRowSelected;

    init (params) {
      this.params.value = params;

      this.eGui = document.createElement('div');
      this.eGui.className = 'ag-cell-value-wrapper';

      this.component = createStandaloneComponent(() => {
        return h(BCheckbox, {
          modelValue: this.selected.value,
          'onUpdate:modelValue': (v) => { this.params.value.node.setSelected(v); },
          readonly: !this.params.value.node.selectable,
          noAnim: true
        });
      }, provides);
      this.component.mount(this.eGui);

      this.onRowSelected = () => { this.selected.value = this.params.value.node.isSelected(); };
      this.onRowSelected();
      this.params.value.node.addEventListener('rowSelected', this.onRowSelected);
    }

    refresh (params) {
      this.params.value = params;
      return true;
    }

    getGui () { return this.eGui; }

    destroy () {
      this.params.value.node.removeEventListener('rowSelected', this.onRowSelected);
      this.component.unmount();
      this.component = null;
      this.eGui = null;
    }
  };
}

export {
  getCustomSelectCellRenderer
};
