<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch, inject } from 'vue';
import type { Component } from 'vue';
import lodash from 'lodash';
import { sum, mean as average, min, max, count } from 'd3-array';
import { lib_Utils as VisionUtils } from '@hig/vision-sdk';

import { PREDEFINED_COMPONENTS } from './types.js';
import type {
  CnsDataWrapperConfig,
  CnsDataWrapperNode,
  CnsDataWrapperTransformation,
  CnsDataWrapperAggregation,
  PredefinedComponents,
  CnsDataWrapperConfigAction,
  FetchDataResult
} from './types.js';

import { Fop } from '../../../libs/fop-utils/index.browser.mjs';
import Utils from '../../../libs/utils/index.mjs';
import DataUtils from '../../../libs/data-utils/index.js';
import type { SeriesData, DataSample, TidyAggregateNodeDataAggregator } from '../../../libs/data-utils/index.js';

import { useFetchData, useFetchAlarms, useWriteDataToDl, parseInterval } from './utils.js';

interface Props {
  config: CnsDataWrapperConfig,
  nodes?: CnsDataWrapperNode[],
  timeZone?: string,
  transformation?: CnsDataWrapperTransformation,
  aggregation?: CnsDataWrapperAggregation,
  cache?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  nodes: () => [],
  timeZone: 'UTC',
  transformation: () => ({}),
  aggregation: () => ({}),
  cache: true
});

const $edw = inject<any>('$edw');
const $fetchData = useFetchData({ tz: props.timeZone, cache: props.cache });
const $fetchAlarms = useFetchAlarms({ tz: props.timeZone });
const $writeDataToDl = useWriteDataToDl();

const data = ref<{ [name: string]: any }>({});
const alarms = ref<{ [name: string]: any }>({});

const loadingCount = ref<number>(0);
const loadingStart = () => { loadingCount.value += 1; };
const loadingStop = () => { loadingCount.value = Math.max(loadingCount.value - 1, 0); };
const loading = computed<boolean>(() => loadingCount.value > 0);

const error = ref<string | undefined>();
const clearError = () => { error.value = undefined; };

const updateInterval = computed<number>(() => {
  if (props.config.dataUpdateInterval == null) { return Utils.ms('5m'); }

  if (Utils.isNum(props.config.dataUpdateInterval)) {
    return Number(props.config.dataUpdateInterval);
  } else {
    return Utils.ms(props.config.dataUpdateInterval);
  }
});

const nodes = computed<CnsDataWrapperNode[]>(() => {
  let res = props.config.nodes?.filter
    ? new Fop().filter(props.config.nodes.filter).apply(props.nodes)
    : props.nodes;

  res = res
    .filter((node: CnsDataWrapperNode) => !!node)
    .map((node: CnsDataWrapperNode) => {
      return {
        ...node,
        id: node.nodeId ?? node.id,
        nodeId: undefined
      };
    });

  res = res.filter((node: CnsDataWrapperNode) => {
    return node.id != null;
  });

  return res;
});

const component = computed<Component | undefined>(() =>
  PREDEFINED_COMPONENTS[props.config.component as keyof PredefinedComponents]
);

// TODO: Implement this
// const transformationMethods = computed<CnsDataWrapperTransformation>(() => ({
//   ...props.transformation
// }));

const first: TidyAggregateNodeDataAggregator = (data, getValue) => data?.[0] ? getValue(data[0]) : undefined;
const last: TidyAggregateNodeDataAggregator = (data, getValue) => data?.[data.length - 1] ? getValue(data[data.length - 1]) : undefined;
const aggregationMethods = computed<CnsDataWrapperAggregation>(() => ({
  sum,
  min,
  max,
  average,
  count,
  first,
  last,
  ...props.aggregation
}));

async function loadData (keys: string[], force?: boolean) {
  const dataRequests = props.config.data;

  await Promise.all(keys.map(async (key: string) => {
    if (dataRequests?.[key] == null) { return; }

    let res: FetchDataResult = { data: [], isValid: false, isConfigured: false, unitOfMeasure: '' };
    if (nodes.value.length > 0) {
      const resByNodeId: { [key: string]: FetchDataResult } = {};
      await Promise.all(nodes.value.map(async (node: CnsDataWrapperNode) => {
        if (node.id == null) { return; }
        resByNodeId[node.id] = await $fetchData(node, dataRequests[key], force)
          .catch((err) => {
            console.error(err, node.id, dataRequests[key]);
            error.value = $edw.errorReadingData;
            return { data: [], isValid: false, isConfigured: false, unitOfMeasure: '' };
          });
      }));

      if (dataRequests[key].type !== 'parameter' || dataRequests[key]?.aggregation) {
        const dataByNode: { [key: string]: SeriesData } = {};
        Object.keys(resByNodeId).forEach((nodeId) => { dataByNode[nodeId] = resByNodeId[nodeId].data; });
        const tidyNodesData = DataUtils.tidyFormatDataByNode(dataByNode);
        let aggregation;
        if (dataRequests[key]?.aggregation && aggregationMethods.value[dataRequests[key].aggregation as string]) {
          aggregation = aggregationMethods.value[dataRequests[key].aggregation as string];
        } else { // default aggregations
          aggregation = aggregationMethods.value.sum;
        }
        const resData = DataUtils.tidyAggregateNodeData(
          tidyNodesData,
          aggregation,
          {
            groupBy: dataRequests[key].groupBy == null ? 'timestamp' : dataRequests[key].groupBy || undefined,
            decimals: dataRequests[key].decimals ?? dataRequests[key].nDec ?? 2
          }
        );

        let resIsValid = false;
        let resIsConfigured = false;
        let resUnitOfMeasure = '';
        Object.keys(resByNodeId).forEach((nodeId) => {
          // If at least one data source is valid, the result is valid
          resIsValid = resIsValid || resByNodeId[nodeId].isValid;

          // Same for the isConfigured flag
          resIsConfigured = resIsConfigured || resByNodeId[nodeId].isConfigured;

          // Take the first valid unit of measure
          if (!resUnitOfMeasure && resByNodeId[nodeId].unitOfMeasure) {
            resUnitOfMeasure = resByNodeId[nodeId].unitOfMeasure;
          }
        });

        res = {
          data: resData,
          isValid: resIsValid,
          isConfigured: resIsConfigured,
          unitOfMeasure: resUnitOfMeasure
        };
      } else {
        res = nodes.value[0]?.id
          ? resByNodeId[nodes.value[0]?.id]
          : { data: [], isValid: false, isConfigured: false, unitOfMeasure: '' };
      }
    }

    data.value[key] = res.data;
    data.value[`${key}IsValid`] = res.isValid;
    data.value[`${key}IsConfigured`] = res.isConfigured;
    data.value[`${key}UnitOfMeasure`] = res.unitOfMeasure;
  }));
}

async function loadAllData () {
  return loadData(Object.keys(props.config?.data || {}));
}

async function loadAlarms (keys: string[] /* , force?: boolean */) {
  const alarmsRequests = props.config.alarms;

  await Promise.all(keys.map(async (key: string) => {
    if (alarmsRequests?.[key] == null) { return; }

    let res;
    if (nodes.value.length > 0) {
      const resByNodeId: { [key: string]: SeriesData } = {};
      await Promise.all(nodes.value.map(async (node: CnsDataWrapperNode) => {
        if (node.id == null) { return; }
        resByNodeId[node.id] = await $fetchAlarms(node, alarmsRequests[key] /* , force */).catch((err) => {
          console.error(err, node.id, alarmsRequests[key]);
          error.value = $edw.errorReadingAlarms;
          return [];
        });
      }));

      res = [];
      Object.values(resByNodeId).forEach((nodeAlarms) => { res.push(...nodeAlarms); });
    }

    alarms.value[key] = res;
  }));
}

async function loadAllAlarms () {
  return loadAlarms(Object.keys(props.config?.alarms || {}));
}

const dataDaemon = new VisionUtils.Daemon(async (finish: () => void) => {
  await Promise.all([loadAllData(), loadAllAlarms()]);
  finish();
}, updateInterval.value);

let tickDataDaemonTo: ReturnType<typeof setTimeout>;
function tickDataDaemon () {
  clearTimeout(tickDataDaemonTo);
  tickDataDaemonTo = setTimeout(async () => {
    loadingStart();
    await dataDaemon.tickNow();
    loadingStop();
  }, 100);
}
// TODO: If the dataUpdateInterval is updated from props it's not applied until this component is destroied
// TODO: Maybe divide the data load from the alarms load

function getAction (action: CnsDataWrapperConfigAction): (arg: any) => Promise<void> {
  if (action.type === 'write-raw-dl') {
    let actionRunning = false;
    return async (value) => {
      if (actionRunning) { return; } // Avoid executing the action more than once at a time
      actionRunning = true;
      loadingStart();

      const valToWrite = action.value ?? value;
      await Promise.all(nodes.value.map(async (node: CnsDataWrapperNode) => {
        if (node.id == null) { return; }
        await $writeDataToDl(node, action, valToWrite).catch((err) => {
          console.error(err, node.id, action);
          error.value = $edw.errorSendingCommand;
        });
      }));

      if (Array.isArray(action.refetchData) && action.refetchData.length > 0) {
        const delay = Utils.isNum(action.refetchDelay) ? action.refetchDelay : 1000;
        if (delay) {
          await new Promise<void>((resolve) => setTimeout(() => { resolve(); }, delay));
        }
        await loadData(action.refetchData, true);
      }

      loadingStop();
      actionRunning = false;
    };
  } else {
    return async () => {};
  }
}

const componentProps = computed(() => {
  const res = { ...props.config.props };

  if (props.config.data != null) {
    Object.keys(props.config.data).forEach((key) => {
      const dataRequest = props.config.data?.[key];
      const resData = data.value[key] ?? [];
      const resIsValid = data.value[`${key}IsValid`] ?? false;
      const resIsConfigured = data.value[`${key}IsConfigured`] ?? true;
      const resUnitOfMeasure = data.value[`${key}UnitOfMeasure`] ?? '';

      if (dataRequest?.valueOnly) {
        if (parseInterval(dataRequest?.interval) === 'last') {
          lodash.set(res, key, resData[resData.length - 1]?.[1]);
        } else {
          lodash.set(res, key, resData.map?.((sample: DataSample) => sample[1]));
        }
      } else {
        lodash.set(res, key, resData);
      }
      lodash.set(res, `${key}IsValid`, resIsValid);
      lodash.set(res, `${key}IsConfigured`, resIsConfigured);
      lodash.set(res, `${key}UnitOfMeasure`, resUnitOfMeasure);
    });
  }

  if (props.config.alarms != null) {
    Object.keys(props.config.alarms).forEach((key) => {
      const alarmsRequests = props.config.alarms?.[key];
      const resAlarm = alarms.value[key] ?? [];

      if (alarmsRequests?.type === 'count') {
        lodash.set(res, key, resAlarm.length);
      } else {
        lodash.set(res, key, resAlarm);
      }
    });
  }

  if (props.config.actions != null) {
    Object.keys(props.config.actions).forEach((key) => {
      if (props.config.actions?.[key]) {
        // Expose the action on both key and onKey for better compatibility
        res[key] = getAction(props.config.actions[key]);
        res[`on${lodash.capitalize(key)}`] = res[key];
      }
    });
  }

  return res;
});

watch(() => props.nodes, () => { tickDataDaemon(); });
watch(() => props.config, () => { tickDataDaemon(); }, { deep: true });

onMounted(async () => {
  loadingStart();
  await dataDaemon.start();
  loadingStop();
});
onUnmounted(() => { dataDaemon.stop(); });
</script>

<template>
  <slot
    :name="props.config.component"
    v-bind="componentProps"
    :loading="loading"
    :error="error"
    :clear-error="clearError"
  >
    <slot
      v-bind="componentProps"
      :loading="loading"
      :error="error"
      :clear-error="clearError"
    >
      <component
        v-if="component"
        :is="component"
        v-bind="componentProps"
        :loading="loading"
        :error="error"
        :clear-error="clearError"
      />
    </slot>
  </slot>
</template>
