<script setup>
/* global HigJS */
import { ref, computed, nextTick, onMounted, watch, onUnmounted, inject } from 'vue';
import Highcharts from 'highcharts';
import _ from 'lodash';
import CnsDiv from '../cns/cns-div.vue';
import CnsIcon from '../cns/cns-icon.vue';

const $edw = inject('$edw');

const props = defineProps({
  series: { type: [Object, Array], default: function () { return {}; } }, // each parameter is a series, the param name is the series id
  data: { type: Object, default: function () { return {}; } }, // each parameter is the data of a series, data is in this format: [ [ utc1, data1 ], [ utc2, data2 ], .... ]
  yAxis: { type: [Object, Array], default: undefined },
  xAxis: { type: [Object, Array], default: undefined },
  options: { type: Object, default: function () { return {}; } },
  debounce: { type: [Number, Boolean], default: 0 },
  local: { type: Boolean, default: false },
  cleanOnUpdate: { type: Boolean, default: false },
  syncWith: { type: Array, default: undefined },
  hideResetZoomButton: { type: Boolean, default: false },
  modules: { type: Array, default: undefined },
  loading: { type: Boolean, default: false }
});

let chart;
const container = ref();
const updating = ref(false);

const DEFAULTS = {
  series: {
    states: {
      inactive: {
        opacity: 0.7
      }
    }
  },
  yAxis: {
    labels: {
      style: { color: 'currentColor' },
      formatter: function () { return HigJS.num.format(this.value) + (this.axis?.userOptions?.um ? ' ' + this.axis?.userOptions?.um : ''); }
    },
    title: {
      text: '',
      style: { color: 'currentColor' },
      margin: 3
    },
    lineColor: 'var(--hig-page-text-muted)',
    tickColor: 'var(--hig-page-text-muted)',
    gridLineColor: 'var(--hig-page-text-muted)'
  },
  xAxis: {
    type: 'datetime',
    labels: {
      style: { color: 'currentColor' }
    },
    title: {
      text: '',
      style: { color: 'currentColor' },
      margin: 3
    },
    lineColor: 'var(--hig-page-text-muted)',
    tickColor: 'var(--hig-page-text-muted)',
    gridLineColor: 'var(--hig-page-text-muted)',
    dateTimeLabelFormats: { month: '%e. %b', year: '%b' },
    minRange: 1,
    crosshair: {
      enabled: true,
      color: 'var(--hig-page-text-muted)'
    },
    endOnTick: false
  },
  options: {
    chart: {
      zoomType: 'xy',
      backgroundColor: 'none',
      events: {
        selection: function (e) {
          if (syncWith.value.length === 0) { return; }
          let extremes = { min: null, max: null };
          if (!e.resetSelection) {
            extremes = { min: e.xAxis?.[0]?.min, max: e.xAxis?.[0]?.max };
          }

          syncWith.value.forEach((otherChart) => {
            otherChart.setExtremes(extremes.min, extremes.max);
          });
        }
      }
    },
    title: { text: '', style: { color: 'currentColor' } },
    subtitle: { text: '', style: { color: 'currentColor' } },
    credits: { enabled: false },
    plotOptions: {
      line: { lineWidth: 2 },
      spline: { lineWidth: 2 },
      column: { borderWidth: 0 },
      pie: { borderWidth: 0 },
      series: {
        label: { connectorAllowed: false },
        marker: {
          enabled: false,
          symbol: 'circle',
          states: {
            hover: {
              radiusPlus: 1
            }
          }
        },
        states: {
          hover: {
            halo: {
              size: 0
            }
          }
        }
      }
    },
    legend: {
      itemStyle: { color: 'currentColor' },
      itemHoverStyle: { color: 'currentColor' }
    },
    accessibility: { enabled: false }
  }
};

const data = computed(() => {
  return props.data;
});

const dataInfo = computed(() => {
  const data = Object.values(props.data ?? {});
  return {
    // check if there is at least a serie with one or more points
    dataExist: !!data?.find(serie => (serie?.length ?? 0) > 0),
    // check if there is at least a serie with at least one valid point
    dataIsValid: !!data?.find(serie => Array.isArray(serie) && serie?.find(point => (Array.isArray(point) ? point[1] : point) != null) != null)
  };
});

const series = computed(() => {
  const ret = [];

  for (const id in props.series) {
    if (props.series[id].disabled) { continue; }
    const curSeries = _.cloneDeep(props.series[id]);

    if (curSeries.id == null) { curSeries.id = id; }
    if (curSeries.um != null) {
      if (curSeries.tooltip == null) { curSeries.tooltip = {}; }
      curSeries.tooltip.valueSuffix = curSeries.um;
    }
    ret.push(_.merge(_.cloneDeep(DEFAULTS.series), curSeries));
  }

  return ret;
});

const yAxis = computed(() => {
  let res = [];
  if (props.yAxis != null) {
    res = Array.isArray(props.yAxis) ? props.yAxis : [props.yAxis];
  } else {
    // If the yAxis is undefined create a axis for every different um specified in the series
    const ums = new Set();
    let addDefaultAxis = false;
    series.value.forEach((series) => {
      if (series.um) {
        ums.add(series.um);
        series.yAxis = series.um;
      } else {
        addDefaultAxis = true;
        series.yAxis = 'defaultY';
      }
    });

    if (ums.size > 0) {
      ums.forEach((um) => {
        res.push({ id: um, um });
      });
    }

    if (addDefaultAxis) {
      res.push({ id: 'defaultY' });
    }
  }

  return res.map((yAxe) => _.merge(_.cloneDeep(DEFAULTS.yAxis), yAxe));
});

const xAxis = computed(() => {
  let res = [];
  if (props.xAxis != null) {
    res = Array.isArray(props.xAxis) ? props.xAxis : [props.xAxis];
  } else {
    res.push({ id: 'defaultX' });
  }

  return res.map((xAxe) => _.merge(_.cloneDeep(DEFAULTS.xAxis), xAxe));
});

const options = computed(() => {
  return _.merge(
    _.cloneDeep(DEFAULTS.options),
    {
      colors: props.options?.boost // The boost module cannot use css variables as colors
        ? [
            '#36C5E9', '#F37C06', '#D82CD5', '#299821', '#6A3D9A',
            '#FFC71F', '#6E3816', '#75D6AC', '#106514', '#FFA2A0',
            '#0866D2', '#DC3545', '#CBA5DD', '#92CE39', '#7068BC',
            '#06A8C9', '#5E4C58', '#AB0003', '#180AB4', '#D1A401'
          ]
        : [...Array(20)].map((_, i) => `var(--hig-color-${i + 1})`)
    },
    props.options,
    { time: { useUTC: !props.local } }
  );
});

const syncWith = computed(() => {
  if (!Array.isArray(props.syncWith)) { return []; }
  return props.syncWith.filter((chart) => chart != null);
});

watch(data, () => { updateConf('data'); }, { deep: true });
watch(series, () => { updateConf('series'); }, { deep: true });
watch(yAxis, () => { updateConf('yAxis'); }, { deep: true });
watch(xAxis, () => { updateConf('xAxis'); }, { deep: true });
watch(options, () => { updateConf('options'); }, { deep: true });

let confUpdateTo;
let confToUpdate = {};
function updateConf (type, immediate) {
  if (!chart) { return; }

  updating.value = true;
  confToUpdate[type] = true;

  clearTimeout(confUpdateTo);
  if (immediate || props.debounce === false) {
    updateConf.apply();
  } else {
    confUpdateTo = setTimeout(() => updateConf.apply(), props.debounce === true ? 0 : props.debounce);
  }
}
updateConf.apply = function () {
  if (!chart) { return; }

  if (confToUpdate.options) { chart.update(options.value, false, false); }
  if (confToUpdate.yAxis) {
    if (props.cleanOnUpdate) chart.update({ yAxis: [] }, false, true);
    chart.update({ yAxis: yAxis.value }, false, true);
  }
  if (confToUpdate.xAxis) {
    if (props.cleanOnUpdate) chart.update({ xAxis: [] }, false, true);
    chart.update({ xAxis: xAxis.value }, false, true);
  }
  if (confToUpdate.series) {
    if (props.cleanOnUpdate) chart.update({ series: [] }, false, true);
    chart.update({ series: series.value }, false, true);
  }
  if (confToUpdate.data) {
    for (const id in data.value) {
      const curSeries = chart.get(id);
      if (curSeries) {
        curSeries.setData(_.cloneDeep(data.value[id]), true);
      }
    }
  }
  redraw();

  updating.value = false;
  confToUpdate = {};
};

let preventHighlight = false;
function containerOnMousedown () {
  preventHighlight = true;
};

function bodyOnMouseup () {
  preventHighlight = false;
};

function containerOnHover (e) {
  if (preventHighlight) { return; }
  if (syncWith.value.length === 0) { return; }

  const event = chart.pointer.normalize(e);
  const coordinates = chart.pointer.getCoordinates(event);
  syncWith.value.forEach((otherChart) => {
    otherChart.onHover(coordinates.xAxis?.[0]?.value);
  });
}

function containerOnMouseout () {
  if (preventHighlight) { return; }
  if (syncWith.value.length === 0) { return; }

  syncWith.value.forEach((otherChart) => {
    otherChart.resetHover();
  });
}

onMounted(() => {
  nextTick(() => {
    if (!container.value) { return; }

    if (Array.isArray(props.modules)) {
      props.modules.forEach((m) => m(Highcharts));
    }

    chart = Highcharts.chart(container.value, {
      ...options.value,
      series: series.value,
      yAxis: yAxis.value,
      xAxis: xAxis.value
    });

    container.value.addEventListener('mousedown', containerOnMousedown);
    document.body.addEventListener('mouseup', bodyOnMouseup);
    container.value.addEventListener('mousemove', containerOnHover);
    container.value.addEventListener('touchmove', containerOnHover);
    container.value.addEventListener('touchstart', containerOnHover);
    container.value.addEventListener('mouseleave', containerOnMouseout);
    container.value.addEventListener('touchend', containerOnMouseout);

    updateConf('data', true);
  });
});

onUnmounted(() => {
  if (chart) { chart.destroy(); }

  container.value?.removeEventListener('mousedown', containerOnMousedown);
  document.body.removeEventListener('mouseup', bodyOnMouseup);
  container.value?.removeEventListener('mousemove', containerOnHover);
  container.value?.removeEventListener('touchmove', containerOnHover);
  container.value?.removeEventListener('touchstart', containerOnHover);
  container.value?.removeEventListener('mouseleave', containerOnMouseout);
  container.value?.removeEventListener('touchend', containerOnMouseout);
});

function redraw () {
  if (!chart) { return; }

  chart.redraw();
  // toggleLegend();
}

function reflow () {
  if (!chart) { return; }

  chart.reflow();
}

function setExtremes (min, max) {
  if (!chart) { return; }

  chart.xAxis[0]?.setExtremes(min, max, undefined, false);
}

let resetHoverTo;
function resetHover () {
  chart.tooltip.update({ shared: options.value?.tooltip?.shared });
  // the timeout fixes the bug for which, when switching hover from one chart to another, the tooltip freezes for some time
  clearTimeout(resetHoverTo);
  resetHoverTo = setTimeout(() => {
    chart.series.forEach(function (series) {
      series.points.forEach(function (point) {
        if (point.state === 'hover') { point.setState(); }
      });
      series.update({ marker: { enabled: false } }); // force disable marker on sync chart when exiting hover area
    });
  }, 200);
  chart.tooltip.hide();
  chart.xAxis[0]?.hideCrosshair();
}

function onHover (x) {
  if (!chart || !chart.series || x == null) { return; }

  // Force shared tooltip
  if (chart.tooltip.options.shared !== true) {
    chart.tooltip.update({ shared: true });
  }

  const points = [];
  chart.series.forEach((s) => {
    let point = getSeriesClosestPointByX(s, x);
    if (s.boosted && point) {
      point = s.boost.getPoint(point);
    }

    if (point) { points.push(point); }
  });

  if (points.length === 0) { return; }
  points.forEach((point) => point.setState?.()); // Workaround to forcefully trigger the hover effect.
  chart.tooltip.refresh(points);
  chart.xAxis[0]?.drawCrosshair({ chartX: points[0].plotX, chartY: points[0].plotY }, points[0]);
}

function getSeriesClosestPointByX (series, x) {
  if (!series || !series.points) { return; }

  let closestPoint;
  for (const point of series.points) {
    if (closestPoint == null || Math.abs(point.x - x) < Math.abs(closestPoint.x - x)) {
      closestPoint = point;
    } else {
      break;
    }
  }

  return closestPoint;
}

function resetZoom () {
  if (!chart) { return; }
  chart.zoomOut();
}

defineExpose({
  redraw,
  reflow,
  setExtremes,
  syncCursor: onHover, // deprecated
  onHover,
  resetHover,
  resetZoom
});
</script>

<template>
  <div v-if="dataInfo" class="cns-data-chart w-100 h-100" :class="{ 'no-reset-zoom-button': props.hideResetZoomButton }">
    <div v-show="dataInfo.dataExist && dataInfo.dataIsValid && !props.loading" ref="container" class="w-100 h-100 cns-data-chart"></div>
    <cns-div v-if="!dataInfo.dataExist || !dataInfo.dataIsValid || props.loading" class="h-100" :loading="props.loading" placeholder="##">
      <div class="w-100 h-100 py-5 d-flex align-items-center justify-content-center gap-2">
        <cns-icon type="chart-simple lg" />  {{ dataInfo.dataExist === false ? $edw.noData : $edw.invalidData }}
      </div>
    </cns-div>
  </div>
</template>

<style scoped>
.cns-data-chart.no-reset-zoom-button :deep(.highcharts-reset-zoom) {
  display: none;
}
</style>
