<script setup>
import { inject, computed } from 'vue';
import CnsAlert from './cns-alert.vue';
import { HigError } from '../../libs/hig-error/index.mjs';

const props = defineProps({
  error: { type: HigError, default: undefined },
  actions: { type: Array, default: undefined }
});

const $edw = inject('$edw');

const variant = computed(() => props.error?.extensions?.warning ? 'warning' : 'danger');
const icon = computed(() => props.error?.extensions?.warning ? 'triangle-exclamation' : 'circle-exclamation');
const desc = computed(() => props.error?.extensions?.userError ? $edw.translateError(props.error) : $edw.UnidentifiedError);
</script>

<template>
  <cns-alert v-if="props.error" :variant="variant" :icon="icon" :actions="props.actions">{{ desc }}</cns-alert>
</template>
