<script setup>
import { ref, computed, watch } from 'vue';

const props = defineProps({
  modelValue: { type: [String, Number, Array], default: undefined },
  options: { type: Array, default: function () { return []; } },
  variant: { type: String, default: 'outline-primary' },
  size: { type: String, default: undefined },
  multiple: { type: Boolean, default: false },
  readonly: { type: Boolean, default: false }
});

const emits = defineEmits(['update:modelValue']);

const classes = computed(() => [
  'btn-group',
  'w-100',
  {
    ['btn-group-' + props.size]: !!props.size
  }
]);

const active = ref({});

watch(() => [props.modelValue, props.multiple], () => {
  const tmpActive = {};
  if (props.multiple) {
    if (Array.isArray(props.modelValue)) {
      props.modelValue.forEach((val) => {
        tmpActive[val] = true;
      });
    }
  } else {
    tmpActive[props.modelValue] = true;
  }
  active.value = tmpActive;
}, { immediate: true });

function optionClick (val) {
  if (props.multiple) {
    active.value[val] = !active.value[val];
    emits('update:modelValue', Object.keys(active.value).filter((val) => active.value[val]));
  } else {
    active.value = { [val]: true };
    emits('update:modelValue', val);
  }
}
</script>

<template>
  <div :class="classes" role="group">
    <button v-for="opt in options" :key="opt.value" :disabled="opt.disabled || props.readonly" class="btn" :class="['btn-' + props.variant, { 'active': active[opt.value] }]" @click="optionClick(opt.value)">
      <slot :name="opt.value + '-option'" :value="opt.value" :text="opt.text" :active="active[opt.value]">
        <slot name="option" :value="opt.value" :text="opt.text" :active="active[opt.value]">
          {{opt.text}}
        </slot>
      </slot>
    </button>
  </div>
</template>

<style scoped>
</style>
